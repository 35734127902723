
import { DeviceTypeEntityModel, DeviceTypeQueryModel, ImportJsonEntityModel } from '@common-src/entity-model/device-type-entity';
import { SelectDeviceModel } from '@common-src/entity-model/device-entity';
import { IResponseFilterData } from '@common-src/interface/reponse-data.interface';
import { ICRUDQ } from '@common-src/model/interface';
import http from '@common-src/service3/http/axios';
import { get, post, del, download } from './request';

const URL_PATH = `${IOT_BASE_REQUEST_PATH}/deviceType`;

class DeviceTypeService implements ICRUDQ<DeviceTypeEntityModel, DeviceTypeQueryModel> {
    async create(model: DeviceTypeEntityModel):Promise<DeviceTypeEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<DeviceTypeEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new DeviceTypeEntityModel().toModel(res);
    }

    async update(model: DeviceTypeEntityModel):Promise<DeviceTypeEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: DeviceTypeEntityModel):Promise<DeviceTypeEntityModel> {
        const url = `${URL_PATH}/delete/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query: DeviceTypeQueryModel, page?: number, limit?: number):Promise<{ total: number, items: Array<DeviceTypeEntityModel> }> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new DeviceTypeEntityModel().toModel(item));
        return res;
    }

    async addFeature(params):Promise<any> {
        const url = `${URL_PATH}/addFeature`;
        const res = await post(url, params);
        return res;
    }

    // async deleteFeature(model: {id: string}, deviceTypeId: string):Promise<any> {
    //     const url = `${URL_PATH}/delFeature/${model.id}/${deviceTypeId}`;
    //     const res = await del(url);
    //     return res;
    // }

    async getDeviceTypeForLinkDevice(parentId: string):Promise<any> {
        const res = await get(`${IOT_BASE_REQUEST_PATH}/product/getSupportTypes/${parentId}`);
        // let deviceTypeList = _.get(res, 'supportedTypeIdList');
        // if (!deviceTypeList || deviceTypeList.length === 0) {
        //     deviceTypeList = _.get(res, 'deviceTypeList');
        // }
        // return _.map(deviceTypeList, item => {
        //     return {
        //         name: item.name,
        //         value: item.id,
        //         type: item.type
        //     };
        // });
        return res;
    }

    async getDeviceTypeAttributeList():Promise<Array<any>> {
        const url = `${URL_PATH}/getDeviceTypeAttrList`;
        const res = await get(url);
        return _.map(_.filter(res, item => item.values && item.values.length > 0), item => new SelectDeviceModel().toModel(item));
    }
    async importDeviceTypesByJsonFile(importModel:any) {
        const url = `${IOT_BASE_REQUEST_PATH}/product/importByJson`;
        const params = importModel.toService();
        const res = await post(url, params, { headers: { 'content-type': 'multipart/form-data' }, timeout: 600000 });
        return res;
    }

    async exportDeviceTypeJson(exportIds:any) {
        const url = `${IOT_BASE_REQUEST_PATH}/product/exportByJson`;
        const res = await post(url, exportIds);
        return res;
    }

    async getDeviceTypeForFacilityType(query: DeviceTypeQueryModel, page?: number, limit?: number):Promise<{ total: number, items: Array<DeviceTypeEntityModel>}> {
        const url = `${URL_PATH}/facilityTypeRelationList`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new DeviceTypeEntityModel().toModel(item));
        return res;
    }

    async syncDeviceTypeAttribute(deviceTypeId: string):Promise<any> {
        const url = `${IOT_BASE_REQUEST_PATH}/product/attr/sync/${deviceTypeId}`;
        const res = await post(url);
        return res;
    }

    async getDeviceTypeClientScope(deviceTypeId: string):Promise<any> {
        if (!deviceTypeId) {
            return;
        }
        const url = `${URL_PATH}/${deviceTypeId}/attrs/CLIENT_SCOPE`;
        const res = await get(url);
        return _.map(res, item => ({
            title: `${_.get(item, 'jsonValue.name')} | ${_.get(item, 'attributeKey')}`,
            value: `${_.get(item, 'attributeKey')}|${_.get(item, 'jsonValue.type')}`
        }));
    }

    async getDeviceTypeEvent(deviceTypeId: string):Promise<any> {
        if (!deviceTypeId) {
            return;
        }
        const url = `${URL_PATH}/${deviceTypeId}/events`;
        const res = await get(url);
        return _.map(res, item => ({
            title: `${_.get(item, 'name')} | ${_.get(item, 'identifier')}`,
            value: _.get(item, 'identifier')
        }));
    }

    async updateGroup(productId: string, groupId: string) {
        const url = `${IOT_BASE_REQUEST_PATH}/product/groups/update/${productId}`;
        const res = await post(url, { groupId });
        return res;
    }

    async currentlySynchronizableEdgeNum() {
        const url = `${IOT_BASE_REQUEST_PATH}/product/sync/currently-synchronizable-number`;
        const res = await get(url);
        return res;
    }

    async statisticSyncList(filter?: any, page?: number, pageSize?: number) {
        const params = Object.assign({}, (filter as any)?.toService?.() || filter, { page, limit: pageSize });
        const res = await http.request<IResponseFilterData<any>>({
            url: `${IOT_BASE_REQUEST_PATH}/product/sync/statistics`,
            method: 'POST',
            params
        });
        return res;
    }

    async statisticSyncDoneList(filter?: any, page?: number, pageSize?: number) {
        const params = Object.assign({}, (filter as any)?.toService?.() || filter, { page, limit: pageSize });
        const res = await http.request<IResponseFilterData<any>>({
            url: `${IOT_BASE_REQUEST_PATH}/product/sync/doneList`,
            method: 'POST',
            params
        });
        return res;
    }

    async syncToDoList(filter?: any, page?: number, pageSize?: number) {
        const params = Object.assign({}, (filter as any)?.toService?.() || filter, { page, limit: pageSize });
        const res = await http.request<IResponseFilterData<any>>({
            url: `${IOT_BASE_REQUEST_PATH}/product/sync/todoList`,
            method: 'POST',
            params
        });
        return res;
    }

    async syncUpgradeCheck() {
        const url = `${IOT_BASE_REQUEST_PATH}/product/sync/upgrade/check`;
        const res = await post(url);
        res.items = _.map(res.items, (item, index) => ({ index: index + 1, ...item }));
        return res;
    }

    async syncEdge(productIds: string[]) {
        const url = `${IOT_BASE_REQUEST_PATH}/product/sync/edge`;
        const res = await post(url, productIds);
        return res;
    }

    async syncDriverUpdateEdge(params) {
        const url = `${IOT_BASE_REQUEST_PATH}/product/sync/driver/update`;
        const res = await post(url, params);
        return res;
    }

    async batchChangeGroup(ids: Array<string>, groupId: string) {
        const url = `${IOT_BASE_REQUEST_PATH}/product/group/${groupId}`;
        const res = await post(url, ids);
        return res;
    }
}

export default new DeviceTypeService();
