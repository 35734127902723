export const DEFAULT_THEME = {
    darkMode: false,
    categoryAxis: {
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: { show: false }
    },
    valueAxis: {
        axisLine: { show: false }
    },
    line: {
        smooth: false,
        symbol: 'circle',
        symbolSize: 4,
        showSymbol: true,
        lineStyle: {
            width: 1
        }
    },
    bar: {
        barMaxWidth: 40
    },
    ring: {
        radius: ['32%', '40%']
    },
    legend: {
        textStyle: {
            color: '#000'
        },
        icon: 'circle'
    },
    grid: {
        containLabel: true,
        left: 20,
        right: 20
    }
};

export const DEFAULT_COLORS = [
    '#43a4fd',
    '#ffc600',
    '#00fcff',
    '#fc8e58',
    '#25e368',
    '#e39025',
    '#fc5858',
    '#25c6e3',
    '#c592f1',
    '#7787ff'
];

export const HEAT_MAP_COLOR = [
    '#313695', '#4575b4', '#74add1',
    '#abd9e9', '#e0f3f8', '#ffffbf',
    '#fee090', '#fdae61', '#f46d43',
    '#d73027', '#a50026'
];

export const HEAT_BMAP_COLOR = [
    'blue', 'blue', 'green',
    'yellow', 'red'
];

export const itemPoint = (color) => {
    return [
        '<span style="',
        `background-color:${color};`,
        'display: inline-block;',
        'width: 10px;',
        'height: 10px;',
        'border-radius: 50%;',
        'margin-right:2px;',
        '"></span>'
    ].join('');
};

export const STATIC_PROPS = [
    'initOptions', 'loading', 'dataEmpty',
    'judgeWidth', 'widthChangeDelay'
];

export const ECHARTS_SETTINGS = [
    'grid', 'dataZoom', 'visualMap',
    'toolbox', 'title', 'legend',
    'xAxis', 'yAxis', 'radar',
    'tooltip', 'axisPointer', 'brush',
    'geo', 'timeline', 'graphic',
    'series', 'backgroundColor', 'textStyle'
];
