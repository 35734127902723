import { BaseModel } from './base-model';

export class AttributeConfig extends BaseModel {
    constructor(name?: string, value?: any) {
        super();
        this.name = name;
        this.value = value;
    }
    name: string = undefined;
    value: any = undefined;

    /**
     * 设置的设备属性key
     */
    deviceKey: string = undefined;

    /**
     * 属性类型
     */
    attributeType: string = undefined;

    /**
     * 固定格式
     * ${deviceId}|${key}
     */
    get DeviceIdAndKey(): string {
        if (this.deviceId && this.deviceKey) {
            return `${this.deviceId}|${this.deviceKey}|${this.attributeType}`;
        }
        return undefined;
    }
    set DeviceIdAndKey(val: string) {
        const valArray = val.split('|');
        if (Array.isArray(valArray) && valArray.length === 3) {
            this.deviceId = valArray[0];
            this.deviceKey = valArray[1];
            this.attributeType = valArray[2];
        }
    }

    /**
     * 设备id
     */
    deviceId: string = undefined;

    /**
     * 设备名称
     */
    deviceName: string = undefined;

    /**
     * 是否关联设备
     */
    hasDevice: boolean = false;

    toModel(json): any {
        this.name = _.get(json, 'attributeName') || _.get(json, 'name');
        this.value = _.get(json, 'value');
        if (_.get(json, 'linkDevice')) {
            this.deviceId = _.get(json, 'linkedDeviceInfo.deviceId') || _.get(json, 'deviceId') || _.get(json, 'entityId');
            this.deviceName = _.get(json, 'linkedDeviceInfo.deviceName') || _.get(json, 'deviceName') || _.get(json, 'entityName');
            this.deviceKey = _.get(json, 'linkedDeviceInfo.keys') || _.get(json, 'deviceKey') || _.get(json, 'attrName');
            this.attributeType = _.get(json, 'linkedDeviceInfo.linkedType');
            this.hasDevice = true;
        } else {
            this.hasDevice = false;
        }
        return this;
    }

    toService() {
        const data: any = {
            attributeName: this.name,
            linkDevice: this.hasDevice
        };
        if (this.hasDevice) {
            data.linkedDeviceInfo = {
                deviceId: this.deviceId,
                keys: this.deviceKey,
                linkedType: this.attributeType
            };
        }
        return data;
    }

    static getTableColumns(listLength: number, hasAddOrDelete: boolean = false) {
        const tableColumns: any = [
            {
                dataIndex: 'hasDevice',
                scopedSlots: { customRender: 'setting' },
                width: 215
            },
            {
                dataIndex: 'device.id',
                scopedSlots: { customRender: 'device' },
                width: 300
            }
        ];
        if (hasAddOrDelete || listLength > 1) {
            tableColumns.unshift({
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' },
                width: 200
            });
        }
        if (hasAddOrDelete) {
            tableColumns.push({
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 80
            });
        }
        return tableColumns;
    }
}
