















import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import { DataParamModel } from '@common-src/entity-model/data-param-entity';

@Component
export default class DataParamFormComponent extends BaseComponent {
    @Prop()
    value: Array<DataParamModel>;
    @Prop()
    disabled: boolean;

    editClick(dataParam: DataParamModel, index: number) {
        this.$emit('edit', { dataParam, index });
    }
    deleteClick(index: number) {
        this.$emit('delete', index);
    }
    valueChange() {
        this.$emit('input', this.value);
        this.$emit('change');
    }
}

