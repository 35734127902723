



















import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import CommonService from '@common-src/service/common';

@Component
export default class JTLSelectPeopleComponent extends BaseComponent {
    /**
     * 正在加载标识
     */
    loading: boolean = false;

    /**
     * 当前选中值
     */
    @Prop()
    value: string;

    /**
     * 所有可选项
     */
    selectOptions: Array<{ name: string, value: string }> = null;

    created() {
        this.loading = true;
        CommonService.getOrgUserWithList()
            .then(res => {
                this.selectOptions = res;
            })
            .catch(err => {
                $log.error(err);
            })
            .finally(() => {
                this.loading = false;
            });
    }

    get SelectValue() {
        return this.value;
    }

    set SelectValue(val: string) {
        this.$emit('input', val);
    }
}
