import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel, FormControlUploadModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { getCityJson } from '@common-src/service/common';

export class ProjectEntityModel extends BaseEntityModel {
    @FormControl({
        label: 'GUID',
        type: FormControlType.TEXT,
        readonly: true,
        addHidden: true
    } as FormControlTextModel)
    guid: string = undefined;

    @FormControl({
        label: '项目名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '项目代码',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    code: string = undefined;

    @FormControl({
        label: '所属地区',
        type: FormControlType.CASCADER_CITYS,
        optionsPromise: getCityJson,
        required: true
    } as FormControlOptionModel)
    citys: Array<any> = undefined;

    @FormControl({
        label: '详细地址',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    address: string = undefined;

    @FormControl({
        label: '建筑面积',
        type: FormControlType.NUMBER,
        message: 'm²',
        noMessageTooltip: true
    } as FormControlTextModel)
    area: string = undefined;

    @FormControl({
        label: '项目介绍',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    remark: string = undefined;

    @FormControl({
        label: '域名',
        type: FormControlType.TEXT,
        placeholder: '请输入域名，http(s)://'
    } as FormControlTextModel)
    domain: string = undefined;

    @FormControl({
        label: '项目logo',
        type: FormControlType.UPLOAD,
        listType: 'picture-card',
        showUploadList: true,
        notUploadOss: true,
        uploadUrl: '/infra-common/api/filestorage/upload',
        message: '建议大小为: 大小不大于1M，格式：png、jpg',
        maxFileNumber: 1
    } as FormControlUploadModel)
    logo: any = undefined;
    permission: boolean = undefined;

    userCount: number = undefined;
    loginBg: string = undefined;
    wxLoginBg: string = undefined;
    wxBanner: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '项目人数',
                dataIndex: 'userCount'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    get DefaultLogo() {
        return _.get(this.logo, '[0].url');
    }

    toModel(json): any {
        super.toModel(json);
        this.guid = this.id;
        this.citys = [_.get(json, 'prov'), _.get(json, 'city'), _.get(json, 'district')];
        const jsonLogo = _.get(json, 'logo');
        if (jsonLogo) {
            if (Array.isArray(jsonLogo)) {
                this.logo = jsonLogo;
            } else {
                this.logo = [{ url: _.get(json, 'logo'), type: 'image' }];
            }
        } else {
            this.logo = [];
        }
        return this;
    }

    toService() {
        const data: any = super.toService();
        data.prov = _.get(this.citys, '[0]');
        data.city = _.get(this.citys, '[1]');
        data.district = _.get(this.citys, '[2]');
        if (this.logo) {
            data.logo = _.get(this.logo, '[0].url');
        } else {
            data.logo = '';
        }
        delete data.citys;
        return data;
    }
}

export class ProjectQueryModel extends QueryPageModel {
    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT,
        span: 8
    })
    params: string = undefined;

    toService() {
        const data: any = super.toService();
        return data;
    }
}
