var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "things-auto-complete-component" },
    [
      _vm.viewMode === _vm.ViewModeType.UPDATE
        ? _c("a-input", {
            attrs: { placeholder: _vm.placeholder, disabled: _vm.disabled },
            on: { change: _vm.onChange },
            model: {
              value: _vm.autoText,
              callback: function ($$v) {
                _vm.autoText = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "autoText",
            },
          })
        : _c(
            "a-auto-complete",
            {
              attrs: {
                placeholder: _vm.placeholder,
                disabled: _vm.disabled,
                "option-label-prop": "title",
              },
              on: {
                select: _vm.onSelect,
                search: _vm.onSearch,
                change: _vm.onChange,
              },
              model: {
                value: _vm.autoText,
                callback: function ($$v) {
                  _vm.autoText = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "autoText",
              },
            },
            [
              _c("a-input-search", {
                attrs: { placeholder: _vm.placeholder, loading: _vm.loading },
                model: {
                  value: _vm.autoText,
                  callback: function ($$v) {
                    _vm.autoText = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "autoText",
                },
              }),
              _c(
                "template",
                { slot: "dataSource" },
                _vm._l(_vm.dataSource, function (item) {
                  return _c(
                    "a-select-option",
                    { key: item.name, attrs: { title: item.name } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(item.name) +
                          " | " +
                          _vm._s(item.identifier) +
                          " "
                      ),
                      _c("jtl-things-function-label-component", {
                        attrs: { "function-type": _vm.formModel.functionType },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }