import { AlarmRuleType, AlarmRuleStatus } from '@common-src/model/enum';
const alarmRuleTypeList: Array<{ name: string, value: AlarmRuleType }> = _.filter(_.map(AlarmRuleType, item => {
    let name: string = null;
    switch (item) {
        case AlarmRuleType.EVENT:
            name = '事件告警';
            break;
        case AlarmRuleType.THRESHOLD:
            name = '阈值告警';
            break;
    }
    return { name, value: item };
}), item => item.name) as any;
const alarmRuleTypeFilter = (val: AlarmRuleType) => {
    return _.get(_.find(alarmRuleTypeList, item => item.value === val), 'name') || '-';
};

const alarmRuleStatusList: Array<{ name: string, value: AlarmRuleStatus }> = _.filter(_.map(AlarmRuleStatus, item => {
    let name: string = null;
    switch (item) {
        case AlarmRuleStatus.WORKING:
            name = '运行中';
            break;
        case AlarmRuleStatus.NOT_START:
            name = '未启动';
            break;
    }
    return { name, value: item };
}), item => item.name) as any;
const alarmRuleStatusFilter = (val: AlarmRuleStatus) => {
    return _.get(_.find(alarmRuleStatusList, item => item.value === val), 'name') || '-';
};

export {
    alarmRuleTypeFilter,
    alarmRuleStatusFilter,
    alarmRuleTypeList,
    alarmRuleStatusList
};
