import { DeviceStatus } from '@common-src/model/enum';

const deviceStatusList: Array<{ name: string, value: DeviceStatus }> = _.filter(_.map(DeviceStatus, item => {
    let name: string = null;
    switch (item) {
        case DeviceStatus.ONLINE:
            name = '在线';
            break;
        case DeviceStatus.OFFLINE:
            name = '离线';
            break;
        // case DeviceStatus.ACTIVE:
        //     name = '激活';
        //     break;
        // case DeviceStatus.DISACTIVE:
        //     name = '未激活';
        //     break;
        // case DeviceStatus.ENABLE:
        //     name = '启用';
        //     break;
        // case DeviceStatus.DISABLE:
        //     name = '禁用';
        //     break;
    }
    return { name, value: item };
}), item => item.name) as any;

const deviceStatusFilter = (val: DeviceStatus) => {
    return _.get(_.find(deviceStatusList, item => item.value === val), 'name') || '-';
};

export {
    deviceStatusFilter,
    deviceStatusList
};
