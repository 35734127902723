

















































































import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import DeviceService from '@common-src/service/device';
import DeviceTypeService from '@common-src/service/device-type';
import { SelectDeviceModel, SelectDeviceAttributeRadioModel, SelectDeviceAttributeModel } from '@common-src/entity-model/device-entity';
import { FormControlSelectDeviceAttributeType } from '@common-src/model/enum';
import FacilityConfigTypeService from '@common-src/service/facility-config-type';

@Component
export default class SelectDeviceAttributeComponent extends BaseComponent {
    allDeviceAttributeList: Array<SelectDeviceModel> = null;
    filterDeviceAttributeList: Array<SelectDeviceModel> = null;
    sourceLoading: boolean = false;

    @Prop()
    value: Array<SelectDeviceAttributeModel | SelectDeviceAttributeRadioModel> | Array<string> | string;

    /**
     * 每个select是否能多选
     */
    @Prop({ default: 'default' })
    mode: string;

    @Prop()
    scopeId: string;

    @Prop()
    identifier: string;

    /**
     * 是否有多个属性
     */
    @Prop({ default: false })
    multiple: boolean;

    @Prop()
    selectType: FormControlSelectDeviceAttributeType;

    /**
     * 设施属性里关联设备属性的范围
     */
    @Prop()
    linkedDeviceAttributeList: Array<SelectDeviceModel>;

    hasParam: boolean = false;
    selectAttribute: Array<string> | string = null;

    get ShowLoadMore() {
        return this.allDeviceAttributeList && this.optionPage * 10 < this.allDeviceAttributeList.length;
    }

    optionPage: number = 1;

    get FilterDeviceAttributeList() {
        // return this.filterDeviceAttributeList;
        let filterList = [];
        if (this.multiple) {
            return this.filterDeviceAttributeList;
        } else {
            const top10List = _.take(this.filterDeviceAttributeList, this.optionPage * 10);
            if (this.selectAttribute) {
                let isInTop10 = false;
                if (Array.isArray(this.selectAttribute)) {
                    // isInTop10 = _.findIndex(top10List, item => {
                    //     return _.findIndex(this.selectAttribute, sAttrItem => sAttrItem.indexOf(item.id) > -1) > -1;
                    // }) > -1;
                    isInTop10 = false;
                } else {
                    isInTop10 = _.findIndex(top10List, attrItem => this.selectAttribute.indexOf(attrItem.id) > -1) > -1;
                }
                if (!isInTop10) {
                    filterList = _.filter(_.cloneDeep(this.allDeviceAttributeList), item => {
                        if (Array.isArray(this.selectAttribute)) {
                            const attrs = _.filter(item.attributes, attrItem => {
                                return _.findIndex(this.selectAttribute, sAttrItem => sAttrItem === attrItem.DeviceNameAndDeviceIdAndAttributeNameAndAttributeKey) > -1;
                            });
                            if (attrs && attrs.length > 0) {
                                item.attributes = attrs;
                                return true;
                            }
                        } else {
                            const attrs = _.filter(item.attributes, attrItem => this.selectAttribute === attrItem.DeviceNameAndDeviceIdAndAttributeNameAndAttributeKey);
                            if (attrs && attrs.length > 0) {
                                item.attributes = attrs;
                                return true;
                            }
                        }
                        return false;
                    });
                }
            }
            return _.union(filterList, top10List);
        }
    }

    loadMore() {
        this.optionPage++;
    }

    created() {
        this.initSelectSource();
        if (this.multiple) {
            this.hasParam = _.has(_.get(this.value, '[0]'), 'radio');
        } else {
            this.selectAttribute = this.value as string;
        }
    }

    initSelectSource() {
        let getAttributePromise = null;
        if (this.selectType === FormControlSelectDeviceAttributeType.DEVICE_TYPE) {
            if (this.linkedDeviceAttributeList && this.linkedDeviceAttributeList.length > 0) {
                this.filterDeviceAttributeList = this.linkedDeviceAttributeList;
                return;
            } else {
                getAttributePromise = DeviceTypeService.getDeviceTypeAttributeList();
            }
        } else if (this.selectType === FormControlSelectDeviceAttributeType.FACILITY_CONFIG) {
            getAttributePromise = FacilityConfigTypeService.getDeviceAttributesByTypeId(this.scopeId, this.identifier);
        } else {
            getAttributePromise = DeviceService.getAllDeviceAttributes(this.scopeId, this.identifier);
        }
        if (!getAttributePromise) {
            return;
        }
        this.sourceLoading = true;
        getAttributePromise.then(res => {
            this.allDeviceAttributeList = res;
            this.filterDeviceAttributeList = res;
            if (this.mode === 'multiple') {
                _.forEach(this.value as Array<string>, (item, index) => {
                    (this.value as Array<string>)[index] = this.initSelectAttributeFromAllDeviceAttributes(item);
                });
            } else if (this.multiple) {
                _.forEach(this.value as Array<SelectDeviceAttributeModel>, item => {
                    const deviceNameAndDeviceIdAndAttributeNameAndAttributeKey = this.initSelectAttributeFromAllDeviceAttributes(item.DeviceIdAndKey);
                    if (deviceNameAndDeviceIdAndAttributeNameAndAttributeKey) {
                        const valArray = deviceNameAndDeviceIdAndAttributeNameAndAttributeKey.split('|');
                        if (valArray && valArray.length === 4) {
                            item.deviceName = valArray[0];
                            item.deviceId = valArray[1];
                            item.attributeName = valArray[2];
                            item.key = valArray[3];
                        }
                    }
                });
            } else {
                if (Array.isArray(this.value)) {
                    if (this.value.length > 0) {
                        this.selectAttribute = this.initSelectAttributeFromAllDeviceAttributes(this.value[0] as string);
                    }
                } else {
                    this.selectAttribute = this.initSelectAttributeFromAllDeviceAttributes(this.value as string);
                }
            }
        }).catch(err => {
            $log.error(err);
        }).finally(() => {
            this.sourceLoading = false;
        });
    }

    initAllSelectSource(selectAttribute) {
        let deviceId, deviceName, attributeName, attributeKey;
        const valArray = selectAttribute.split('|');
        if (valArray) {
            if (valArray.length === 2) {
                deviceId = valArray[0];
                attributeKey = valArray[1];
            } else if (valArray.length === 4) {
                deviceId = valArray[0];
                deviceName = valArray[1];
                attributeName = valArray[2];
                attributeKey = valArray[3];
            }
        }
        // let hasInSource = false;
        // for (let i = 0; i < this.allDeviceAttributeList.length; i++) {
        //     const item = this.allDeviceAttributeList[i];
        //     if (item.id === deviceId) {
        //         if (_.find(item.attributes, attribute => attribute.key === attributeKey)) {
        //             hasInSource = true;
        //             break;
        //         }
        //     }
        // }
        // if (!hasInSource) {
        //     const model = new SelectDeviceModel();
        //     model.id = deviceId;
        //     model.name = deviceName;
        //     const attributeModel = new SelectDeviceAttributeModel();
        //     attributeModel.deviceId = deviceId;
        //     attributeModel.attributeName = attributeName;
        //     attributeModel.key = attributeKey;
        //     attributeModel.disabled = true;
        //     model.attributes = [attributeModel];
        //     this.allDeviceAttributeList.unshift(model);
        // }
    }

    initSelectAttributeFromAllDeviceAttributes(attributeItem: string) {
        if (attributeItem) {
            this.initAllSelectSource(attributeItem);
            const valArray = attributeItem.split('|');
            if (valArray && valArray.length === 2) {
                const findDevice = _.find(this.allDeviceAttributeList, item => item.id === valArray[0]);
                const findAttribute = _.find(findDevice?.attributes, item => item.key === valArray[1]);
                if (findDevice && findAttribute) {
                    return `${findDevice.name}|${findDevice.id}|${findAttribute.attributeName}|${findAttribute.key}`;
                }
            }
        }
        return attributeItem;
    }

    addClick() {
        (this.value as Array<any>).push(new SelectDeviceAttributeRadioModel());
    }

    removeClick(index: number) {
        (this.value as Array<any>).splice(index, 1);
    }

    // filterOption(input, option) {
    //     return _.get(option.componentOptions, 'children[0].children[1].text', '').toLowerCase().indexOf(input.toLowerCase()) > -1 ||
    //         _.get(option.componentOptions, 'children[1].componentOptions.children[0].text', '').toLowerCase().indexOf(input.toLowerCase()) > -1;
    // }

    onSearch(value: string) {
        this.optionPage = 1;
        const trimValue = _.toUpper(_.trim(value));
        if (trimValue) {
            this.filterDeviceAttributeList = _.filter(this.allDeviceAttributeList, item => {
                return _.toUpper(_.trim(item.name)).indexOf(trimValue) > -1 || _.findIndex(item.attributes, attr => {
                    return _.toUpper(_.trim(attr.attributeName)).indexOf(trimValue) > -1 || _.toUpper(_.trim(attr.key)).indexOf(trimValue) > -1;
                }) > -1;
            });
        } else {
            this.filterDeviceAttributeList = this.allDeviceAttributeList;
        }
    }

    onChange() {
        this.$emit('input', this.selectAttribute);
        this.$emit('change', this.selectAttribute);
    }

    validate() {
        if (this.multiple) {
            for (let index = 0; index < this.value.length; index++) {
                const item: SelectDeviceAttributeRadioModel = this.value[index] as SelectDeviceAttributeRadioModel;
                if (!item.DeviceNameAndDeviceIdAndAttributeNameAndAttributeKey) {
                    this.showMessageWarning('请选择设备属性');
                    return false;
                } else if (this.hasParam && !item.radio) {
                    this.showMessageWarning('请输入分摊系数');
                    return false;
                }
            }

            if (_.uniqBy(this.value as Array<any>, 'DeviceNameAndDeviceIdAndAttributeNameAndAttributeKey').length < this.value.length) {
                this.showMessageWarning('有重复的设备属性');
                return false;
            }
        }
        return true;
    }
}

