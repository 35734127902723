var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-enum-component" }, [
    _vm.thingsType === _vm.ThingsModelType.ENUM
      ? _c(
          "div",
          [
            _c(
              "a-row",
              [
                _c("a-col", { attrs: { span: 11 } }, [_vm._v("输入值")]),
                _c("a-col", { attrs: { span: 2 } }),
                _c("a-col", { attrs: { span: 11 } }, [_vm._v("输出值")]),
              ],
              1
            ),
            _vm._l(_vm.formModel.currentEnumList, function (item, index) {
              return _c(
                "a-row",
                { key: index },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 11 } },
                    [
                      _c("a-input", {
                        attrs: {
                          disabled: _vm.disabled,
                          placeholder: "编号如'0'",
                          "allow-clear": "",
                        },
                        model: {
                          value: item.source,
                          callback: function ($$v) {
                            _vm.$set(item, "source", $$v)
                          },
                          expression: "item.source",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { staticClass: "text-center", attrs: { span: 2 } },
                    [_vm._v("~")]
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请选择",
                          },
                          model: {
                            value: item.target,
                            callback: function ($$v) {
                              _vm.$set(item, "target", $$v)
                            },
                            expression: "item.target",
                          },
                        },
                        _vm._l(item.enumCloneList, function (option) {
                          return _c(
                            "a-select-option",
                            {
                              key: option.value,
                              attrs: { value: option.value },
                            },
                            [_vm._v(" " + _vm._s(option.name) + " ")]
                          )
                        }),
                        1
                      ),
                      index > -1 && !_vm.disabled
                        ? _c(
                            "a",
                            {
                              staticClass: "jtl-link enum-remove-link",
                              on: {
                                click: function ($event) {
                                  return _vm.removeEnumClick(index)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            !_vm.disabled
              ? _c(
                  "a",
                  {
                    staticClass: "jtl-link",
                    on: {
                      click: function ($event) {
                        return _vm.addEnumClick()
                      },
                    },
                  },
                  [_vm._v("+增加枚举项")]
                )
              : _vm._e(),
          ],
          2
        )
      : _vm.thingsType === _vm.ThingsModelType.BOOLEAN
      ? _c(
          "div",
          [
            _c(
              "a-row",
              [
                _c("a-col", { attrs: { span: 11 } }, [_vm._v("输入值")]),
                _c("a-col", { attrs: { span: 2 } }),
                _c("a-col", { attrs: { span: 11 } }, [_vm._v("输出值")]),
              ],
              1
            ),
            _c(
              "a-row",
              [
                _c("a-col", { attrs: { span: 11 } }, [
                  _c(
                    "div",
                    { staticClass: "form-item__data-bool-false" },
                    [
                      _c("a-input", {
                        attrs: {
                          disabled: _vm.disabled,
                          placeholder: "请输入",
                          "allow-clear": "",
                        },
                        model: {
                          value: _vm.formModel.dataFalseValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModel, "dataFalseValue", $$v)
                          },
                          expression: "formModel.dataFalseValue",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "a-col",
                  { staticClass: "text-center", attrs: { span: 2 } },
                  [_vm._v("~")]
                ),
                _c("a-col", { attrs: { span: 11 } }, [
                  _c("div", { staticClass: "form-item__data-bool-true" }, [
                    _vm._v(" false （0）" + _vm._s(_vm.dataFalseName) + " "),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "a-row",
              [
                _c(
                  "a-col",
                  { attrs: { span: 11 } },
                  [
                    _c("a-input", {
                      attrs: {
                        disabled: _vm.disabled,
                        placeholder: "请输入",
                        "allow-clear": "",
                      },
                      model: {
                        value: _vm.formModel.dataTrueValue,
                        callback: function ($$v) {
                          _vm.$set(_vm.formModel, "dataTrueValue", $$v)
                        },
                        expression: "formModel.dataTrueValue",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { staticClass: "text-center", attrs: { span: 2 } },
                  [_vm._v("~")]
                ),
                _c("a-col", { attrs: { span: 11 } }, [
                  _c("div", { staticClass: "form-item__data-bool-true" }, [
                    _vm._v(" true （1）" + _vm._s(_vm.dataTrueName) + " "),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("a-input", {
              attrs: {
                disabled: _vm.disabled,
                placeholder: "请输入数据转换",
                "allow-clear": "",
              },
              model: {
                value: _vm.formModel.expressionOther,
                callback: function ($$v) {
                  _vm.$set(_vm.formModel, "expressionOther", $$v)
                },
                expression: "formModel.expressionOther",
              },
            }),
          ],
          1
        ),
    _vm.isShowAdvancedConfig
      ? _c("div", [
          _c(
            "a",
            { staticClass: "jtl-link", on: { click: _vm.showAdvancedConfig } },
            [
              _vm._v("高级配置 "),
              _vm.isShowAdvancedConfigDetail
                ? _c("a-icon", { attrs: { type: "up" } })
                : _c("a-icon", { attrs: { type: "down" } }),
            ],
            1
          ),
          _vm.isShowAdvancedConfigDetail
            ? _c(
                "div",
                [
                  !_vm.isShowAdvancedConfigExcludeInt
                    ? [
                        _c(
                          "a-row",
                          [
                            _c(
                              "a-col",
                              {
                                staticStyle: { textAlign: "right" },
                                attrs: { span: 4 },
                              },
                              [_vm._v("保留小数位：")]
                            ),
                            _c("a-col", { attrs: { span: 1 } }),
                            _c(
                              "a-col",
                              { attrs: { span: 11 } },
                              [
                                _c("a-input-number", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请输入0~8的整数",
                                    min: 0,
                                    step: 1,
                                    max: 8,
                                    disabled: _vm.disabled,
                                  },
                                  model: {
                                    value:
                                      _vm.formModel.mathOperations[0].params
                                        .decimalDigit,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formModel.mathOperations[0].params,
                                        "decimalDigit",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formModel.mathOperations[0].params.decimalDigit",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("a-col", { attrs: { span: 1 } }),
                            _c("a-col", { attrs: { span: 2 } }, [_vm._v("位")]),
                          ],
                          1
                        ),
                        _c(
                          "a-row",
                          [
                            _c(
                              "a-col",
                              {
                                staticStyle: { textAlign: "right" },
                                attrs: { span: 4 },
                              },
                              [_vm._v("末位处理：")]
                            ),
                            _c("a-col", { attrs: { span: 1 } }),
                            _c(
                              "a-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "a-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      disabled: _vm.disabled,
                                      placeholder: "请选择数据末位处理方式",
                                      "allow-clear": true,
                                    },
                                    model: {
                                      value:
                                        _vm.formModel.mathOperations[0].params
                                          .roundType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formModel.mathOperations[0]
                                            .params,
                                          "roundType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formModel.mathOperations[0].params.roundType",
                                    },
                                  },
                                  [
                                    _c(
                                      "a-select-option",
                                      { attrs: { value: "Round" } },
                                      [_vm._v(" 四舍五入 ")]
                                    ),
                                    _c(
                                      "a-select-option",
                                      { attrs: { value: "Ceil" } },
                                      [_vm._v(" 向上取整 ")]
                                    ),
                                    _c(
                                      "a-select-option",
                                      { attrs: { value: "Floor" } },
                                      [_vm._v(" 向下取整 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        {
                          staticStyle: { textAlign: "right" },
                          attrs: { span: 4 },
                        },
                        [_vm._v("取值规则：")]
                      ),
                      _c("a-col", { attrs: { span: 1 } }),
                      _c(
                        "a-col",
                        { attrs: { span: 11 } },
                        [
                          _c(
                            "a-checkbox",
                            {
                              attrs: { disabled: _vm.disabled },
                              model: {
                                value: _vm.formModel.mathOperations[1].params,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formModel.mathOperations[1],
                                    "params",
                                    $$v
                                  )
                                },
                                expression:
                                  "formModel.mathOperations[1].params",
                              },
                            },
                            [_vm._v(" 取绝对值 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }