import { BaseEntityModel } from '@common-src/model/base-model';
import { ThingsFunctionType, GroupEntityType, ViewModeType } from '@common-src/model/enum';
import ThingsAttributeService from '@common-src/service/things-attribute';
import ThingsEventService from '@common-src/service/things-event';
import ThingsMethodService from '@common-src/service/things-method';
import { thingsModelFunctionFilter, thingsModelTypeFilter, eventLevelFilter, attributeTypeFilter, readWriteFilter } from '@common-src/filter';
import { ThingsAttributeEntityModel } from './things-attribute-entity';
import { ThingsEventEntityModel } from './things-event-entity';
import { ThingsMethodEntityModel } from './things-method-entity';
import { DeviceTypeFeatureModel } from './device-type-feature';
// import DeviceTypeService from '@common-src/service/device-type';
// import ThingsFeatureService from '@common-src/service/things-feature';
import CommonService from '@common-src/service/common';
import { FormControl, FormControlType, FormControlSelectGroupModel } from '@common-src/model/form-control';
import moment from 'moment';
import { UserStoreModule } from '@common-src/store/modules/user';
import { ThingsExtendAttributeEntityModel } from './things-extend-attribute-entity';

export class ThingsFunctionEntityModel extends BaseEntityModel {
    attrs: Array<ThingsAttributeEntityModel>;
    extendAttrs: Array<ThingsExtendAttributeEntityModel>;
    events: Array<ThingsEventEntityModel>;
    methods: Array<ThingsMethodEntityModel>;
    features: Array<DeviceTypeFeatureModel>;
    version: string;
    syncEnabled: boolean;

    public getFunctionList(funcionType: ThingsFunctionType) {
        let filterDataList = null;
        switch (funcionType) {
            case ThingsFunctionType.ATTRIBUTE:
                filterDataList = this.attrs;
                break;
            case ThingsFunctionType.EXTENDATTRIBUTE:
                filterDataList = this.extendAttrs;
                break;
            case ThingsFunctionType.METHOD:
                filterDataList = this.methods;
                break;
            case ThingsFunctionType.EVENT:
                filterDataList = this.events;
                break;
            case ThingsFunctionType.FEATURE:
                filterDataList = this.features;
        }
        return filterDataList;
    }

    public getFunctionName(funcionType: ThingsFunctionType) {
        return thingsModelFunctionFilter(funcionType);
    }

    public deleteFunction(funcionType: ThingsFunctionType, model: ThingsFunctionEntityModel, entityId?: string): Promise<any> {
        let deleteService: (model: any, entityId?: string) => Promise<any> = null;
        switch (funcionType) {
            case ThingsFunctionType.ATTRIBUTE:
                deleteService = ThingsAttributeService.delete;
                break;
            case ThingsFunctionType.METHOD:
                deleteService = ThingsMethodService.delete;
                break;
            case ThingsFunctionType.EVENT:
                deleteService = ThingsEventService.delete;
                break;
            case ThingsFunctionType.FEATURE:
                // deleteService = DeviceTypeService.deleteFeature;
                // deleteService = ThingsFeatureService.deleteDeviceFeature;
                deleteService = CommonService.deleteDeviceFeature;
                break;
        }
        return deleteService(model, entityId);
    }

    toModel(json): any {
        super.toModel(_.get(json, 'entity') || (_.get(json, 'type.id') ? _.get(json, 'type') : json));
        this.attrs = _.map(_.get(json, 'attrs'), item => new ThingsAttributeEntityModel().toModel(item));
        this.extendAttrs = _.map(_.get(json, 'extendAttrs'), item => new ThingsExtendAttributeEntityModel().toModel(item));
        this.events = _.map(_.get(json, 'events'), item => new ThingsEventEntityModel().toModel(item));
        this.methods = _.map(_.get(json, 'methods'), item => new ThingsMethodEntityModel().toModel(item));
        this.features = _.map(_.get(json, 'features'), item => new DeviceTypeFeatureModel().toModel(item));
        this.version = _.get(json, 'version');
        this.syncEnabled = true;
        return this;
    }

    static getFunctionTabelColumns(): Array<any> {
        const data = [
            // {
            //     title: '序号',
            //     dataIndex: 'idx',
            //     width: 80,
            //     customRender: function(text, record, index) {
            //         return index + 1;
            //     }
            // },
            {
                title: '属性名称',
                dataIndex: 'name'
                // scopedSlots: { customRender: 'name' }
            },
            {
                title: '标识符',
                dataIndex: 'identifier',
                customRender: function(text, record, index) {
                    return _.get(record, 'identifier') || _.get(record, 'identifier');
                }
            },
            {
                title: '数据类型',
                dataIndex: 'type',
                customRender: function(text, record, index) {
                    const dataType = _.get(record, 'thingsModelTypeModel.thingsModelType');
                    return thingsModelTypeFilter(dataType);
                },
                functionType: [ThingsFunctionType.ATTRIBUTE, ThingsFunctionType.EXTENDATTRIBUTE]
            },
            // {
            //     title: '数据定义',
            //     dataIndex: 'thingsModelTypeModel'
            //     // customRender: (text, record, index) => {
            //     //     return thingsModelTypeFilter(text);
            //     // }
            // },
            {
                title: '读写类型',
                dataIndex: 'accessMode',
                customRender: function(text, record, index) {
                    return readWriteFilter(text);
                },
                width: 100,
                functionType: [ThingsFunctionType.ATTRIBUTE, ThingsFunctionType.EXTENDATTRIBUTE]
            },
            {
                title: '属性范围',
                dataIndex: 'attributeType',
                customRender: (text, record, index) => {
                    return attributeTypeFilter(text);
                },
                width: 100,
                functionType: [ThingsFunctionType.ATTRIBUTE, ThingsFunctionType.EXTENDATTRIBUTE]
            },
            {
                title: '事件类型',
                dataIndex: 'level',
                customRender: function(text, record, index) {
                    return eventLevelFilter(text);
                },
                functionType: [ThingsFunctionType.EVENT]
            },
            {
                title: '是否启用',
                dataIndex: 'enabled',
                scopedSlots: { customRender: 'enabled' },
                width: 100,
                functionType: [ThingsFunctionType.ATTRIBUTE, ThingsFunctionType.EVENT, ThingsFunctionType.METHOD]
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 140,
                functionType: [ThingsFunctionType.ATTRIBUTE, ThingsFunctionType.EVENT, ThingsFunctionType.METHOD, ThingsFunctionType.EXTENDATTRIBUTE]
            },
            {
                title: '操作',
                dataIndex: 'featureAction',
                scopedSlots: { customRender: 'featureAction' },
                functionType: [ThingsFunctionType.FEATURE]
            }
        ];

        // 库平台隐藏是否启用，属性添加单位列
        if (UserStoreModule.IsSuperAdmin) {
            data.splice(6, 1);
            data.splice(3, 0, {
                title: '单位',
                dataIndex: 'definition',
                customRender: function(text, record, index) {
                    return record.thingsModelTypeModel.unit || '-';
                },
                functionType: [ThingsFunctionType.ATTRIBUTE]
            });
        }
        return data;
    }
    static getEdageTypeTabelColumns(): Array<any> {
        return [
            {
                title: '同步时间',
                dataIndex: 'syncTime',
                customRender: function(text) {
                    return text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '';
                }
            },
            {
                title: '同步产品数',
                dataIndex: 'syncNum'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                functionType: [ThingsFunctionType.FEATURE]
            }
        ];
    }
    static getEdageTypeTabelDetailColumns(type: ViewModeType): Array<any> {
        const Columns = [
            {
                title: '产品名称',
                dataIndex: 'name'
            },
            {
                title: '标识符',
                dataIndex: 'identifier'
            },
            {
                title: '驱动',
                dataIndex: 'driver'
            },
            {
                title: '是否网关',
                dataIndex: 'type',
                customRender: function(text) {
                    return text === 'GATEWAY' ? '是' : '否';
                }
            },
            {
                title: '平台版本时间',
                dataIndex: 'cloudVersionTime',
                customRender: function(text) {
                    return text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '';
                }
            },
            {
                title: '本地版本时间',
                dataIndex: 'localVersionTime',
                customRender: function(text) {
                    return text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '';
                }
            }
        ];
        if (type === ViewModeType.UPDATE) {
            const actionColumn = {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                functionType: [ThingsFunctionType.FEATURE]
            };
            Columns.push(actionColumn);
        }
        return Columns;
    }
}

export class ThingsFunctionGroupModel extends ThingsFunctionEntityModel {
    @FormControl({
        label: '分组',
        type: FormControlType.SELECT_GROUP,
        required: true
    } as FormControlSelectGroupModel)
    groupId: string = undefined;
    groupName: string = undefined;
    groupType: GroupEntityType;

    constructor(groupId?: string, groupType?: GroupEntityType) {
        super();
        this.groupId = groupId || undefined;
        this.groupType = groupType;
    }

    toService(): any {
        const template = super.toService();
        const data = {
            groupId: this.groupId,
            template,
            ...template
        };
        delete data.template.groupId;
        return data;
    }
}
