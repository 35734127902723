var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jtl-qrcode-component" },
    [
      _vm.qrInfo
        ? _c(
            "div",
            {
              staticClass: "qrcode-wrapper",
              on: {
                click: function ($event) {
                  _vm.previewVisible = true
                },
              },
            },
            [
              _c("vue-qr", {
                staticClass: "qrcodeBg",
                attrs: { text: _vm.qrInfo.id, margin: 1, size: _vm.qrSize },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "a-modal",
        {
          class: [
            "qrcode-modal",
            { "qrcode-modal-download": _vm.batchDownload },
          ],
          attrs: { visible: _vm.previewVisible, closable: false, mask: false },
          on: {
            cancel: function ($event) {
              _vm.previewVisible = false
            },
          },
        },
        [
          _c(
            "div",
            { ref: "qrcodeContent", staticClass: "qrcode-modal-content" },
            [
              _c("div", { staticClass: "qrcode-logo text-center" }, [
                _vm.projectLogo
                  ? _c("img", {
                      attrs: { src: _vm.projectLogo, crossOrigin: "anonymous" },
                    })
                  : _vm._e(),
              ]),
              _c(
                "a-row",
                { staticStyle: { padding: "24px" }, attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 10 } },
                    [
                      _vm.previewVisible && _vm.qrInfo
                        ? _c("vue-qr", {
                            staticClass: "qrcode",
                            attrs: {
                              text: _vm.qrInfo.id,
                              margin: 1,
                              size: 300,
                              callback: _vm.qrCodeOKCallback,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("a-col", { attrs: { span: 14 } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "16px",
                          "font-weight": "bold",
                          "line-height": "2",
                        },
                      },
                      [
                        _vm._v(" 设备编号：" + _vm._s(_vm.qrInfo.code)),
                        _c("br"),
                        _vm._v(" 设备名称：" + _vm._s(_vm.qrInfo.name)),
                        _c("br"),
                        _vm._v(
                          " 出厂编号：" +
                            _vm._s(_vm._f("text")(_vm.qrInfo.factoryCode)) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "jtl-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.previewVisible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
              _c(
                "jtl-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadClick(true)
                    },
                  },
                },
                [_vm._v("下载")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }