







import { Component, Vue, Prop } from 'vue-property-decorator';
import { ICamera, CameraHkModel } from '@common-src/model/camera-hk-model';

const HikPlatform = 'HikPlatform';
const HikPlatformCameraChannel = 'HikPlatformCameraChannel';
const HikCameraIndexCode = 'CameraIndexCode';

@Component
export default class JTLVideo extends Vue {
    @Prop()
    title: string;

    cameraModel: ICamera = null;// 监控播放模型

    created() {
        this.cameraModel = new CameraHkModel();
        window.addEventListener('resize', this.onResize);
        this.$eventHub.$on('DASHBOARD_MENU_CHANGE', () => {
            setTimeout(() => {
                this.onResize();
            }, 300);
        });
    }

    mounted() {
        this.onResize();
    }

    /**
     * 初始化播放器
     */
    async init(ip, appKey, appSecret) {
        await this.cameraModel.initPlugin('playWnd', this.integrationCallBack.bind(this));
        await this.cameraModel.init(ip, appKey, appSecret);
        this.onResize();
    }

    /**
     * 通过code播放
     */
    startPreview(cameraIndexCode, winId) {
        this.cameraModel.startPreview(cameraIndexCode, winId);
    }

    /**
     * 通过设施id和设施属性播放
     */
    async startPreviewByFacilityId(facilityId: string, deviceAttr: any, winId?: string) {
        if (!deviceAttr || !deviceAttr[HikPlatform] || !deviceAttr[HikPlatformCameraChannel]) {
            throw new Error('设备配置不正确，请联系运维人员');
        }
        const cameraIndexCode = _.get(deviceAttr, `${HikPlatformCameraChannel}.${HikCameraIndexCode}`);
        if (!this.cameraModel.isInit) {
            let ip = _.get(deviceAttr, `${HikPlatform}.IP`);
            let appKey = _.get(deviceAttr, `${HikPlatform}.AppKey`);
            let appSecret = _.get(deviceAttr, `${HikPlatform}.AppSecret`);
            if (appKey === '22375398') {
                // 22375398：对外网关key; 25868156: 对内网关key
                // web视频监控预览和移动端视频监控预览问题：
                // 海康平台内外网访问，需要设置2个合作方，1个对内合作放（ip），1个对外合作方（domain）
                // 每个合作方有自己的appkey和appsecret
                // 每个合作方的权限也可以单独配置
                // 解决方案1：我们在配置设备时，需要配置2个海康海康综合平台网关，1个对内网关，1个对外网关
                // 解决方案2：web端和移动端都使用对外合作方（domain），即都用域名访问
                // 目前Web端采用方案1实现，下面的代码先写死对内网关的配置
                ip = '172.21.231.175';
                appKey = '25868156';
                appSecret = '7ooR3xPhfPBKDklbzD9A';
            }
            if (!ip || !appKey || !appSecret) {
                throw new Error('设备配置不正确，请联系运维人员');
            }
            await this.init(ip, appKey, appSecret);
            this.startPreview(cameraIndexCode, winId);
            // 设置窗口对应的设施id
            this.setWndFacilityMap(facilityId, winId);
        } else {
            this.startPreview(cameraIndexCode, winId);
            // 设置窗口对应的设施id
            this.setWndFacilityMap(facilityId, winId);
        }
    }

    /**
     * 停止所有播放
     */
    stopAllPreview() {
        this.cameraModel.stopAllPreview();
    }

    /**
     * 设置播放器布局
     */
    setLayout(layout) {
        this.cameraModel.setLayout(layout);
    }

    /**
     * 设施窗口对应的facilityId
     * 用户保存视图和设施的关系
     */
    setWndFacilityMap(facilityId, winId) {
        this.cameraModel.setWndFacilityMap(facilityId, winId);
    }

    onResize() {
        if (this.$refs.playWnd && this.cameraModel) {
            // @ts-ignore
            const playWndHeight = this.$refs.playWnd.clientHeight;
            this.$emit('resize', playWndHeight);
            // @ts-ignore
            this.cameraModel.resize(this.$refs.playWnd.clientWidth, playWndHeight);
        }
    }

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
        if (this.cameraModel) {
            this.cameraModel.destroy();
            this.cameraModel = null;
        }
    }

    private integrationCallBack(msg: any) {
        console.log('海康插件回调消息', msg);
    }
}

