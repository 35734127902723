var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-enum-component" },
    [
      _c(
        "a-row",
        [
          _c("a-col", { attrs: { span: 8 } }, [_vm._v("微信KeyWord")]),
          _c("a-col", { attrs: { span: 8 } }, [_vm._v("名称")]),
          _c("a-col", { attrs: { span: 8 } }, [_vm._v("内容")]),
        ],
        1
      ),
      _vm._l(_vm.value, function (item, index) {
        return _c(
          "a-row",
          { key: index },
          [
            _c(
              "a-col",
              { attrs: { span: 6 } },
              [
                _c("a-input", {
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "如keyword" + (index + 1) + "等",
                    "allow-clear": "",
                  },
                  model: {
                    value: item[_vm.keyword],
                    callback: function ($$v) {
                      _vm.$set(item, _vm.keyword, $$v)
                    },
                    expression: "item[keyword]",
                  },
                }),
              ],
              1
            ),
            _c("a-col", { staticClass: "text-center", attrs: { span: 2 } }),
            _c(
              "a-col",
              { staticClass: "text-center", attrs: { span: 6 } },
              [
                _c("a-input", {
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "请输入名称",
                    "allow-clear": "",
                  },
                  model: {
                    value: item[_vm.labelname],
                    callback: function ($$v) {
                      _vm.$set(item, _vm.labelname, $$v)
                    },
                    expression: "item[labelname]",
                  },
                }),
              ],
              1
            ),
            _c("a-col", { staticClass: "text-center", attrs: { span: 2 } }),
            _c(
              "a-col",
              { attrs: { span: 6 } },
              [
                _c("a-input", {
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "请输入内容",
                    "allow-clear": "",
                  },
                  model: {
                    value: item[_vm.valuename],
                    callback: function ($$v) {
                      _vm.$set(item, _vm.valuename, $$v)
                    },
                    expression: "item[valuename]",
                  },
                }),
              ],
              1
            ),
            _c("a-col", { staticClass: "text-center", attrs: { span: 2 } }, [
              !_vm.disabled
                ? _c(
                    "a",
                    {
                      staticClass: "jtl-link enum-remove-link",
                      on: {
                        click: function ($event) {
                          return _vm.removeEnumClick(index)
                        },
                      },
                    },
                    [_vm._v(" 删除")]
                  )
                : _vm._e(),
            ]),
          ],
          1
        )
      }),
      !_vm.disabled
        ? _c(
            "a",
            {
              staticClass: "jtl-link",
              on: {
                click: function ($event) {
                  return _vm.addEnumClick()
                },
              },
            },
            [_vm._v("+增加参数")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }