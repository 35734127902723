var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jtl-button-component" },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.btnLoading } },
        [
          _c("a-icon", {
            staticStyle: { "font-size": "20px" },
            attrs: { slot: "indicator", type: "loading", spin: "" },
            slot: "indicator",
          }),
          _c(
            "a-button",
            {
              attrs: { type: _vm.type, size: _vm.size, disabled: _vm.disabled },
              on: { click: _vm.loginClick },
            },
            [_vm._t("default")],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }