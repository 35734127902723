

































import { Component, Prop, Watch } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import { FormControlType } from '@common-src/model/form-control';

@Component
export default class JTLFormTableComponent extends BaseComponent {
    @Prop()
    columns:Array<{title:string, dataIndex:string, width?:number, type?:FormControlType, required?:boolean, scopedSlots?:any}>;
    @Prop()
    value: any;
    @Prop()
    cellType: any;
    @Prop()
    disabled: boolean;
    @Prop()
    deleteAction: boolean;
    @Prop()
    pagination: boolean;
    @Prop()
    addAction: boolean;

    FormControlType = FormControlType;
    dataSource:any = [];

    @Watch('value')
    update(newVal) {
        this.dataSource = this.value;
    }

    created() {
        if (this.deleteAction && !this.disabled) {
            if (this.columns.filter(item => { return item.title === '操作'; }).length <= 0) {
                this.columns.push({ title: '操作', dataIndex: 'action', scopedSlots: { customRender: 'action' } });
            }
        }
        this.dataSource = this.value;
    }

    add() {
        this.dataSource.push({});
        this.$emit('formTableAdd');
    }

    inputChanged(text, record, index, scopeIndex) {
        if (scopeIndex['related'] && Array.isArray(scopeIndex['related']) && scopeIndex['selectOptions']) {
            _.forEach(scopeIndex['related'], (item) => {
                const ff = _.find(scopeIndex['selectOptions'], item => item.value === record[scopeIndex['key']]);
                const result = _.get(ff, scopeIndex['relateVal']);
                record[item] = result;
            });
        }
        this.dataSource = [...this.dataSource];
        const source = [...this.value];
        const target = this.dataSource[index];
        console.log(text, record, index, scopeIndex);
    }

    deleteClick(key, index) {
        const source = [...this.value];
        this.dataSource.splice(index, 1);
        this.$emit('formTableChange', this.dataSource);
    }

    // validate() {
    //     let validateFlag = true;

    //     _.forEach(this.dataSource, item => {
    //         if (item.required && !item.result) {
    //             this.showMessageWarning('表格中有必填项未填，请补充完整');
    //             validateFlag = false;
    //             return false;
    //         }
    //     });
    //     if (validateFlag) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }
}

