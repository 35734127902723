


















import { Component, Prop, Watch, Ref } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';

@Component({
    name: 'jtl-image'
})
export default class JTLImage extends BaseComponent {
    @Prop()
    private src: string;
    @Ref('jtlImage')
    jtlImage: HTMLImageElement;
    private loading: boolean = true;
    private hasError: boolean = false;

    get IsLoading() {
        return !!(this.loading && this.src);
    }

    onload() {
        this.hasError = false;
        this.loading = false;
    }

    onerror(ev:Event) {
        this.$log.warn(`JTL-image load image error from: ${_.get(ev, 'currentTarget.src', '[EMPTY URL]')}`);
        this.loading = false;
        this.hasError = true;
    }

    @Watch('src', { immediate: true })
    srcChange(newVal: string) {
        if (newVal) {
            this.hasError = false;
            this.loading = true;
        }
    }
}

