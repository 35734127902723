import { Component, Ref } from 'vue-property-decorator';
import FormComponent from './form-component';
import { BaseComponent } from './base-component';
import { ViewModeType } from '@common-src/model/enum';
import { ICRUDQ } from '@common-src/model/interface';

@Component
export default class FormDialogComponent<T> extends BaseComponent {
    ViewModeType = ViewModeType;
    /**
     * 弹窗打开/关闭标识
     */
    protected dialogVisible: boolean = false;

    /**
     * 弹窗标题
     */
    public dialogTitle: string = null;

    public viewMode: ViewModeType = null;

    /**
     * 表单组件
     */
    @Ref(JTL.CONSTANT.DEFAULT_FORM_NAME)
    jtlForm: FormComponent<T>;

    /**
     * 打开弹窗
     * @param model 数据模型
     * @param service 增删改查服务
     * @param needGetDetail 是否需要调用详情接口
     */
    dialogOpen(model: T, service: ICRUDQ<T, any>, viewMode: ViewModeType, needGetDetail: boolean = false): any {
        this.dialogVisible = true;
        this.viewMode = viewMode;
        this.initDialogTitle(this.viewMode);
        if (needGetDetail && service) {
            this.startFullScreenLoading();
            return service.retrieve(_.get(model, 'id')).then(res => {
                this.dialogInit(res, service, viewMode);
                return res;
            }).catch(err => {
                if (_.get(err, 'message') === 'Not implement.') {
                    this.dialogInit(model, service, viewMode);
                }
                $log.error(err);
            }).finally(() => {
                this.stopFullScreenLoading();
            });
        } else {
            this.dialogInit(model, service, viewMode);
        }
    }

    dialogInit(model: T, service: ICRUDQ<T, any>, viewMode: ViewModeType) {
        this.$nextTick(() => {
            if (this.jtlForm) {
                this.jtlForm.initService(service);
                this.jtlForm.initForm(model, viewMode);
            }
        });
    }

    initDialogTitle(viewMode: ViewModeType) {
        switch (viewMode) {
            case ViewModeType.NEW:
                this.dialogTitle = '添加';
                break;
            case ViewModeType.UPDATE:
                this.dialogTitle = '编辑';
                break;
            case ViewModeType.VIEW:
                this.dialogTitle = '查看';
                break;
        }
    }

    /**
     * 点击弹窗保存事件
     * 提交表单数据
     */
    dialogOK(): Promise<any> {
        if (!this.jtlForm) {
            return;
        }
        return this.jtlForm.submitForm().then((ret) => {
            this.dialogClose();
            switch (this.viewMode) {
                case ViewModeType.NEW:
                    this.$emit('dialogOK', { viewMode: this.viewMode, res: Object.assign({}, this.jtlForm.formModel, { id: ret }) });
                    break;
                case ViewModeType.UPDATE:
                    this.$emit('dialogOK', { viewMode: this.viewMode, res: this.jtlForm.formModel });
                    break;
            }
            this.showMessageSuccess('保存成功');
            // this.$emit('dialogOK', { viewMode: this.viewMode, res: ret });
        }).catch(err => {
            throw err;
        });
    }

    /**
     * 关闭弹窗
     */
    dialogClose(): void {
        if (this.jtlForm) {
            this.jtlForm.resetFields();
            this.jtlForm.clearValidate();
        }
        this.dialogVisible = false;
        this.dialogTitle = null;
    }
}
