var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "jtl-video-component" }, [
    _c("div", {
      ref: "playWnd",
      staticClass: "playWnd",
      attrs: { id: "playWnd" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }