export type SelectMode = 'default' | 'multiple' | 'tags' | 'combobox';
export type UploadListType = 'text' | 'picture' | 'picture-card';

export enum ViewModeType {
    NEW = 0, // 新增模式
    UPDATE = 1, // 编辑模式
    VIEW = 2, // 查看模式
}

export enum EnableType {
    ENABLE = 1, // 启用状态
    DISABLE = 0 // 禁用状态
}

export enum DeviceTypeEnum {
    INNER_GATEWAY = 'INTERNAL_GATEWAY', // 系统内置网关，不能删除，不能修改
    GATEWAY = 'GATEWAY', // 普通网关
    DEVICE = 'DEVICE' // 普通设备
}

/**
 * depreciated
 */
export enum AttributeDataType {
    STRING = 1,
    INT = 2,
    FLOAT = 3,
    BOOLEAN = 4,
    JSON = 5
}

export enum FacilityAttributeDataType {
    DEVICE = 1,
    INT = 4,
    FLOAT = 2,
    BOOLEAN = 3,
    STRING = 7,
    PEOPLE = 6,
    ATTRIBUTE = 8,
    SPACE = 9,
    SERVER_ATTRIBUTE = 10,
    ENUM = 11
}

export enum ThingsModelType {
    STRING = 'string',
    TEXT = 'text',
    PASSWORD = 'password',
    INT = 'int',
    FLOAT = 'float',
    DOUBLE = 'double',
    BOOLEAN = 'bool',
    ENUM = 'enum',
    DATE = 'date',
    JSON = 'json'
}

export enum AttributeType {
    SERVER = 'SERVER_SCOPE', // 服务端属性
    SHARE = 'SHARE_SCOPE', // 共享属性
    CLIENT = 'CLIENT_SCOPE' // 设备属性
}

export enum ReadWriteEnum {
    READONLY = 'r',
    READ_WRITE = 'rw'
}

export enum AttributeEntityType {
    DEVICE = 1,
    DEVICE_TYPE = 2,
    FACILITY = 3,
    FACILITY_TYPE = 4
}

export enum DeviceStatus {
    ONLINE = 'true',
    OFFLINE = 'false',
    // ENABLE = 'enable',
    // DISABLE = 'disable',
    // ACTIVE = 'active',
    // DISACTIVE = 'disactive'
}

export enum EntityStatus {
    ENABLE = 'true',
    DISABLE = 'false',
}

export enum EventLevelType {
    INFO = 'INFO', // 信息
    ALERT = 'ALERT', // 告警
    ERROR = 'FAULT' // 故障
}
export enum ProtocolType{
    MODBUS='Modbus',
    BACNET='Bacnet',
    OPCWEB='OPCWeb',
    KNX='KNX'
}
export enum VariableOption{
    ANALOGINPUT='analog-input',
    ANALOGOUTPUT='analog-output',
    ANALOGVALUE='analog-value',
    BINARYINPUT='binary-input',
    BINARYOUTPUT='binary-output',
    BINARYVALUE='binary-value',
    MULTI_STATE_VALUE='multi-state-value'
}

// 配置类型
export enum PropertyEntityType {
    MODEL_CONF = 'model', // 空间位置
    EVENT_LEVEL_CONF = 'eventLevel', // 事件级别
    EVENT_STATUS_CONF = 'eventStatus', // 处理状态
    WORK_ORDER_TYPE_CONF = 'workOrderType', // 类型
    WORK_ORDER_LEVEL_CONF = 'workOrderLevel', // 级别
    WORK_ORDER_EVALUATION_LEVEL_CONF = 'workOrderEvalLevel', // 满意度
    UNIT_CONF ='unit', // 单位
    EVENT_TYPE_CONF ='eventType', // 事件分类（工单）
    SERVICE_REQUEST_TYPE_CONF = 'serviceRequestType', // 服务请求分类（工单）
    ASSET_STATUS = 'assetStatus', // 资产状态
    EVENT_GRADE = 'eventGrade', // 事件级别
    LABEL_TYPE = 'tagType', // 标签类型
    ALARM_TYPE = 'alarmWarningType', // 告警类型
    DEVICE_DRIVER_CATEGORY = 'deviceDriverCategory', // 设备驱动分类
    DEVICE_DRIVER_MANUFACTURER = 'deviceDriverManufacturer', // 设备驱动厂家
}

export enum ThingsFunctionType {
    ATTRIBUTE = 'attribute',
    EXTENDATTRIBUTE = 'extendAttribute',
    EVENT = 'event',
    METHOD = 'method',
    FEATURE = 'feature'
}

export enum ThingsEntityType {
    FUNCTION = 1, // 物功能
    TEMPLATE = 2, // 物模板
    FEATURE = 3, // 物特征
    DEVICE_TYPE = 4, // 产品
}

export enum SyncType {
    // 0:同步到本地 1:同步到目标服务
    UPLOAD = 1,
    DOWNLOAD = 0
}

export enum GroupEntityType {
    TEMPLATE = 'TEMPLATE', // 物模板
    FEATURE = 2, // 物特征
    DEVICE_TYPE = 'PRODUCT', // 产品
    DEVICE = 'DEVICE', // 设备
    ORG = 5, // 组织架构分组
    FACILITY = 'FACILITYV2', // 设施分组
    ASSET = 7, // 资产分组
    FACILITY_CONFIG = 10, // api没有这个类型，只在前端使用
    TAG = 'TAG', // 标签分组
    FACILITY_TYPE = 'FACILITYTYPE',
    LINKAGE = 'LINKAGE',
    LINKAGECONFIGE = 'LINKAGECONFIGE',
    // 运维相关分组
    BACKUP_ASSET = 'BACKUP', // 备品分组
    KNOWLEDGE = 'KNOWLEDGE', // 知识库
    SYSTEM_MONITOR = 'SYSTEM_MONITOR',
    BATCH_CONTROL = 12,
    APP_GROUP = 'APP_GROUP' // 应用分组
}

export enum TagEntityType {
    FACILITY_V2 = 'FACILITY_V2',
    LOCATION ='LOCATION',
    FACILITY_TYPE = 'FACILITY_TYPE',
    ASSET = 'ASSET'
}

export enum WorkOrderType {
    EVENT = 'Event',
    PROBLEM = 'Problem',
    CHANGE = 'Change',
    PUBLISH = 'Publish',
    LORE = 'Lore',
    REPAIR = 'Repair',
    INSPECTION = 'Inspection',
    MAINTENANCE = 'Maintenance'
}

export enum ServiceOptionType {
    SERVICE_CATEGORY = 'ServiceCategory',
    WORK_ORDER_STATUS_ENUM = 'WorkOrderStatusEnum',
    WORK_ORDER_OVERTIME_STATUS_ENUM = 'WorkOrderOverTimeStatusEnum'
}

// 业务配置 - 设施类型
export enum FacilityType{
    SPACE = 'space', // 环境空间类型
    OFFICE = 'office', // 环境空间-办公室管理
    MEETING = 'meeting', // 环境空间-会议室管理
    DEPARTMENT = 'department',
    FACILITY = 'facility', // 设施管理类型&设备
    SECURITY = 'security', // 综合安防类型&设备
    PEDESTRIAN = 'pedestrian', // 通行管理-人行管理
    VEHICLE = 'vehicle', // 通行管理-车辆管理
    PARKING_SPACE ='parking', // 通行管理-车位管理
    ENERGY ='energy', // 能源管理类型
    EnergyManage = 'energy-manage',
    HVAC = 'hvac', // 暖通
    LIGHTING = 'lighting', // 照明
    COLD_HEAT_SOURCE = 'cold-heat-source', // DHC
    // Elevator = 'elevator', // 电梯
    // EnvironmentalQuality = 'environmental-quality', // 环境质量
    ENGINE_ROOM = 'engine-room', // 机房
    FACILITY_OTHER = 'facility-other', // 设施其他

    SECURITY_HOUSE = 'security-house', // 安保岗亭
    // ShuttleBus = 'shuttle-bus', // 班车管理
    ACCESS_OTHER = 'access-other', // 其他
    CUSTOMER_FLOW = 'customer-flow', // 客流管理

    CAMERA = 'camera', // 摄像头
    FIRE = 'fire', // 消防
    SECURITY_OTHER = 'security-other', // 安防其他
    PUBLIC_OPINION = 'public-opinion', // 舆情监控

    DRAINAGE = 'water-supply-and-drainage', // 给排水
    POWER_SUPPLY = 'power-supply-and-distribution', // 供配电
    ELEVATOR_MANAGE = 'elevator-manage', // 电梯管理
}

export enum EnergyType {
    // 能源-建筑
    BUILDING = 'building',
    // 能源-分项
    SUB_METERING = 'sub',
    // 能源-分项
    DEPARTMENT = 'depart'
}

// 服务台 - 服务类型
export enum ServiceType{
    // 事件管理
    EVENT_MANAGEMENT = 'EventManagement',
    // 服务请求
    SERVICE_REQUEST = 'ServiceRequest',
    // 投诉管理
    COMPLAINT_MANAGEMENT = 'ComplaintManagement'

}

export enum WorkOrderMenuType {
    ALL = 'All',
    CREATEDBY = 'CreatedBy',
    TOCLAIM = 'ToClaim',
    ASSIGNEDTO = 'AssignedTo',
    RELATED ='Related'
}

export enum TimePlanRuleType {
    DAY = 1,
    WEEK = 3,
    MONTH = 2,
    SEASON = 4,
    YEAR = 5
}

export enum WorkOrderProcessGroupType {
    WAIT = 'wait', // 待处理
    HANDLE = 'handle', // 处理中
    DONE = 'done' // 已完成
}

export enum SpaceType {
    BUILDING = 'BUILDING',
    FLOOR = 'FLOOR',
    SPACE = 'SPACE',
    UNIT = 'UNIT'
}

export enum FacilityBindType {
    STATS = 'STATS',
    STATS_ATTR = 'STATS_ATTR'
}

export enum FacilityLinkType {
    DEVICE = 'DEVICE',
    DEVICE_ATTR = 'DEVICE_ATTR',
    NONE = 'NONE'
}

export enum TemplateType {
    DEVICE = 'device',
    ATTRIBUTE = 'attribute',
    PROTOCOL = 'protocol'
}

export enum FormControlSelectDeviceAttributeType {
    DEVICE_TYPE = 'DEVICE_TYPE',
    FACILITY_CONFIG = 'FACILITY_CONFIG',
    DEFAULT = 'DEFAULT'
}

export enum GraphSysName {
    CUSTOMER_FLOW_SYSTEM = 'CUSTOMER_FLOW_SYSTEM',
    CAR_PARK_SYSTEM = 'CAR_PARK_SYSTEM',
    VIDEO_SYSTEM = 'VIDEO_SYSTEM',
    DHC_SYSTEM = 'DHC_SYSTEM',
    HVAC_SYSTEM = 'HVAC_SYSTEM'
}

export enum GraphGroupName {
    CUSTOMER_FLOW_AREA = 'CUSTOMER_FLOW_AREA',
    CUSTOMER_FLOW_FLOOR = 'CUSTOMER_FLOW_FLOOR',
    CAR_PACKING_AREA = 'CAR_PACKING_AREA',
    CAR_PACKING_FLOOR = 'CAR_PACKING_FLOOR',
    VIDEO_AREA = 'VIDEO_AREA',
    VIDEO_FLOOR = 'VIDEO_FLOOR',
    DHC_PROFESSION = 'DHC_PROFESSION',
    HVAC_PROFESSION = 'HVAC_PROFESSION'
}

export enum PermissionType {
    PLATFORM = 'PLATFORM',
    CORP = 'CORP',
    PROJECT = 'PROJECT'
}

export enum ReviewResult {
    PASS = 'PASS',
    REJECT = 'REJECT',
    DISABLE = 'DISABLE'
}

export enum AlarmRuleType {
    EVENT = 'EVENT',
    THRESHOLD = 'THRESHOLD'
}

export enum AlarmRuleStatus {
    WORKING = 'true',
    NOT_START = 'false'
}

export enum ParkingGateType {
    ENTRANCE = 'ENTRANCE',
    EXIT= 'EXIT'
}

export enum PeriodTypeEnum {
    HOUR = 'HOUR',
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    SEASON = 'SEASON',
    YEAR = 'YEAR',
    CUSTOM = 'CUSTOM'
}

export enum TimeDimensionEnum {
    MINUTE = 'MINUTE',
    FIVE_MINUTE = 'FIVE_MINUTE',
    HOUR = 'HOUR',
    DAY = 'DAY'
}

export enum ParkingSpaceTypeEnum {
    USING = 'USING',
    FREE = 'FREE',
    RATE = 'RATE'
}

export enum SubSystemType {
    ALL = 'ALL',
    PARKING = 'PARKING',
    FIRE = 'FIRE',
    CAMERA = 'CAMERA',
    ENVIROMENT = 'ENVIROMENT',
    ENERGY = 'ENERGY',
    BA = 'BA',
    CUSTOMER_FLOW = 'CUSTOMER_FLOW',
    ALARM = 'ALARM',
    ALARM_HISTORY = 'ALARM_HISTORY',
    ELEVATOR = 'elevatorSystem',
    LIGHT = 'lightingSystem',
    ACCESS_CONTROL = 'accessControlSystem',
    COMPUTER_ROOM = 'computerRoomSystem',
    PATROL = 'patrolSystem'
}

export enum ChartType {
    GAUGE = 'gauge',
    LINE = 'line',
    PIE = 'pie',
    BAR = 'bar',
    HISTOGRAM = 'histogram'
}

export enum SubSystemNumberEnum {
    ParkingSpaceTotal= 'ParkingSpaceTotal',
    ParkingFacilityTotal = 'ParkingFacilityTotal',
    ParkingIncomeTotal= 'ParkingIncomeTotal',
    ParkingFacilityFault = 'ParkingFacilityFault',
    ParkingAlarmUnsolved = 'ParkingAlarmUnsolved',
    FireAlarmActive = 'FireAlarmActive',
    FireAlarmUnsolved = 'FireAlarmUnsolved',
    FireAlarmTotal = 'FireAlarmTotal',
    FireFacilityFault = 'FireFacilityFault',
    FireFacilityTotal = 'FireFacilityTotal',
    CameraFacilityTotal = 'CameraFacilityTotal',
    CameraFacilityOnline = 'CameraFacilityOnline',
    CameraFacilityOffline = 'CameraFacilityOffline',
    CameraAlarmTotal = 'CameraAlarmTotal',
    CameraAlarmUnsolved = 'CameraAlarmUnsolved',
    EnvFacilityTotal = 'EnvFacilityTotal',
    EnvFacilityOnline = 'EnvFacilityOnline',
    EnvAlarmTotal = 'EnvAlarmTotal',
    EnvAlarmActive = 'EnvAlarmActive',
    EnvAlarmUnsolved = 'EnvAlarmUnsolved',
    ElecToday = 'ElecToday',
    ElecMonth = 'ElecMonth',
    ElecYear = 'ElecYear',
    ElecAlarmActive = 'ElecAlarmActive',
    ElecFacilityFault = 'ElecFacilityFault',
    BAFacilityTotal = 'BAFacilityTotal',
    BAFcilityRunning = 'BAFcilityRunning',
    BAFacilityFault = 'BAFacilityFault',
    BAAlarmActive = 'BAAlarmActive',
    BAAlarmUnsolved = 'BAAlarmUnsolved',
    CustRetainedCount = 'CustRetainedCount',
    CustHourInCount = 'CustHourInCount',
    CustHourOutCount = 'CustHourOutCount',
    CustTodayInCount = 'CustTodayInCount',
    CustMonthInCount = 'CustMonthInCount',
    ElevatorFacilityTotal = 'ElevatorFacilityTotal',
    ElevatorFacilityNormal = 'ElevatorFacilityNormal',
    ElevatorFacilityFault = 'ElevatorFacilityFault',
    ElevatorAlarmActive = 'ElevatorAlarmActive',
    ElevatorAlarmUnsoloved = 'ElevatorAlarmUnsoloved',
    LightTodayCount = 'LightTodayCount',
    LightMonthCount = 'LightMonthCount',
    LightFacilityTotal = 'LightFacilityTotal',
    LightFacilityFault = 'LightFacilityFault',
    LightAlarmActive = 'LightAlarmActive',
    ACCount = 'ACCount',
    ACFacilityTotal = 'ACFacilityTotal',
    ACFacilityFault = 'ACFacilityFault',
    ACAlarmToday = 'ACAlarmToday',
    ACAlarmActive = 'ACAlarmActive'
}

export enum SubSystemChartEnum {
    ParkingStatus = 'ParkingStatus',
    ParkingTodayTrafficFlow = 'ParkingTodayTrafficFlow',
    ParkingEntranceType = 'ParkingEntranceType',
    ParkingAlarmStatus = 'ParkingAlarmStatus',
    ParkingGateRank = 'ParkingGateRank',
    ParkingFacilityStatus = 'ParkingFacilityStatus',
    FireAlarmDistribution = 'FireAlarmDistribution',
    FireAlarmStatus = 'FireAlarmStatus',
    FireAlarmTrend = 'FireAlarmTrend',
    FireAlarmLevel = 'FireAlarmLevel',
    FireAlarmRank = 'FireAlarmRank',
    FireFacilityStatus = 'FireFacilityStatus',
    CameraFacilityStatus = 'CameraFacilityStatus',
    CameraAlarmDistribution = 'CameraAlarmDistribution',
    CameraAlarmTrend = 'CameraAlarmTrend',
    CameraAlarmStatus = 'CameraAlarmStatus',
    EnvAirQuality = 'EnvAirQuality',
    EnvTemp = 'EnvTemp',
    EnvAlarmDistribution = 'EnvAlarmDistribution',
    EnvAlarmStatus = 'EnvAlarmStatus',
    EnvAlarmTrend = 'EnvAlarmTrend',
    EnvAlarmLevel = 'EnvAlarmLevel',
    ElecCount = 'ElecCount',
    ElecType = 'ElecType',
    ElecMonth = 'ElecMonth',
    ElecAlarmStatus = 'ElecAlarmStatus',
    BARunningSpan = 'BARunningSpan',
    BAWaterPressure = 'BAWaterPressure',
    BAFacilityStatus = 'BAFacilityStatus',
    BAFacilityFaultType = 'BAFacilityFaultType',
    BAAlarmRank = 'BAAlarmRank',
    BAAlarmStatus = 'BAAlarmStatus',
    BAAlarmTrend = 'BAAlarmTrend',
    BAAlarmLevel = 'BAAlarmLevel',
    CustRetainedDistribution = 'CustRetainedDistribution',
    CustInCountDistribution = 'CustInCountDistribution',
    CustFlowTrend = 'CustFlowTrend',
    CustWeeklyFlowTrend = 'CustWeeklyFlowTrend',
    CustAlarmRank = 'CustAlarmRank',
    CustAlarmStatus = 'CustAlarmStatus',
    CustAlarmTrend = 'CustAlarmTrend',
    CustAlarmLevel = 'CustAlarmLevel',
    ElevatorFacilityStatus = 'ElevatorFacilityStatus',
    ElevatorAlarmStatus = 'ElevatorAlarmStatus',
    ElevatorFacilityDistribution = 'ElevatorFacilityDistribution',
    ElevatorAlarmRank = 'ElevatorAlarmRank',
    LightRate = 'LightRate',
    LightFacilityStatus = 'LightFacilityStatus',
    LightAlarmTrend = 'LightAlarmTrend',
    LightMonthStatus = 'LightMonthStatus',
    ACTrend = 'ACTrend',
    ACType = 'ACType',
    ACAlarmStatus = 'ACAlarmStatus',
    ACAlarmTrend = 'ACAlarmTrend'
}

export enum MonitorType {
    CONTAINER = 'CONTAINER',
    VIRTUAL = 'VIRTUAL',
    SERVICE = 'SERVICE'
}

export enum NodeType {
    TABLE = 'table',
    KAFKA = 'kafka'
}

export enum ResourceType {
    PAGE = 'PAGE',
    FUNCTION = 'FUNCTION',
    SERVICE = 'SERVICE'
}

export enum RuleType {
    CUSTOMIZATION = 'CUSTOMIZATION',
    DEBOUNCING = 'DEBOUNCING',
    BASE = 'BASE'
}

export enum PeriodType {
    REALTIME = 'REALTIME',
    FIX_PERIOD = 'FIX_PERIOD',
    NONE = 'NONE'
}

export enum RepairStatus {
    REPAIR_WAIT = 'REPAIR_WAIT',
    REPAIR_HANDLE = 'REPAIR_HANDLE',
    REPAIR_DONE = 'REPAIR_DONE',
    REPAIR_TERMINATION = 'REPAIR_TERMINATION',
    REPAIR_ON_HOLD = 'REPAIR_ON_HOLD'
}

export enum InspectionStatus {
    INSPECTION_WAIT = 'INSPECTION_WAIT',
    INSPECTION_WAIT_TIMEOUT = 'INSPECTION_WAIT_TIMEOUT',
    INSPECTION_DONE = 'INSPECTION_DONE',
    INSPECTION_DONE_TIMEOUT = 'INSPECTION_DONE_TIMEOUT',
    INSPECTION_TERMINATION = 'INSPECTION_TERMINATION'
}

export enum MaintenanceStatus {
    MAINTENANCE_WAIT = 'MAINTENANCE_WAIT',
    MAINTENANCE_WAIT_TIMEOUT = 'MAINTENANCE_WAIT_TIMEOUT',
    MAINTENANCE_DONE = 'MAINTENANCE_DONE',
    MAINTENANCE_DONE_TIMEOUT = 'MAINTENANCE_DONE_TIMEOUT',
    MAINTENANCE_TERMINATION = 'MAINTENANCE_TERMINATION'
}

export enum InventoryStatus {
    WAIT = 'WAIT',
    CHECKING = 'CHECKING',
    VERIFYING = 'VERIFYING',
    COMPLETED = 'COMPLETED',
    CLOSED = 'CLOSED'
}

export enum AssetInventoryType {
    UNCHECK = 'uncheck',
    CHECKED = 'checked',
    SURPLUS = 'surplus'
 }

export enum CRUDQType {
    CREATE = 'create',
    RETRIEVE = 'retrieve',
    UPDATE = 'update',
    DELETE = 'delete',
    QUERY = 'query',
}

export enum TableTreeType {
    DEVICE = 'device',
    DRIVERINSTANCELOG = 'driverInstanceLog',
}

// 监控列表-监控类型
export enum MonitorListType{
    PERFORMANCE = 'PERFORMANCE', // 性能
    CONNECTION = 'CONNECTION', // 连接
    DATA = 'DATA', // 数据
    DRIVERINSTANCE = 'EDGE_SERVICE', // 驱动实例,
    EDGEWORKLOAD = 'EDGE_WORKLOAD' // 工作负载
}

export enum FileExportType {
    EXPORT = 'export',
    IMPORT = 'import',
}

export enum NodeImportType {
    GATEWAY = 'gateway',
    SDK = 'sdk',
}

export enum DeploymentStatus {
    UN_DEPLOYED = 'UN_DEPLOYED', // 不部署
    DEPLOY_SUCCESS = 'DEPLOY_SUCCESS', // 部署成功
    DEPLOY_FAILED = 'DEPLOY_FAILED', // 部署失败
    OFFLINE = 'OFFLINE' // 下线
}
