import { TopoDataType } from '../model/enum';

const topoDataTypeList: Array<{name: string, value: TopoDataType}> = _.filter(_.map(TopoDataType, item => {
    let name: string = null;
    switch (item) {
        case TopoDataType.STATIC:
            name = '固定数据';
            break;
        case TopoDataType.HTTP:
            name = 'API';
            break;
        case TopoDataType.INNER_HTTP:
            name = '内置API';
            break;
        case TopoDataType.PARENT:
            name = '从父组件获取';
            break;
    }
    return { name, value: item };
}), item => item.name) as any;

const topoDataTypeFilter = (val: TopoDataType) => {
    return _.get(_.find(topoDataTypeList, item => item.value === val), 'name') || '-';
};

export {
    topoDataTypeFilter,
    topoDataTypeList
};
