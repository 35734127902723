var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jtl-input-group-component" },
    [
      _c(
        "jtl-button",
        {
          attrs: { type: "primary", disabled: _vm.disabled },
          on: {
            click: function ($event) {
              return _vm.addEnumClick()
            },
          },
        },
        [_vm._v(_vm._s(_vm.addDesc))]
      ),
      _c(
        "a-row",
        [
          _c("a-col", { attrs: { span: 11 } }, [_vm._v(_vm._s(_vm.valueDesc))]),
          _c("a-col", { attrs: { span: 2 } }),
          _c("a-col", { attrs: { span: 11 } }, [_vm._v(_vm._s(_vm.nameDesc))]),
        ],
        1
      ),
      _vm._l(_vm.options, function (item, index) {
        return _c(
          "a-row",
          { key: index },
          [
            _c(
              "a-col",
              { attrs: { span: 11 } },
              [
                _c("a-input", {
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "编号如'0'",
                    "allow-clear": "",
                  },
                  on: { change: _vm.change },
                  model: {
                    value: item.value,
                    callback: function ($$v) {
                      _vm.$set(item, "value", $$v)
                    },
                    expression: "item.value",
                  },
                }),
              ],
              1
            ),
            _c("a-col", { staticClass: "text-center", attrs: { span: 2 } }, [
              _vm._v("~"),
            ]),
            _c(
              "a-col",
              { attrs: { span: 11 } },
              [
                _c("a-input", {
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "对该枚举项的描述",
                    "allow-clear": "",
                  },
                  on: { change: _vm.change },
                  model: {
                    value: item.name,
                    callback: function ($$v) {
                      _vm.$set(item, "name", $$v)
                    },
                    expression: "item.name",
                  },
                }),
                _c(
                  "a",
                  {
                    staticClass: "jtl-link",
                    staticStyle: {
                      position: "absolute",
                      width: "36px",
                      "margin-left": "8px",
                    },
                    attrs: { disabled: _vm.disabled },
                    on: {
                      click: function ($event) {
                        return _vm.removeEnumClick(index)
                      },
                    },
                  },
                  [_vm._v("删除")]
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }