import { FormControl, FormControlOptionModel, FormControlTextModel, FormControlType } from '@common-src/model/form-control';
import TopoStyleBaseModel from './topo-style-base-model';

export default class TopoStyleLineModel extends TopoStyleBaseModel {
    @FormControl({
        label: 'Line Width',
        type: FormControlType.NUMBER,
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' },
        index: 91
    } as FormControlTextModel)
    lineWidth: number = undefined;
    points: Array<any> = undefined;
}

export class TopoStyleLineDashedModel extends TopoStyleLineModel {
    setLineDash: string = undefined; // "8, 5"
    @FormControl({
        label: 'Dot Space',
        type: FormControlType.NUMBER,
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' },
        index: 81
    } as FormControlTextModel)
    dotSpace: number = undefined;

    @FormControl({
        label: 'Dot Width',
        type: FormControlType.NUMBER,
        layout: { labelCol: 8, wrapperCol: 12, width: '100%' },
        index: 82
    } as FormControlTextModel)
    dotWidth:number = undefined;
}
