import { QueryPageModel } from '@common-src/model/query-model';
import { ThingsEntityType, ThingsFunctionType } from '@common-src/model/enum';
import { ThingsModelTypeModel } from './things-model-type-entity';
import { thingsModelTypeFilter, readWriteFilter } from '@common-src/filter';
import { ThingsAttributeEntityModel } from './things-attribute-entity';

export class ThingsExtendAttributeEntityModel extends ThingsAttributeEntityModel {
    static baseUrl = `${IOT_BASE_REQUEST_PATH}/product/attr`;
    constructor(type?: any, entityId?: string) {
        super(type, entityId);
        this.thingsModelTypeModel = new ThingsModelTypeModel();
        this.functionType = ThingsFunctionType.ATTRIBUTE;
    }

    static getTableColumns() {
        return [
            {
                title: '属性名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '标识符',
                dataIndex: 'identifier'
            },
            {
                title: '数据类型',
                dataIndex: 'thingsModelTypeModel.thingsModelType',
                customRender: (text, record, index) => {
                    return thingsModelTypeFilter(text);
                }
            },
            {
                title: '读写类型',
                dataIndex: 'accessMode',
                customRender: (text, record, index) => {
                    return readWriteFilter(text);
                }
            },
            {
                title: '更新时间',
                dataIndex: 'modifiedTime'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }
}

export class ThingsExtendAttributeQueryModel extends QueryPageModel {
    name: string = undefined;

    toService() {
        const data = super.toService();
        data.params = this.name;
        delete data.name;
        return data;
    }
}
