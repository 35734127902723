/**
 * @description: 请求方法
 */
export enum RequestEnum {
    GET = 'GET',
    POST = 'POST',
    PATCH = 'PATCH',
    PUT = 'PUT',
    DELETE = 'DELETE'
}

/**
 * @description:  常用的contentTyp类型
 */
export enum ContentTypeEnum {
    // json
    JSON = 'application/json;charset=UTF-8',
    // json
    TEXT = 'text/plain;charset=UTF-8',
    // form-data 一般配合qs
    FORM_URLENCODED = 'application/x-www-form-urlencoded;charset=UTF-8',
    // form-data  上传
    FORM_DATA = 'multipart/form-data;charset=UTF-8'
}

export const httpMethodList: Array<{label: string, value: RequestEnum}> = _.filter(_.map(RequestEnum, item => {
    let label: string = null;
    switch (item) {
        case RequestEnum.GET:
            label = 'GET';
            break;
        case RequestEnum.POST:
            label = 'POST';
            break;
        case RequestEnum.PUT:
            label = 'PUT';
            break;
        case RequestEnum.DELETE:
            label = 'DELETE';
            break;
    }
    return { label, value: item };
}), item => item.label) as any;
