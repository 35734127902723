var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "things-model-type-component" }, [
    _c(
      "div",
      { staticClass: "jtl-form-component" },
      [
        _vm.formModel
          ? _c(
              "a-form-model",
              {
                ref: _vm.JTL.CONSTANT.DEFAULT_FORM_NAME,
                attrs: { model: _vm.formModel, rules: _vm.formRules },
              },
              [
                _c(
                  "a-form-model-item",
                  {
                    attrs: {
                      label: "数据类型",
                      prop: "thingsModelType",
                      "label-col": { span: 5 },
                      "wrapper-col": { span: 12 },
                    },
                  },
                  [
                    _c(
                      "a-select",
                      {
                        attrs: {
                          placeholder: "请选择数据类型",
                          name: "thingsModelType",
                          disabled: _vm.disabled,
                        },
                        on: { change: _vm.thingsModelTypeChange },
                        model: {
                          value: _vm.formModel.thingsModelType,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModel, "thingsModelType", $$v)
                          },
                          expression: "formModel.thingsModelType",
                        },
                      },
                      _vm._l(_vm.ThingsModelTypeList, function (option) {
                        return _c(
                          "a-select-option",
                          {
                            key: option.value,
                            attrs: {
                              value: option.value,
                              disabled: option.disabled,
                            },
                          },
                          [
                            option.disabled
                              ? _c(
                                  "a-popover",
                                  {
                                    attrs: { content: option.disabledMessage },
                                  },
                                  [_vm._v(_vm._s(option.name))]
                                )
                              : _c("span", [_vm._v(_vm._s(option.name))]),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                [
                  _vm.ThingsModelType.STRING,
                  _vm.ThingsModelType.PASSWORD,
                  _vm.ThingsModelType.TEXT,
                ].indexOf(_vm.formModel.thingsModelType) > -1
                  ? _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: _vm.DataStringText,
                          prop: "dataString",
                          "label-col": { span: 5 },
                          "wrapper-col": { span: 12 },
                        },
                      },
                      [
                        _c("a-input", {
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请输入" + _vm.DataStringText,
                            name: "dataString",
                            "allow-clear": "",
                          },
                          model: {
                            value: _vm.formModel.dataString,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModel, "dataString", $$v)
                            },
                            expression: "formModel.dataString",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                [
                  _vm.ThingsModelType.INT,
                  _vm.ThingsModelType.FLOAT,
                  _vm.ThingsModelType.DOUBLE,
                ].indexOf(_vm.formModel.thingsModelType) > -1
                  ? _c(
                      "div",
                      [
                        _c(
                          "a-form-model-item",
                          {
                            staticStyle: { width: "50%" },
                            attrs: {
                              label: "取值范围",
                              prop: "dataMin",
                              "label-col": { span: 10 },
                              "wrapper-col": { span: 14 },
                            },
                          },
                          [
                            _c("a-input", {
                              staticStyle: { width: "150px" },
                              attrs: {
                                disabled: _vm.disabled,
                                placeholder: "最小值",
                                "allow-clear": "",
                              },
                              model: {
                                value: _vm.formModel.dataMin,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formModel, "dataMin", $$v)
                                },
                                expression: "formModel.dataMin",
                              },
                            }),
                            _c(
                              "span",
                              {
                                staticClass: "text-center",
                                staticStyle: {
                                  display: "inline-block",
                                  width: "calc(100% - 150px)",
                                },
                              },
                              [_vm._v(" ~ ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            staticStyle: { width: "50%" },
                            attrs: {
                              prop: "dataMax",
                              "wrapper-col": { span: 14 },
                            },
                          },
                          [
                            _c("a-input", {
                              staticStyle: { width: "150px" },
                              attrs: {
                                disabled: _vm.disabled,
                                placeholder: "最大值",
                                "allow-clear": "",
                              },
                              model: {
                                value: _vm.formModel.dataMax,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formModel, "dataMax", $$v)
                                },
                                expression: "formModel.dataMax",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                [
                  _vm.ThingsModelType.INT,
                  _vm.ThingsModelType.FLOAT,
                  _vm.ThingsModelType.DOUBLE,
                ].indexOf(_vm.formModel.thingsModelType) > -1
                  ? _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: "步长",
                          prop: "step",
                          "label-col": { span: 5 },
                          "wrapper-col": { span: 12 },
                        },
                      },
                      [
                        _c("a-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请输入步长",
                            name: "step",
                          },
                          model: {
                            value: _vm.formModel.step,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModel, "step", $$v)
                            },
                            expression: "formModel.step",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.setValue &&
                [
                  _vm.ThingsModelType.INT,
                  _vm.ThingsModelType.FLOAT,
                  _vm.ThingsModelType.DOUBLE,
                ].indexOf(_vm.formModel.thingsModelType) > -1
                  ? _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: "单位",
                          prop: "unit",
                          "label-col": { span: 5 },
                          "wrapper-col": { span: 12 },
                        },
                      },
                      [
                        _c(
                          "a-select",
                          {
                            attrs: {
                              disabled: _vm.disabled,
                              placeholder: "请选择单位",
                              name: "unit",
                              "show-search": "",
                              "option-filter-prop": "title",
                              "allow-clear": true,
                            },
                            model: {
                              value: _vm.formModel.unit,
                              callback: function ($$v) {
                                _vm.$set(_vm.formModel, "unit", $$v)
                              },
                              expression: "formModel.unit",
                            },
                          },
                          _vm._l(_vm.unitList, function (option) {
                            return _c(
                              "a-select-option",
                              {
                                key: option.value,
                                attrs: {
                                  value: option.value,
                                  title: option.name + " | " + option.value,
                                },
                              },
                              [
                                option.disabled
                                  ? _c(
                                      "a-popover",
                                      {
                                        attrs: {
                                          content: option.disabledMessage,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            option.name + " | " + option.value
                                          )
                                        ),
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          option.name + " | " + option.value
                                        )
                                      ),
                                    ]),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.formModel.thingsModelType === _vm.ThingsModelType.DATE
                  ? _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: "时间格式",
                          "label-col": { span: 5 },
                          "wrapper-col": { span: 12 },
                        },
                      },
                      [
                        _c("a-input", {
                          attrs: {
                            value: "String类型的UTC时间戳(毫秒)",
                            disabled: "",
                            "allow-clear": "",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.formModel.thingsModelType === _vm.ThingsModelType.BOOLEAN
                  ? _c(
                      "div",
                      [
                        _c(
                          "a-form-model-item",
                          {
                            attrs: {
                              label: "布尔值",
                              prop: "dataFalseName",
                              "label-col": { span: 5 },
                              "wrapper-col": { span: 12 },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "form-item__data-bool-false" },
                              [
                                _vm._v(" false （0）- "),
                                _c("a-input", {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    placeholder: "如：关",
                                    "allow-clear": "",
                                  },
                                  model: {
                                    value: _vm.formModel.dataFalseName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formModel,
                                        "dataFalseName",
                                        $$v
                                      )
                                    },
                                    expression: "formModel.dataFalseName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            staticStyle: { "margin-left": "20.8%" },
                            attrs: {
                              prop: "dataTrueName",
                              "wrapper-col": { span: 12 },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "form-item__data-bool-true" },
                              [
                                _vm._v(" true （1）- "),
                                _c("a-input", {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    placeholder: "如：开",
                                    "allow-clear": "",
                                  },
                                  model: {
                                    value: _vm.formModel.dataTrueName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formModel,
                                        "dataTrueName",
                                        $$v
                                      )
                                    },
                                    expression: "formModel.dataTrueName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.formModel.thingsModelType === _vm.ThingsModelType.JSON
                  ? _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: "JSON对象",
                          prop: "jsonParams",
                          "label-col": { span: 5 },
                          "wrapper-col": { span: 12 },
                        },
                      },
                      [
                        _c("data-param-component", {
                          attrs: { "form-name": "jsonParams" },
                          on: {
                            delete: function ($event) {
                              return _vm.deleteDataParam($event)
                            },
                            edit: function ($event) {
                              return _vm.editDataParam($event)
                            },
                          },
                          model: {
                            value: _vm.formModel.jsonParams,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModel, "jsonParams", $$v)
                            },
                            expression: "formModel.jsonParams",
                          },
                        }),
                        !_vm.disabled
                          ? _c(
                              "a",
                              {
                                staticClass: "jtl-link",
                                on: {
                                  click: function ($event) {
                                    return _vm.addDataParam()
                                  },
                                },
                              },
                              [_vm._v("+增加参数")]
                            )
                          : _vm._e(),
                        _c("data-param-edit-dialog", {
                          ref: "dataParamEditDialog",
                          on: {
                            dialogOK: function ($event) {
                              return _vm.dataParamFormDialogOK($event)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.formModel.thingsModelType === _vm.ThingsModelType.ENUM
                  ? _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: "枚举项",
                          prop: "enumList",
                          "label-col": { span: 5 },
                          "wrapper-col": { span: 12 },
                        },
                      },
                      [
                        _c(
                          "a-row",
                          [
                            _c("a-col", { attrs: { span: 11 } }, [
                              _vm._v("参数值"),
                            ]),
                            _c("a-col", { attrs: { span: 2 } }),
                            _c("a-col", { attrs: { span: 11 } }, [
                              _vm._v("参数描述"),
                            ]),
                          ],
                          1
                        ),
                        _vm._l(_vm.formModel.enumList, function (item, index) {
                          return _c(
                            "a-row",
                            { key: index },
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 11 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      staticClass: "margin-0",
                                      attrs: {
                                        prop: "enumList." + index + ".value",
                                        rules: {
                                          required: true,
                                          message: "参数值是必填项",
                                          trigger: "blur",
                                        },
                                      },
                                    },
                                    [
                                      _c("a-input", {
                                        attrs: {
                                          disabled: _vm.disabled,
                                          placeholder: "编号如'0'",
                                          "allow-clear": "",
                                        },
                                        model: {
                                          value: item.value,
                                          callback: function ($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { span: 2 },
                                },
                                [_vm._v("~")]
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 11 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      staticClass: "margin-0",
                                      attrs: {
                                        prop: "enumList." + index + ".name",
                                        rules: {
                                          required: true,
                                          message: "参数描述是必填项",
                                          trigger: "blur",
                                        },
                                      },
                                    },
                                    [
                                      _c("a-input", {
                                        attrs: {
                                          disabled: _vm.disabled,
                                          placeholder: "对该枚举项的描述",
                                          "allow-clear": "",
                                        },
                                        model: {
                                          value: item.name,
                                          callback: function ($$v) {
                                            _vm.$set(item, "name", $$v)
                                          },
                                          expression: "item.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  index > 0 && !_vm.disabled
                                    ? _c(
                                        "a",
                                        {
                                          staticClass:
                                            "jtl-link enum-remove-link",
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeEnumClick(index)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        !_vm.disabled
                          ? _c(
                              "a",
                              {
                                staticClass: "jtl-link",
                                on: {
                                  click: function ($event) {
                                    return _vm.addEnumClick()
                                  },
                                },
                              },
                              [_vm._v("+增加枚举项")]
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                [_vm.ThingsModelType.ENUM, _vm.ThingsModelType.BOOLEAN].indexOf(
                  _vm.formModel.thingsModelType
                ) > -1
                  ? _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: "默认值",
                          prop: "value",
                          "label-col": { span: 5 },
                          "wrapper-col": { span: 12 },
                        },
                      },
                      [
                        _c(
                          "a-radio-group",
                          {
                            model: {
                              value: _vm.DefaultFormValue,
                              callback: function ($$v) {
                                _vm.DefaultFormValue = $$v
                              },
                              expression: "DefaultFormValue",
                            },
                          },
                          _vm._l(_vm.DefaultOptions, function (option) {
                            return _c(
                              "a-radio",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: option.value && option.name,
                                    expression: "option.value && option.name",
                                  },
                                ],
                                key: option.value,
                                attrs: { value: option.value },
                              },
                              [_vm._v(" " + _vm._s(option.name) + " ")]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : [_vm.ThingsModelType.PASSWORD].indexOf(
                      _vm.formModel.thingsModelType
                    ) > -1
                  ? _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: "默认值",
                          prop: "value",
                          "label-col": { span: 5 },
                          "wrapper-col": { span: 12 },
                        },
                      },
                      [
                        _c("a-input-password", {
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请输入默认值",
                          },
                          model: {
                            value: _vm.formModel.value,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModel, "value", $$v)
                            },
                            expression: "formModel.value",
                          },
                        }),
                      ],
                      1
                    )
                  : [_vm.ThingsModelType.JSON].indexOf(
                      _vm.formModel.thingsModelType
                    ) === -1
                  ? _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: "默认值",
                          prop: "value",
                          "label-col": { span: 5 },
                          "wrapper-col": { span: 12 },
                        },
                      },
                      [
                        _c("a-input", {
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请输入默认值",
                            "allow-clear": "",
                          },
                          model: {
                            value: _vm.formModel.value,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModel, "value", $$v)
                            },
                            expression: "formModel.value",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                [
                  _vm.ThingsModelType.INT,
                  _vm.ThingsModelType.FLOAT,
                  _vm.ThingsModelType.DOUBLE,
                ].indexOf(_vm.formModel.thingsModelType) > -1
                  ? _c(
                      "div",
                      [
                        _c(
                          "a-form-model-item",
                          {
                            staticStyle: { width: "50%" },
                            attrs: {
                              label: "参考范围",
                              prop: "refsMin",
                              "label-col": { span: 10 },
                              "wrapper-col": { span: 14 },
                            },
                          },
                          [
                            _c("a-input", {
                              staticStyle: { width: "150px" },
                              attrs: {
                                disabled: _vm.disabled,
                                placeholder: "最小值",
                                "allow-clear": "",
                              },
                              model: {
                                value: _vm.formModel.refs.min,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formModel.refs, "min", $$v)
                                },
                                expression: "formModel.refs.min",
                              },
                            }),
                            _c(
                              "span",
                              {
                                staticClass: "text-center",
                                staticStyle: {
                                  display: "inline-block",
                                  width: "calc(100% - 150px)",
                                },
                              },
                              [_vm._v(" ~ ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            staticStyle: { width: "50%" },
                            attrs: {
                              prop: "refsMax",
                              "wrapper-col": { span: 14 },
                            },
                          },
                          [
                            _c("a-input", {
                              staticStyle: { width: "150px" },
                              attrs: {
                                disabled: _vm.disabled,
                                placeholder: "最大值",
                                "allow-clear": "",
                              },
                              model: {
                                value: _vm.formModel.refs.max,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formModel.refs, "max", $$v)
                                },
                                expression: "formModel.refs.max",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : [
                      _vm.ThingsModelType.ENUM,
                      _vm.ThingsModelType.BOOLEAN,
                    ].indexOf(_vm.formModel.thingsModelType) > -1
                  ? _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: "参考范围",
                          prop: "refs",
                          "label-col": { span: 5 },
                          "wrapper-col": { span: 12 },
                        },
                      },
                      [
                        _c(
                          "a-select",
                          {
                            attrs: { mode: "multiple" },
                            model: {
                              value: _vm.formModel.refs.range,
                              callback: function ($$v) {
                                _vm.$set(_vm.formModel.refs, "range", $$v)
                              },
                              expression: "formModel.refs.range",
                            },
                          },
                          _vm._l(_vm.DefaultOptions, function (option) {
                            return _c(
                              "a-select-option",
                              {
                                key: option.value,
                                attrs: { value: option.value },
                              },
                              [_vm._v(" " + _vm._s(option.name) + " ")]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }