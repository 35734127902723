var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jtl-select-property-component" },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _c(
            "a-select",
            {
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.SelectValue,
                callback: function ($$v) {
                  _vm.SelectValue = $$v
                },
                expression: "SelectValue",
              },
            },
            _vm._l(_vm.selectOptions, function (item) {
              return _c(
                "a-select-option",
                { key: item.value, attrs: { value: item.value } },
                [_vm._v(" " + _vm._s(item.name) + " ")]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }