import { BaseModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlThingsTypeModel } from '@common-src/model/form-control';
import { ThingsModelTypeModel } from './things-model-type-entity';
import { NUMBER_LETTER_UNDERLINE_REGEXP } from '@common-src/model/regexp';
import { ThingsFunctionType } from '@common-src/model/enum';

export class DataParamModel extends BaseModel {
    constructor() {
        super();
        this.thingsModelTypeModel = new ThingsModelTypeModel();
    }

    @FormControl({
        label: '参数名称',
        type: FormControlType.TEXT,
        required: true,
        max: 50
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '标识符',
        type: FormControlType.TEXT,
        required: true,
        max: 50,
        pattern: NUMBER_LETTER_UNDERLINE_REGEXP
    } as FormControlTextModel)
    identifier: string = undefined;

    @FormControl({
        type: FormControlType.THINGS_MODEL_TYPE,
        layout: { labelCol: 0, wrapperCol: 24, width: '100%' },
        thingsType: ThingsFunctionType.EVENT
    } as FormControlThingsTypeModel)
    thingsModelTypeModel: ThingsModelTypeModel = undefined;

    get DisplayName(): string {
        return `${this.name}`;
    }

    toModel(json): any {
        this.identifier = _.get(json, 'identifier');
        this.name = _.get(json, 'name');
        // this.thingsModelTypeModel = new ThingsModelTypeModel().toModel(Object.assign({ dataType: _.get(json, 'type'), specs: _.get(json, 'specs') }));
        this.thingsModelTypeModel = new ThingsModelTypeModel().toModel(json);
        return this;
    }

    toService() {
        const data = {
            name: this.name,
            identifier: this.identifier,
            type: this.thingsModelTypeModel.thingsModelType,
            specs: this.thingsModelTypeModel.toSpecs(),
            unit: this.thingsModelTypeModel.unit
        };
        return data;
    }
}
