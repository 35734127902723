class CacheModel {
    /**
     * 缓存key
     */
    private cacheKey: string = undefined;

    // /**
    //  * 缓存数据
    //  */
    private cacheData: any = undefined;

    /**
     * 缓存过期时间
     * 单位毫秒
     */
    private expires: number = undefined;

    /**
     * 缓存起始时间
     * 单位毫秒
     */
    private startTime: number = undefined;

    /**
     * 定时器
     * 缓存过期时间过后清理缓存
     */
    private timer: NodeJS.Timeout = undefined;

    constructor(key: string, expires?: number) {
        this.cacheKey = key;
        this.expires = expires;
    }

    get CacheData(): any {
        if (this.isExpires()) {
            return null;
        }
        // try {
        //     return JSON.parse(sessionStorage.getItem(this.cacheKey));
        // } catch (e) {
        //     return null;
        // }
        // console.log('Get cache key: ', this.cacheData);
        return _.cloneDeep(this.cacheData);
    }
    set CacheData(data: any) {
        this.cacheData = _.cloneDeep(data);
        // console.log('Set cache key: ', this.cacheData.items);
        // sessionStorage.setItem(this.cacheKey, JSON.stringify(data));
        this.startTime = new Date().getTime();
        if (this.timer) {
            clearTimeout(this.timer);
        }
        if (this.expires) {
            // this.timer = setTimeout(this.cleanCache.bind(this), this.expires);
        }
    }

    public cleanCache() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.cacheData = null;
        // sessionStorage.removeItem(this.cacheKey);
        this.expires = null;
        this.startTime = null;
        CACHE_MAP.delete(this.cacheKey);
        this.cacheKey = null;
    }

    private isExpires(): boolean {
        if (this.expires > 0) {
            return new Date().getTime() - this.startTime > this.expires;
        }
        return false;
    }
}

/**
 * 全局缓存Map对象
 */
const CACHE_MAP: Map<string, CacheModel> = new Map();
window['CACHE_MAP'] = CACHE_MAP;

export class CacheUtil {
    static getCacheData(key: any): any {
        if (typeof (key) !== 'string') {
            key = JSON.stringify(key);
        }
        const cacheModel = CACHE_MAP.get(key);
        if (cacheModel) {
            return cacheModel.CacheData;
        }
        return null;
    }
    static setCacheData(key: any, data: any, expires?: number) {
        if (typeof (key) !== 'string') {
            key = JSON.stringify(key);
        }
        if (!key) {
            return;
        }
        const cacheModel = new CacheModel(key, expires);
        cacheModel.CacheData = data;
        CACHE_MAP.set(key, cacheModel);
    }

    static cleanCache(key?: string): boolean {
        if (!key) {
            CACHE_MAP.clear();
            return true;
        }
        const cacheModel = CACHE_MAP.get(key);
        if (cacheModel) {
            cacheModel.cleanCache();
            CACHE_MAP.delete(key);
            return true;
        }
        return false;
    }
}

export const CACHE_ALL_COMPONENT_KEY = 'CACHE_TOPO_ALL_COMPONENT_KEY';
export const CACHE_ALL_PANEL_COMPONENT_KEY = 'CACHE_ALL_PANEL_COMPONENT_KEY';
