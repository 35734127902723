import kilo from './kilo';

/**
 * 文本格式化
 */
export default (val: string | number | boolean, defaultText: string = '-'): string | number => {
    if (typeof (val) === 'boolean') {
        return (val as boolean).toString();
    }
    if (val !== 0 && (!val || ['null', 'undefined', 'NULL', 'UNDEFINED', 'NaN', null, undefined].indexOf(val as string) > -1)) {
        return defaultText;
    }
    return val;
};

const percentNumber = (text: string | number, decimal = 2) => {
    if (!text && text !== 0) {
        return '-';
    }
    let textNumber: number = null;
    try {
        textNumber = parseFloat(text + '');
    } catch (e) {
        console.warn(e);
        return '-';
    }
    return (textNumber * 100).toFixed(decimal);
};

const percentFormat = (text: string | number, decimal = 2) => {
    const pNumber = percentNumber(text, decimal);
    if (pNumber === '-') {
        return pNumber;
    }
    return `${pNumber}%`;
};

const BYTE_UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB'];
const BIT_UNITS = ['bps', 'Kbps', 'Mbps', 'Gbps'];
function formatByte(text, index) {
    if (text > 1024 && index < 8) {
        return formatByte(text / 1024, index + 1);
    }
    return kilo(text) + BYTE_UNITS[index];
}
function formatBit(text, index) {
    if (text > 1024 && index < 4) {
        return formatBit(text / 1024, index + 1);
    }
    return kilo(text) + BIT_UNITS[index];
}

const byteFormat = (text: string | number) => {
    return formatByte(text, 0);
};
const bitFormat = (text: string | number) => {
    return formatBit(text, 0);
};

export {
    percentNumber,
    percentFormat,
    byteFormat,
    bitFormat
};
