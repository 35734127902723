export default [
    {
        path: 'topo-metrics-list',
        component: () => import('@common-src/pages3/app-page3.vue'),
        meta: { parentMenu: 'dashboardTopo' },
        props: {
            routePath: '/topo-mng/metrics-list',
            padding: '16px'
        }
    },
    {
        path: 'topo-datasource-list',
        component: () => import('@common-src/pages3/app-page3.vue'),
        meta: { parentMenu: 'dashboardTopo' },
        props: {
            routePath: '/topo-mng/datasource-list',
            padding: '16px'
        }
    },
    {
        path: 'topo-page-list',
        component: () => import('@common-src/pages3/app-page3.vue'),
        meta: { parentMenu: 'dashboardTopo' },
        props: {
            routePath: '/topo-mng/page-list',
            padding: '16px'
        }
    },
    {
        path: 'topo-menu-list',
        component: () => import('@common-src/pages3/app-page3.vue'),
        meta: { parentMenu: 'dashboardPicTopo' },
        props: {
            routePath: '/topo-mng/menu-list',
            padding: '16px'
        }
    },
    {
        path: 'topo-meta2d-page-list',
        component: () => import('@common-src/pages3/app-page3.vue'),
        meta: { parentMenu: 'dashboardPicTopo' },
        props: {
            routePath: '/topo-mng/meta2d-page-list',
            padding: '16px'
        }
    },
    {
        path: 'topo-facility-list',
        component: () => import('@common-src/pages3/app-page3.vue'),
        meta: { parentMenu: 'dashboardPicTopo' },
        props: {
            routePath: '/topo-mng/facility-list',
            padding: '16px'
        }
    },
    // {
    //     path: 'topo-business-component',
    //     component: () => import('@common-src/pages3/app-page3.vue'),
    //     meta: { parentMenu: 'dashboardPicTopo' },
    //     props: {
    //         routePath: '/topo-mng/business-component',
    //         padding: '16px'
    //     }
    // },
    {
        path: 'topo-resource-manage',
        component: () => import('@common-src/pages3/app-page3.vue'),
        meta: { parentMenu: 'dashboardPicTopo' },
        props: {
            routePath: '/topo-mng/resource-manage',
            padding: '16px'
        }
    },
    {
        path: 'topo-component-list',
        component: () => import('@common-src/pages3/app-page3.vue'),
        meta: { parentMenu: 'dashboardTopo' },
        props: {
            routePath: '/topo-mng/component-list',
            padding: '16px'
        }
    }
];
