import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel } from '@common-src/model/form-control';
import { SimpleTreeModel } from '@common-src/model/simple-tree-model';
import { GroupEntityType } from '@common-src/model/enum';
import LabelService from '@common-src/service/label';
export class FacilityConfigGroupEntityModel extends SimpleTreeModel {
    // constructor() {
    //     super();
    //     this.type = GroupEntityType.FACILITY_CONFIG;
    // }

    constructor(groupType?: GroupEntityType) {
        super();
        this.type = groupType || GroupEntityType.FACILITY_CONFIG;
    }

    /**
     * 用于验证分组名称是否重复的临时数据
     * 添加同级分组和添加下级分组时，该数组的值是不一样的
     */
    groupNameValidateList: Array<any> = undefined;
    type: GroupEntityType = undefined;

    @FormControl({
        label: '名称',
        type: FormControlType.TEXT,
        required: true,
        max: 50,
        validator: (rule, value, callback) => {
            if (_.filter(rule.formModel.groupNameValidateList, item => (value && item.name === value && rule.formModel.id !== item.id)).length > 0) {
                callback(new Error('分组名称重复，请调整后保存'));
            } else {
                callback();
            }
        }
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '标签',
        type: FormControlType.SELECT,
        optionsPromise: LabelService.getAllLabel.bind(LabelService),
        mode: 'multiple',
        required: false,
        invisibleFunction: model => model.type === GroupEntityType.FACILITY_TYPE
    } as FormControlOptionModel)
    tagIds: string[] = undefined;

    @FormControl({
        label: '标签值',
        type: FormControlType.TEXT,
        required: false,
        invisibleFunction: model => model.type === GroupEntityType.FACILITY_TYPE
    } as FormControlTextModel)
    mark: string = undefined;

    toModel(json): any {
        this.name = this.title = _.get(json, 'name');
        this.type = _.get(json, 'type');
        this.key = this.value = this.id = _.get(json, 'id');
        this.pId = _.get(json, 'parentId');
        if (json && json.children && json.children.length > 0) {
            this.children = _.map(json.children, item => {
                item.type = this.type;
                const child: FacilityConfigGroupEntityModel = new FacilityConfigGroupEntityModel().toModel(item);
                child.parent = this;
                return child;
            });
        } else {
            this.children = [];
        }
        this.tagIds = _.get(json, 'tagIds');
        this.mark = _.get(json, 'mark');
        return this;
    }

    toService() {
        return {
            id: this.id,
            name: this.name,
            parentId: this.pId,
            type: this.type,
            tagIds: this.tagIds,
            mark: this.mark
        };
    }
}
