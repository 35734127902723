import BaseCRUDQService from '@common-src/service3/base-crudq.service';
import { UnitEntityModel, UnitQueryModel } from '@common-src/entity-model/unit-entity';
import { get, post } from './request';
import { ModuleType } from '@common-src/enum/module-type.enum';

const baseUrl = `${COMMON_BASE_REQUEST_PATH}/unit`;
export default class UnitService extends BaseCRUDQService<UnitEntityModel, UnitQueryModel> {
    constructor(baseUrl = UnitEntityModel.baseUrl) {
        super(baseUrl, UnitEntityModel);
    }

    async getAll(): Promise<any> {
        const url = `${baseUrl}/getAll`;
        const res = await get(url);
        return res;
    }
}
