var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "jtl-card-component" }, [
    _c(
      "div",
      { staticClass: "card-header" },
      [
        _vm._t("header", function () {
          return [
            _c(
              "span",
              { staticClass: "card-header-title" },
              [
                _vm._t("title", function () {
                  return [_vm._v(_vm._s(_vm.title))]
                }),
              ],
              2
            ),
            _c("div", { staticClass: "pull-right" }, [_vm._t("extra")], 2),
          ]
        }),
      ],
      2
    ),
    _c("div", { staticClass: "card-body" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }