
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, put, del } from './request';
import { FacilityGroupEntityModel } from '@common-src/entity-model/facility-group-entity';
import { SimpleTreeModel } from '@common-src/model/simple-tree-model';
import { GroupEntityType } from '@common-src/model/enum';
import { ThingsGroupEntityModel } from '@common-src/entity-model/things-group-entity';

const URL_PATH = `${ALARM_CENTER_BASE_REQUEST_PATH}/linkage/group`;

class LinkageGroupService implements ICRUDQ<FacilityGroupEntityModel, any> {
    async create(model: FacilityGroupEntityModel):Promise<FacilityGroupEntityModel> {
        let url = `${URL_PATH}/add`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<any> {
        // let url = `${URL_PATH}/detail/${modelId}`;
        // const res = await get(url);
        // return new ThingsGroupEntityModel().toModel(res);
        /** 由列表数据传入，不走接口 */
        throw new Error('Not implement.');
    }

    async update(model: FacilityGroupEntityModel):Promise<FacilityGroupEntityModel> {
        const url = `${URL_PATH}/update`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: FacilityGroupEntityModel):Promise<FacilityGroupEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(type: any):Promise<any> {
        const url = `${URL_PATH}/tree`;
        let res = await get(url);
        // return res;
        if (!res) {
            res = [];
        }

        res.unshift({
            id: '',
            name: '全部分组',
            type: type
        });

        // res.push({ id: ' ', name: '未分组', type });
        // const allGroup = [{
        //     id: '',
        //     name: '全部分组',
        //     type: type,
        //     children: res
        // }];
        /** 更改为 ThingsGroupEntityModel */
        return _.map(res, item => item = new ThingsGroupEntityModel().toModel(Object.assign({ type }, item)));
    }

    async getGroupTree(type: GroupEntityType):Promise<Array<SimpleTreeModel>> {
        const url = `${URL_PATH}/tree`;
        let res = await get(url);

        // if (hasUnGroup) {
        //     if (!res || res.length === 0) {
        //         res = [];
        //     }
        //     res.push({ id: ' ', name: '未分组' });
        // }
        return _.map(res, item => {
            return new SimpleTreeModel().toModel(item);
        });
    }
}

export default new LinkageGroupService();
