export enum TopoComponentType {
    TEXT = 'text',
    IMAGE = 'image',
    TRIANGLE = 'triangle',
    RECT = 'tect',
    CIRCULAR = 'circular',
    LINE = 'line',
    LINE_ARROW = 'line-arrow',
    BIZIER_CURVE_ARROW = 'bizier-curve-arrow',
    DASHED = 'dashed',

    CHART_LINE = 'chart-line',
    CHART_LINE_STEP = 'chart-line-step',
    CHART_BAR = 'chart-bar',
    CHART_PIE = 'chart-pie',
    CHART_GAUGE = 'chart-gauge',
    SVG_STATIC = 'svg-static',
    SVG_IMAGE = 'svg-image',

    BUTTON = 'button',

    PANEL = 'panel',

    JTL_COMPONENT = 'jtl-component'
}

export enum TopoDataType {
    HTTP = 'http',
    INNER_HTTP = 'inner_http',
    STATIC = 'static',
    PARENT = 'parent'
}

export enum TopoBehaveType {
    CLICK = 'click',
    DBCLICK = 'dbclick',
    MOUSEIN = 'mousein',
    MOUSEOUT = 'mouseout',
    SUBSCRIBE = 'subscribe',
    BROADCAST = 'broadcast'
}

export enum TopoActionType {
    LINK = 'link',
    VISIBLE = 'visible',
    BROADCAST = 'broadcast',
    RELOAD_DATA = 'reload_data',
    RESET_DATA = 'reset_data',
    STYLE = 'style'
}
