
import { ThingsGroupEntityModel } from '@common-src/entity-model/things-group-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, put, del } from './request';
import { SimpleTreeModel } from '@common-src/model/simple-tree-model';
import { GroupEntityType, MonitorType } from '@common-src/model/enum';

const URL_PATH = `${IOT_BASE_REQUEST_PATH}/groups`;
const SYSTEM_MONITOR_GROUP_TREE = [
    // {
    //     id: '',
    //     name: '全部分组',
    //     type: GroupEntityType.SYSTEM_MONITOR
    // },
    // {
    //     id: 'server_machine',
    //     name: '服务器',
    //     children: [
    //         { id: MonitorType.PHYSICAL, name: '物理机', parentId: 'server_machine' },
    //         { id: MonitorType.VIRTUAL, name: '虚拟机', parentId: 'server_machine' },
    //         { id: MonitorType.CONTAINER, name: '容器', parentId: 'server_machine' }
    //     ]
    // },
    { id: MonitorType.CONTAINER, name: '容器' },
    { id: MonitorType.VIRTUAL, name: '主机' },
    { id: MonitorType.SERVICE, name: '服务' }
];

class ThingsGroupService implements ICRUDQ<ThingsGroupEntityModel, any> {
    async create(model: ThingsGroupEntityModel):Promise<ThingsGroupEntityModel> {
        const url = `${URL_PATH}/add`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<ThingsGroupEntityModel> {
        throw new Error('Not implement.');
    }

    async update(model: ThingsGroupEntityModel):Promise<ThingsGroupEntityModel> {
        const url = `${URL_PATH}/update`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: ThingsGroupEntityModel):Promise<ThingsGroupEntityModel> {
        // const url = `${URL_PATH}/delete/${model.id}`;
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(type: any):Promise<any> {
        if (type === GroupEntityType.SYSTEM_MONITOR) {
            return _.map(SYSTEM_MONITOR_GROUP_TREE, item => item = new ThingsGroupEntityModel().toModel(Object.assign({ type }, item)));
        }
        const url = `${URL_PATH}/getByType/${type}`;
        let res = await get(url);
        // return res;

        if (!res) {
            res = [];
        }

        res.unshift({
            id: '',
            name: '全部分组',
            type: type
        });

        // res.push({ id: ' ', name: '未分组', type });
        // const allGroup = [{
        //     id: '',
        //     name: '全部分组',
        //     type: type,
        //     children: res
        // }];
        return _.map(res, item => item = new ThingsGroupEntityModel().toModel(Object.assign({ type }, item)));
    }

    async getGroupTree(type: GroupEntityType, hasUnGroup: boolean = false):Promise<Array<SimpleTreeModel>> {
        const url = `${URL_PATH}/getByType/${type}`;
        let res = await get(url);

        // if (hasUnGroup) {
        //     if (!res || res.length === 0) {
        //         res = [];
        //     }
        //     res.push({ id: ' ', name: '未分组', type });
        // }

        return _.map(res, item => {
            return new SimpleTreeModel().toModel(item);
        });
    }
}

export default new ThingsGroupService();
