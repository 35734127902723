import { FormControl, FormControlOptionModel, FormControlTextModel, FormControlType } from '@common-src/model/form-control';
import { topoDataTypeList } from '../../filter/topo-data-type';
import { TopoDataType } from '../enum';

export default abstract class TopoDataBaseModel {
    @FormControl({
        label: '数据类型',
        type: FormControlType.SELECT,
        options: topoDataTypeList,
        layout: { labelCol: 6, wrapperCol: 16, width: '100%' },
        index: 1
    } as FormControlOptionModel)
    type: TopoDataType = TopoDataType.STATIC;

    @FormControl({
        key: 'ComponentData',
        type: FormControlType.TEXT_AREA,
        rows: 20,
        layout: { labelCol: 0, wrapperCol: 24, width: '100%' },
        invisibleFunction: function(model: TopoDataBaseModel) { return model.type !== TopoDataType.STATIC; },
        index: 2
    } as FormControlTextModel)
    componentData: any = null;

    /**
     * get
     * 右侧属性列表Text Area绑定的字段
     */
    get ComponentData() {
        if (typeof (this.componentData) === 'string') {
            return this.componentData;
        }
        let data = null;
        try {
            data = JSON.stringify(this.componentData);
        } catch (e) {
            data = this.componentData;
        }
        return data;
    }
    /**
     * set
     * 右侧属性列表Text Area绑定的字段
     */
    set ComponentData(val: string) {
        try {
            this.componentData = JSON.parse(val);
        } catch (e) {
            this.componentData = val;
        }
    }

    /**
     * 抽象方法
     * 获取数据的方式
     * @param parentData 父组件的数据
     */
    abstract getData(parentData?: any): any;

    /**
     * 抽象方法
     * 取消获取数据
     */
    abstract cancelGetData();
}
