import { BaseEntityModel, BaseModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { alarmRuleTypeList, alarmRuleStatusList } from '@common-src/filter/alarm';
import { AlarmRuleType, DeviceTypeEnum, GroupEntityType, PropertyEntityType } from '@common-src/model/enum';
import { NUMBER_FLOAT_REGEXP, NUMBER_INT_REGEXP } from '@common-src/model/regexp';
import CommonService from '@common-src/service/common';
import DeviceService from '@common-src/service/device';
import DeviceTypeService from '@common-src/service/device-type';
import FacilityGroupService from '@common-src/service/facility-group';

export const operateList = [
    { label: '>', value: 'GT' },
    { label: '>=', value: 'GTE' },
    { label: '<', value: 'LT' },
    { label: '<=', value: 'LTE' },
    { label: '==', value: 'EQ' },
    { label: '!=', value: 'NOTEQ' },
    { label: 'in', value: 'IN' }
    // { label: 'between', value: 'BTW' },
    // { label: 'contain', value: 'CONTAIN' }
];

export class AlarmRuleEntityModel extends BaseEntityModel {
    @FormControl({
        label: '规则类型',
        type: FormControlType.RADIO_GROUP,
        options: alarmRuleTypeList,
        required: true,
        cascad: true
    } as FormControlOptionModel)
    type: string = AlarmRuleType.EVENT;

    @FormControl({
        label: '规则名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '产品',
        // labelFunction: (model: AlarmRuleEntityModel) => model.type === AlarmRuleType.EVENT ? '事件告警-产品' : '阈值告警-产品',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getDeviceTypeOptions,
        optionsPromiseParam: [DeviceTypeEnum.DEVICE],
        required: true,
        relationKeys: ['alarmAttributeRules', 'alarmEventRules', 'deviceIds']
    } as FormControlOptionModel)
    deviceTypeId: string = undefined;
    productName: string = undefined;

    @FormControl({
        label: '设备列表',
        type: FormControlType.SELECT,
        mode: 'multiple',
        optionsPromise: DeviceService.getDeviceListByTypeId,
        optionsPromiseParam: 'deviceTypeId'
    } as FormControlOptionModel)
    deviceIds: Array<string> = undefined;

    @FormControl({
        label: '规则描述',
        type: FormControlType.ALARM_RULE_ATTRIBUTE,
        // relationPath: `${BI_BASE_REQUEST_PATH}/deviceType/[]/attrs/CLIENT_SCOPE`,
        optionsPromise: DeviceTypeService.getDeviceTypeClientScope,
        optionsPromiseParam: 'deviceTypeId',
        required: true,
        invisibleFunction: model => model.type === AlarmRuleType.EVENT,
        defaultValue: [{ duration: 30 }]
    } as FormControlOptionModel)
    alarmAttributeRules: Array<any> = [{ duration: 30 }];

    @FormControl({
        label: '规则描述',
        type: FormControlType.ALARM_RULE_EVENT,
        // relationPath: `${BI_BASE_REQUEST_PATH}/deviceType/[]/events`,
        optionsPromise: DeviceTypeService.getDeviceTypeEvent,
        optionsPromiseParam: 'deviceTypeId',
        required: true,
        invisibleFunction: model => model.type === AlarmRuleType.THRESHOLD,
        defaultValue: [{ periodTime: 60 }]
    } as FormControlOptionModel)
    alarmEventRules: Array<any> = [{ periodTime: 60 }];

    // @FormControl({
    //     label: '规则描述',
    //     type: FormControlType.SELECT,
    //     relationPath: `${BI_BASE_REQUEST_PATH}/deviceType/[]/attrs/CLIENT_SCOPE`,
    //     required: true,
    //     invisibleFunction: model => model.type === AlarmRuleType.EVENT,
    //     layout: { labelCol: 10, wrapperCol: 14, width: '50% ' },
    //     placeholder: '请选择属性'
    // } as FormControlOptionModel)
    // attributeKey: string = undefined;

    // @FormControl({
    //     // label: '规则',
    //     type: FormControlType.SELECT,
    //     options: [
    //         { name: '大于等于', value: '>=' },
    //         { name: '大于', value: '>' },
    //         { name: '小于等于', value: '<=' },
    //         { name: '小于', value: '<' },
    //         { name: '等于', value: '=' },
    //         { name: '不等于', value: '!=' }
    //     ],
    //     required: true,
    //     invisibleFunction: model => model.type === AlarmRuleType.EVENT,
    //     layout: { labelCol: 0, wrapperCol: 24, width: '10.4% ' },
    //     placeholder: '请选择规则'
    // } as FormControlOptionModel)
    // operate: string = undefined;

    // @FormControl({
    //     // label: '阈值',
    //     type: FormControlType.TEXT,
    //     required: true,
    //     invisibleFunction: model => model.type === AlarmRuleType.EVENT,
    //     layout: { labelCol: 0, wrapperCol: 24, width: '10.4% ' },
    //     placeholder: '阈值'
    // } as FormControlTextModel)
    // value: string = undefined;

    // @FormControl({
    //     label: '事件',
    //     type: FormControlType.SELECT,
    //     relationPath: `${BI_BASE_REQUEST_PATH}/deviceType/[]/events`,
    //     mode: 'multiple',
    //     required: true
    // } as FormControlOptionModel)
    // eventId: string = undefined;
    // eventName: string = undefined;

    @FormControl({
        label: '告警级别',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getPropertyOptions,
        optionsPromiseParam: PropertyEntityType.EVENT_GRADE,
        required: true
    } as FormControlOptionModel)
    grade: string = undefined;
    gradeDesc: string = undefined;

    @FormControl({
        label: '告警周期',
        type: FormControlType.NUMBER,
        pattern: NUMBER_FLOAT_REGEXP,
        suffix: '分钟',
        required: true,
        message: '分钟',
        noMessageTooltip: true
    } as FormControlTextModel)
    cycle: number = undefined;

    @FormControl({
        label: '通知周期',
        type: FormControlType.NUMBER,
        pattern: NUMBER_FLOAT_REGEXP,
        required: true,
        message: '分钟',
        noMessageTooltip: true
    } as FormControlTextModel)
    notifyPeriod: number = undefined;

    // @FormControl({
    //     label: '几次告警',
    //     type: FormControlType.TEXT,
    //     pattern: NUMBER_INT_REGEXP,
    //     suffix: '次',
    //     required: true
    // } as FormControlTextModel)
    // triggerTimes: string = undefined;

    @FormControl({
        label: '通知对象',
        type: FormControlType.SELECT_USER,
        mode: 'multiple',
        required: true
    } as FormControlOptionModel)
    noticeList: Array<any> = [];

    @FormControl({
        label: '通知方式',
        type: FormControlType.CHECKBOX_GROUP,
        options: [
            { name: '微信小程序', value: 'APPLETS' },
            { name: '3D大屏', value: 'TVWALL' }
        ]
    } as FormControlOptionModel)
    noticeWays: Array<string> = undefined;

    @FormControl({
        label: '联动摄像头',
        type: FormControlType.TREE_SELECT,
        optionsPromise: FacilityGroupService.getGroupTree,
        optionsPromiseParam: GroupEntityType.LINKAGE
    } as FormControlOptionModel)
    deviceLinkGroupId: string = undefined;

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    description: string = undefined;

    enable: boolean = undefined;

    static getTableColumns() {
        return [
            {
                title: '规则名称',
                dataIndex: 'name'
            },
            {
                title: '级别',
                dataIndex: 'gradeDesc'
            },
            {
                title: '产品',
                dataIndex: 'productName'
            },
            {
                title: '创建时间',
                dataIndex: 'createdTime'
            },
            {
                title: '运行状态',
                dataIndex: 'enable',
                scopedSlots: { customRender: 'enable' }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        this.deviceTypeId = _.get(json, 'triggerConfigList[0].productId');
        this.deviceIds = _.get(json, 'triggerConfigList[0].deviceIds');
        if (this.cycle > 0) {
            this.cycle = this.cycle / 60;
        }
        if (this.notifyPeriod > 0) {
            this.notifyPeriod = this.notifyPeriod / 60;
        }
        if (this.type === AlarmRuleType.THRESHOLD) {
            this.alarmAttributeRules = _.map(_.get(json, 'triggerConfigList'), item => {
                return {
                    attributeKey: `${item.attributeKey}|${item.dataType}`,
                    compareType: item.compareType,
                    value: item.value,
                    duration: item.duration
                };
            });
            // this.attributeKey = _.get(json, 'condition.attributeKey');
            // this.operate = _.get(json, 'condition.operate');
            // this.value = _.get(json, 'condition.value');
        } else if (this.type === AlarmRuleType.EVENT) {
            this.alarmEventRules = _.map(_.get(json, 'triggerConfigList'), item => {
                return {
                    identifier: item.eventIdentifier,
                    count: item.count,
                    periodTime: item.periodTime
                };
            });
        }
        // this.noticeWays = _.map(_.get(json, 'actionConfigList'), item => item.type);
        this.noticeWays = _.get(json, 'actionConfigList[0].notifyType')?.split(',');
        this.deviceLinkGroupId = _.get(json, 'actionConfigList[0].deviceId');
        const isUser = _.get(json, 'actionConfigList[0].noticeType') === 'USER' ? true : false;
        this.noticeList = _.map(_.get(json, 'actionConfigList[0].notice'), item => ({ id: item.id, name: item.name, isUser }));
        // this.noticeList = _.map(json.noticeList, item => {
        //     return { id: item.entityId, name: item.name, isUser: item.type === 'USER' ? 'USER' : 'ORG' };
        // });
        // if (json.noticeWeixin) {
        //     this.noticeWays.push('noticeWeixin');
        // }
        // if (json.noticeLargeScreen) {
        //     this.noticeWays.push('noticeLargeScreen');
        // }
        return this;
    }

    toService() {
        const data: any = {
            id: this.id,
            type: this.type,
            name: this.name,
            productId: this.deviceTypeId,
            deviceIds: this.deviceIds,
            cycle: this.cycle * 60,
            notifyPeriod: this.notifyPeriod * 60,
            grade: this.grade,
            description: this.description
        };
        data.productId = this.deviceTypeId;
        if (this.type === AlarmRuleType.THRESHOLD) {
            // data.condition = {
            //     attributeKey: this.attributeKey,
            //     operate: this.operate,
            //     value: this.value
            // };
            data.triggerList = _.map(this.alarmAttributeRules, item => {
                const selectAttributeArray = item.attributeKey.split('|');
                return {
                    attributeKey: _.get(selectAttributeArray, '[0]'),
                    dataType: _.get(selectAttributeArray, '[1]'),
                    compareType: item.compareType,
                    value: item.value,
                    duration: item.duration,
                    type: 'DEVICE_ATTRIBUTE'
                };
            });
        } else if (this.type === AlarmRuleType.EVENT) {
            data.triggerList = _.map(this.alarmEventRules, item => {
                return {
                    eventIdentifier: item.identifier,
                    count: item.count,
                    periodTime: item.periodTime,
                    type: 'DEVICE_EVENT'
                };
            });
        }
        data.actionConfigList = [{
            type: 'ALARM',
            deviceId: this.deviceLinkGroupId,
            noticeType: _.get(this.noticeList, '[0].isUser') ? 'USER' : 'ORG',
            notifyType: this.noticeWays?.join(','),
            entityIds: _.map(this.noticeList, item => item.id)
        }];
        // data.actionConfigList = _.map(this.noticeWays, item => ({
        //     type: item,
        //     deviceId: this.deviceLinkGroupId,
        //     noticeType: _.get(this.noticeList, '[0].isUser') ? 'USER' : 'ORG',
        //     entityIds: _.map(this.noticeList, item => item.id)
        // }));
        // data.noticeList = _.map(this.noticeList, item => {
        //     return {
        //         entityId: item.id,
        //         type: item.isUser ? 'USER' : 'ORG'
        //     };
        // });

        // data.noticeWeixin = !!_.find(this.noticeWays, item => item === 'noticeWeixin');
        // data.noticeLargeScreen = !!_.find(this.noticeWays, item => item === 'noticeLargeScreen');

        // delete data.attributeKey;
        // delete data.operate;
        // delete data.value;
        // delete data.deviceTypeId;
        // delete data.noticeWays;
        // delete data.enable;
        // delete data.eventName;
        // delete data.gradeName;
        return data;
    }
}

export class AlarmRuleQueryModel extends QueryPageModel {
    @QueryControl({
        label: '告警级别',
        type: QueryControlType.SELECT,
        optionsPromise: CommonService.getPropertyOptions,
        optionsPromiseParam: PropertyEntityType.EVENT_GRADE,
        hasAllOption: true,
        span: 8
    })
    grade: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '运行状态',
        options: alarmRuleStatusList,
        type: QueryControlType.SELECT,
        hasAllOption: true,
        span: 8
    })
    enable: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        placeholder: '请输入告警名称',
        type: QueryControlType.TEXT,
        span: 8
    })
    name: string = undefined;

    toService() {
        return super.getParams();
    }
}
