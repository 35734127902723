import { AttributeDataType } from '@common-src/model/enum';
const attributeDataTypeList: Array<{name: string, value: AttributeDataType}> = _.filter(_.map(AttributeDataType, item => {
    let name: string = null;
    switch (item) {
        case AttributeDataType.STRING:
            name = 'text(字符串)';
            break;
        case AttributeDataType.INT:
            name = 'int32(整数型)';
            break;
        case AttributeDataType.FLOAT:
            name = 'float(单精度浮点型)';
            break;
        case AttributeDataType.BOOLEAN:
            name = 'bool(布尔型)';
            break;
        case AttributeDataType.JSON:
            name = 'JSON对象';
            break;
    }
    return { name, value: item };
}), item => item.name) as any;
const attributeDataTypeFilter = (val: AttributeDataType) => {
    return _.get(_.find(attributeDataTypeList, item => item.value === val), 'name') || '-';
};
export {
    attributeDataTypeFilter,
    attributeDataTypeList
};
