











import { Component, Vue, Prop } from 'vue-property-decorator';

function isEllipsis(dom) {
    const checkDom = dom.cloneNode();
    checkDom.style.width = dom.offsetWidth + 'px';
    checkDom.style.height = dom.offsetHeight + 'px';
    checkDom.style.overflow = 'auto';
    checkDom.style.position = 'absolute';
    checkDom.style.zIndex = -1;
    checkDom.style.opacity = 0;
    checkDom.style.whiteSpace = 'nowrap';
    checkDom.innerHTML = dom.innerHTML;
    const parent = dom.parentNode;
    parent.appendChild(checkDom);
    const flag = checkDom.scrollWidth > checkDom.offsetWidth;
    parent.removeChild(checkDom);
    return flag;
}

@Component
export default class JTLTooltip extends Vue {
    @Prop()
    title: string;
    @Prop()
    placement: string;

    private isEllipsis: boolean = true;
    private hasEllipsis: boolean = false;

    mounted() {
        this.$nextTick(() => {
            this.isEllipsis = isEllipsis(this.$el);
            this.hasEllipsis = this.isEllipsis;
        });
    }
}

