import { FacilityBindType, FacilityLinkType } from '@common-src/model/enum';
const facilityBindTypeList: Array<{ name: string, value: FacilityBindType }> = _.filter(_.map(FacilityBindType, item => {
    let name: string = null;
    switch (item) {
        case FacilityBindType.STATS:
            name = '绑定自身';
            break;
        case FacilityBindType.STATS_ATTR:
            name = '绑定属性';
            break;
    }
    return { name, value: item };
}), item => item.name) as any;
const facilityBindTypeFilter = (val: FacilityBindType) => {
    return _.get(_.find(facilityBindTypeList, item => item.value === val), 'name') || '-';
};

const facilityLinkTypeList: Array<{ name: string, value: FacilityLinkType }> = _.filter(_.map(FacilityLinkType, item => {
    let name: string = null;
    switch (item) {
        case FacilityLinkType.DEVICE:
            name = '关联设备';
            break;
        case FacilityLinkType.DEVICE_ATTR:
            name = '关联设备属性';
            break;
        // case FacilityLinkType.NONE:
        //     name = '无';
        //     break;
    }
    return { name, value: item };
}), item => item.name) as any;
const facilityLinkTypeFilter = (val: FacilityLinkType) => {
    return _.get(_.find(facilityLinkTypeList, item => item.value === val), 'name') || '-';
};

export {
    facilityBindTypeFilter,
    facilityBindTypeList,
    facilityLinkTypeList,
    facilityLinkTypeFilter
};
