

































import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import moment, { Moment } from 'moment';

@Component
export default class FormEnumTripleComponent extends BaseComponent {
    @Prop()
    value: Array<any>;
    @Prop()
    disabled: boolean;
    @Prop({ default: 'name' })
    labelname?: string;
    @Prop({ default: 'value' })
    valuename: string;
    @Prop({ default: 'value' })
    keyword: string;

    addEnumClick() {
        this.value.push({ [this.keyword]: '', [this.labelname]: '', [this.valuename]: '' });
    }

    removeEnumClick(index: number) {
        this.value.splice(index, 1);
    }

    validate() {
        if (!this.value || this.value.length === 0) {
            this.showMessageWarning('请添加参数配置');
            return false;
        }
        for (let index = 0; index < this.value.length; index++) {
            if (!this.value[index][this.keyword]) {
                this.showMessageWarning('请填写微信KeyWord');
                return false;
            }
            if (!this.value[index][this.valuename]) {
                this.showMessageWarning('请填写字段名称');
                return false;
            }
            if (!this.value[index][this.labelname]) {
                this.showMessageWarning('请填写内容');
                return false;
            }
        }
        return true;
    }
}

