export const ApiMap = {
    login: `${AUTH_BASE_REQUEST_PATH}/noauth/login`,
    loginv2: `${AUTH_BASE_REQUEST_PATH}/noauth/v2/login`,
    getPublicKey: `${AUTH_BASE_REQUEST_PATH}/noauth/getPublicKey`,
    getPermissions: `${AUTH_BASE_REQUEST_PATH}/user/sauth/permissions`,
    getUserInfo: `${AUTH_BASE_REQUEST_PATH}/user/sauth/getUserInfo`,
    getProject: `${AUTH_BASE_REQUEST_PATH}/project/sauth/projects`,
    getAllThingsTemplate: `${IOT_BASE_REQUEST_PATH}/things/template/getAll`,
    getAllDeviceTypeList: `${IOT_BASE_REQUEST_PATH}/product/getAllProducts`,
    getDeviceTypeAttrs: `${IOT_BASE_REQUEST_PATH}/product/attr/{deviceTypeId}`,
    getDeviceTypeEvents: `${IOT_BASE_REQUEST_PATH}/product/event/{deviceTypeId}`,
    getDeviceTypeMethods: `${IOT_BASE_REQUEST_PATH}/product/method/{deviceTypeId}`,
    exportDeviceTypeJson: `${IOT_BASE_REQUEST_PATH}/product/exportByJson`,
    importDeviceTypesByJsonFile: `${IOT_BASE_REQUEST_PATH}/product/importByJson`,
    deviceBatchDelete: `${IOT_BASE_REQUEST_PATH}/device/batchDelete`
};

export function getApiMap(name: string, params: any) {
    let apiUrl: string = ApiMap[name];
    _.forOwn(params, (value: string, key: string) => {
        apiUrl = (apiUrl as any).replaceAll(`{${key}}`, value);
    });
    return apiUrl;
}
