import { ThingsModelType } from '@common-src/model/enum';

const thingsModelTypeList: Array<{ name: string, value: ThingsModelType }> = _.filter(_.map(ThingsModelType, item => {
    let name: string = null;
    switch (item) {
        case ThingsModelType.STRING:
            name = 'string(明文字符串)';
            break;
        case ThingsModelType.TEXT:
            name = 'text(多行字符串)';
            break;
        case ThingsModelType.PASSWORD:
            name = 'password(密文字符串)';
            break;
        case ThingsModelType.INT:
            name = 'int32(整数型)';
            break;
        case ThingsModelType.FLOAT:
            name = 'float(单精度浮点型)';
            break;
        case ThingsModelType.DOUBLE:
            name = 'double(双精度浮点型)';
            break;
        case ThingsModelType.BOOLEAN:
            name = 'bool(布尔型)';
            break;
        case ThingsModelType.ENUM:
            name = 'enum(枚举型)';
            break;
        case ThingsModelType.DATE:
            name = 'date(时间型)';
            break;
        case ThingsModelType.JSON:
            name = 'json(结构体)';
            break;
    }
    return { name, value: item };
}), item => item.name) as any;

const thingsModelTypeFilter = (val: ThingsModelType) => {
    return _.get(_.find(thingsModelTypeList, item => item.value === val), 'name') || '-';
};

export {
    thingsModelTypeFilter,
    thingsModelTypeList
};
