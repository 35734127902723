<template>
    <div class="components-input-demo-presuffix">
        <a-input v-model="cron" placeholder="cron表达式编辑器" @click="openModal" @change="inputChange">
            <a-icon slot="prefix" type="schedule" title="cron表达式编辑器" />
            <a-icon v-if="cron" slot="suffix" type="close-circle" title="清空" @click="handleEmpty" />
        </a-input>
        <JCronModal ref="innerVueCron" :data="cron" @ok="handleOK"></JCronModal>
    </div>
</template>
<script>
import JCronModal from './modal/JCronModal';
export default {
    name: 'JtlCron',
    components: {
        JCronModal
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: {
            required: false,
            type: String,
            default: () => {
                return '* * * * * ? *';
            }
        }
    },
    data() {
        return {
            cron: this.value
        };
    },
    watch: {
        value(val) {
            this.cron = val;
        }
    },
    methods: {
        openModal() {
            this.$refs.innerVueCron.show();
        },
        handleOK(val) {
            this.cron = val;
            this.$emit('change', this.cron);
        },
        handleEmpty() {
            this.handleOK('');
        },
        inputChange() {
            this.$emit('change', this.cron);
        }
    }
};
</script>
<style scoped>
  .components-input-demo-presuffix .anticon-close-circle {
    cursor: pointer;
    color: #ccc;
    transition: color 0.3s;
    font-size: 12px;
  }
  .components-input-demo-presuffix .anticon-close-circle:hover {
    color: #f5222d;
  }
  .components-input-demo-presuffix .anticon-close-circle:active {
    color: #666;
  }
</style>
