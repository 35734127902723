import { FormControl, FormControlTextModel, FormControlType } from '@common-src/model/form-control';
import { TopoDataType } from '../enum';
import TopoDataBaseModel from './topo-data-base-model';

export default class TopoDataParentModel extends TopoDataBaseModel {
    constructor(options?: any) {
        super();
        this.type = TopoDataType.PARENT;
        this.parentAdapter = _.get(options, 'parentAdapter');
        // this.componentData = _.get(options, 'componentData');
    }

    @FormControl({
        label: 'Parent Adapter',
        type: FormControlType.TEXT_AREA,
        rows: 5,
        layout: { labelCol: 24, wrapperCol: 24, width: '100%' },
        index: 100
    } as FormControlTextModel)
    parentAdapter: string = undefined;

    getData(parentData) {
        if (this.parentAdapter) {
            // let self = this;
            /* eslint-disable-next-line */
            // this.componentData = (function() { return eval(self.parentAdapter); }(parentData));
            /* eslint-disable-next-line */
            this.componentData = eval(this.parentAdapter)(parentData);
        } else {
            this.componentData = parentData;
        }
        return this.componentData;
    }
    cancelGetData() {}
}
