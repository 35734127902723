import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import SystemResourcesService from '@common-src/service3/system-resources.service';

//  处理显示关联
// export const InvisibleFunc = (...type) => (model:any, controlList: Array<any>) => {
//     if (!model.identifier && !model.name) {
//         return true;
//     } else if (model.name && controlList) {
//         const { options } = _.find(controlList, item => item.key === 'name');
//         const { key } = _.find(options, item => item.name === model.name);
//         return !_.some(type, i => key === i);
//     }
//     return !_.some(type, item => model.identifier === item);
// };
//  处理显示关联
export const InvisibleFun = (...list) => (model:any) => (
    !_.some(list, item => model.identifier === item)
);
const filterName = (item) => {
    switch (item) {
        case 'host':
            return '地址';
        case 'port':
            return '端口';
        case 'password':
            return '密码';
        case 'username':
            return '用户名';
        case 'url':
            return '资源值';
        default:
            return '';
    }
};

export class SystemResourcesEntityModel extends BaseEntityModel {
    static baseUrl = `/infra-appmgr/api/resource/config`;

    config: any = {};
    configTags: Array<string> = [];

    @FormControl({
        label: '资源类型',
        type: FormControlType.SELECT,
        optionsPromise: SystemResourcesService.IdentifiersTypeList,
        cascad: true,
        required: true,
        readonly: true
    } as FormControlTextModel)
    identifier: string = undefined;

    @FormControl({
        label: '资源名称',
        type: FormControlType.TEXT,
        required: true,
        readonly: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '地址',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFun('POSTGRE_SQL', 'REDIS')
    } as FormControlTextModel)
    host: string = undefined;

    @FormControl({
        label: '端口',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFun('POSTGRE_SQL', 'REDIS')
    } as FormControlTextModel)
    port: string = undefined;

    @FormControl({
        label: '用户名',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFun('POSTGRE_SQL')
    } as FormControlTextModel)
    username: string = undefined;

    @FormControl({
        label: '密码',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFun('POSTGRE_SQL', 'REDIS')
    } as FormControlTextModel)
    password: string = undefined;

    @FormControl({
        label: '资源值',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFun('KAFKA'),
        placeholder: '请输入kafka地址（IP:PORT）'
    } as FormControlTextModel)
    url: string = undefined;

    @FormControl({
        label: '资源说明',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    remark: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '资源名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' },
                width: 400
            },
            {
                title: '资源类型',
                dataIndex: 'identifier'
            },
            {
                title: '资源值',
                dataIndex: 'configTags',
                scopedSlots: { customRender: 'tags' }
            },
            {
                title: '资源说明',
                dataIndex: 'remark'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 200
            }
        ];
    }
    toModel(json): any {
        super.toModel(json);
        this.host = this.config?.host;
        this.password = this.config?.password;
        this.port = this.config?.port;
        this.username = this.config?.username;
        this.url = this.config?.url;
        for (const item in this.config) {
            this.configTags.push(`${filterName(item)}:${this.config[item]} `);
        }
        return this;
    }

    toService() {
        const data: any = super.toService();
        data.config = {
            host: data?.host,
            password: data?.password,
            port: data?.port,
            username: data?.username,
            url: data?.url
        };
        delete data.host;
        delete data.password;
        delete data.port;
        delete data.username;
        delete data.configTags;
        delete data.url;
        return data;
    }
}

export class SystemResourcesQueryModel extends QueryPageModel {
    @QueryControl({
        label: '资源名称',
        type: QueryControlType.TEXT,
        span: 6
    })
    name: string = undefined;

    @QueryControl({
        label: '资源标识符',
        type: QueryControlType.SELECT,
        optionsPromise: SystemResourcesService.IdentifiersTypeList,
        span: 6
    })
    identifier: string = undefined;

    toService() {
        // return { params: this.channelName };
        return super.getParams();
    }
}
