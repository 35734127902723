// @ts-nocheck
import AntMessage from 'ant-design-vue/lib/message';

let oWebControl = null;

export enum CameraHKMessageType {
    WindowSelect = 1, // 窗口选中消息
    RealplayOrPlayback = 2, // 预览/回放播放消息
    CapturePicture = 3, // 抓图结果消息
    LayoutOnChange = 6, // 切换布局消息
}

export interface ICamera {
    isInit: boolean;
    initPlugin(containerId: string, cbIntegrationCallBack: Function);
    async init(ip, appkey, appSecret);
    startPreview(cameraIndexCode: string, wndId?: string);
    setWndFacilityMap(facilityId: string, wndId: string);
    getWndFacilityMap();
    stopAllPreview();
    setLayout(layout: string);
    resize(width, height);
    destroy();
}

export class CameraHkModel {
    private initCount = 0;
    isInit: boolean = false;
    layout: string = '1x1'; // 当前播放窗口布局
    wndId: number; // 当前窗口id
    private wndFacilityMap: Object = {}; // 播放窗口和设施的关系

    /**
     * 初始化插件
     * @param containerId
     * @param cbIntegrationCallBack
     * @returns
     */
    initPlugin(containerId: string, cbIntegrationCallBack: Function) {
        var self = this;
        return new Promise((resolve, reject) => {
            oWebControl = new WebControl({
                szPluginContainer: containerId, // 指定容器id
                iServicePortStart: 15900, // 指定起止端口号，建议使用该值
                iServicePortEnd: 15909,
                szClassId: '23BF3B0A-2C56-4D97-9C03-0CB103AA8F11', // 用于IE10使用ActiveX的clsid
                cbConnectSuccess: function() { // 创建WebControl实例成功
                    oWebControl.JS_StartService('window', { // WebControl实例创建成功后需要启动服务
                        dllPath: './VideoPluginConnect.dll' // 值"./VideoPluginConnect.dll"写死
                    }).then(() => { // 启动插件服务成功
                        oWebControl.JS_SetWindowControlCallback({ // 设置消息回调
                            cbIntegrationCallBack: (msg) => {
                                self.msgCallBack(msg);
                                if (cbIntegrationCallBack) {
                                    cbIntegrationCallBack(msg);
                                }
                            }
                        });

                        oWebControl.JS_CreateWnd(containerId, 0, 0).then(() => { // JS_CreateWnd创建视频播放窗口，宽高可设定
                            console.log('创建播放实例成功');
                            resolve();
                        // self.init(resizeCallBack); // 创建播放实例成功后初始化
                        });
                    }, function() { // 启动插件服务失败
                    });
                },
                cbConnectError: function() { // 创建WebControl实例失败
                    oWebControl = null;
                    AntMessage.warn('插件未启动，正在尝试启动，请稍候...');
                    WebControl.JS_WakeUp('VideoWebPlugin://'); // 程序未启动时执行error函数，采用wakeup来启动程序
                    this.initCount++;
                    if (this.initCount < 3) {
                        setTimeout(function() {
                            self.initPlugin();
                        }, 3000);
                    } else {
                        reject();
                        AntMessage.warn('插件启动失败，请检查插件是否安装！');
                    }
                },
                cbConnectClose: function(bNormalClose) {
                // 异常断开：bNormalClose = false
                // JS_Disconnect正常断开：bNormalClose = true
                    console.log('cbConnectClose');
                    oWebControl = null;
                }
            });
        });
    }

    /**
     * 初始化plugin
     * @param ip
     * @param appkey
     * @param appSecret
     * @returns
     */
    async init(ip, appkey, appSecret) {
        /// /////////////////////////////// 请自行修改以下变量值	////////////////////////////////////
        // var appkey = APP_KEY; // 综合安防管理平台提供的appkey，必填
        // var secret = await this.setEncrypt(APP_SECRET); // 综合安防管理平台提供的secret，必填
        // var ip = CAMERA_SERVER_IP; // 综合安防管理平台IP地址，必填
        var secret = await this.setEncrypt(appSecret); // 综合安防管理平台提供的secret，必填
        var playMode = 0; // 初始播放模式：0-预览，1-回放
        var port = 443; // 综合安防管理平台端口，若启用HTTPS协议，默认443
        var snapDir = 'C:\\SnapDir'; // 抓图存储路径
        var videoDir = 'C:\\VideoDir'; // 紧急录像或录像剪辑存储路径
        // var layout = '2x2'; // playMode指定模式的布局
        var enableHTTPS = 1; // 是否启用HTTPS协议与综合安防管理平台交互，这里总是填1
        var encryptedFields = 'secret'; // 加密字段，默认加密领域为secret
        var showToolbar = 1; // 是否显示工具栏，0-不显示，非0-显示
        var showSmart = 0; // 是否显示智能信息（如配置移动侦测后画面上的线框），0-不显示，非0-显示
        var buttonIDs = '0,16,256,257,258,259,260,512,513,514,515,516,517,768,769'; // 自定义工具条按钮
        /// /////////////////////////////// 请自行修改以上变量值	////////////////////////////////////

        return oWebControl.JS_RequestInterface({
            funcName: 'init',
            argument: JSON.stringify({
                appkey: appkey, // API网关提供的appkey
                secret: secret, // API网关提供的secret
                ip: ip, // API网关IP地址
                playMode: playMode, // 播放模式（决定显示预览还是回放界面）
                port: port, // 端口
                snapDir: snapDir, // 抓图存储路径
                videoDir: videoDir, // 紧急录像或录像剪辑存储路径
                layout: this.layout, // 布局
                enableHTTPS: enableHTTPS, // 是否启用HTTPS协议
                encryptedFields: encryptedFields, // 加密字段
                showToolbar: showToolbar, // 是否显示工具栏
                showSmart: showSmart, // 是否显示智能信息
                buttonIDs: buttonIDs // 自定义工具条按钮
            })
        }).then((oData) => {
            this.isInit = true;
            // oWebControl.JS_Resize(1000, 600); // 初始化后resize一次，规避firefox下首次显示窗口后插件窗口未与DIV窗口重合问题
            // this.resize();
            // resizeCallBack();
            // oWebControl.JS_CuttingPartWindow(495, 10, 768, 405);
        });
    }

    /**
     * 打开预览
     * @param cameraIndexCode
     */
    startPreview(cameraIndexCode: string, wndId?: string = -1) {
        console.log('startPreview', cameraIndexCode, wndId);
        // var cameraIndexCode = cameraIndexCode; // 获取输入的监控点编号值，必填
        var streamMode = 0; // 主子码流标识：0-主码流，1-子码流
        var transMode = 1; // 传输协议：0-UDP，1-TCP
        var gpuMode = 0; // 是否启用GPU硬解，0-不启用，1-启用
        // var wndId = -1; // 播放窗口序号（在2x2以上布局下可指定播放窗口）

        if (this.wndId > 0) {
            wndId = this.wndId;
        }

        cameraIndexCode = cameraIndexCode.replace(/(^\s*)/g, '');
        cameraIndexCode = cameraIndexCode.replace(/(\s*$)/g, '');

        oWebControl.JS_RequestInterface({
            funcName: 'startPreview',
            argument: JSON.stringify({
                cameraIndexCode: cameraIndexCode, // 监控点编号
                streamMode: streamMode, // 主子码流标识
                transMode: transMode, // 传输协议
                gpuMode: gpuMode, // 是否开启GPU硬解
                wndId // 可指定播放窗口
            })
        });
    }

    /**
     * 设置窗口对应的设施id
     * @param facilityId
     * @param wndId
     */
    setWndFacilityMap(facilityId: string, wndId: string = 1) {
        if (this.wndId > 0) {
            wndId = this.wndId;
        }
        _.set(this.wndFacilityMap, `${wndId}.facilityId`, facilityId);
    }

    /**
     * 获取设施和窗口的对应关系
     * @returns
     */
    getWndFacilityMap() {
        const facilityMap = [];
        _.forOwn(this.wndFacilityMap, (value, key) => {
            // 播放失败的窗口不保存视图关系
            // if (value && value.cameraIndexCode && value.facilityId) {
            //     facilityMap.push({
            //         facilityId: value.facilityId,
            //         index: key
            //     });
            // }

            // 播放失败的窗口保存视图关系
            if (value && value.facilityId) {
                facilityMap.push({
                    facilityId: value.facilityId,
                    index: key
                });
            }
        });
        return facilityMap;
    }

    /**
     * 停止所有窗口的预览
     */
    stopAllPreview() {
        if (oWebControl) {
            oWebControl.JS_RequestInterface({
                funcName: 'stopAllPreview'
            });
            this.wndId = null;
            this.wndFacilityMap = {};
        }
    }

    /**
     * 设置布局
     * @param layout
     */
    setLayout(layout: string) {
        if (!layout || !oWebControl) {
            return;
        }
        oWebControl.JS_RequestInterface({
            funcName: 'setLayout',
            argument: JSON.stringify({ layout })
        });
    }

    resize(width, height) {
        if (oWebControl) {
            oWebControl.JS_Resize(width, height);
        }
    }

    destroy() {
        if (oWebControl != null) {
            oWebControl.JS_HideWnd(); // 先让窗口隐藏，规避可能的插件窗口滞后于浏览器消失问题
            return oWebControl.JS_Disconnect().then(function() { // 断开与插件服务连接成功
                // oWebControl = null;
            },
            function() { // 断开与插件服务连接失败
            });
        }
    }

    /**
     * 消息回调
     * @param msg
     */
    private msgCallBack(msg: any) {
        console.log('海康插件回调消息', msg);
        const messageType = _.get(msg, 'responseMsg.type');
        switch (messageType) {
            case CameraHKMessageType.RealplayOrPlayback:
                {
                    const wndId = _.get(msg, 'responseMsg.msg.wndId');
                    const cameraIndexCode = _.get(msg, 'responseMsg.msg.cameraIndexCode');
                    const msgResult = _.get(msg, 'responseMsg.msg.result');
                    if (msgResult === 816) {
                        // 播放结束
                        _.set(this.wndFacilityMap, `${wndId}.cameraIndexCode`, null);
                    } else if (msgResult === 768) {
                        // 开始播放
                        _.set(this.wndFacilityMap, `${wndId}.cameraIndexCode`, cameraIndexCode);
                    }
                }
                break;
            case CameraHKMessageType.WindowSelect:
                this.wndId = _.get(msg, 'responseMsg.msg.wndId');
                break;
            case CameraHKMessageType.LayoutOnChange:
                this.layout = _.get(msg, 'responseMsg.msg.layout');
                break;
        }
    }

    private async setEncrypt(value) {
        const pubKey = await this.getPubKey();
        var encrypt = new JSEncrypt();
        encrypt.setPublicKey(pubKey);
        return encrypt.encrypt(value);
    }

    private async getPubKey() {
        if (oWebControl) {
            const oData = await oWebControl.JS_RequestInterface({
                funcName: 'getRSAPubKey',
                argument: JSON.stringify({
                    keyLength: 1024
                })
            });
            if (oData) {
                return oData.responseMsg.data;
            }
        }
    }
}
