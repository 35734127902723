import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel, FormControlSelectDeviceAttributeModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { DeviceTypeEnum, FacilityBindType, FacilityLinkType, FormControlSelectDeviceAttributeType } from '@common-src/model/enum';
import { facilityBindTypeList, facilityLinkTypeList, facilityBindTypeFilter, facilityLinkTypeFilter } from '@common-src/filter/facility-bind-type';
import CommonService from '@common-src/service/common';
import { getDeviceIdAndAttributeKey } from './device-entity';

export class FacilityConfigTypeEntityModel extends BaseEntityModel {
    @FormControl({
        label: '名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '绑定方式',
        type: FormControlType.SELECT,
        options: facilityBindTypeList,
        required: true,
        cascad: true,
        readonly: true
    } as FormControlOptionModel)
    bindType: FacilityBindType = undefined;

    @FormControl({
        label: '关联方式',
        type: FormControlType.SELECT,
        options: facilityLinkTypeList,
        required: true,
        cascad: true,
        invisibleFunction: model => !(model.bindType === FacilityBindType.STATS)
    } as FormControlOptionModel)
    linkType: FacilityLinkType = undefined;

    @FormControl({
        label: '关联产品类型',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getDeviceTypeOptions,
        optionsPromiseParam: [DeviceTypeEnum.DEVICE],
        mode: 'multiple',
        invisibleFunction: model => model.linkType !== FacilityLinkType.DEVICE,
        required: true
    } as FormControlOptionModel)
    linkDeviceList: Array<string> = undefined;

    @FormControl({
        label: '关联设备属性范围',
        type: FormControlType.SELECT_DEVICE_ATTRIBUTE,
        mode: 'multiple',
        selectType: FormControlSelectDeviceAttributeType.DEVICE_TYPE,
        invisibleFunction: model => model.linkType !== FacilityLinkType.DEVICE_ATTR,
        required: true
    } as FormControlSelectDeviceAttributeModel)
    linkDeviceAttributeList: Array<string> = undefined;

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    remark: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '绑定方式',
                dataIndex: 'bindType',
                customRender: (text, record, index) => {
                    return facilityBindTypeFilter(text);
                }
            },
            {
                title: '关联方式',
                dataIndex: 'linkType',
                customRender: (text, record, index) => {
                    return facilityLinkTypeFilter(text);
                }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        switch (this.linkType) {
            case FacilityLinkType.DEVICE:
                this.linkDeviceList = _.map(_.get(json, 'relations'), item => item.entityId);
                break;
            case FacilityLinkType.DEVICE_ATTR:
                this.linkDeviceAttributeList = _.map(_.get(json, 'relations'), item => `${item.entityId}|${item.attrKey}`);
                break;
        }
        return this;
    }

    toService() {
        const data: any = super.toService();
        if (this.bindType === FacilityBindType.STATS) {
            switch (this.linkType) {
                case FacilityLinkType.DEVICE:
                    data.relations = _.map(this.linkDeviceList, item => {
                        return { entityId: item };
                    });
                    break;
                case FacilityLinkType.DEVICE_ATTR:
                    data.relations = _.map(this.linkDeviceAttributeList, item => {
                        const { deviceId, attributeKey } = getDeviceIdAndAttributeKey(item);
                        return {
                            entityId: deviceId,
                            attrKey: attributeKey
                        };
                    });
                    break;
            }
        } else {
            data.linkType = FacilityLinkType.NONE;
        }
        delete data.linkDeviceTypeList;
        delete data.linkDeviceAttributeList;
        return data;
    }
}

export class FacilityConfigTypeQueryModel extends QueryPageModel {
    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT,
        span: 8
    })
    name: string = undefined;

    toService() {
        return {
            params: this.name
        };
    }
}
