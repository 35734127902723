import { BaseModel } from '@common-src/model/base-model';
import { FormControl, FormControlOptionModel, FormControlTextModel, FormControlType } from '@common-src/model/form-control';
import { TopoActionType, TopoBehaveType } from '../enum';
import TopoActionBaseModel, { TopoBroadcastActionModel, TopoLinkActionModel, TopoVisibleActionModel, TopoReloadDataActionModel, TopoResetDataActionModel, TopoStyleActionModel } from './topo-action-model';
import { topoBehaveTypeList } from '@topo/filter/topo-behave-type';

export default class TopoBehaveBaseModel extends BaseModel {
    @FormControl({
        label: '行为',
        type: FormControlType.SELECT,
        options: topoBehaveTypeList,
        layout: { labelCol: 6, wrapperCol: 16, width: '100%' },
        index: 1
    } as FormControlOptionModel)
    type: TopoBehaveType = undefined;
    action: any = undefined;

    initAction(options) {
        switch (_.get(options, 'type')) {
            case TopoActionType.LINK:
                this.action = new TopoLinkActionModel().toModel(options);
                break;
            case TopoActionType.VISIBLE:
                this.action = new TopoVisibleActionModel().toModel(options);
                break;
            case TopoActionType.BROADCAST:
                this.action = new TopoBroadcastActionModel().toModel(options);
                break;
            case TopoActionType.RELOAD_DATA:
                this.action = new TopoReloadDataActionModel().toModel(options);
                break;
            case TopoActionType.RESET_DATA:
                this.action = new TopoResetDataActionModel().toModel(options);
                break;
            case TopoActionType.STYLE:
                this.action = new TopoStyleActionModel().toModel(options);
                break;
            default:
                this.action = new TopoActionBaseModel().toModel(options);
                break;
        }
        return this.action;
    }

    toModel(json) {
        super.toModel(json);
        this.initAction(_.get(json, 'action'));
        return this;
    }

    toService() {
        const data = super.toService();
        data.action = this.action.toService();
        return data;
    }
}

export class TopoBehaveClickModel extends TopoBehaveBaseModel {

}

export class TopoBehaveBroadcastAndSubscribeModel extends TopoBehaveBaseModel {
    @FormControl({
        label: '事件名称',
        type: FormControlType.TEXT,
        rows: 3,
        layout: { labelCol: 6, wrapperCol: 16, width: '100%' },
        index: 10
    } as FormControlTextModel)
    eventName: string = undefined;
}
