import { BaseModel } from '@common-src/model/base-model';
import { ThingsModelType, ReadWriteEnum } from '../model/enum';
import { DataParamModel } from './data-param-entity';

export enum RefType {
    RANGE = 'RANGE',
    VALUE = 'VALUE'
}
export class ThingsModelTypeModel extends BaseModel {
    constructor() {
        super();
        this.thingsModelType = ThingsModelType.STRING;
        this.enumList = [{ name: '', value: '' }];
    }

    thingsModelType: ThingsModelType = undefined;
    dataMin: number = -2147483648;
    dataMax: number = 2147483648;
    dataTrueName: string = undefined;
    dataFalseName: string = undefined;
    dataString: number = 10240;
    step: number = 1;
    unit: string = undefined;
    enumList: Array<{ name: string, value: string }> = [];
    jsonParams: Array<DataParamModel> = undefined;
    value: any = undefined;
    boolSpecs: Array<{ label: string, value: string }> = null;
    refs: { min?: any, max?: any, range?: Array<any>, type?: RefType } = {};

    get BooleanOptions() {
        return [
            { label: this.dataTrueName, value: true },
            { label: this.dataFalseName, value: false }
        ];
    }

    toModel(json): any {
        this.thingsModelType = _.get(json, 'type');
        this.value = _.get(json, 'val') || _.get(json, 'value');
        switch (this.thingsModelType) {
            case ThingsModelType.STRING:
            case ThingsModelType.PASSWORD:
            case ThingsModelType.TEXT:
                this.dataString = _.get(json, 'specs.length');
                this.refs = { min: _.get(json, 'refs.range[0]'), max: _.get(json, 'refs.range[1]'), type: _.get(json, 'refs.type') };
                break;
            case ThingsModelType.INT:
            case ThingsModelType.FLOAT:
            case ThingsModelType.DOUBLE:
                this.dataMin = parseFloat(_.get(json, 'specs.min'));
                this.dataMax = parseFloat(_.get(json, 'specs.max'));
                this.unit = _.get(json, 'unit');
                this.step = parseFloat(_.get(json, 'specs.step'));
                this.refs = { min: _.get(json, 'refs.range[0]'), max: _.get(json, 'refs.range[1]'), type: _.get(json, 'refs.type') };
                break;
            case ThingsModelType.BOOLEAN:
                {
                    // this.dataFalseName = _.get(json, 'specs[0]');
                    // this.dataTrueName = _.get(json, 'specs[1]');
                    this.dataFalseName = _.get(json, 'specs[false]') || _.get(json, 'specs[0]');
                    this.dataTrueName = _.get(json, 'specs[true]') || _.get(json, 'specs[1]');
                    const boolObj = _.get(json, 'specs');
                    this.boolSpecs = _.map(_.keysIn(boolObj), key => {
                        return {
                            label: boolObj[key],
                            name: boolObj[key],
                            value: key
                        };
                    });
                    this.refs = { range: _.get(json, 'refs.range'), type: _.get(json, 'refs.type') };
                }
                break;
            case ThingsModelType.ENUM:
                {
                    const enumObj = _.get(json, 'specs');
                    this.enumList = _.map(_.keysIn(enumObj), key => {
                        return {
                            label: enumObj[key],
                            name: enumObj[key],
                            value: key.toString()
                        };
                    });
                    this.refs = { range: _.get(json, 'refs.range'), type: _.get(json, 'refs.type') };
                }
                break;
            case ThingsModelType.JSON:
                this.jsonParams = _.map(_.get(json, 'specs'), item => new DataParamModel().toModel(item));
                break;
            default:
                break;
        }
        return this;
    }

    toService() {
        const data: any = {
            type: this.thingsModelType,
            specs: this.toSpecs(),
            unit: this.unit,
            value: null
        };
        switch (this.thingsModelType) {
            case ThingsModelType.INT:
            case ThingsModelType.FLOAT:
            case ThingsModelType.DOUBLE:
                data.value = parseFloat(this.value) || 0;
                if (this.refs && !_.isEmpty(this.refs.min)) {
                    data.refs = {
                        range: [parseFloat(this.refs.min), parseFloat(this.refs.max)],
                        type: RefType.RANGE
                    };
                }
                break;
            case ThingsModelType.BOOLEAN:
                data.value = !!this.value;
                if (this.refs && !_.isEmpty(this.refs.range)) {
                    data.refs = {
                        // range: _.map(this.refs.range, item => item === 'true' ? true : false),
                        range: this.refs.range,
                        type: RefType.VALUE
                    };
                }
                break;
            case ThingsModelType.JSON:
                data.value = this.value || '{}';
                break;
            case ThingsModelType.STRING:
            case ThingsModelType.PASSWORD:
            case ThingsModelType.TEXT:
            default:
                data.value = this.value || '';
                if (this.refs && !_.isEmpty(this.refs.min)) {
                    data.refs = {
                        range: [this.refs.min, this.refs.max],
                        type: RefType.RANGE
                    };
                }
                break;
            case ThingsModelType.ENUM:
                if (this.refs && !_.isEmpty(this.refs.range)) {
                    data.refs = {
                        range: this.refs.range,
                        type: RefType.VALUE
                    };
                }
                break;
        }
        data.val = data.value;
        return data;
    }

    toSpecs() {
        let data: any = {};
        switch (this.thingsModelType) {
            case ThingsModelType.STRING:
            case ThingsModelType.PASSWORD:
            case ThingsModelType.TEXT:
                data.length = this.dataString;
                break;
            case ThingsModelType.INT:
            case ThingsModelType.FLOAT:
            case ThingsModelType.DOUBLE:
                data.min = this.dataMin;
                data.max = this.dataMax;
                data.step = this.step;
                // data.unit = this.unit;
                break;
            case ThingsModelType.BOOLEAN:
                data['false'] = this.dataFalseName;
                data['true'] = this.dataTrueName;
                break;
            case ThingsModelType.ENUM:
                data = {};
                _.forEach(this.enumList, item => {
                    data[item.value] = item.name;
                });
                break;
            case ThingsModelType.JSON:
                data = _.map(this.jsonParams, item => item.toService());
                break;
            default:
                break;
        }
        return data;
    }
}
