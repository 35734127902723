var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alarm-attribute-rule-component" },
    _vm._l(_vm.value, function (item, index) {
      return _c(
        "a-row",
        { key: index, attrs: { type: "flex", justify: "center" } },
        [
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("a-select", {
                staticClass: "width100",
                attrs: { placeholder: "请选择属性", options: _vm.options },
                model: {
                  value: item.attributeKey,
                  callback: function ($$v) {
                    _vm.$set(item, "attributeKey", $$v)
                  },
                  expression: "item.attributeKey",
                },
              }),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 6 } },
            [
              _c("a-select", {
                staticClass: "width100",
                attrs: {
                  placeholder: "请选择比较类型",
                  options: _vm.OperateList,
                },
                model: {
                  value: item.compareType,
                  callback: function ($$v) {
                    _vm.$set(item, "compareType", $$v)
                  },
                  expression: "item.compareType",
                },
              }),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 6 } },
            [
              _c("a-input", {
                attrs: { placeholder: "阈值" },
                model: {
                  value: item.value,
                  callback: function ($$v) {
                    _vm.$set(item, "value", $$v)
                  },
                  expression: "item.value",
                },
              }),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 22 } },
            [
              _c("a-select", {
                staticClass: "width100",
                attrs: {
                  placeholder: "请选择持续时间",
                  options: _vm.DurationList,
                },
                model: {
                  value: item.duration,
                  callback: function ($$v) {
                    _vm.$set(item, "duration", $$v)
                  },
                  expression: "item.duration",
                },
              }),
            ],
            1
          ),
          _c(
            "a-col",
            { staticClass: "text-center", attrs: { span: 2 } },
            [
              index === 0
                ? _c("a-icon", {
                    attrs: { type: "plus-circle" },
                    on: { click: _vm.addClick },
                  })
                : _c("a-icon", {
                    attrs: { type: "minus-circle" },
                    on: {
                      click: function ($event) {
                        return _vm.removeClick(index)
                      },
                    },
                  }),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }