import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlModel, FormControlOptionModel, FormControlSelectDeviceAttributeModel, FormControlTextModel } from '@common-src/model/form-control';
import { QueryModel } from '@common-src/model/query-model';
import { facilityAttributeDataTypeList, facilityAttributeDataTypeFilter } from '@common-src/filter/facility-attribute-data-type';
import { FacilityAttributeDataType, FacilityType, DeviceTypeEnum, FormControlSelectDeviceAttributeType } from '@common-src/model/enum';
import CommonService from '@common-src/service/common';
import { getDeviceId, getDeviceIdAndAttributeKey, SelectDeviceModel } from './device-entity';
import { NUMBER_LETTER_UNDERLINE_REGEXP } from '@common-src/model/regexp';

export function supportedDeviceTypesInvisibleFunction(model: FacilityAttributeEntityModel) {
    return model.type !== FacilityAttributeDataType.DEVICE;
}

export function supportedDeviceAttributeInvisibleFunction(model: FacilityAttributeEntityModel) {
    return [FacilityAttributeDataType.ATTRIBUTE, FacilityAttributeDataType.SERVER_ATTRIBUTE].indexOf(model.type) === -1;
}

export class FacilityAttributeEntityModel extends BaseEntityModel {
    constructor(isFacilityType: boolean, entityId: string) {
        super();
        this.isFacilityType = isFacilityType;
        this.entityId = entityId;
    }

    @FormControl({
        label: '名称',
        type: FormControlType.TEXT,
        required: true,
        readonly: false,
        max: 255
    } as FormControlModel<string>)
    name: string = undefined;

    @FormControl({
        label: '标识符',
        type: FormControlType.TEXT,
        required: true,
        max: 50,
        pattern: NUMBER_LETTER_UNDERLINE_REGEXP,
        readonly: true
    } as FormControlTextModel)
    identifier: string = undefined;

    @FormControl({
        label: '属性类型',
        type: FormControlType.SELECT,
        required: true,
        options: facilityAttributeDataTypeList,
        cascad: true
    } as FormControlOptionModel)
    type: FacilityAttributeDataType = undefined;

    @FormControl({
        label: '关联产品类型',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getDeviceTypeOptions,
        optionsPromiseParam: [DeviceTypeEnum.DEVICE],
        mode: 'multiple',
        // invisibleFunction: supportedDeviceTypesInvisibleFunction,
        // invisibleFunction: function(model: FacilityAttributeEntityModel, controlList: Array<any>) {
        //     const control3Visible = model.type !== FacilityAttributeDataType.DEVICE;
        //     if (controlList && controlList.length > 4) {
        //         const control4Visible = [FacilityAttributeDataType.ATTRIBUTE, FacilityAttributeDataType.SERVER_ATTRIBUTE].indexOf(model.type) === -1;
        //         controlList[3].invisible = control3Visible;
        //         controlList[4].invisible = control4Visible;
        //     }
        //     return control3Visible;
        // },
        invisibleFunction: model => model.type !== FacilityAttributeDataType.DEVICE,
        required: true
    } as FormControlOptionModel)
    supportedDeviceTypeList: Array<string> = undefined;
    attrGroupsList: Array<any> = undefined;

    @FormControl({
        label: '关联设备属性范围',
        type: FormControlType.SELECT_DEVICE_ATTRIBUTE,
        mode: 'multiple',
        selectType: FormControlSelectDeviceAttributeType.DEVICE_TYPE,
        // invisibleFunction: supportedDeviceAttributeInvisibleFunction,
        // invisibleFunction: function(model: FacilityAttributeEntityModel, controlList: Array<any>) {
        //     const control4Visible = [FacilityAttributeDataType.ATTRIBUTE, FacilityAttributeDataType.SERVER_ATTRIBUTE].indexOf(model.type) === -1;
        //     if (controlList && controlList.length > 4) {
        //         const control3Visible = model.type !== FacilityAttributeDataType.DEVICE;
        //         controlList[3].invisible = control3Visible;
        //         controlList[4].invisible = control4Visible;
        //     }
        //     return control4Visible;
        // },
        invisibleFunction: model => [FacilityAttributeDataType.ATTRIBUTE, FacilityAttributeDataType.SERVER_ATTRIBUTE].indexOf(model.type) === -1,
        required: true
    } as FormControlSelectDeviceAttributeModel)
    supportedDeviceAttributeList: Array<string> = undefined;

    @FormControl({
        label: '枚举定义',
        type: FormControlType.FORM_ENUM,
        selectType: FormControlSelectDeviceAttributeType.DEVICE_TYPE,
        invisibleFunction: model => model.type !== FacilityAttributeDataType.ENUM,
        required: true
    } as FormControlModel<any>)
    enumList: Array<any> = [];

    linkedDeviceAttributeList: Array<SelectDeviceModel> = undefined;
    isFacilityType: boolean = undefined;
    entityId: string;

    dataValue: any = undefined;
    // displayName: string = undefined;
    feedback: boolean = false;
    validatestatus: string ='success';
    relations: Array<any> = undefined;

    // get DisplayNameHtml() {
    //     if (this.displayName) {
    //         return this.displayName;
    //     }
    //     return '-';
    // }

    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '标识符',
                dataIndex: 'identifier'
            },
            {
                title: '属性类型',
                dataIndex: 'type',
                customRender: (text) => {
                    return facilityAttributeDataTypeFilter(text);
                }
            },
            {
                title: '最后更新时间',
                dataIndex: 'modifiedTime',
                width: 200
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 150
            }
        ];
    }

    static getFacilityTabelColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name',
                width: 150
            },
            {
                title: '标识符',
                dataIndex: 'identifier',
                width: 150
            },
            {
                title: '属性类型',
                dataIndex: 'type',
                customRender: (text) => {
                    return facilityAttributeDataTypeFilter(text);
                },
                width: 150
            },
            {
                title: '数据定义',
                dataIndex: 'dataValue',
                scopedSlots: { customRender: 'dataValue' }
            },
            {
                title: '最后更新时间',
                dataIndex: 'modifiedTime',
                width: 180
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 150
            }
        ];
    }

    toService() {
        const data: any = {
            id: this.id,
            name: this.name,
            type: this.type,
            entityId: this.entityId,
            identifier: this.identifier
        };
        switch (this.type) {
            case FacilityAttributeDataType.DEVICE:
                if (this.isFacilityType) {
                    data.relations = _.map(this.supportedDeviceTypeList, item => {
                        return { entityId: item };
                    });
                } else if (this.dataValue) {
                    // data.linkId = getDeviceId(this.dataValue);
                    // data.relations = [{ entityId: getDeviceId(this.dataValue) }];
                    if (Array.isArray(this.dataValue)) {
                        data.relations = _.map(this.dataValue, item => {
                            return { entityId: getDeviceId(item) };
                        });
                    } else {
                        data.relations = [{ entityId: getDeviceId(this.dataValue) }];
                    }
                }
                break;
            case FacilityAttributeDataType.ATTRIBUTE:
            case FacilityAttributeDataType.SERVER_ATTRIBUTE:
                if (this.isFacilityType) {
                    data.relations = _.map(this.supportedDeviceAttributeList, item => {
                        const { deviceId, attributeKey } = getDeviceIdAndAttributeKey(item);
                        return {
                            attrKey: attributeKey,
                            entityId: deviceId
                        };
                    });
                } else if (this.dataValue) {
                    if (Array.isArray(this.dataValue)) {
                        data.relations = _.map(this.dataValue, item => {
                            const { deviceId, attributeKey } = getDeviceIdAndAttributeKey(item);
                            return { entityId: deviceId, attrKey: attributeKey };
                        });
                        data.linkId = _.get(data.relations, '[0].entityId');
                        data.attributeKey = _.get(data.relations, '[0].attrKey');
                    } else {
                        const { deviceId, attributeKey } = getDeviceIdAndAttributeKey(this.dataValue);
                        data.relations = [{ entityId: deviceId, attrKey: attributeKey }];
                        data.linkId = deviceId;
                        data.attributeKey = attributeKey;
                    }
                }
                break;
            case FacilityAttributeDataType.BOOLEAN:
                data.val = _.isUndefined(this.dataValue) ? false : this.dataValue;
                break;
            case FacilityAttributeDataType.ENUM:
                data.options = this.enumList;
                data.val = this.dataValue;
                break;
            case FacilityAttributeDataType.SPACE:
                data.relations = [{ entityId: this.dataValue }];
                break;
            case FacilityAttributeDataType.INT:
            case FacilityAttributeDataType.FLOAT:
            case FacilityAttributeDataType.STRING:
            case FacilityAttributeDataType.PEOPLE:
            default:
                data.val = this.dataValue;
                break;
        }
        return data;
    }

    toValueService() {
        const data = this.toService();
        delete data.attrGroupsList;
        return data;
    }

    toModel(json) {
        super.toModel(json);
        switch (this.type) {
            case FacilityAttributeDataType.DEVICE:
                // if (_.get(json, 'relations')) {
                //     this.dataValue = _.get(json, 'relations[0].entityId');
                // } else {
                //     // this.dataValue = _.get(json, 'linkId');
                // }
                this.dataValue = _.map(_.get(json, 'relations'), item => item.entityId);
                // this.displayName = _.get(json, 'relations[0].entityName');
                // this.dataValue = _.filter(_.map(_.get(json, 'relations'), item => {
                //     return _.get(item, 'entityId');
                // }), item => item);
                this.attrGroupsList = _.map(_.get(json, 'relations'), item => {
                    return {
                        deviceTypeId: item.entityId,
                        deviceTypeName: item.entityName
                    };
                });
                this.supportedDeviceTypeList = _.map(_.get(json, 'relations'), item => item.entityId);
                break;
            case FacilityAttributeDataType.ATTRIBUTE:
            case FacilityAttributeDataType.SERVER_ATTRIBUTE:
                if (_.get(json, 'relations')) {
                    this.dataValue = _.filter(_.map(_.get(json, 'relations'), item => {
                        const linkId = _.get(item, 'entityId');
                        const attributeKey = _.get(item, 'attrKey');
                        if (linkId && attributeKey) {
                            return `${linkId}|${attributeKey}`;
                        }
                        return null;
                    }), item => item);
                } else {
                    // const linkId = _.get(json, 'linkId');
                    // const attributeKey = _.get(json, 'attributeKey');
                    // if (linkId && attributeKey) {
                    //     this.dataValue = `${linkId}|${attributeKey}`;
                    // }
                }
                this.supportedDeviceAttributeList = _.map(_.get(json, 'relations'), item => `${item.entityId}|${item.attrKey}`);
                this.linkedDeviceAttributeList = _.map(_.get(json, 'relations'), item => new SelectDeviceModel().toModel(item));
                // this.displayName = `${_.get(json, 'relations[0].entityName')}|${_.get(json, 'relations[0].attrName')}(${_.get(json, 'relations[0].attrKey')})`;
                // this.displayName = _.map(_.get(json, 'relations'), item => {
                //     return `${item.entityName} | ${item.attrName}(${item.attrKey})`;
                // }).join('<br>');
                break;
            case FacilityAttributeDataType.ENUM:
                this.enumList = _.get(json, 'options') || [];
                this.dataValue = _.get(json, 'val');
                break;
            case FacilityAttributeDataType.BOOLEAN:
                this.dataValue = _.get(json, 'val');
                if (_.isUndefined(this.dataValue)) {
                    this.dataValue = false;
                }
                break;
            case FacilityAttributeDataType.SPACE:
                this.dataValue = _.get(json, 'relations[0].entityId');
                break;
            case FacilityAttributeDataType.INT:
            case FacilityAttributeDataType.FLOAT:
            case FacilityAttributeDataType.STRING:
            case FacilityAttributeDataType.PEOPLE:
            default:
                this.dataValue = _.get(json, 'val');
                break;
        }
        return this;
    }
}

export class FacilityAttributeQueryModel extends QueryModel {
    constructor(entityId: string, isFacilityType: boolean) {
        super();
        this.isFacilityType = isFacilityType;
        this.entityId = entityId;
    }
    isFacilityType: boolean = undefined;
    entityId: string = undefined;
}
