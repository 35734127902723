















import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';

@Component
export default class ResourceUrlFormComponent extends BaseComponent {
    @Prop()
    value: Array<{ httpMethod: string, url: string }>;
    @Prop()
    options: Array<{ label: string, value: any }>;
    @Prop()
    disabled: boolean;
    addClick() {
        this.value.push({} as any);
    }
    deleteClick(index: number) {
        this.value.splice(index, 1);
    }
}

