import { SubSystemType } from '@common-src/model/enum';

export default [
    // {
    //     path: 'parking-index',
    //     component: () => import('@/pages/sub-system/parking-index.vue'),
    //     meta: { parentMenu: 'parking' }
    // },
    {
        path: 'parking-index',
        component: () => import('@/pages/sub-system/subsystem-index.vue'),
        meta: { parentMenu: 'parking', type: SubSystemType.PARKING }
    },
    {
        path: 'parking-chart/:chartCode',
        component: () => import('@/pages/sub-system/report-chart.vue')
    },
    {
        path: 'parking-facility-list',
        component: () => import('@/pages/sub-system/parking-facility-list.vue'),
        meta: { parentMenu: 'parking', type: SubSystemType.PARKING }
    },
    {
        path: 'parking-event',
        component: () => import('@/pages/dashboard/alarm/alarm-event-list.vue'),
        meta: { parentMenu: 'parking', type: SubSystemType.PARKING }
    },
    {
        path: 'parking-entrance-exit-list',
        component: () => import('@/pages/sub-system/parking-entrance-or-exit-list.vue'),
        meta: { parentMenu: 'parking', type: SubSystemType.PARKING }
    },
    {
        path: 'parking-guide-card-list',
        component: () => import('@/pages/sub-system/guide-card-list.vue'),
        meta: { parentMenu: 'parking', type: SubSystemType.PARKING }
    },
    {
        path: 'parking-car-in-out-record',
        component: () => import('@/pages/sub-system/parking-car-in-out-record-list.vue'),
        meta: { parentMenu: 'parking', type: SubSystemType.PARKING }
    },
    // {
    //     path: 'fire-index',
    //     component: () => import('@/pages/sub-system/fire-index.vue'),
    //     meta: { parentMenu: 'fire' }
    // },
    {
        path: 'fire-index',
        component: () => import('@/pages/sub-system/subsystem-index.vue'),
        meta: { parentMenu: 'fire', type: SubSystemType.FIRE }
    },
    {
        path: 'fire-facility-list',
        component: () => import('@/pages/sub-system/parking-facility-list.vue'),
        meta: { parentMenu: 'fire', type: SubSystemType.FIRE }
    },
    {
        path: 'fire-event',
        component: () => import('@/pages/dashboard/alarm/alarm-event-list.vue'),
        meta: { parentMenu: 'fire', type: SubSystemType.FIRE }
    },
    // {
    //     path: 'camera-index',
    //     component: () => import('@/pages/sub-system/camera-index.vue'),
    //     meta: { parentMenu: 'camera' }
    // },
    {
        path: 'camera-index',
        component: () => import('@/pages/sub-system/subsystem-index.vue'),
        meta: { parentMenu: 'camera', type: SubSystemType.CAMERA }
    },
    {
        path: 'camera-facility-list',
        component: () => import('@/pages/sub-system/parking-facility-list.vue'),
        meta: { parentMenu: 'camera', type: SubSystemType.CAMERA }
    },
    {
        path: 'camera-event',
        component: () => import('@/pages/dashboard/alarm/alarm-event-list.vue'),
        meta: { parentMenu: 'camera', type: SubSystemType.CAMERA }
    },
    {
        path: 'camera-realplay',
        component: () => import('@/pages/sub-system/camera-realplay.vue'),
        meta: { parentMenu: 'camera', type: SubSystemType.CAMERA }
    },
    // {
    //     path: 'enviroment-index',
    //     component: () => import('@/pages/sub-system/enviroment-index.vue'),
    //     meta: { parentMenu: 'enviroment' }
    // },
    {
        path: 'enviroment-index',
        component: () => import('@/pages/sub-system/subsystem-index.vue'),
        meta: { parentMenu: 'enviroment', type: SubSystemType.ENVIROMENT }
    },
    {
        path: 'enviroment-facility-list',
        component: () => import('@/pages/sub-system/parking-facility-list.vue'),
        meta: { parentMenu: 'enviroment', type: SubSystemType.ENVIROMENT }
    },
    {
        path: 'enviroment-event',
        component: () => import('@/pages/dashboard/alarm/alarm-event-list.vue'),
        meta: { parentMenu: 'enviroment', type: SubSystemType.ENVIROMENT }
    },
    // {
    //     path: 'energy-index',
    //     component: () => import('@/pages/sub-system/energy-index.vue'),
    //     meta: { parentMenu: 'energy' }
    // },
    {
        path: 'energy-index',
        component: () => import('@/pages/sub-system/subsystem-index.vue'),
        meta: { parentMenu: 'energy', type: SubSystemType.ENERGY }
    },
    {
        path: 'energy-facility-list',
        component: () => import('@/pages/sub-system/parking-facility-list.vue'),
        meta: { parentMenu: 'energy', type: SubSystemType.ENERGY }
    },
    {
        path: 'energy-event',
        component: () => import('@/pages/dashboard/alarm/alarm-event-list.vue'),
        meta: { parentMenu: 'energy', type: SubSystemType.ENERGY }
    },
    // {
    //     path: 'ba-index',
    //     component: () => import('@/pages/sub-system/ba-index.vue'),
    //     meta: { parentMenu: 'ba' }
    // },
    {
        path: 'ba-index',
        component: () => import('@/pages/sub-system/subsystem-index.vue'),
        meta: { parentMenu: 'ba', type: SubSystemType.BA }
    },
    {
        path: 'ba-facility-list',
        component: () => import('@/pages/sub-system/parking-facility-list.vue'),
        meta: { parentMenu: 'ba', type: SubSystemType.BA }
    },
    {
        path: 'ba-event',
        component: () => import('@/pages/dashboard/alarm/alarm-event-list.vue'),
        meta: { parentMenu: 'ba', type: SubSystemType.BA }
    },
    // {
    //     path: 'customer-flow-index',
    //     component: () => import('@/pages/sub-system/customer-flow-index.vue'),
    //     meta: { parentMenu: 'customer-flow' }
    // },
    {
        path: 'customer-flow-index',
        component: () => import('@/pages/sub-system/subsystem-index.vue'),
        meta: { parentMenu: 'customer-flow', type: SubSystemType.CUSTOMER_FLOW }
    },
    {
        path: 'customer-flow-facility-list',
        component: () => import('@/pages/sub-system/parking-facility-list.vue'),
        meta: { parentMenu: 'customer-flow', type: SubSystemType.CUSTOMER_FLOW }
    },
    {
        path: 'customer-flow-event',
        component: () => import('@/pages/dashboard/alarm/alarm-event-list.vue'),
        meta: { parentMenu: 'customer-flow', type: SubSystemType.CUSTOMER_FLOW }
    },
    {
        path: 'customer-flow-group',
        component: () => import('@/pages/sub-system/customer-flow-group-list.vue'),
        meta: { parentMenu: 'customer-flow', type: SubSystemType.CUSTOMER_FLOW }
    },
    // {
    //     path: 'elevator-index',
    //     component: () => import('@/pages/sub-system/elevator-index.vue'),
    //     meta: { parentMenu: 'elevator' }
    // },
    {
        path: 'elevator-index',
        component: () => import('@/pages/sub-system/subsystem-index.vue'),
        meta: { parentMenu: 'elevator', type: SubSystemType.ELEVATOR }
    },
    {
        path: 'elevator-facility-list',
        component: () => import('@/pages/sub-system/parking-facility-list.vue'),
        meta: { parentMenu: 'elevator', type: SubSystemType.ELEVATOR }
    },
    {
        path: 'elevator-event',
        component: () => import('@/pages/dashboard/alarm/alarm-event-list.vue'),
        meta: { parentMenu: 'elevator', type: SubSystemType.ELEVATOR }
    },
    // {
    //     path: 'light-index',
    //     component: () => import('@/pages/sub-system/light-index.vue'),
    //     meta: { parentMenu: 'light' }
    // },
    {
        path: 'light-index',
        component: () => import('@/pages/sub-system/subsystem-index.vue'),
        meta: { parentMenu: 'light', type: SubSystemType.LIGHT }
    },
    {
        path: 'light-facility-list',
        component: () => import('@/pages/sub-system/parking-facility-list.vue'),
        meta: { parentMenu: 'light', type: SubSystemType.LIGHT }
    },
    {
        path: 'light-event',
        component: () => import('@/pages/dashboard/alarm/alarm-event-list.vue'),
        meta: { parentMenu: 'light', type: SubSystemType.LIGHT }
    },
    // {
    //     path: 'access-control-index',
    //     component: () => import('@/pages/sub-system/access-control-index.vue'),
    //     meta: { parentMenu: 'accessControl' }
    // },
    {
        path: 'access-control-index',
        component: () => import('@/pages/sub-system/subsystem-index.vue'),
        meta: { parentMenu: 'accessControl', type: SubSystemType.ACCESS_CONTROL }
    },
    {
        path: 'access-control-facility-list',
        component: () => import('@/pages/sub-system/parking-facility-list.vue'),
        meta: { parentMenu: 'accessControl', type: SubSystemType.ACCESS_CONTROL }
    },
    {
        path: 'access-control-event',
        component: () => import('@/pages/dashboard/alarm/alarm-event-list.vue'),
        meta: { parentMenu: 'accessControl', type: SubSystemType.ACCESS_CONTROL }
    },
    {
        path: 'facility-list',
        component: () => import('@/pages/sub-system/parking-facility-list.vue'),
        meta: { type: SubSystemType.ALL }
    },
    {
        path: 'computer-room-index',
        component: () => import('@/pages/sub-system/subsystem-index.vue'),
        meta: { parentMenu: 'computerRoomSystem', type: SubSystemType.COMPUTER_ROOM }
    },
    {
        path: 'computer-room-facility-list',
        component: () => import('@/pages/sub-system/parking-facility-list.vue'),
        meta: { parentMenu: 'computerRoomSystem', type: SubSystemType.COMPUTER_ROOM }
    },
    {
        path: 'computer-room-event',
        component: () => import('@/pages/dashboard/alarm/alarm-event-list.vue'),
        meta: { parentMenu: 'computerRoomSystem', type: SubSystemType.COMPUTER_ROOM }
    },
    {
        path: 'battery',
        component: () => import('@/pages/sub-system/battery-list.vue'),
        meta: { parentMenu: 'computerRoomSystem', type: SubSystemType.COMPUTER_ROOM }
    },
    {
        path: 'patrol-list',
        component: () => import('@/pages/sub-system/patrol-list.vue'),
        meta: { parentMenu: 'patrolSystem', type: SubSystemType.PATROL }
    }
];
