/**
 * 缩放步长
 */
const SCALE_STEP = 0.1;
export default {
    update: function(el, binding, vnode) {
        const isZoom: boolean = binding.value;
        if (isZoom) {
            let scale = 1;
            // el.style.transform = `scale(${0.5}) translateX(-50%) translateY(-50%)`;
            el.onmousewheel = (ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                if (ev.deltaY > 0) {
                    scale -= SCALE_STEP;
                } else {
                    scale += SCALE_STEP;
                }
                if (scale < 0.2) {
                    scale = 0.2;
                }
                el.style.transform = `scale(${scale})`;
            };
        } else {
            // el.style.transform = 'scale(1)';
            el.onmousewheel = null;
        }
    }
};
