import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';

export class UnitEntityModel extends BaseEntityModel {
    static baseUrl = `${COMMON_BASE_REQUEST_PATH}/unit`;
    updateTime: string = undefined;

    @FormControl({
        label: '名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '符号',
        type: FormControlType.TEXT,
        required: true,
        readonly: true
    } as FormControlTextModel)
    identifier: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' },
                width: 400
            },
            {
                title: '符号',
                dataIndex: 'identifier'
            },
            {
                title: '更新时间',
                dataIndex: 'updateTime'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 300
            }
        ];
    }
    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class UnitQueryModel extends QueryPageModel {
    @QueryControl({
        label: '名称/符号',
        type: QueryControlType.TEXT,
        span: 6
    })
    keywords: string = undefined;

    toService() {
        // return { params: this.name };
        return super.getParams();
    }
}
