var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "attribute-data-type-value-component" },
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { span: 10 } },
            [
              _c(
                "a-select",
                {
                  staticClass: "inline-block attribute-data__type-wrapper",
                  attrs: { name: _vm.formName },
                  on: { change: _vm.selectTypeChange },
                  model: {
                    value: _vm.value.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "type", $$v)
                    },
                    expression: "value.type",
                  },
                },
                _vm._l(_vm.attributeDataTypeList, function (item) {
                  return _c(
                    "a-select-option",
                    { key: item.value, attrs: { value: item.value } },
                    [_vm._v(" " + _vm._s(item.name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c("a-col", { attrs: { span: 14 } }, [
            _c(
              "div",
              { staticClass: "inline-block attribute-data__value-wrapper" },
              [
                _vm.value.type === _vm.AttributeDataType.STRING
                  ? _c("a-input", {
                      attrs: {
                        placeholder: "字符串",
                        name: _vm.formName,
                        "allow-clear": "",
                      },
                      on: { change: _vm.valueChange },
                      model: {
                        value: _vm.value.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "value", $$v)
                        },
                        expression: "value.value",
                      },
                    })
                  : _vm._e(),
                _vm.value.type === _vm.AttributeDataType.INT
                  ? _c("a-input", {
                      attrs: {
                        type: "number",
                        name: _vm.formName,
                        placeholder: "整型",
                        "allow-clear": "",
                      },
                      on: { change: _vm.valueChange },
                      model: {
                        value: _vm.value.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "value", $$v)
                        },
                        expression: "value.value",
                      },
                    })
                  : _vm._e(),
                _vm.value.type === _vm.AttributeDataType.FLOAT
                  ? _c("a-input", {
                      attrs: {
                        placeholder: "浮点型",
                        name: _vm.formName,
                        "allow-clear": "",
                      },
                      on: { change: _vm.valueChange },
                      model: {
                        value: _vm.value.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "value", $$v)
                        },
                        expression: "value.value",
                      },
                    })
                  : _vm._e(),
                _vm.value.type === _vm.AttributeDataType.BOOLEAN
                  ? _c("a-switch", {
                      staticStyle: { "margin-left": "24px" },
                      attrs: {
                        name: _vm.formName,
                        "checked-children": "true",
                        "un-checked-children": "false",
                      },
                      model: {
                        value: _vm.value.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "value", $$v)
                        },
                        expression: "value.value",
                      },
                    })
                  : _vm._e(),
                _vm.value.type === _vm.AttributeDataType.JSON
                  ? _c("a-textarea", {
                      staticStyle: { height: "120px" },
                      attrs: { placeholder: "JSON", name: _vm.formName },
                      on: { change: _vm.valueChange },
                      model: {
                        value: _vm.value.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "value", $$v)
                        },
                        expression: "value.value",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }