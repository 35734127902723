import { FormControl, FormControlType, FormControlTextModel, FormControlUploadModel } from '@common-src/model/form-control';
import { NUMBER_LETTER_UNDERLINE_REGEXP } from '@common-src/model/regexp';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { BaseEntityModel } from '@common-src/model/base-model';
import CommonService from '@common-src/service/common';
import { PropertyEntityType } from '@common-src/model/enum';

export class DeviceDriverEntityModel extends BaseEntityModel {
    static baseUrl = `${IOT_BASE_REQUEST_PATH}/driver/config`;

    @FormControl({
        label: '驱动名称',
        type: FormControlType.TEXT,
        required: true,
        max: 255
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '驱动标识符',
        type: FormControlType.TEXT,
        required: true,
        max: 50,
        pattern: NUMBER_LETTER_UNDERLINE_REGEXP,
        readonly: true
    } as FormControlTextModel)
    identifier: string = undefined;

    @FormControl({
        label: '驱动包地址',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    libFileUrl: string = undefined;

    @FormControl({
        label: '版本号',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    version: string = undefined;

    @FormControl({
        label: '分类',
        type: FormControlType.SELECT,
        optionsPromise: async function() {
            const res = await CommonService.getPropertyOptions(PropertyEntityType.DEVICE_DRIVER_CATEGORY);
            return _.map(res, r => { return { name: r.name, value: r.code }; });
        }
    } as FormControlTextModel)
    category: string = undefined;

    @FormControl({
        label: '驱动定义',
        type: FormControlType.TEXT_AREA,
        rows: 10,
        required: true
    } as FormControlTextModel)
    definition: string = undefined;

    @FormControl({
        label: '厂家',
        type: FormControlType.SELECT,
        optionsPromise: async function() {
            const res = await CommonService.getPropertyOptions(PropertyEntityType.DEVICE_DRIVER_MANUFACTURER);
            return _.map(res, r => { return { name: r.name, value: r.code }; });
        }
    } as FormControlTextModel)
    manufacturer: string = undefined;

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    description: string = undefined;

    releaseDate: string = undefined;
    uploadPath: string = undefined;

    connectionParas: Array<any> = undefined;
    variables: Array<any> = undefined;
    jarName: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '驱动标识符',
                dataIndex: 'identifier',
                width: 200
            },
            {
                title: '驱动名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' },
                width: 200
            },
            {
                title: '分类',
                dataIndex: 'category',
                scopedSlots: { customRender: 'category' },
                width: 100
            },
            {
                title: '最新版本',
                dataIndex: 'version',
                width: 80
            },
            {
                title: '版本时间',
                dataIndex: 'releaseDate',
                width: 180
            },
            {
                title: '驱动包地址',
                dataIndex: 'libFileUrl'
            },
            {
                title: '厂家',
                dataIndex: 'manufacturer',
                scopedSlots: { customRender: 'manufacturer' },
                width: 100
            },
            {
                title: '描述',
                dataIndex: 'description',
                ellipsis: true
            },
            {
                title: '操作',
                dataIndex: 'action',
                width: 150,
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        // this.id = this.identifier;
        try {
            this.definition = JSON.stringify(json.definition);
            this.manufacturer = _.get(json, 'manufacturer') || undefined;
            this.category = _.get(json, 'category') || undefined;
        } catch (e) {
            console.warn(e);
        }
        return this;
    }

    toService() {
        let data: any = super.toService();
        try {
            data.definition = JSON.parse(this.definition);
            data.manufacturer = _.get(data, 'manufacturer') || '';
            data.category = _.get(data, 'category') || '';
        } catch (e) {
            console.warn(e);
        }
        return data;
    }
}

export class DeviceDriverReadConfigModel extends BaseEntityModel {
    @FormControl({
        label: '读功能码',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    readFunctionCode: string = undefined;

    @FormControl({
        label: '写功能码',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    writeFunctionCode: string = undefined;

    @FormControl({
        label: '地址',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    startingAddress: string = undefined;

    @FormControl({
        label: '字节交换',
        type: FormControlType.CHECKBOX
    } as FormControlTextModel)
    isByteSwap: boolean = false;

    @FormControl({
        label: '扩大倍数',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    expandMultiple: string = undefined;

    @FormControl({
        label: '大小端',
        type: FormControlType.SELECT,
        options: []
    }as FormControlTextModel)
    endian: string = undefined;

    @FormControl({
        label: '长度',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    length: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '字段名称',
                dataIndex: 'name'
            },
            {
                title: '字段标识符',
                dataIndex: 'identifier'
            },
            {
                title: '字段类型',
                dataIndex: 'type'
            },
            {
                title: '默认值',
                dataIndex: 'value'
            }
        ];
    }
}

export class DeviceDriverConnectConfigModel extends BaseEntityModel {
    @FormControl({
        label: 'IP地址',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    address: string = undefined;

    @FormControl({
        label: '端口',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    port: string = undefined;

    @FormControl({
        label: '测试字段',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    test: string = undefined;

    @FormControl({
        label: 'unitID',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    unitID: string = undefined;

    @FormControl({
        label: '上报时间(s)',
        type: FormControlType.TEXT,
        suffix: '秒'
    } as FormControlTextModel)
    reportDpsInterval: string = undefined;

    @FormControl({
        label: '设备指令间隔(ms)',
        type: FormControlType.TEXT,
        suffix: '毫秒'
    } as FormControlTextModel)
    devCmdInterval: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '字段名称',
                dataIndex: 'name'
            },
            {
                title: '字段标识符',
                dataIndex: 'identifier'
            },
            {
                title: '字段类型',
                dataIndex: 'type'
            },
            {
                title: '默认值',
                dataIndex: 'value'
            }
        ];
    }
}

export class DeviceDriverQueryModel extends QueryPageModel {
    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT,
        span: 10
    })
    name: string = undefined;

    toService() {
        return super.getParams();
    }
}

export class DeviceDriveImportEntityModel {
    @FormControl({
        label: '选择文件',
        type: FormControlType.UPLOAD,
        accept: '.zip',
        maxFileNumber: 1,
        notUploadOss: true,
        required: true
    } as FormControlUploadModel)
    excelFile: Array<any> = undefined;
    parentId: string = undefined;

    isProtocol: boolean = false;

    toService() {
        const formData = new FormData();
        formData.append('file', _.get(this.excelFile, '[0].file'));
        return formData;
    }
}
