export default (str: string, length: number) => {
    if (!str) return '';
    let len = 0; // 字节长度
    let newStr = ''; // 输出的字符串
    for (let i = 0; i < str.length; i++) {
        // 返回在字符串中i位置的字符的 Unicode 编码
        let c = str.charCodeAt(i)
      // 根据编码判断, 英文字符数字len+1, 中文汉字符号len+2
      ;(c >= 0x0001 && c <= 0x007e) || (c >= 0xff60 && c <= 0xff9f)
            ? len++
            : (len += 2);
        len > length ? '' : (newStr += str[i]);
    }
    return len > length ? newStr + '...' : str;
};
