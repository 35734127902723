// import { UserStoreModule } from '@common-src/store/modules/user';
// import CommonService from '@common-src/service/common';

import { SubSystemType } from '@common-src/model/enum';

export function beforeEnter(to, from, next) {
    // CommonService.getUserInfo(5000).then(() => {
    //     next();
    // }).catch(error => {
    //     UserStoreModule.SET_HAS_ERROR(true);
    //     UserStoreModule.SET_REDIRECT_PATH(to.fullPath);
    //     $log.error(error);
    //     next('/sign/error');
    // }).finally(() => {
    //     // $eventHub.$emit('STOP_FULL_SCREEN_LOADING');
    // });
    next();
}

export default [
    {
        path: '/message',
        redirect: '/message/channel-config',
        meta: { authGuard: true },
        beforeEnter: beforeEnter,
        component: () => import('@common-src/pages/dashboard/dashboard.vue'),
        children: [
            {
                path: 'channel-config',
                component: () => import('@/pages/message/channel-config/channel-list.vue')
            },
            {
                path: 'message-template',
                component: () => import('@common-src/pages/admin-dashboard/template-list.vue'),
                meta: { parentMenu: 'message' }
            },
            {
                path: 'message-center',
                component: () => import('@/pages/message/message-center/message-center-list.vue')
            },
            {
                path: 'message-logger',
                component: () => import('@/pages/message/message-logger/logger-list.vue')
            },
            {
                path: 'websocket-logger',
                component: () => import('@/pages/message/websocket-logger/logger-list.vue')
            },
            {
                path: 'message-alarm-detail/:id',
                component: () => import('@/pages/message/alarm-detail/alarm-item-detail.vue')
            }
        ]
    }
];
