


















import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import SelectUserDialog from './select-user-dialog.vue';
import moment, { Moment } from 'moment';

@Component({
    components: {
        'select-user-dialog': SelectUserDialog
    }
})
export default class SelectUserComponent extends BaseComponent {
    @Prop()
    value: Array<any>;
    @Prop()
    placeholder: string;
    @Prop()
    disabled: boolean;
    @Prop()
    format: string;
    @Prop({ default: true })
    hasSelectBtn: boolean;

    selectUserClick() {
        (this.$refs.selectUserDialog as SelectUserDialog).dialogOpen(this.value);
    }
    selectUserChange(value) {
        this.$emit('input', value);
    }
    deleteItem(index) {
        this.value.splice(index, 1);
    }
    validate() {
        // for (let index = 0; index < this.value.length; index++) {
        //     if (!this.value[index]) {
        //         this.showMessageWarning('请选择计划时间');
        //         return false;
        //     }
        // }
        return true;
    }
}

