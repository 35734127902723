import { FormControl, FormControlOptionModel, FormControlTextModel, FormControlType } from '@common-src/model/form-control';
import TopoInnerService, { topoInnerServiceOptions } from '@topo/service/topo-http-service';
import { TopoDataType } from '../enum';
import { TopoDataHttpBaseModel } from './topo-data-http-model';

export default class TopoDataInnerHttpModel extends TopoDataHttpBaseModel {
    constructor(options?: any) {
        super(options);
        this.type = TopoDataType.INNER_HTTP;
        this.key = _.get(options, 'key');
        this.params = _.get(options, 'params');
    }

    @FormControl({
        label: 'Inner Http',
        type: FormControlType.SELECT,
        options: topoInnerServiceOptions,
        layout: { labelCol: 6, wrapperCol: 16, width: '100%' },
        index: 11
    } as FormControlOptionModel)
    key: string = '';

    @FormControl({
        key: 'Params',
        label: 'Params',
        type: FormControlType.TEXT_AREA,
        rows: 5,
        layout: { labelCol: 24, wrapperCol: 24, width: '100%' },
        index: 12
    } as FormControlTextModel)
    params: any = undefined;

    /**
     * get
     * 右侧属性列表Text Area绑定的字段
     */
    get Params() {
        if (typeof (this.params) === 'string') {
            return this.params;
        }
        let data = null;
        try {
            data = JSON.stringify(this.params);
        } catch (e) {
            data = this.params;
        }
        return data;
    }
    /**
     * set
     * 右侧属性列表Text Area绑定的字段
     */
    set Params(val: string) {
        try {
            this.params = JSON.parse(val);
        } catch (e) {
            this.params = val;
        }
    }

    getData() {
        if (this.seconds && this.seconds > 0 && !this.timerInterval) {
            this.timerInterval = setInterval(() => {
                this.requestData();
            }, this.seconds * 1000);
        }
        return this.requestData();
    }

    private requestData() {
        if (this.key && TopoInnerService[this.key]) {
            return TopoInnerService[this.key](this.Params).then(res => {
                this.componentData = res;
                // return this.componentData;
            });
        }
    }
}
