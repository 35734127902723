
import { DeviceEntityModel, SelectDeviceModel, DeviceQueryModel, DeviceImportExcelEntityModel, ExportTemplateEntityModel } from '@common-src/entity-model/device-entity';
import { TemplateType } from '@common-src/model/enum';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, del, download } from './request';
const URL_PATH = `${IOT_BASE_REQUEST_PATH}/device`;

class DeviceService implements ICRUDQ<DeviceEntityModel, DeviceQueryModel> {
    async create(model: DeviceEntityModel):Promise<DeviceEntityModel> {
        let url = null;

        if ((model as any).isImport) {
            url = `${URL_PATH}/childrenbatchsave`;
        } else if ((model as any).isCopy) {
            url = `${URL_PATH}/childrenbatchcopy`;
        } else {
            url = `${URL_PATH}/save`;
        }

        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async getDeviceAttr(modelId: string):Promise<any> {
        const url = `${IOT_BASE_REQUEST_PATH}/driver/device/deviceAttr/${modelId}`;
        const res = await get(url);
        return _.map(res, item => {
            return {
                identifier: item.identifier,
                name: _.get(item, 'definition.name'),
                value: item.value,
                definition: item.definition,
                scope: item.scope
            };
        });
    }

    async retrieve(modelId: string):Promise<any> {
        const url = `${URL_PATH}/detail/${modelId}`;
        // const res = await getDetailMockData();
        const res = await get(url);
        return {
            deviceModel: new DeviceEntityModel().toModel(res),
            serverAttrs: _.map(_.get(res, 'serverAttributeList'), item => {
                return {
                    key: item.identifier,
                    name: _.get(item, 'definition.name'),
                    value: item.value,
                    definition: item.definition
                };
            }),
            // clientAttrs: _.map(_.get(res, 'clientAttrs'), item => {
            //     item.jsonValue.attrstring = null;
            //     item.jsonValue.variablename = null;
            //     item.jsonValue.address = null;
            //     item.jsonValue.len = null;
            //     item.jsonValue.bitIndex = null;
            //     item.jsonValue.funCode = null;
            //     item.jsonValue.dataType = null;
            //     return item.jsonValue;
            // }),
            clientAttrs: _.map(_.get(res, 'clientAttributeList'), item => {
                item.definition.attrstring = null;
                item.definition.variablename = null;
                item.definition.address = null;
                item.definition.len = null;
                item.definition.bitIndex = null;
                item.definition.funCode = null;
                item.definition.dataType = null;
                return item.definition;
            })
        };
    }

    async update(model: DeviceEntityModel):Promise<DeviceEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: DeviceEntityModel):Promise<DeviceEntityModel> {
        const url = `${URL_PATH}/delete/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: DeviceQueryModel, page?: number, limit?: number):Promise<any> {
        const params = Object.assign({ page, limit }, query?.toService());

        if (_.get(params, 'params.deviceId')) {
            const url = `${URL_PATH}/getRelatedDevices/${_.get(params, 'params.deviceId')}`;
            const res = await post(url, params);
            return _.map(res, item => item = new DeviceEntityModel().toModel(item));
        } else {
            const url = `${URL_PATH}/list`;
            const res = await post(url, params);
            res.items = _.map(res.items, item => item = new DeviceEntityModel().toModel(item));
            return res;
        }
    }

    /**
     * 设备快速查询
     * @param query
     * @param page
     * @param limit
     * @returns
     */

    async getSimpleDeviceList(query?: DeviceQueryModel, page?: number, limit?: number) {
        const params = Object.assign({ page, limit }, query?.toService());
        const url = `${URL_PATH}/listSimple`;
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new DeviceEntityModel().toModel(item));
        return res;
    }

    async listFacDevs(query?: DeviceQueryModel, page?: number, limit?: number):Promise<any> {
        const params:any = Object.assign({ page, limit }, query?.toService());
        params.params.typeIds = [params.params.typeId];
        const url = `${URL_PATH}/listFacDevs`;
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new DeviceEntityModel().toModel(item));
        return res;
    }

    async saveAttr(deviceId: string, params):Promise<any> {
        const url = `${URL_PATH}/saveAttr/${deviceId}`;
        const res = await post(url, params);
        return res;
    }

    async getDeviceListByTypeId(productId: string):Promise<Array<any>> {
        if (!productId) {
            return;
        }
        const url = `${URL_PATH}/getDevices`;
        const res = await post(url, { productId, forceRoot: false });
        // return _.map(res, item => item = new DeviceEntityModel().toModel(item));
        return _.map(res, item => {
            return {
                id: item.id,
                value: item.id,
                name: item.name,
                title: item.name,
                serviceArea: item.serviceArea
            };
        });
    }

    async link(id: string, parentId: string):Promise<any> {
        const url = `${URL_PATH}/link`;
        return await post(url, { childId: id, parentId: parentId });
    }

    async unlink(id: string, parentId: string):Promise<any> {
        const url = `${URL_PATH}/unlink`;
        return await post(url, [id]);
    }

    async getAllDeviceAttributes(scopeId?: string, identifier?: string):Promise<Array<SelectDeviceModel>> {
        let url = null;

        if (scopeId) {
            if (scopeId === 'energy') {
                url = `${BI_BASE_REQUEST_PATH}/energyItem/building/getDeviceAttr`;
            } else {
                url = `${BI_BASE_REQUEST_PATH}/facilitytype/getDeviceAttrs/${scopeId}/${identifier}`;
            }
        } else {
            url = `${URL_PATH}/getDevsWithAttrs`;
        }

        const res = await get(url, null, { cache: 10000 });
        return _.map(_.filter(res, item => item.values && item.values.length > 0), item => new SelectDeviceModel().toModel(item));
    }

    async getDeviceTree():Promise<Array<SelectDeviceModel>> {
        const url = `${URL_PATH}/getDeviceTree`;
        const res = await get(url);
        return _.map(res, item => {
            return this.initTreeData(item, null);
        });
    }

    initTreeData(treeData, parentId) {
        const treeItem: any = {
            title: _.get(treeData, 'displayName'),
            value: _.get(treeData, 'id'),
            id: _.get(treeData, 'id'),
            pId: parentId
        };
        const children = _.get(treeData, 'children');

        if (children && children.length > 0) {
            treeItem.children = _.map(children, child => {
                return this.initTreeData(child, treeItem.id);
            });
        }

        return treeItem;
    }

    async links(parentId: string, childId: Array<string>):Promise<any> {
        const url = `${URL_PATH}/link/${parentId}`;
        return await post(url, childId);
    }

    async batchUnlink(ids: Array<string>) {
        const url = `${URL_PATH}/unlink`;
        const res = await post(url, ids);
        return res;
    }

    async batchDelete(ids: Array<string>) {
        const url = `${URL_PATH}/batchDelete`;
        const params = {
            list: ids
        };
        const res = await post(url, params);
        return res;
    }

    async batchChangeGroup(ids: Array<string>, groupId: string) {
        const url = `${URL_PATH}/group/${groupId}`;
        const res = await post(url, ids);
        return res;
    }

    async batchChangePosition(ids: Array<string>, positionId: string) {
        const url = `${URL_PATH}/location/${positionId}`;
        const res = await post(url, ids);
        return res;
    }

    async exportExcelByDeviceTypeId(deviceTypeId: string, type: string) {
        const url = `${URL_PATH}/exportTemplate/${deviceTypeId}/${type}`;
        const res = await download(url);
        // const res = await get(url);
        return res;
    }

    async exportExcelByDeviceTypeId2(model: ExportTemplateEntityModel) {
        // if (model.type === TemplateType.PROTOCOL) {
        //     const url = `${URL_PATH}/exportProtocolTemplate/${model.protocolType}`;
        //     const res = await download(url, { protocol: model.protocolType });
        //     return res;
        // }
        // const url = `${URL_PATH}/exportTemplate/${model.DeviceTypeId}`;
        // const res = await download(url);
        if (model.type === TemplateType.PROTOCOL) {
            const url = `${URL_PATH}/downloadTpl/driver/${model.protocolType}`;
            const res = await download(url, { protocol: model.protocolType });
            return res;
        }
        const url = `${URL_PATH}/downloadTpl/product/${model.DeviceTypeId}/${model.driver}`;
        const res = await download(url);
        return res;
    }

    async importExcel(importModel: DeviceImportExcelEntityModel) {
        const url = `${URL_PATH}/import`;
        const params = importModel.toService();
        const res = await post(url, params, { headers: { 'Content-Type': 'multipart/form-data' }, timeout: 600000, responseType: 'blob' });
        return res;
    }
    async importProtocolExcel(importModel: DeviceImportExcelEntityModel) {
        const url = `${URL_PATH}/importByProtocol`;
        const params = importModel.toService();
        const res = await post(url, params, { headers: { 'Content-Type': 'multipart/form-data' }, timeout: 600000, responseType: 'blob' });
        return res;
    }
    async importExcelMatch(importModel: any) {
        const url = `${URL_PATH}/childrenAttrsImport`;
        const params = importModel.toService();
        const res = await post(url, params, { headers: { 'Content-Type': 'multipart/form-data' }, timeout: 600000, responseType: 'blob' });
        return res;
    }

    async getDetailMatterModel(id: string) {
        let res: any = {};
        res.attrs = await DeviceService.getAttrs(id);
        res.events = await DeviceService.getEvents(id);
        res.methods = await DeviceService.getMethods(id);
        return new DeviceEntityModel().toModel(res);
    }
    static async getAttrs(deviceTypeId: string): Promise<any> {
        if (!deviceTypeId) {
            return;
        }
        const url = `${URL_PATH}/attrs/definitions/${deviceTypeId}?type=BASIC`;
        const res = await get(url);
        return res;
    }

    static async getMethods(deviceTypeId: string): Promise<any> {
        if (!deviceTypeId) {
            return;
        }
        const url = `${URL_PATH}/services/definitions/${deviceTypeId}`;
        const res = await get(url);
        return res;
    }

    static async getEvents(deviceTypeId: string): Promise<any> {
        if (!deviceTypeId) {
            return;
        }
        const url = `${URL_PATH}/events/definitions/${deviceTypeId}`;
        const res = await get(url);
        return res;
    }

    async updateUsing(params: { list: { column: string; ids: string[]; value: boolean; }[]; }):Promise<DeviceEntityModel> {
        const url = `${URL_PATH}/attrs/batchUpdate`;
        const res = await post(url, params);
        return res;
    }

    /**
     * 解析驱动zip压缩包
     * @param file
     */
    async parseDriverZip(importModel: any) {
        const url = `${IOT_BASE_REQUEST_PATH}/driver/config/parseDriverZip`;
        const params = importModel.toService();
        const res = await post(url, params, { headers: { 'Content-Type': 'multipart/form-data' }, timeout: 600000 });
        return res;
    }
    /** 导入设备接口调整 */
    async importProductExcel(importModel: DeviceImportExcelEntityModel) {
        const url = `${URL_PATH}/importByProduct`;
        const params = importModel.toService();
        const res = await post(url, params, { headers: { 'Content-Type': 'multipart/form-data' }, timeout: 600000, responseType: 'blob' });
        return res;
    }
    /** 导入驱动设备接口调整 */
    async importDriverExcel(importModel: DeviceImportExcelEntityModel) {
        const url = `${URL_PATH}/importByDriver`;
        const params = importModel.toService();
        const res = await post(url, params, { headers: { 'Content-Type': 'multipart/form-data' }, timeout: 600000, responseType: 'blob' });
        return res;
    }

    /** 批量导入批次ID结果查询 */
    async getImportExcelResult(batchId: string) {
        const url = `${URL_PATH}/query/imgRes/${batchId}`;
        const res = await post(url);
        return res;
    }

    /** 批量导入批次ID结果查询 */
    async downloadImportExcelResult(batchId: string) {
        const url = `${URL_PATH}/download/impRes/${batchId}`;
        const res = await download(url, null, 'post');
        return res;
    }

    /** 导入驱动设备接口调整 */
    async imporDrivertExcel(importModel: DeviceImportExcelEntityModel) {
        const url = `${URL_PATH}/importByDriver`;
        const params = importModel.toService();
        const res = await post(url, params, { headers: { 'Content-Type': 'multipart/form-data' }, timeout: 600000, responseType: 'blob' });
        return res;
    }

    /** 批量修改子系统 */
    async updateSystemEditData(deviceIds: string[], targetBizType: string) {
        const url = `${URL_PATH}/bizType/${targetBizType}`;
        const res = await post(url, deviceIds);
        return res;
    }

    /** 批量导出设备 */
    async exportBatchExcelResult(batchIds: string[]) {
        const url = `${URL_PATH}/exportByProduct`;
        const res = await download(url, batchIds, 'post');
        return res;
    }
}

export default new DeviceService();
