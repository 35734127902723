import BaseCRUDQService from '@common-src/service3/base-crudq.service';
import http from '@common-src/service3/http/axios';
import { AppEntityModel, AppQueryModel, AppGroupEntityModel, AppGroupQueryModel } from '@common-src/entity-model/app-entity';
import { SystemResourcesEntityModel } from '@common-src/entity-model/system-resources-entity';
import { post, get } from '../service/request';
import { SimpleTreeModel } from '@common-src/model/simple-tree-model';

export default class AppService extends BaseCRUDQService<AppEntityModel, AppQueryModel> {
    constructor(baseUrl = AppEntityModel.baseUrl) {
        super(baseUrl, AppEntityModel);
    }

    async auditPass(id: string):Promise<any> {
        const res = await http.request<Array<any>>({
            url: `${AppEntityModel.baseUrl}/pass/${id}`,
            method: 'PUT'
        });
        return res;
    }

    async uninstallApp(ids: Array<string>) {
        const res = await http.request<Array<any>>({
            url: `${AppEntityModel.baseUrl}/uninstall`,
            method: 'POST',
            params: ids
        });
        return res;
    }

    async getDeployResult(deployId: string):Promise<any> {
        const res = await http.request<any>({
            url: `${AppEntityModel.baseUrl}/getDeployResult/${deployId}`,
            method: 'GET'
        }, { isShowMessage: false });
        return res;
    }

    async auditReject(id: string):Promise<any> {
        const res = await http.request<Array<any>>({
            url: `${AppEntityModel.baseUrl}/reject/${id}`,
            method: 'PUT'
        });
        return res;
    }

    static async getAllApp():Promise<Array<AppEntityModel>> {
        const res = await http.request<Array<AppEntityModel>>({
            url: `${AppEntityModel.baseUrl}/pass/web/list`,
            method: 'GET'
        }, { modelType: AppEntityModel });
        return res;
    }

    async exportAppList(ids: Array<string>):Promise<any> {
        const url = `${AppEntityModel.baseUrl}/export`;
        const res = await post(url, ids);
        return res;
    }

    async importAppList(file: any):Promise<Array<Object>> {
        const url = `${AppEntityModel.baseUrl}/import`;
        const formData = new FormData();
        formData.append('file', _.get(file, '[0].file'));
        const res = await post(url, formData, { headers: { 'content-type': 'multipart/form-data' }, timeout: 600000 });
        return _.get(res, 'resources');
    }

    async deployApplication(params: any):Promise<any> {
        const res = await http.request<Array<any>>({
            url: `${AppEntityModel.baseUrl}/deploy`,
            method: 'POST',
            params
        });
        return res;
    }

    async retrieveDetail(Id: string):Promise<any> {
        const url = `${AppEntityModel.baseUrl}/detail/${Id}`;
        const res = await get(url);
        return res;
    }

    // async getLogList(params):Promise<any> {
    //     const url = `${AppEntityModel.baseUrl}/deployLogs/list`;
    //     const res = await post(url, params);
    //     return res;
    // }
    async getLogList(filter?: any, page?: number, pageSize?: number):Promise<any> {
        const url = `${AppEntityModel.baseUrl}/deployLogs/list`;
        const params = Object.assign({}, filter, { page, limit: pageSize });
        const res = await http.request<any>(
            {
                url,
                method: 'POST',
                params
            }
        );
        return res;
    }

    async systemResourceList():Promise<any> {
        const url = `${SystemResourcesEntityModel.baseUrl}/list`;
        const res = await post(url, {
            limit: 10000,
            page: 1,
            params: {}
        });
        return _.map(res.items || res, item => ({
            name: item.name,
            value: item.id,
            identifier: item.identifier
        }));
    }

    static async getAllApps():Promise<any> {
        const url = `${AppEntityModel.baseUrl}/list`;
        const res = await post(url, {
            page: JTL.CONSTANT.FIRST_PAGE,
            limit: JTL.CONSTANT.PAGE_MAX_SIZE
        });
        return _.get(res, 'items');
    }
}

export class AppGroupService extends BaseCRUDQService<AppGroupEntityModel, AppGroupQueryModel> {
    baseUrl = null;
    constructor(baseUrl = '/infra-appmgr/api/appgroup') {
        super(baseUrl, AppGroupEntityModel);
        this.baseUrl = baseUrl;
    }
    async query():Promise<any> {
        const url = `${this.baseUrl}/list`;
        const res = await http.request(
            {
                url,
                method: 'POST'
            }
        );
        const options = _.map(res, (item: any) => {
            if (!item.id) {
                item.id = item.identifier;
            }
            return new SimpleTreeModel().toModel(item);
        });
        _.forEach(options, item => {
            item.name = item.title;
        });
        return options;
    }

    /**
     * 应用分组重排序
     * @param params
     * @returns
     */
    async updateSequence(params):Promise<any> {
        const url = `${this.baseUrl}/sequence/update`;
        const res = await http.request(
            {
                url,
                method: 'POST',
                params
            }
        );
        return res;
    }
}

export class AppAssociateGroupService extends BaseCRUDQService<AppGroupEntityModel, AppQueryModel> {
    constructor(baseUrl = AppGroupEntityModel.baseUrl) {
        super(baseUrl, AppGroupEntityModel);
    }
}

/**
 * 批量关联应用和分组
 * @param params
 * @returns
 */
export async function batchAssociateGroup(params):Promise<any> {
    const url = `${AppGroupEntityModel.baseUrl}/batch/update`;
    const res = await http.request(
        {
            url,
            method: 'POST',
            params
        }
    );
    return res;
}

/**
* 修改应用排序
* @param params
* @returns
*/
export async function updateAppSequence(params):Promise<any> {
    const url = `${AppEntityModel.baseUrl}/updatesequence`;
    const res = await http.request(
        {
            url,
            method: 'POST',
            params
        }
    );
    return res;
}

/**
* 修改应用排序
* @param params
* @returns
*/
export async function getAppGroupList(params):Promise<any> {
    const url = `/infra-appmgr/api/appgroup/list`;
    let res = await http.request(
        {
            url,
            method: 'POST',
            params
        }
    );
    res = _.filter(res, item => item.id);
    return _.map(res, item => {
        return {
            label: item.name,
            value: item.id
        };
    });
}
