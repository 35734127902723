import axios from 'axios';
import { get, post, put, del } from './request';

/**
 * 同时传多个文件到oss，任何一个发生错误都返回reject
 * file未从el-upload从去除的元对象, filePath为传入的路径前缀比如'smp/images/project/'
 * @param files el-upload 空间中取出的文件对象数组
 * @param filePath 中间文件路径，比如: smp/images/project
 * @returns A new Promise. 成功后返回对象未结果数组:  [{ fileName: xxx, url: xxx }, ...];
 */
export async function uploadCustomFiles(files: any[], url) {
    // 允许为空
    if (!files || files.length === 0) {
        throw new Error('uploadFiles 参数不能为空');
    }

    let requsts: any = [];

    files.forEach(file => {
        requsts.push(uploadCustomFile(file, url));
    });
    return Promise.all(requsts);
}

/**
 * 服务端会从根目录下创建对应的文件并加上随机中间目录作为区分
 * 返回的路径示例如下：https://jtl3d-platform-dev.oss-cn-hangzhou.aliyuncs.com/smp/images/project/07418b221ab74c7b9a8d963b0967171d/测试.jpg
 * @param file el-upload 空间中取出的文件对象
 * @param filePath 中间文件路径，比如: smp/images/project
 * @returns A new Promise. 成功后返回对象:  { fileName: xxx, url: xxx };
 */
export async function uploadCustomFile(file: any, host) {
    return new Promise((resolve, reject) => {
        let fd = new FormData();
        fd.append('success_action_status', '200');
        fd.append('file', file);
        post(host, fd)
            .then(response => {
                if (!response || !response['url']) {
                    reject(response);
                } else {
                    resolve({
                        fileName: file.name,
                        url: `${response.url}`
                    });
                }
            })
            .catch(error => {
                console.log('error', error);
                reject(error);
            });
    });
}
