import text from './text';
/**
 * 千分位且保留n位小数，n默认是2
 */
export default (val: string | number, n: number = 2) => {
    val = text(val);
    if (typeof val === 'string') {
        try {
            val = parseFloat(val);
            if (_.isNaN(val)) {
                return '-';
            }
        } catch (e) {
            return val;
        }
    }
    val = (Math.floor(val * 100) / 100).toFixed(n);
    const reg = /(-?\d+)(\d{3})/;
    if (reg.test(val)) {
        return val.replace(reg, '$1,$2').replace('.00', '');
    }
    return val.replace('.00', '');
};
