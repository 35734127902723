






















































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { BaseComponent } from '../../mixins/base-component';
import { FacilityAttributeDataType, FacilityLinkType, FormControlSelectDeviceAttributeType } from '@common-src/model/enum';
import { NUMBER_FLOAT_REGEXP, NUMBER_INT_REGEXP } from '@common-src/model/regexp';
import FacilityTypeService from '@common-src/service/facility-type';
import FacilityConfigTypeService from '@common-src/service/facility-config-type';

@Component
export default class JTLEntityAttributeComponent extends BaseComponent {
    FormControlSelectDeviceAttributeType = FormControlSelectDeviceAttributeType;
    FacilityLinkType = FacilityLinkType;
    @Prop()
    record: any;

    @Prop()
    facilityTypeId: string;

    @Prop()
    selectType: FormControlSelectDeviceAttributeType;

    @Prop({ default: true })
    hasEdit: boolean;

    deviceOptions: Array<any> = null;
    saveable = [];
    savebtndisabled = true;
    FacilityAttributeDataType = FacilityAttributeDataType;
    deviceSearchText: string = '';
    deviceOptionPage: number = 1;
    isEdit: boolean = false;

    get DeviceOptions() {
        // return this.deviceOptions;
        let filterOptions = null;
        const deviceSearchTextUpper = _.toUpper(_.trim(this.deviceSearchText));
        if (deviceSearchTextUpper) {
            filterOptions = _.filter(this.deviceOptions, item => {
                if (item.name) {
                    return item.name.indexOf(deviceSearchTextUpper) > -1;
                }
                return false;
            });
        } else {
            filterOptions = this.deviceOptions;
        }
        let selectOption = [];
        if (this.record.dataValue) {
            selectOption = _.filter(this.deviceOptions, item => {
                if (_.isArray(this.record.dataValue)) {
                    return _.findIndex(this.record.dataValue, (valueIndex: string) => valueIndex.indexOf(item.value) > -1) > -1;
                }
                return item.value === this.record.dataValue;
            });
        }
        return _.union(selectOption, _.take(filterOptions, this.deviceOptionPage * 10));
    }
    deviceOnSearchTextChange(searchText: string) {
        this.deviceOptionPage = 1;
        this.deviceSearchText = searchText;
    }

    created() {
        this.validateField = _.debounce(this.validateField, 500);
        // if (this.selectType !== FormControlSelectDeviceAttributeType.DEVICE_TYPE) {
        //     if (this.record.type === FacilityAttributeDataType.DEVICE) {
        //         FacilityTypeService.getDeviceListByFacilityType(this.facilityTypeId).then(res => {
        //             this.deviceOptions = res;
        //         });
        //     } else if (this.selectType === FormControlSelectDeviceAttributeType.FACILITY_CONFIG && this.record.type === FacilityLinkType.DEVICE) {
        //         FacilityConfigTypeService.getDeviceListByTypeId(this.facilityTypeId, this.record.identifier).then(res => {
        //             this.deviceOptions = res;
        //         });
        //     }
        // }
    }

    async validateField(record) {
        if (record.type === FacilityAttributeDataType.FLOAT) {
            if (!NUMBER_FLOAT_REGEXP.test(record.dataValue) && record.dataValue.length > 0) {
                // this.showMessageError('单精度浮点型类型错误，请更正！');
                record.validatestatus = 'error';
                record.feedback = true;
                this.saveable.push(record.name);
            } else {
                record.validatestatus = 'success';
                record.feedback = false;

                if (this.saveable.indexOf(record.name) >= 0) {
                    this.saveable.splice(this.saveable.indexOf(record.name), 1);
                }
            }
        } else if (record.type === FacilityAttributeDataType.INT) {
            if (!NUMBER_INT_REGEXP.test(record.dataValue) && record.dataValue.length > 0) {
                // this.showMessageError('整型类型错误，请更正！');
                record.validatestatus = 'error';
                record.feedback = true;
                this.saveable.push(record.name);
            } else {
                record.validatestatus = 'success';
                record.feedback = false;
                if (this.saveable.indexOf(record.name) >= 0) {
                    this.saveable.splice(this.saveable.indexOf(record.name), 1);
                }
            }
        }
        if (this.saveable.length === 0) {
            this.savebtndisabled = false;
        } else {
            this.savebtndisabled = true;
        }

        this.$emit('change', this.savebtndisabled);
    }

    filterOption(input, option) {
        // this.deviceOptionPage = 1;
        // const upperInput = _.toUpper(_.trim(input));
        // return _.toUpper(_.get(option.componentOptions, 'children[0].text', '')).indexOf(upperInput) > -1;
        return true;
    }

    editLinkDeviceClick() {
        this.isEdit = !this.isEdit;
        if (this.isEdit) {
            if (this.selectType !== FormControlSelectDeviceAttributeType.DEVICE_TYPE) {
                if (this.record.type === FacilityAttributeDataType.DEVICE) {
                    FacilityTypeService.getDeviceListByFacilityType(this.facilityTypeId).then(res => {
                        this.deviceOptions = res;
                    });
                } else if (this.selectType === FormControlSelectDeviceAttributeType.FACILITY_CONFIG && this.record.type === FacilityLinkType.DEVICE) {
                    FacilityConfigTypeService.getDeviceListByTypeId(this.facilityTypeId, this.record.identifier).then(res => {
                        this.deviceOptions = res;
                    });
                }
            }
        }
    }
}
