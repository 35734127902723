












import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';

@Component
export default class ArrayStringFormComponent extends BaseComponent {
    @Prop()
    value: Array<string>;
    @Prop()
    disabled: boolean;
    @Prop({ default: '请输入' })
    placeholder: string;

    addClick() {
        this.value.push('');
    }
    deleteClick(index: number) {
        this.value.splice(index, 1);
    }
    change(value) {
        this.$emit('change', value);
    }
}

