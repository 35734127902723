

































































import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import OrgService from '@common-src/service/org';

@Component
export default class SelectUserDialog extends BaseComponent {
    searchValue: string = null;
    dialogVisible: boolean = false;
    treeData: Array<any> = null;
    expandedKeys: Array<string> = [];
    checkedKeys: { checked: Array<any>, halfChecked?: Array<any> } = null;
    treeLoading: boolean = true;
    isUserOrGroup: string = 'all';

    get TrimSearchValue() {
        return _.toUpper(_.trim(this.searchValue));
    }

    get RightDataList() {
        if (!this.checkedKeys || !this.checkedKeys.checked || this.checkedKeys.checked.length === 0) {
            return null;
        }
        let rightDataList: Array<any> = [];
        _.forEach(this.treeData, item => {
            rightDataList.push(...this.getTreeSelectItem(item));
        });
        return _.flattenDeep(rightDataList);
    }

    getTreeSelectItem(treeItem) {
        const dataList = [];
        if (this.checkedKeys && this.checkedKeys.checked && this.checkedKeys.checked.indexOf(treeItem.id) > -1) {
            dataList.push(treeItem);
        }
        if (treeItem.children && treeItem.children.length > 0) {
            _.forEach(treeItem.children, item => {
                dataList.push(...this.getTreeSelectItem(item));
            });
        }
        return dataList;
    }

    dialogOpen(selectedKeys: Array<any>) {
        this.dialogVisible = true;
        if (selectedKeys?.length > 0) {
            const { isUser } = selectedKeys[0];
            this.isUserOrGroup = isUser ? 'user' : 'group';
        }
        this.checkedKeys = { checked: _.map(selectedKeys, item => item.id) };
        this.treeLoading = true;
        OrgService.getOrgUserTree().then(res => {
            this.treeData = this.calculateDisable(res, 'isUser');
            _.forEach(this.treeData, item => {
                const checked = this.checkedKeys.checked.indexOf(item.id) > -1;
                if (checked) {
                    _.forEach(item.children, item => {
                        this.checkChildren(item, true);
                    });
                }
            });
        }).catch(err => {
            $log.error(err);
        }).finally(() => {
            this.treeLoading = false;
        });
    }

    onSearchValueChange() {
        if (!this.TrimSearchValue) {
            _.forEach(this.treeData, item => {
                this.initSearchExpandedKeys(item);
            });
            return;
        }
        this.expandedKeys = [];
        _.forEach(this.treeData, item => {
            if (_.toUpper(item.title).indexOf(this.TrimSearchValue) > -1) {
                this.getParentKey(item.parent);
            }
            this.initSearchExpandedKeys(item);
        });
    }

    initSearchExpandedKeys(treeItem) {
        if (!this.TrimSearchValue || _.toUpper(treeItem.title).indexOf(this.TrimSearchValue) > -1) {
            this.getParentKey(treeItem);
        }
        if (treeItem.children && treeItem.children.length > 0) {
            _.forEach(treeItem.children, item => {
                this.initSearchExpandedKeys(item);
            });
        }
    }
    getParentKey(treeItem) {
        if (treeItem && treeItem.parent) {
            this.expandedKeys.push(treeItem.parent.id);
            this.getParentKey(treeItem.parent);
        }
    }

    dialogOK() {
        this.$emit('dialogOK', _.map(this.RightDataList, item => {
            return {
                id: item.id,
                name: item.name,
                isUser: item.isUser
            };
        }));
        this.dialogClose();
    }

    dialogClose(): void {
        this.dialogVisible = false;
    }

    removeItemClick(model, index) {
        const removeIndex = _.findIndex(this.checkedKeys.checked, item => item === model.id);
        this.checkedKeys.checked.splice(removeIndex, 1);
        if (this.checkedKeys.checked.length === 0) {
            this.isUserOrGroup = 'all';
            this.treeData = this.calculateDisable(this.treeData, 'isUser');
        }
    }
    removeAllClick() {
        if (this.checkedKeys) {
            this.checkedKeys = null;
            this.isUserOrGroup = 'all';
            this.treeData = this.calculateDisable(this.treeData, 'isUser');
        }
    }

    onCheck(checkedKeys, e:{checked: boolean, checkedNodes, node, event}) {
        _.forEach(_.get(e.node.dataRef, 'children'), item => {
            this.checkChildren(item, e.checked);
        });
        if (checkedKeys.checked.length === 0) {
            this.isUserOrGroup = 'all';
            this.treeData = this.calculateDisable(this.treeData, 'isUser');
        } else if (checkedKeys.checked.length === 1) {
            const { isUser } = e.checkedNodes[0]['data']['props'];
            this.isUserOrGroup = isUser ? 'user' : 'group';
            this.treeData = this.calculateDisable(this.treeData, 'isUser');
        } else {
            this.treeData = this.calculateDisable(this.treeData, 'isUser');
        }
    }

    checkChildren(treeItem, checked) {
        treeItem.disabled = checked;
        treeItem.disableCheckbox = checked;
        if (checked) {
            const checkedIndex = _.findIndex(this.checkedKeys.checked, checkedKeyItem => checkedKeyItem === treeItem.id);
            if (checkedIndex > -1) {
                this.checkedKeys.checked.splice(checkedIndex, 1);
            }
        }
        _.forEach(treeItem.children, item => {
            this.checkChildren(item, checked);
        });
    }

    calculateDisable(arrTree, isUser) {
        let arr = arrTree || [];
        if (arr && arr.length === 0) {
            return [];
        }
        arr.forEach(element => {
            if (!element[isUser] && this.isUserOrGroup === 'user') {
                element.disableCheckbox = true;
                element.disabled = true;
            } else if (element[isUser] && this.isUserOrGroup === 'group') {
                element.disableCheckbox = true;
                element.disabled = true;
            } else if (this.isUserOrGroup === 'all') {
                element.disableCheckbox = false;
                element.disabled = false;
            }
            if (element.children) {
                this.calculateDisable(element.children, isUser);
            }
        });
        return arr;
    }
}
