var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jtl-json-editor-component" },
    [
      _vm.isEdit
        ? _c("JsonEditor", {
            attrs: {
              options: {
                confirmText: "confirm",
                cancelText: "cancel",
              },
              "obj-data": _vm.JSONData,
            },
            model: {
              value: _vm.JSONData,
              callback: function ($$v) {
                _vm.JSONData = $$v
              },
              expression: "JSONData",
            },
          })
        : _vm.value
        ? _c("json-viewer", { attrs: { value: _vm.JSONData } })
        : _vm._e(),
      _c(
        "a",
        {
          staticClass: "json-operate-btn",
          on: {
            click: function ($event) {
              _vm.isEdit = !_vm.isEdit
            },
          },
        },
        [_vm._v(_vm._s(_vm.isEdit ? "查看" : "编辑"))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }