var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jtl-form-table-component" },
    [
      _vm.addAction && !_vm.disabled
        ? _c(
            "jtl-button",
            { attrs: { type: "primary" }, on: { click: _vm.add } },
            [_vm._v("+添加")]
          )
        : _vm._e(),
      _c("a-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.dataSource,
          pagination: _vm.pagination,
          size: "small",
          bordered: "",
        },
        scopedSlots: _vm._u([
          {
            key: "input",
            fn: function (text, record, index, scopeIndex) {
              return [
                _c("a-input", {
                  attrs: { disabled: _vm.disabled, placeholder: "请输入" },
                  on: {
                    change: function ($event) {
                      return _vm.inputChanged(text, record, index, scopeIndex)
                    },
                  },
                  model: {
                    value: record[scopeIndex.dataIndex],
                    callback: function ($$v) {
                      _vm.$set(record, scopeIndex.dataIndex, $$v)
                    },
                    expression: "record[scopeIndex.dataIndex]",
                  },
                }),
              ]
            },
          },
          {
            key: "select",
            fn: function (text, record, index, scopeIndex) {
              return [
                _c(
                  "a-select",
                  {
                    attrs: { placeholder: "请选择" },
                    on: {
                      change: function ($event) {
                        return _vm.inputChanged(text, record, index, scopeIndex)
                      },
                    },
                    model: {
                      value: record[scopeIndex.dataIndex],
                      callback: function ($$v) {
                        _vm.$set(record, scopeIndex.dataIndex, $$v)
                      },
                      expression: "record[scopeIndex.dataIndex]",
                    },
                  },
                  _vm._l(scopeIndex["selectOptions"], function (item) {
                    return _c(
                      "a-select-option",
                      { key: item.value, attrs: { value: item.value } },
                      [_vm._v(" " + _vm._s(item.name) + " ")]
                    )
                  }),
                  1
                ),
              ]
            },
          },
          {
            key: "action",
            fn: function (text, record, index, scopeIndex) {
              return [
                _vm.deleteAction
                  ? _c(
                      "a-popconfirm",
                      {
                        attrs: {
                          title: "确认删除?",
                          "ok-text": "确认",
                          "cancel-text": "取消",
                        },
                        on: {
                          confirm: function ($event) {
                            return _vm.deleteClick(
                              record.key,
                              index,
                              record,
                              scopeIndex
                            )
                          },
                        },
                      },
                      [
                        _c("a", { staticClass: "jtl-del-link" }, [
                          _vm._v("删除"),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }