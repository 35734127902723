import BaseCRUDQService from '@common-src/service3/base-crudq.service';
import http from '@common-src/service3/http/axios';
import { DeviceEntityModel, DeviceQueryModel } from '@common-src/entity-model/device-entity';
import { ApiMap } from './api-map';

export default class DeviceService extends BaseCRUDQService<DeviceEntityModel, DeviceQueryModel> {
    constructor(baseUrl = DeviceEntityModel.baseUrl) {
        super(baseUrl, DeviceEntityModel);
    }

    static async batchDelete(ids: Array<string>): Promise<any> {
        const res = await http.request<Array<any>>({
            url: ApiMap.deviceBatchDelete,
            method: 'POST',
            params: ids
        });
        return res;
    }

    async unlink(id: string, parentId: string):Promise<any> {
        const res = await http.request<Array<any>>({
            url: `${DeviceEntityModel.baseUrl}/unlink`,
            method: 'POST',
            params: [id]
        });
        return res;
    }

    static async getEdgeDetail(): Promise<any> {
        const res = await http.request<any>({
            url: `${DeviceEntityModel.baseUrl}/edge/detail`,
            method: 'GET'
        });
        return res;
    }

    static async syncDriver(): Promise<any> {
        const res = await http.request<any>({
            url: `${IOT_BASE_REQUEST_PATH}/device/edge/driver/update`,
            method: 'POST'
        });
        return res;
    }

    static async getEdgeName(): Promise<any> {
        const res = await http.request<any>({
            url: `${DeviceEntityModel.baseUrl}/edge/getTitle`,
            method: 'GET'
        });
        return res;
    }

    static async initEdge(file: any): Promise<any> {
        const formData = new FormData();
        formData.append('file', _.get(file, '[0].file'));
        const res = await http.request<any>({
            url: `${DeviceEntityModel.baseUrl}/edge/initProduct`,
            method: 'POST',
            params: formData
        },
        { headers: { 'content-type': 'multipart/form-data' }, timeout: 600000 }
        );
        return res;
    }

    static async getDriverDetail(id: string): Promise<any> {
        const res = await http.request<any>({
            url: `${IOT_BASE_REQUEST_PATH}/driver/device/driverInfo/${id}`,
            method: 'GET'
        });
        return res;
    }

    static async saveDriverConnection(deviceId: string, driverConnection: any):Promise<any> {
        const res = await http.request<Array<any>>({
            url: `${IOT_BASE_REQUEST_PATH}/driver/device/connection/update`,
            method: 'POST',
            params: { deviceId, variables: driverConnection }
        });
        return res;
    }

    static async saveDriverConfig(params):Promise<any> {
        const res = await http.request<Array<any>>({
            url: `${IOT_BASE_REQUEST_PATH}/driver/device/attrDriverPointMapping/update`,
            method: 'POST',
            params
        });
        return res;
    }

    /**
     * 获取驱动点映射详情列表
     * @param id
     * @returns
     */
    static async getMappingsForEditData(id: string): Promise<any> {
        const res = await http.request<Array<any>>({
            url: `${IOT_BASE_REQUEST_PATH}/driver/device/config/mappingsForEdit/${id}`,
            method: 'GET'
        });
        return res;
    }

    /**
     * 服务端属性保存(3.4.0)
     * @param params
     * @returns
     */
    static async updateDeviceAttr(modelId:string, params: any): Promise<any> {
        const res = await http.request<Array<any>>({
            url: `${IOT_BASE_REQUEST_PATH}/driver/device/deviceAttr/${modelId}`,
            method: 'POST',
            params
        });
        return res;
    }

    /**
     * 批量更新属性驱动点位映射
     * @param params
     * @returns
     */
    static async updateMappingsForEditData(params: any): Promise<any> {
        const res = await http.request<Array<any>>({
            url: `${IOT_BASE_REQUEST_PATH}/driver/device/driverInfo`,
            method: 'POST',
            params
        });
        return res;
    }
    // /**
    //  * 批量更新属性驱动点位映射
    //  * @param params
    //  * @returns
    //  */
    // static async updateMappingsForEditData(params: any): Promise<any> {
    //     const res = await http.request<Array<any>>({
    //         url: `${IOT_BASE_REQUEST_PATH}/driver/device/attrDriverPointMapping/batch/update`,
    //         method: 'POST',
    //         params
    //     });
    //     return res;
    // }
}
