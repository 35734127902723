import { Component } from 'vue-property-decorator';
import TableComponent from './table-component';
import { GroupEntityType } from '@common-src/model/enum';

@Component
export default class TransformComponent extends TableComponent<any, any> {
    GroupEntityType = GroupEntityType;
    dialogVisible: boolean = false;
    rightDataList: Array<any> = null;
    debounceTransformGetList = null;

    get AllCheck() {
        if (!this.listData || this.listData.length === 0) {
            return false;
        }
        return _.filter(this.listData, item => item.check).length === this.listData.length;
    }

    set AllCheck(val: boolean) {
        _.forEach(this.listData, item => {
            item.check = val;
            if (val) {
                const index = _.findIndex(this.rightDataList, rightItem => rightItem.id === item.id);
                if (index === -1) {
                    this.rightDataList.push(item);
                }
            }
        });
        if (!val) {
            this.rightDataList = [];
        }
    }

    checkOnChange(model: any) {
        if (model.check) {
            this.rightDataList.push({ id: model.id, code: model.code, name: model.displayName || model.name, deviceTypeId: this.queryModel?.typeId, identifier: model.identifier, serviceArea: model.serviceArea });
        } else {
            const index = _.findIndex(this.rightDataList, item => item.id === model.id);
            if (index > -1) {
                this.rightDataList.splice(index, 1);
            }
        }
    }

    removeItemClick(model, index) {
        model.check = false;
        const listDataItem = _.find(this.listData, item => item.id === model.id);
        if (listDataItem) {
            listDataItem.check = false;
        }
        this.rightDataList.splice(index, 1);
    }

    removeAllClick() {
        this.rightDataList = [];
        _.forEach(this.listData, item => {
            item.check = false;
        });
    }

    transformLoadMoreClick() {
        this.loadMoreClick().then(() => {
            this.checkLeftListData();
        });
    }

    checkLeftListData() {
        if (this.listData && this.listData.length > 0 && this.rightDataList && this.rightDataList.length > 0) {
            _.forEach(this.listData, item => {
                item.check = _.findIndex(this.rightDataList, rd => rd.id === item.id) > -1;
            });
        }
    }

    transformGetList() {
        this.getList().then(() => {
            this.checkLeftListData();
        });
    }

    dialogClose(): void {
        this.listData = null;
        this.rightDataList = [];
        this.dialogVisible = false;
    }
}
