

















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class JTLJsonEditor extends Vue {
    @Prop()
    value: any;

    get JSONData() {
        if (typeof (this.value) === 'string') {
            try {
                return JSON.parse(this.value);
            } catch (e) {
                console.warn(e);
            }
        }
        return this.value;
    }
    set JSONData(val) {
        console.log(val);
        if (typeof (val) === 'string') {
            try {
                this.$emit('input', JSON.parse(val));
            } catch (e) {
                this.$emit('input', val);
            }
        }
    }

    isEdit: boolean = false;

    mounted() {
    }
}

