






import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Editor from 'wangeditor';

@Component
export default class JTLEditor extends Vue {
    @Prop()
    value: any;

    @Prop()
    output: string;

    /**
     * 富文本实例
     */
    private editor: Editor;

    mounted() {
        this.editor = new Editor(this.$refs.editor as any);
        // 配置 onchange 回调函数
        this.editor.config.onchange = newHtml => {
            switch (this.output) {
                case 'txt':
                    this.$emit('input', this.editor.txt.text());
                    break;
                case 'json':
                    // this.$emit('input', this.editor.txt.getJSON());
                    try {
                        this.$emit('input', JSON.parse(this.editor.txt.text()));
                    } catch (e) {
                        this.$emit('input', this.editor.txt.text());
                    }
                    break;
                default:
                    this.$emit('input', newHtml);
                    break;
            }
        };
        // 配置触发 onchange 的时间频率，默认为 200ms
        this.editor.config.onchangeTimeout = 500; // 修改为 500ms
        this.editor.config.placeholder = '';
        this.editor.create();
        // if (this.output === 'json') {
        //     this.editor.txt.setJSON([this.value]);
        // } else {
        //     if (typeof (this.value) === 'object') {
        //         this.editor.txt.html(JSON.stringify(this.value));
        //     } else {
        //         this.editor.txt.html(this.value);
        //     }
        // }
        if (typeof (this.value) === 'object') {
            this.editor.txt.html(JSON.stringify(this.value));
        } else {
            this.editor.txt.html(this.value);
        }
    }

    // @Watch('value')
    // valueChange(newVal: any): void {
    //     this.editor.txt.html(newVal);
    // }

    beforeDestroy() {
        this.editor.destroy();
        this.editor = null;
    }
}

