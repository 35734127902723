import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel, FormControlUploadModel } from '@common-src/model/form-control';
import { DeviceTypeEnum, GroupEntityType } from '@common-src/model/enum';
// import CommonService from '@common-src/service/common';
import { ThingsTemplateQueryModel } from './things-template-entity';
import { ThingsFunctionGroupModel } from './things-function-entity';
import { deviceTypeList } from '@common-src/filter/device-type';
import { NUMBER_LETTER_UNDERLINE_REGEXP } from '@common-src/model/regexp';
// import { protocolTypeList } from '@common-src/filter/protocol-type';
import DeviceTypeService from '@common-src/service3/device-type';
import { getBizTypes } from '@common-src/service3/common.service';
import DeviceDriverService from '@common-src/service3/device-driver';

export class DeviceTypeEntityModel extends ThingsFunctionGroupModel {
    static baseUrl = `${IOT_BASE_REQUEST_PATH}/product`;
    constructor(groupId?: string) {
        super(groupId, GroupEntityType.DEVICE_TYPE);
    }

    @FormControl({
        label: '产品名称',
        type: FormControlType.TEXT,
        required: true,
        max: 255
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '产品标识符',
        type: FormControlType.TEXT,
        required: true,
        max: 50,
        pattern: NUMBER_LETTER_UNDERLINE_REGEXP,
        readonly: true
    } as FormControlTextModel)
    identifier: string = undefined;

    @FormControl({
        label: '驱动',
        type: FormControlType.SELECT,
        mode: 'multiple',
        required: true,
        // options: protocolTypeList,
        optionsPromise: DeviceDriverService.getAllDriver,
        invisibleFunction: (model: DeviceTypeEntityModel) => model.type === DeviceTypeEnum.INNER_GATEWAY
        // relationKeys: ['supportedProducts']
        // cascad: true
        // change: function(formControlList, formControl, formModel, formRules, isFirstLoad) {
        //     if (formModel.driver && formModel.driver.length > 0) {
        //         const allDriverList = formControl.options;
        //         const selectDriverList = _.filter(allDriverList, item => formModel.driver.indexOf(item.value) > -1);
        //         formControlList[4].options = selectDriverList;
        //     } else {
        //         formControlList[4].options = null;
        //     }
        // }
    }as FormControlOptionModel)
    drivers: Array<string> = [];
    driverNames: Array<string> = [];

    // @FormControl({
    //     label: '驱动连接',
    //     type: FormControlType.CHECKBOX_GROUP,
    //     invisibleFunction: model => _.isEmpty(model.driver)
    // }as FormControlOptionModel)
    // linkDirver: Array<string> = undefined;

    // @FormControl({
    //     label: '协议',
    //     type: FormControlType.SELECT,
    //     options: protocolTypeList
    // }as FormControlTextModel)
    // protocol: string = undefined;

    // @FormControl({
    //     label: '物模板',
    //     type: FormControlType.SELECT,
    //     required: false,
    //     optionsPromise: DeviceTypeService.getTemplateOptions,
    //     readonly: true
    // } as FormControlOptionModel)
    // templateId: string = undefined;

    @FormControl({
        label: '设备类型',
        type: FormControlType.RADIO_GROUP,
        required: true,
        options: deviceTypeList,
        readonly: true,
        cascad: true,
        change: function(formControlList, formControl, formModel, formRules) {
            if (formModel.type === DeviceTypeEnum.GATEWAY) {
                formRules['bizType'][0].required = false;
            } else if (formModel.type === DeviceTypeEnum.DEVICE) {
                formRules['bizType'][0].required = true;
            }
            if (formModel.type === DeviceTypeEnum.INNER_GATEWAY) {
                formModel.drivers = [];
            }
        }
    } as any)
    type: DeviceTypeEnum = undefined;

    @FormControl({
        label: '子系统类型',
        type: FormControlType.SELECT,
        optionsPromise: getBizTypes,
        // required: true,
        // errorMessage: { required: '设备子系统类型是必填项' },
        invisibleFunction: (model: DeviceTypeEntityModel) => [DeviceTypeEnum.GATEWAY, DeviceTypeEnum.DEVICE].indexOf(model.type) === -1
    } as FormControlOptionModel)
    bizType: string = undefined;

    // @FormControl({
    //     label: '子系统类型',
    //     type: FormControlType.SELECT,
    //     optionsPromise: getBizTypes,
    //     required: false,
    //     invisibleFunction: (model: DeviceTypeEntityModel) => !model.type || model.type === DeviceTypeEnum.INNER_GATEWAY || model.type === DeviceTypeEnum.DEVICE
    // } as FormControlOptionModel)
    // bizTypeGateway: string = undefined;

    @FormControl({
        label: '关联产品类型',
        type: FormControlType.SELECT,
        optionsPromise: DeviceTypeService.getDeviceTypeOptionsByDrivers,
        // optionsPromise: DeviceTypeService.getDeviceTypeOptions,
        // optionsPromiseParam: DeviceTypeEnum.DEVICE,
        // optionsPromiseParam: [],
        optionsPromiseParam: 'drivers',
        mode: 'multiple',
        message: '若选择关联产品类型，则本产品类型的设备在关联子设备时，只可以选择属于关联产品类型的设备',
        invisibleFunction: (model: DeviceTypeEntityModel) => model.type === DeviceTypeEnum.DEVICE
    } as FormControlOptionModel)
    supportedProducts: Array<string> = undefined;
    supportedProductNames: string = undefined;

    /**
     * 获取是否网关显示文本
     */
    get DisplayGateway() {
        return this.type === DeviceTypeEnum.DEVICE ? '否' : '是';
    }
    get IsInnerGateway(): boolean {
        return this.type === DeviceTypeEnum.INNER_GATEWAY;
    }

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    remark: string = undefined;

    @FormControl({
        label: '关键属性',
        type: FormControlType.SELECT,
        optionsPromise: DeviceTypeService.getDeviceTypeAttrs,
        optionsPromiseParam: 'id',
        mode: 'multiple',
        required: false,
        addHidden: true
    } as FormControlOptionModel)
    keyAttributes: Array<any> = undefined;
    versionTime: string = undefined;
    version: string = undefined;

    static getTableColumns(isEdge?: boolean) {
        const columns = [
            {
                title: '产品名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' },
                width: 200
            },
            {
                title: '标识符',
                dataIndex: 'identifier',
                width: 200
            },
            {
                title: '是否网关',
                dataIndex: 'DisplayGateway'
            },
            {
                title: '版本号',
                dataIndex: 'version'
            },
            {
                title: '添加时间',
                dataIndex: 'createdTime',
                width: 200
            },
            {
                title: '描述',
                dataIndex: 'remark',
                width: 200
            },
            {
                title: '操作',
                dataIndex: 'action',
                width: 300,
                scopedSlots: { customRender: 'action' }
            }
        ];
        isEdge ? columns.splice(2, 0, { title: '版本时间', dataIndex: 'versionTime', width: 150 }) : null;
        return columns;
    }

    toModel(json): any {
        super.toModel(json);
        // if (this.type === DeviceTypeEnum.INNER_GATEWAY) {
        //     this.remark = `${this.remark || ''}(内置网关，不可删除，不可被下挂)`;
        // }
        this.supportedProducts = _.get(json, 'supportedProducts') || _.get(json, 'type.supportedProducts');
        this.supportedProductNames = _.get(json, 'supportedProductNames', [])?.join(', ');
        // if (this.type === DeviceTypeEnum.GATEWAY) {
        //     this.bizTypeGateway = this.bizType;
        // }
        this.drivers = json.drivers || [];
        this.driverNames = json.driverNames || [];
        return this;
    }

    toService() {
        let data: any = super.toService();
        // data.templateId = this.templateId;
        data.groupId = this.groupId;
        // delete data.template.templateId;
        // delete data.template.groupId;
        // data.type = data.template;
        data = Object.assign({}, data, data.template);
        if (this.type === DeviceTypeEnum.DEVICE) {
            delete data.supportedProducts;
        }
        // if (this.type === DeviceTypeEnum.GATEWAY) {
        //     this.bizType = this.bizTypeGateway;
        //     delete data.bizTypeGateway;
        // }
        delete data.template;
        delete data.attrs;
        delete data.events;
        delete data.methods;
        delete data.features;
        delete data.supportedProductNames;
        delete data.driverNames;
        return data;
    }
}
export class ImportJsonEntityModel extends ThingsFunctionGroupModel {
    constructor(groupId?: string) {
        super(groupId, GroupEntityType.DEVICE_TYPE);
    }

    @FormControl({
        label: '上传',
        type: FormControlType.UPLOAD,
        accept: '.json',
        maxFileNumber: 1,
        notUploadOss: true,
        required: true
    } as FormControlUploadModel)
    jsonFile: Array<any> = undefined;

    toService() {
        const formData = new FormData();
        formData.append('file', _.get(this.jsonFile, '[0].file'));
        if (this.groupId) {
            formData.append('groupId', this.groupId);
        }
        return formData;
    }
}

export class DeviceTypeQueryModel extends ThingsTemplateQueryModel {

}
