import { FormControl, FormControlType, FormControlTextModel, FormControlUploadModel, FormControlOptionModel } from '@common-src/model/form-control';
import { GroupQueryModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { NUMBER_LETTER_UNDERLINE_REGEXP } from '@common-src/model/regexp';
import { ThingsFunctionGroupModel } from './things-function-entity';
import { GroupEntityType, PropertyEntityType } from '@common-src/model/enum';
import CommonService from '@common-src/service/common';
export class LabelEntityModel extends ThingsFunctionGroupModel {
    constructor(groupId?: string, groupType: GroupEntityType = GroupEntityType.TAG) {
        super(groupId, groupType);
    }

    @FormControl({
        label: '名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '标识符',
        type: FormControlType.TEXT,
        required: true,
        max: 50,
        pattern: NUMBER_LETTER_UNDERLINE_REGEXP,
        readonly: true
    } as FormControlTextModel)
    identifier: string = undefined;

    @FormControl({
        label: '类型',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getPropertyOptions,
        optionsPromiseParam: PropertyEntityType.LABEL_TYPE
    } as FormControlOptionModel)
    type: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name'
            },
            {
                title: '标识符',
                dataIndex: 'identifier'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        return {
            id: this.id,
            groupId: this.groupId,
            groupType: this.groupType,
            name: this.name,
            identifier: this.identifier,
            type: this.type
        };
    }
}

export class LabelQueryModel extends GroupQueryModel {
    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT,
        span: 12
    })
    name: string = undefined;

    toService() {
        const data = super.toService();
        data.params = {
            groupIds: this.GroupIds
        };
        if (this.name) {
            data.params.name = this.name;
        }
        delete data.name;
        delete data.groupIds;
        return data;
    }
}

export class ImporExcelEntityModel extends ThingsFunctionGroupModel {
    constructor(groupId?: string, groupType: GroupEntityType = GroupEntityType.TAG) {
        super(groupId, groupType);
    }
    @FormControl({
        label: '上传',
        type: FormControlType.UPLOAD,
        accept: '.xlsx',
        maxFileNumber: 1,
        notUploadOss: true,
        required: true
    } as FormControlUploadModel)
    file: Array<any> = undefined;

    toService() {
        const formData = new FormData();
        formData.append('file', _.get(this.file, '[0].file'));
        if (this.groupId) {
            formData.append('groupId', this.groupId);
        }
        return formData;
    }
}
