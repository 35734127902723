import BaseCRUDQService from '@common-src/service3/base-crudq.service';
import http from '@common-src/service3/http/axios';
import { DeviceDriverEntityModel, DeviceDriverQueryModel } from '@common-src/entity-model/device-driver.entity';
import { FormControlType } from '@common-src/model/form-control';
import { ThingsModelType } from '@common-src/model/enum';

// const MOCK_DATA = {
//     'name': 'parking-jieshun',
//     'identifier': 'parking-jieshun',
//     'version': '1.0.1',
//     'desc': '捷顺停车场驱动',
//     'releaseDate': null,
//     'libFileUrl': null,
//     'objectTypeProps': [
//         {
//             'identifier': 'Area-SpaceTotal',
//             'name': '区域-车位总数',
//             'pointAttrFormat': '{FloorCode}#{RegionCode}',
//             'variables': [
//                 'FloorCode',
//                 'RegionCode'
//             ]
//         },
//         {
//             'identifier': 'Area-SpaceFree',
//             'name': '区域-空闲车位数',
//             'pointAttrFormat': '{FloorCode}#{RegionCode}',
//             'variables': [
//                 'FloorCode',
//                 'RegionCode'
//             ]
//         },
//         {
//             'identifier': 'Floor-SpaceTotal',
//             'name': '楼层-车位总数',
//             'pointAttrFormat': '{FloorCode}',
//             'variables': [
//                 'FloorCode'
//             ]
//         },
//         {
//             'identifier': 'Floor-SpaceFree',
//             'name': '楼层-空闲车位数',
//             'pointAttrFormat': '{FloorCode}',
//             'variables': [
//                 'FloorCode'
//             ]
//         }
//     ],
//     'variables': [
//         {
//             'identifier': 'DriverAddress',
//             'name': '驱动地址',
//             'type': 'string',
//             'value': '',
//             'required': true,
//             'specs': {
//                 'length': 10240
//             }
//         },
//         {
//             'identifier': 'FloorCode',
//             'name': '楼层编号',
//             'type': 'String',
//             'value': null,
//             'required': true,
//             'specs': {}
//         },
//         {
//             'identifier': 'RegionCode',
//             'name': '区域编号',
//             'type': 'String',
//             'value': null,
//             'required': true,
//             'specs': {}
//         }
//     ],
//     'connectionParams': [
//         {
//             'identifier': 'FuncUrl',
//             'name': '车位状态查询接口地址',
//             'type': 'string',
//             'value': '',
//             'required': true,
//             'specs': {
//                 'length': 10240
//             }
//         }
//     ]
// };

export function initAttributeFormControl(variable) {
    let controlType = null;
    let controlOptions = null;
    if (ThingsModelType.ENUM === variable.type && variable.specs && _.keys(variable.specs).length > 0) {
        controlType = FormControlType.SELECT;
        controlOptions = _.map(_.keys(variable.specs), key => ({ name: variable.specs[key], value: key }));
    } else if (ThingsModelType.BOOLEAN === variable.type && variable.specs && _.keys(variable.specs).length > 0) {
        controlType = FormControlType.RADIO_GROUP;
        // controlOptions = _.map(_.keys(variable.specs), key => ({ name: variable.specs[key], value: variable.specs[key] }));
        controlOptions = [
            { name: variable.specs['0'] || variable.specs['false'], value: false },
            { name: variable.specs['1'] || variable.specs['true'], value: true }
        ];
    } else {
        controlType = FormControlType.TEXT;
    }
    return {
        key: variable.identifier,
        label: variable.name,
        type: controlType,
        options: controlOptions,
        value: variable.value,
        required: variable.required
    };
}

function initDriverConfig(res) {
    return {
        /** 设置五个初始值，不需要设值，响应式需要 */
        dataFalseValue: '',
        dataTrueValue: '',
        expressionOther: '',
        currentEnumList: [],
        mathOperations: _.get(res.commonConfig, 'dataConversionExpression.mathOperations') || [
            {
                name: 'Round',
                params: {
                    decimalDigit: undefined,
                    roundType: undefined
                }
            },
            {
                name: 'Absolute',
                params: false
            }
        ],
        identifier: res.identifier || res.driverIdentifier,
        name: res.name || res.identifier || res.driverIdentifier,
        attrType: _.get(res, 'objectTypePropIdentifier'),
        pollInterval: _.get(res.commonConfig, 'pollInterval'),
        ratio: _.get(res.commonConfig, 'coefficient'),
        expression: _.get(res.commonConfig, 'dataConversionExpression'),
        attrTypeList: _.map(res.objectTypeProps, item => {
            const variables = _.filter(res.variables, child => item.variables && item.variables.indexOf(child.identifier) > -1);
            const controlList = _.map(variables, item => {
                return initAttributeFormControl(item);
            });
            return {
                name: item.name,
                value: item.identifier,
                format: item.pointAttrFormat,
                children: controlList
            };
        }),
        variables: _.map(res.variables, item => {
            return initAttributeFormControl(item);
        })
    };
}

export default class DeviceDriverService extends BaseCRUDQService<DeviceDriverEntityModel, DeviceDriverQueryModel> {
    constructor(baseUrl = DeviceDriverEntityModel.baseUrl) {
        super(baseUrl, DeviceDriverEntityModel);
    }

    static async getAllDriver(): Promise<any> {
        const deviceDriverService = new DeviceDriverService();
        const deviceDriverQueryModel = new DeviceDriverQueryModel();
        const res = await deviceDriverService.query(deviceDriverQueryModel, JTL.CONSTANT.FIRST_PAGE, JTL.CONSTANT.PAGE_MAX_SIZE);
        const options = _.map(res.items, item => {
            return {
                name: item.name,
                value: item.identifier
            };
        });
        return options;
    }

    async getDetailByIdentifier(identifier: string): Promise<any> {
        const res = await http.request<any>(
            {
                url: `${this.baseUrl}/detailByIdentifier/${identifier}`,
                method: 'GET'
            },
            { modelType: DeviceDriverEntityModel }
        );
        return res;
    }

    static async getProductAttributeDriverDetail(attrId: string): Promise<any> {
        // const MOCK_DATA2 = _.cloneDeep(MOCK_DATA);
        // MOCK_DATA2.identifier = 'modbus';
        // MOCK_DATA2.name = 'modbus';
        // return [
        //     initDriverConfig(MOCK_DATA),
        //     initDriverConfig(MOCK_DATA2)
        // ];
        const res = await http.request<any>({
            url: `${IOT_BASE_REQUEST_PATH}/product/attr/detail/${attrId}`,
            method: 'GET'
        });
        return _.map(res?.driverPointDetails, item => initDriverConfig(item));
    }

    static async getProductDriverDetail(productId: string): Promise<any> {
        const res = await http.request<Array<any>>({
            url: `${IOT_BASE_REQUEST_PATH}/product/driver/detail/${productId}`,
            method: 'GET'
        });
        return _.map(res, item => initDriverConfig(item));
    }

    static async getDriverConfigDetail(id: string, identifier: string): Promise<any> {
        const res = await http.request<any>({
            url: `${IOT_BASE_REQUEST_PATH}/driver/device/attrDriverPointMapping/detail/${id}/${identifier}`,
            method: 'POST'
        });
        return initDriverConfig(res);
    }

    static async getThingsTemplateDriverDetail(productId: string): Promise<any> {
        const res = await http.request<Array<any>>({
            url: `${IOT_BASE_REQUEST_PATH}/things/template/driver/detail/${productId}`,
            method: 'GET'
        });
        return res;
    }

    // static async getDriverDetail(): Promise<any> {
    //     return initDriverConfig(MOCK_DATA);
    //     // const res = await http.request<Array<any>>({
    //     //     url: `${DeviceDriverEntityModel.baseUrl}/attr/faultStatus`,
    //     //     method: 'GET'
    //     // });
    //     // const options = _.map(res, item => {
    //     //     return {
    //     //         name: `${item.name} | ${item.identifier}`,
    //     //         value: item.id
    //     //     };
    //     // });
    //     // return options;
    // }
}

export const deviceDriverService = new DeviceDriverService();
