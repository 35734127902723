import { FormControl, FormControlType, FormControlOptionModel } from '@common-src/model/form-control';
import { GroupQueryModel } from '@common-src/model/query-model';
import { ThingsGroupEntityModel } from './things-group-entity';
import CommonService from '@common-src/service/common';
import { GroupEntityType } from '@common-src/model/enum';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
export class OrgEntityModel extends ThingsGroupEntityModel {
    constructor() {
        super(GroupEntityType.ORG);
    }

    @FormControl({
        label: '负责人',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getOrgUserList,
        optionsPromiseParam: 'id'
    } as FormControlOptionModel)
    userIds: string = undefined;
    isUser: boolean = false;
    // cascadeName: string = '';

    getCascadeName() {
        let cascadeName = '';
        if (this.parent) {
            cascadeName += this.parent.getCascadeName();
        }
        return cascadeName ? cascadeName + '/' + this.name : this.name;
    }

    static getTableColumns() {
        return [
            {
                title: '姓名',
                dataIndex: 'name'
            },
            {
                title: '手机号',
                dataIndex: 'phone'
            },
            {
                title: '职位',
                dataIndex: 'postName'
            }
        ];
    }

    toModel(json): any {
        this.name = this.title = _.get(json, 'name');
        this.type = _.get(json, 'type');
        this.key = this.value = this.id = _.get(json, 'id');
        this.pId = _.get(json, 'parentId');
        this.userIds = _.get(json, 'userIds');

        if (json && (json.children && json.children.length > 0) || (json.users && json.users.length > 0)) {
            const orgList = _.map(json.children, item => {
                item.type = this.type;
                const child: OrgEntityModel = new OrgEntityModel().toModel(item);
                child.parent = this;
                child.pId = this.id;
                return child;
            });

            const userList = _.map(json.users, item => {
                item.type = this.type;
                const child: OrgEntityModel = new OrgEntityModel().toModel(item);
                child.parent = this;
                child.pId = this.id;
                child.isUser = true;
                return child;
            });
            this.children = _.union(orgList, userList);
        } else {
            this.children = [];
        }
        return this;
    }

    toService(): any {
        const data:any = {
            org: {
                id: this.id,
                name: this.name,
                parentId: this.pId
            }
        };
        if (this.userIds) {
            data.userIds = Array.isArray(this.userIds) ? this.userIds : [this.userIds];
        }
        return data;
    }
}

export class OrgQueryModel extends GroupQueryModel {
    @QueryControl({
        placeholder: '请输入名称或手机号',
        type: QueryControlType.TEXT,
        span: 12
    })
    name: string = undefined;
    isOrgQuery: boolean = true;
    excludeOrgId: string = undefined;

    toService() {
        return {
            params: {
                keyword: this.name,
                orgId: Array.isArray(this.groupIds) ? _.get(this.groupIds, '[0]') : this.groupIds,
                excludeOrgId: this.excludeOrgId
            }
        };
    }
}
