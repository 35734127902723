import { EventLevelType } from '@common-src/model/enum';
const eventLevelList: Array<{ name: string, value: EventLevelType }> = _.filter(_.map(EventLevelType, item => {
    let name: string = null;
    switch (item) {
        case EventLevelType.ALERT:
            name = '告警';
            break;
        case EventLevelType.INFO:
            name = '信息';
            break;
        case EventLevelType.ERROR:
            name = '故障';
            break;
    }
    return { name, value: item };
}), item => item.name) as any;
const eventLevelFilter = (val: EventLevelType) => {
    return _.get(_.find(eventLevelList, item => item.value === val), 'name') || '-';
};
export {
    eventLevelFilter,
    eventLevelList
};
