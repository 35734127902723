export default {
    update: function(el, binding, vnode) {
        const isMove: boolean = binding.value;
        let startX: number = 0;
        let startY: number = 0;
        let startMove: boolean = false;
        // el.style.left = '0';
        // el.style.top = '0';
        if (isMove) {
            el.style.userSelect = 'none';
            el.style.cursor = 'pointer';
            el.onmousedown = (ev) => {
                // console.log('move onmousedown');
                ev.preventDefault();
                ev.stopPropagation();
                startMove = true;
                startX = parseFloat(el.style.left);
                startY = parseFloat(el.style.top);
            };
            el.onmousemove = (ev) => {
                // console.log('move onmousemove');
                ev.preventDefault();
                ev.stopPropagation();
                if (!startMove) {
                    return;
                }
                startX += ev.movementX;
                startY += ev.movementY;
                el.style.left = `${startX}px`;
                el.style.top = `${startY}px`;
            };
            el.onmouseup = (ev) => {
                // console.log('move onmouseup');
                ev.preventDefault();
                ev.stopPropagation();
                if (!startMove) {
                    return;
                }
                startMove = false;
                startX = 0;
                startY = 0;
            };
        } else {
            el.onmousedown = null;
            el.onmousemove = null;
            el.onmouseup = null;
            el.style.cursor = 'inherit';
            el.style.userSelect = 'inherit';
        }
    }
};
