import axios from 'axios';

/**
 * 同时传多个文件到oss，任何一个发生错误都返回reject
 * file未从el-upload从去除的元对象, filePath为传入的路径前缀比如'smp/images/project/'
 * @param files el-upload 空间中取出的文件对象数组
 * @param filePath 中间文件路径，比如: smp/images/project
 * @returns A new Promise. 成功后返回对象未结果数组:  [{ fileName: xxx, url: xxx }, ...];
 */
export async function uploadFiles(files: any[]) {
    // 允许为空
    if (!files || files.length === 0) {
        throw new Error('uploadFiles 参数不能为空');
    }

    let requsts: any = [];

    files.forEach(file => {
        requsts.push(uploadFile(file));
    });
    return Promise.all(requsts);
}

/**
 * 上传单个文件到oss: 先从file-service的getSignature API获取oss签名，然后通过获取的签名直接传入阿里云oss
 * 服务端会从根目录下创建对应的文件并加上随机中间目录作为区分
 * 返回的路径示例如下：https://jtl3d-platform-dev.oss-cn-hangzhou.aliyuncs.com/smp/images/project/07418b221ab74c7b9a8d963b0967171d/测试.jpg
 * @param file el-upload 空间中取出的文件对象
 * @param filePath 中间文件路径，比如: smp/images/project
 * @returns A new Promise. 成功后返回对象:  { fileName: xxx, url: xxx };
 */
export async function uploadFile(file: any) {
    return new Promise((resolve, reject) => {
        getSignature(file.name)
            .then((result: any) => {
                let data = result;
                let fd = new FormData();
                fd.append('OSSAccessKeyId', data.accessKey);
                fd.append('Signature', data.signature);
                fd.append('policy', data.policy);
                fd.append('key', data.startsWith);
                fd.append('success_action_status', '200');
                fd.append('file', file);

                uploadToOss(data.host, fd)
                    .then(uploadResponse => {
                        if (uploadResponse) {
                            const retObj = {
                                fileName: file.name,
                                url: `${data.host}/${data.startsWith}`
                            };
                            resolve(retObj);
                        } else {
                            reject(uploadResponse);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
            .catch(error => {
                reject(error);
            });
    });
}

// 通过签名上传单个文件到oss
export async function uploadToOss(host: any, fd: FormData) {
    return new Promise((resolve, reject) => {
        axios.create().request(
            {
                url: host,
                method: 'post',
                data: fd,
                headers: { 'Content-Type': 'multipart/form-data' }
            }
        )
            .then(response => {
                if (!response || response['status'] !== 200) {
                    reject(response.statusText);
                } else {
                    resolve(true);
                }
            })
            .catch(error => {
                console.log('error', error);
                reject(error);
            });
    });
}

// 通过后台file-service动态获取OSS签名
export async function getSignature(fileName: string) {
    return new Promise((resolve, reject) => {
        axios({
            url: `${COMMON_BASE_REQUEST_PATH}/file/getSignature`,
            method: 'post',
            data: {
                fileName: fileName,
                type: 'WORKFLOW'
            }
        })
            .then((data: any) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            });
    });
}
