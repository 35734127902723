





















import { Component } from 'vue-property-decorator';
import { SelectTreeComponent } from '@common-src/mixins/tree-select-component';
import DeviceService from '@common-src/service/device';

@Component
export default class JTLSelectDeviceComponent extends SelectTreeComponent {
    created() {
        this.loading = true;
        DeviceService.getDeviceTree().then(res => {
            this.options = res;
            this.$nextTick(() => {
                // this.selectId = this.value;
                this.setSelectId();
            });
        }).catch(err => {
            $log.error(err);
        }).finally(() => {
            this.loading = false;
        });
    }
}

