import { BaseEntityModel } from '@common-src/model/base-model';
import { TopoBehaveType, TopoDataType } from './enum';
import TopoDataBaseModel from './data-model/topo-data-base-model';
import TopoDataHttpModel from './data-model/topo-data-http-model';
import TopoDataStaticModel from './data-model/topo-data-static-model';
import TopoDataParentModel from './data-model/topo-data-parent-model';
import TopoDataInnerHttpModel from './data-model/topo-data-inner-http-model';
import TopoBehaveModel, { TopoBehaveClickModel, TopoBehaveBroadcastAndSubscribeModel } from './behave-model/topo-behave-model';

export default class TopoBaseComponentModel extends BaseEntityModel {
    /**
     * 正在加载标识
     */
    loading: boolean = true;

    /**
     * 组件的数据
     */
    data: TopoDataBaseModel = null;

    /**
     * 组件的行为
     */
    behaves: Array<TopoBehaveModel> = undefined;

    /**
     * 初始化组件数据模型
     * @param dataOptions
     */
    initDataModel(dataOptions) {
        switch (_.get(dataOptions, 'type')) {
            case TopoDataType.HTTP:
                this.data = new TopoDataHttpModel(dataOptions);
                break;
            case TopoDataType.INNER_HTTP:
                this.data = new TopoDataInnerHttpModel(dataOptions);
                break;
            case TopoDataType.PARENT:
                this.data = new TopoDataParentModel(dataOptions);
                break;
            default:
            case TopoDataType.STATIC:
                this.data = new TopoDataStaticModel(dataOptions);
                break;
        }
        return this;
    }

    /**
     * 初始化组件行为模型
     * @param behaveOptions
     */
    initBehaveModel(behaveOptions?) {
        if (!behaveOptions) {
            return;
        }
        let behaveModel = null;
        switch (behaveOptions.type) {
            case TopoBehaveType.SUBSCRIBE:
            case TopoBehaveType.BROADCAST:
                behaveModel = new TopoBehaveBroadcastAndSubscribeModel().toModel(behaveOptions);
                break;
            case TopoBehaveType.CLICK:
            case TopoBehaveType.DBCLICK:
            default:
                behaveModel = new TopoBehaveClickModel().toModel(behaveOptions);
                break;
        }
        return behaveModel;
    }

    toModel(options) {
        super.toModel(options);
        this.initDataModel(_.get(options, 'data'));
        this.behaves = _.filter(_.map(_.get(options, 'behaves'), item => {
            return this.initBehaveModel(item);
        }), item => item);
        return this;
    }
}
