import Vue from 'vue';
import { parse as QsParse } from 'qs';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

const jtl: any = {
    debug: !!localStorage.getItem('debug'),
    moduleType: null,
    CONFIG: null,
    // CONSTANT: Object.freeze({
    CONSTANT: {
        PAGE_SIZE: 20,
        PAGE_MAX_SIZE: 9999,
        FIRST_PAGE: 1,
        DEFAULT_FORM_NAME: 'JTL_FORM',
        DEFAULT_CUSTOMER_FORM_NAME: 'JTL_CUSOMER_FORM_NAME',
        DEFAULT_TABLE_QUERY_NAME: 'JTL_TABLE_QUERY',
        LAST_DAY_OF_MONTH_FLAG: '0',
        CUSTOM_DAY_OF_MONTH_FLAG: 'C',
        ALL_OPTION: { label: '全部', name: '全部', value: '' },
        COLORS: ['pink', 'red', 'orange', 'green', 'cyan', 'blue', 'purple'],
        TODAY: () => {
            return [moment().utcOffset(8).startOf('day'), moment().utcOffset(8)];
        },
        LAST_24_HOUR() {
            return [moment().utcOffset(8).add('-24', 'hour'), moment().utcOffset(8)];
        },
        YESTERDAY: () => {
            return [moment().utcOffset(8).add(-1, 'day').startOf('day'), moment().utcOffset(8).add(-1, 'day').endOf('day')];
        },
        LAST_3_DAY: () => {
            return [moment().utcOffset(8).add(-3, 'day').startOf('day'), moment().utcOffset(8)];
        },
        LAST_7_DAY: () => {
            return [moment().utcOffset(8).add(-7, 'day').startOf('day'), moment().utcOffset(8)];
        },
        LAST_30_DAY: () => {
            return [moment().utcOffset(8).add(-30, 'day').startOf('day'), moment().utcOffset(8)];
        },
        CURRENT_WEEK: () => {
            return [moment().utcOffset(8).startOf('week'), moment().utcOffset(8).endOf('week')];
        },
        LAST_WEEK: () => {
            return [moment().utcOffset(8).add(-1, 'week').startOf('week'), moment().utcOffset(8).add(-1, 'week').endOf('week')];
        },
        CURRENT_MONTH: () => {
            return [moment().utcOffset(8).startOf('month'), moment().utcOffset(8).endOf('month')];
        },
        LAST_1_MONTH: () => {
            return [moment().utcOffset(8).add(-1, 'month').startOf('month'), moment().utcOffset(8).add(-1, 'month').endOf('month')];
        },
        CURRENT_YEAR: () => {
            return [moment().utcOffset(8).startOf('year'), moment().utcOffset(8).endOf('year')];
        },
        LAST_YEAR: () => {
            return [moment().utcOffset(8).add(-1, 'year').startOf('year'), moment().utcOffset(8).add(-1, 'year').endOf('year')];
        }
    }
};

window['JTL'] = Vue.prototype.JTL = jtl;

const BI_API = 'bi-api';
const WORKORDER_API = 'wo-api';
const BIZ_API = 'biz-api';
const AUTH_API = 'auth-api';
const ASSET_API = 'asset-api';
const RULE_API = 'rule-api';
const BIBIZ_API = 'bibiz-api';
const IOT_API = 'iot-api';
const COMMON_API = 'common-api';
const querySearch = window.location.search;

if (querySearch && querySearch.length > 0) {
    const queryObj = QsParse(querySearch.substr(1, querySearch.length - 1));
    // $log.info('Url qs: ', queryObj);
    let alertMessage = '';

    if (queryObj) {
        if (queryObj[BI_API]) {
            window.localStorage.setItem(BI_API, queryObj[BI_API] as string);
            alertMessage += `bi-api地址: ${queryObj[BI_API]}; `;
        }

        if (queryObj[WORKORDER_API]) {
            window.localStorage.setItem(WORKORDER_API, queryObj[WORKORDER_API] as string);
            alertMessage += `wo-api地址: ${queryObj[WORKORDER_API]}; `;
        }

        if (queryObj[BIZ_API]) {
            window.localStorage.setItem(BIZ_API, queryObj[BIZ_API] as string);
            alertMessage += `biz-api地址: ${queryObj[BIZ_API]}; `;
        }
        if (queryObj[AUTH_API]) {
            window.localStorage.setItem(AUTH_API, queryObj[AUTH_API] as string);
            alertMessage += `auth-api地址: ${queryObj[AUTH_API]}; `;
        }
        if (queryObj[ASSET_API]) {
            window.localStorage.setItem(ASSET_API, queryObj[ASSET_API] as string);
            alertMessage += `asset-api地址: ${queryObj[ASSET_API]}; `;
        }
        if (queryObj[RULE_API]) {
            window.localStorage.setItem(RULE_API, queryObj[RULE_API] as string);
            alertMessage += `rule-api地址: ${queryObj[RULE_API]}; `;
        }
        if (queryObj[BIBIZ_API]) {
            window.localStorage.setItem(BIBIZ_API, queryObj[BIBIZ_API] as string);
            alertMessage += `bibiz-api地址: ${queryObj[BIBIZ_API]}; `;
        }
        if (queryObj[IOT_API]) {
            window.localStorage.setItem(IOT_API, queryObj[IOT_API] as string);
            alertMessage += `iot-devicemgr地址: ${queryObj[IOT_API]}; `;
        }
        if (queryObj[COMMON_API]) {
            window.localStorage.setItem(COMMON_API, queryObj[COMMON_API] as string);
            alertMessage += `infra-common地址: ${queryObj[COMMON_API]}; `;
        }
    }

    if (alertMessage) {
        alert(`请注意！请注意！请注意！，您正在使用代理api地址：${alertMessage}`);
    }
}

window['BI_BASE_REQUEST_PATH'] = window.localStorage.getItem(BI_API) || process.env.VUE_APP_BI_BASE_REQUEST_PATH;
window['WORKORDER_BASE_REQUEST_PATH'] = window.localStorage.getItem(WORKORDER_API) || process.env.VUE_APP_WORKORDER_BASE_REQUEST_PATH;
window['BIZ_BASE_REQUEST_PATH'] = window.localStorage.getItem(BIZ_API) || process.env.VUE_APP_BIZ_BASE_REQUEST_PATH;
window['AUTH_BASE_REQUEST_PATH'] = window.localStorage.getItem(AUTH_API) || process.env.VUE_APP_AUTH_BASE_REQUEST_PATH;
window['ASSET_BASE_REQUEST_PATH'] = window.localStorage.getItem(ASSET_API) || process.env.VUE_APP_ASSET_BASE_REQUEST_PATH;
window['RULE_BASE_REQUEST_PATH'] = window.localStorage.getItem(RULE_API) || process.env.VUE_APP_RULE_BASE_REQUEST_PATH;
window['ALARM_CENTER_BASE_REQUEST_PATH'] = process.env.VUE_APP_ALARM_CENTER_BASE_REQUEST_PATH;
window['BIBIZ_BASE_REQUEST_PATH'] = window.localStorage.getItem(BIBIZ_API) || process.env.VUE_APP_BIBIZ_BASE_REQUEST_PATH;
window['META_BASE_REQUEST_PATH'] = process.env.VUE_APP_META_BASE_REQUEST_PATH;
window['IOT_BASE_REQUEST_PATH'] = window.localStorage.getItem(IOT_API) || process.env.VUE_APP_IOT_BASE_REQUEST_PATH;
window['COMMON_BASE_REQUEST_PATH'] = window.localStorage.getItem(COMMON_API) || process.env.VUE_APP_COMMON_BASE_REQUEST_PATH;
window['DATA_CENTER_MANGER_URL'] = process.env.VUE_APP_DATA_CENTER_MANGER_URL;
window['MESSAGE_BASE_REQUEST_PATH'] = process.env.VUE_APP_MESSAGE_BASE_REQUEST_PATH;
