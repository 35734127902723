import { Component, Watch } from 'vue-property-decorator';
import { BaseCRUDComponent } from './base-component';
import { ICRUDQ } from '@common-src/model/interface';
import { JTLTableQueryComponent } from '@common-src/components';
import BaseCRUDQService from '@common-src/service/base-crudq.service';
// import draggable from 'vuedraggable';

@Component
export default class TableComponent<ModelT, QueryModelT> extends BaseCRUDComponent<ModelT> {
    /**
     * 列表数据源
     */
    protected listData: Array<ModelT> = [];

    /**
     * 是否是加载更多
     * true:追加到listData中
     * false:替换listData
     */
    protected isLoadMore: boolean = false;

    /**
     * 数据总数量
     */
    protected total: number = 0;

    /**
     * 列表正在加载状态
     */
    protected listLoading: boolean = false;

    /**
     * 列表过滤模型
     */
    protected queryModel: QueryModelT = null;

    /**
     * 当前页码
     */
    protected page: number = 1;

    /**
     * 每页数量
     */
    protected pageSize: number = JTL.CONSTANT.PAGE_SIZE;

    /**
     * 是否自动过滤
     * 默认为true
     */
    protected isAutoQuery: boolean;

    /**
     * 是否第一次加载
     * 防止首次加载时触发2次搜索
     */
     protected isFirstLoad: boolean = true;

    /**
     * 防抖动处理
     * 500毫秒内只触发一次
     */
    protected debounceGetList: Function;

    /**
     * 列表列集合
     */
    protected tableColumns: Array<any> = null;

    /**
     * 可选择列集合
     */
     protected filterTableColumns: Array<any> = null;

    /**
     * 选中的行
     */
    protected selectedRowKeys: Array<string> = [];
    onSelectChange(selectedRowKeys) {
        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.selectedRowKeys = selectedRowKeys;
    }
    onSelect(record: any, selected, selectedRows?, nativeEvent?) {
        if (!selected) {
            return;
        }
        if (record && record.children && record.children.length > 0) {
            _.forEach(record.children, item => {
                const findIndex = _.findIndex(this.selectedRowKeys, selectedItem => item.uuid === selectedItem);
                if (selected) {
                    if (findIndex === -1) {
                        this.selectedRowKeys.push(item.uuid);
                    }
                } else {
                    if (findIndex > -1) {
                        this.selectedRowKeys.splice(findIndex, 1);
                    }
                }
                this.onSelect(item, selected);
            });
        }
    }

    /**
     * 设置横向或纵向滚动，也可用于指定滚动区域的宽和高，建议为 x 设置一个数字，如果要设置为 true，需要配合样式 .ant-table td { white-space: nowrap; }
     * 可以设置为像素值，百分比，true 和 'max-content'
     */
    private tableScroll: { x?: number | boolean | string, y?: number | boolean | string } = { x: true }; // { y: 'max-content' };

    /**
     * 上次请求时间
     * 防止短时间多次请求
     */
    // private lastQueryTime: number;

    /**
     * 开始监听QueryModel
     */
    private startWatchQueryModel: boolean = false;

    /**
     * 覆盖默认的 table 元素
     */
    private tableComponents: any = null;

    private tableBordered: boolean = true;

    created(): void {
        this.isAutoQuery = true;
        this.debounceGetList = _.debounce(this.getList.bind(this), 500);
    }

    /**
     * 获取分页的配置
     */
    get Pagination() {
        return {
            pageSize: this.pageSize,
            total: this.total,
            current: this.page,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `共: ${total} 项`,
            pageSizeOptions: ['10', '20', '50', '100', '500']
        };
    }

    get TableRowKey() {
        return 'uuid';
    }
    get TableSize() {
        return 'small';
    }
    get FilterTableColumns() {
        return _.filter(this.tableColumns, item => item.visible);
    }

    /**
     * 动态选择列改变事件
     * @param col
     */
    tableColumnChange(col) {
        col.visible = !col.visible;
        window.localStorage.setItem('device_table_column', JSON.stringify(this.tableColumns));
    }

    /**
     * 列表分页、排序、筛选变化时触发
     * @param pagination 分页配置
     * @param filters 过滤
     * @param sorter 排序
     */
    tableChange(pagination, filters, sorter) {
        this.page = pagination.current;
        this.pageSize = pagination.pageSize;
        this.getList(null);
    }

    /**
     * 初始化service、过滤模型和列表列集合
     * @param params
     */
    protected initTable(params: { listFunc?: Function, queryModel?: QueryModelT, tableColumns?: Array<any>, service?: any }): void {
        if (params.service) {
            this.initService(params.service);
        }
        const getListFunc = _.get(params, 'listFunc');
        if ((!this.service || !this.service.query) && getListFunc) {
            this.service = {
                query: getListFunc
            } as any;
        }
        this.queryModel = _.get(params, 'queryModel');
        this.tableColumns = _.map(_.get(params, 'tableColumns'), item => {
            item.ellipsis = true;
            if (typeof (item.visible) !== 'boolean') {
                item.visible = true;
            }
            return item;
        });
        this.initTableComponents();
        this.$nextTick(() => {
            this.startWatchQueryModel = true;
            if (this.$refs[JTL.CONSTANT.DEFAULT_TABLE_QUERY_NAME] && this.queryModel) {
                (this.$refs[JTL.CONSTANT.DEFAULT_TABLE_QUERY_NAME] as any).initQuery(this.queryModel);
            }
        });
    }

    /**
     * 监听过滤模型字段的变化，自动触发搜索
     * @param newVal
     */
    @Watch('queryModel', { immediate: false, deep: true })
    queryChange(newVal: QueryModelT): void {
        if (!newVal) {
            return;
        }
        if (this.isFirstLoad || !this.startWatchQueryModel) {
            this.isFirstLoad = false;
            // 第一次不触发
            return;
        }
        this.page = 1;
        if (this.isAutoQuery) {
            this.debounceGetList();
        }
    }

    private initTableComponents() {
        this.tableComponents = {
            header: {
                cell: (h, props, children) => {
                    const { key, ...restProps } = props;
                    const col = this.tableColumns.find(col => {
                        const k = col.dataIndex || col.key;
                        return k === key;
                    });

                    if (!col || !col.width) {
                        return h('th', { ...restProps }, [...children]);
                    }
                    // console.log('ResizeableTitle', key);
                    const dragProps = {
                        key: col.dataIndex || col.key,
                        class: 'table-draggable-handle',
                        attrs: {
                            w: 10,
                            x: col.width,
                            z: 1,
                            axis: 'x',
                            draggable: true,
                            resizable: false
                        },
                        on: {
                            dragging: (x, y) => {
                                col.width = Math.max(x, 1);
                            }
                        }
                    };
                    const drag = h('vue-draggable-resizable', { ...dragProps });
                    return h('th', { ...restProps, class: 'resize-table-th' }, [...children, drag]);
                }
            },
            body: {
                wrapper: {
                    name: 'draggable',
                    onChange: this.sortOnChange.bind(this)
                }
            }
        };
    }

    sortOnChange(evt: Array<any>) {
        // console.log(this.listData);
        // console.log(evt);
        if (!this.service || !this.service.sort) {
            return;
        }
        const cloneListData: Array<any> = _.cloneDeep(this.listData);
        this.listData = evt;
        let sourceId: string = null;
        let targetId: string = null;
        for (let i = 0; i < cloneListData.length; i++) {
            if (cloneListData[i].id !== evt[i].id) {
                sourceId = cloneListData[i].id;
                targetId = evt[i].id;
                break;
            }
        }
        this.service.sort(sourceId, targetId).catch(() => {
            this.listData = cloneListData;
        });
    }

    // private throttleQuery(): boolean {
    //     if (this.lastQueryTime) {
    //         const currentTime = new Date().getTime();
    //         // console.log(currentTime - this.lastQueryTime);
    //         if (currentTime - this.lastQueryTime < 1000) {
    //             // 1秒之内触发多次查询请求，只发送第一次请求
    //             return true;
    //         }
    //     }
    //     this.lastQueryTime = new Date().getTime();
    //     return false;
    // }

    /**
     * 调用service的搜索接口
     * @param appendToData 是否追加数据
     */
    protected getList(ev: any = null, appendToData: boolean = false): Promise<any> {
        if (!this.service || !_.isFunction(this.service.query)) {
            console.warn('Page component service is not initilize');
            return;
        }
        // if (this.throttleQuery()) {
        //     return;
        // }
        this.listLoading = true;
        return this.service.query(this.queryModel as any, this.page, this.pageSize || JTL.CONSTANT.PAGE_SIZE).then((res: any) => {
            if (_.isArray(res)) {
                res = {
                    items: res
                };
            }
            if (res && res.items) {
                if (!_.isUndefined(res.total)) {
                    this.total = res.total;
                } else if (!_.isUndefined(res.Total)) {
                    this.total = res.Total;
                }
                if (this.isLoadMore && appendToData) {
                    if (!this.listData) {
                        this.listData = [];
                    }
                    this.listData.push(...res.items);
                } else {
                    this.listData = res.items;
                }
            } else if (!this.isLoadMore) {
                this.total = 0;
                this.listData = [];
            }
            this.selectedRowKeys = [];
            return res;
        }).catch((error) => {
            if (!this.isLoadMore) {
                this.total = 0;
                this.listData = [];
            }
            if (this.page > 1) {
                this.page--;
            }
            throw error;
            // this.showMessageError('查询失败');
        }).finally(() => {
            this.listLoading = false;
        });
    }

    /**
     * 删除操作
     * @param model 数据模型
     */
    deleteClick(model: ModelT): Promise<boolean> {
        this.startFullScreenLoading('正在删除');
        return this.service.delete(model).then((ret) => {
            this.removeModel(model);
            this.showMessageSuccess('删除成功');
            return true;
        }).catch((err) => {
            throw err;
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }

    loadMoreClick() {
        this.page++;
        return this.getList(null, true);
    }

    searchClick() {
        this.page = 1;
        return this.getList();
    }

    protected getSelectedRowIds(): any {
        return _.reverse(_.map(_.filter(this.selectedRowKeys, item => item.split('|').length === 2), item => _.get(item.split('|'), '[0]')));
    }

    /**
     * 从列表中移除一个数据
     * @param model 数据模型
     */
    protected removeModel(model: ModelT) {
        if (this.selectedRowKeys && this.selectedRowKeys.indexOf(_.get(model, 'uuid')) > -1) {
            this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(_.get(model, 'uuid')), 1);
        }
        let parentChildren = _.get(model, 'parent.children');
        if (parentChildren && parentChildren.length > 0) {
            const index = _.findIndex(parentChildren, (item: any) => item.id === _.get(model, 'id'));
            if (index > -1) {
                parentChildren.splice(index, 1);
                (model as any).parent.childrenTotalCount--;
            }
        } else {
            const index = _.findIndex(this.listData, (item: any) => item.id === _.get(model, 'id'));
            if (index > -1) {
                this.listData.splice(index, 1);
            }
        }
    }

    initMockData(ModelType: { new (): ModelT }, count: number = 10) {
        this.listData = [];
        for (let i = 0; i < count; i++) {
            this.listData.push((new ModelType() as any).toMockModel());
        }
    }
}
