import BaseCRUDQService from '@common-src/service3/base-crudq.service';
import http from '@common-src/service3/http/axios';
import { SystemResourcesEntityModel, SystemResourcesQueryModel } from '@common-src/entity-model/system-resources-entity';

export default class SystemResourcesService extends BaseCRUDQService<SystemResourcesEntityModel, SystemResourcesQueryModel> {
    constructor(baseUrl = SystemResourcesEntityModel.baseUrl) {
        super(baseUrl, SystemResourcesEntityModel);
    }

    // 获取资源类型下拉列表
    static async IdentifiersTypeList():Promise<any> {
        const url = `${SystemResourcesEntityModel.baseUrl}/identifiers`;
        const res = await http.request<any>(
            {
                url,
                method: 'GET'
            }
        );
        return _.map(res.items || res, item => ({
            name: item.name,
            value: item.identifier,
            key: item.identifier
        }));
    }
}
