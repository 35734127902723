import { SelectMode } from '@common-src/model/enum';

export const DEFAULT_DATE_RANGES = () => {
    return {
        '今天': JTL.CONSTANT.TODAY(),
        '昨天': JTL.CONSTANT.YESTERDAY(),
        '本周': JTL.CONSTANT.CURRENT_WEEK(),
        '上周': JTL.CONSTANT.LAST_WEEK(),
        '本月': JTL.CONSTANT.CURRENT_MONTH(),
        '上月': JTL.CONSTANT.LAST_1_MONTH(),
        '本年': JTL.CONSTANT.CURRENT_YEAR(),
        '上年': JTL.CONSTANT.LAST_YEAR(),
        '过去7天': JTL.CONSTANT.LAST_7_DAY(),
        '过去30天': JTL.CONSTANT.LAST_30_DAY()
    };
};

export enum QueryControlType {
    DEFAULT = 'TEXT',
    TEXT = 'TEXT',
    SELECT = 'SELECT',
    TREE_SELECT = 'TREE_SELECT',
    SELECT_DATE = 'SELECT_DATE',
    SELECT_SPACE = 'SELECT_SPACE',
    CHECKBOX = 'CHECKBOX',
    RADIO_GROUP = 'RADIO_GROUP'
}

export class QueryControlModel {
    key?: string;
    label: string;
    placeholder?: string;
    type: QueryControlType;
    span?: number;
    index?: number; // 字段排列顺序，按照升序排列。1->2->3......
    invisible?: boolean;
    invisibleFunction?: (model: any, controlList?: Array<any>) => boolean;
    required?: boolean;
    disabled?: boolean;

    constructor(option: any) {
        this.key = option.key || '';
        this.label = option.label || '';
        this.placeholder = option.placeholder || `请输入${this.label}`;
        this.type = option.type || QueryControlType.DEFAULT;
        this.span = option.span || 6;
        this.index = option.index;
        this.invisible = option.invisible;
        this.invisibleFunction = option.invisibleFunction;
        this.required = !!option.required;
        this.disabled = !!option.disabled;
    }
}

export class QueryControlOptionModel extends QueryControlModel {
    options?: Array<{ name: string, value: any } | { name: string, id: any }>;
    optionsPromise?: (...param: any) => Promise<Array<any>>;
    optionsPromiseParam?: any;
    mode?: SelectMode;
    loading?: boolean;
    selectChildren?: boolean;
    relationKeys?: Array<string>;
    relationPath?: string;
    defaultValue?: string;
    onlySelectLeaf?: boolean;
    disableSelectCross?: boolean;

    constructor(option) {
        super(option);
        this.mode = option.mode || 'default';
        this.options = option.options;
        this.loading = option.loading || false;
        this.selectChildren = option.selectChildren;
        this.relationKeys = option.relationKeys;
        this.relationPath = option.relationPath;
        this.defaultValue = option.defaultValue;
        this.onlySelectLeaf = option.onlySelectLeaf;
        this.disableSelectCross = option.disableSelectCross;
        this.optionsPromise = option.optionsPromise;
    }
}

export class QueryControlDateModel extends QueryControlModel {
    showTime?: boolean;
    isRange?: boolean;
    ranges?: any;
    disabledDate?: (currentDate: any) => boolean;
    constructor(option) {
        super(option);
        this.showTime = option.showTime;
        this.isRange = _.isUndefined(option.isRange) ? true : option.isRange;
        this.ranges = option.ranges || DEFAULT_DATE_RANGES();
        this.disabledDate = option.disabledDate || null;
    }
}

/**
 * Query控件类型元数据
 * @param option Form控件配置
 */
export function QueryControl(option: any) {
    return Reflect.metadata('QueryControl', option);
}
