import { get, post, del, put } from './request';
import { PropertyEntityType, ThingsFunctionType, ServiceOptionType, FacilityType, DeviceTypeEnum } from '@common-src/model/enum';
import ThingsAttributeService from './things-attribute';
import { ThingsAttributeQueryModel } from '@common-src/entity-model/things-attribute-entity';
import ThingsEventService from './things-event';
import { ThingsEventQueryModel } from '@common-src/entity-model/things-event-entity';
import ThingsMethodService from './things-method';
import { ThingsMethodQueryModel } from '@common-src/entity-model/things-method-entity';
import { UserStoreModule } from '@common-src/store/modules/user';
import { SimpleTreeModel, SimpleNameTreeModel } from '@common-src/model/simple-tree-model';
import { ProjectEntityModel } from '@common-src/entity-model/project-entity';
import { DeviceShadowEntityModel } from '@common-src/entity-model/device-shadow-entity';

/**
 * 获取全国省份城市区县数据
 */
export function getCityJson(): Promise<any> {
    return window.fetch('/lib/city.json').then(res => {
        return res.json();
    });
}

class CommonService {
    async getUserInfo(cache: number = 0):Promise<any> {
        const url = `${AUTH_BASE_REQUEST_PATH}/user/sauth/getUserInfo`;
        const res = await get(url, null, { timeout: 10000, cache });
        UserStoreModule.SET_USER_EXTRA_INFO(res);
        return res;
    }

    async initProject(cache: number = 0):Promise<any> {
        const url = `${AUTH_BASE_REQUEST_PATH}/project/sauth/projects`;
        const res = await get(url, null, { cache });
        const projectList = _.map(res, item => item = new ProjectEntityModel().toModel(item));
        if (projectList && projectList.length > 0) {
            const currentProject = _.find(projectList, item => item.id === UserStoreModule.CurrentProjectId) || projectList[0];
            UserStoreModule.SET_CURRENT_PROJECT(currentProject);
        } else {
            UserStoreModule.SET_CURRENT_PROJECT(null);
        }
        return projectList;
    }

    async getUserPermissions(cache: number = 0, userId: string = UserStoreModule.UserId):Promise<Array<string>> {
        if (UserStoreModule.IsSuperAdmin) {
            return new Promise((resolve) => {
                resolve(null);
            });
        }
        const url = `${BI_BASE_REQUEST_PATH}/roles/getPrivList/${userId}`;
        const res = await get(url, null, { timeout: 10000, cache });
        const permissionList = _.get(res, 'code', []);
        UserStoreModule.SET_USER_PERMISSION(permissionList);
        return permissionList;
    }

    async getUserPermissions2(cache: number = 0, userId: string = UserStoreModule.UserId):Promise<Array<string>> {
        const url = `${AUTH_BASE_REQUEST_PATH}/user/sauth/permissions`;
        const res = await get(url, null, { timeout: 10000, cache });
        const permissionList = res;
        UserStoreModule.SET_USER_PERMISSION(permissionList);
        return permissionList;
    }
    /**
    * 对应边缘网关授权对应的接口结构
    */
    async getUserPermissionsEdge(cache: number = 0, userId: string = UserStoreModule.UserId):Promise<Array<string>> {
        const url = `${AUTH_BASE_REQUEST_PATH}/user/sauth/permissions`;
        const res = await get(url, null, { timeout: 10000, cache });
        const permissionList = _.get(res, [0]).permissions.map((item) => item.identifier);
        UserStoreModule.SET_USER_PERMISSION(permissionList);
        return permissionList;
    }

    async getFacilityTypeOptions(): Promise<any> {
        const url = `${BI_BASE_REQUEST_PATH}/facilitytype/list`;
        const res = await post(url, { page: 1, limit: 999 });
        const options = _.map(res.items, item => {
            return {
                name: item.name,
                value: item.id
            };
        });
        return options;
    }

    async getTemplateOptions(): Promise<any> {
        const url = `${BI_BASE_REQUEST_PATH}/things/template/getAll`;
        const res = await get(url);
        const options = _.map(res, item => {
            return {
                name: `${item.name} | ${item.identifier}`,
                value: item.id
            };
        });
        return options;
    }

    async getPropertyOptions(propertyType: PropertyEntityType):Promise<Array<{name: string, value: string, code: string}>> {
        const url = `${COMMON_BASE_REQUEST_PATH}/property/list`;
        const res = await post(url, { type: propertyType, active: true, page: 1, limit: 999 });
        return _.map(_.get(res, 'items'), item => {
            return {
                value: item.id,
                name: item.name,
                code: item.code
            };
        });
    }

    /**
     * 仅供kyw的资产状态使用
     * 资产状态直接保存名称，不使用id
     * @returns
     */
    async getAssetStatusPropertyOptions():Promise<Array<{name: string, value: string, code: string}>> {
        const url = `${COMMON_BASE_REQUEST_PATH}/property/list`;
        const res = await post(url, { type: PropertyEntityType.ASSET_STATUS, page: 1, limit: 999 });
        return _.map(_.get(res, 'items'), item => {
            return {
                value: item.name,
                name: item.name,
                code: item.code
            };
        });
    }

    async getAllRoles() {
        const url = `${BI_BASE_REQUEST_PATH}/roles/getAll`;
        const res = await get(url);
        return _.map(res, item => {
            return {
                value: item.id,
                name: item.name
            };
        });
    }

    async getAllRolesV2() {
        const url = `${AUTH_BASE_REQUEST_PATH}/role/getRoles`;
        const res = await get(url);
        return _.map(res, item => {
            return {
                value: item.id,
                name: item.name
            };
        });
    }

    async getThingsFunctionByType(thingsFunctionType: ThingsFunctionType, keyword: string):Promise<Array<{name: string, value: string}>> {
        let getService: (qm: any, page?: number, limit?: number) => Promise<any> = null;
        let queryModel: any = null;

        switch (thingsFunctionType) {
            case ThingsFunctionType.ATTRIBUTE:
                getService = ThingsAttributeService.query;
                queryModel = new ThingsAttributeQueryModel();
                queryModel.name = keyword;
                break;
            case ThingsFunctionType.EVENT:
                getService = ThingsEventService.query;
                queryModel = new ThingsEventQueryModel();
                queryModel.name = keyword;
                break;
            case ThingsFunctionType.METHOD:
                getService = ThingsMethodService.query;
                queryModel = new ThingsMethodQueryModel();
                queryModel.name = keyword;
                break;
            default:
                throw new Error('Not implement.');
        }

        const res = await getService(queryModel, 1, 10);
        return _.get(res, 'items') || res;
    }

    async getPersonList():Promise<Array<{value: string, name: string}>> {
        const url = `${AUTH_BASE_REQUEST_PATH}/user/all`;
        const res = await get(url);
        return _.map(res, item => {
            return {
                value: item.id,
                name: item.name
            };
        });
    }

    async getPersonNameList():Promise<Array<{value: string, name: string}>> {
        const url = `${AUTH_BASE_REQUEST_PATH}/user/all`;
        const res = await get(url);
        return _.map(res, item => {
            return {
                value: item.name,
                name: item.name
            };
        });
    }

    async getOrgUserWithList():Promise<Array<{value: string, name: string}>> {
        const url = `${AUTH_BASE_REQUEST_PATH}/org/getOrgUserWithList`;
        const res = await get(url);
        return res;
    }

    async getSelectOrgTree():Promise<Array<{value: string, name: string}>> {
        const url = `${AUTH_BASE_REQUEST_PATH}/org/tree`;
        const res = await get(url);
        return _.map(res, item => {
            return new SimpleTreeModel().toModel(item);
        });
    }

    async getOrgUserList(orgId: string):Promise<Array<{value: string, name: string}>> {
        if (!orgId || orgId === 'id' || orgId === 'groupId') {
            return null;
        }

        const url = `${AUTH_BASE_REQUEST_PATH}/org/orgUserList`;
        const res = await post(url, { limit: JTL.CONSTANT.PAGE_MAX_SIZE, page: JTL.CONSTANT.FIRST_PAGE, params: { orgId } });

        return _.map(res?.items, item => {
            return {
                value: item.id,
                name: item.name
            };
        });
    }

    async getOptionsByType(optionsType: ServiceOptionType):Promise<Array<{value: string, name: string}>> {
        let name = null;
        let value = null;

        switch (optionsType) {
            case ServiceOptionType.SERVICE_CATEGORY:
                name = 'displayName';
                value = 'name';
                break;
            default:
                name = 'desc';
                value = 'name';
                break;
        }

        const url = `${WORKORDER_BASE_REQUEST_PATH}/common/select/options/${optionsType}/${value}/${name}`;
        const res = await get(url);

        return _.map(res, item => {
            return {
                value: item.key,
                name: item.value
            };
        });
    }

    async deleteDeviceFeature(model: {id: string}, deviceTypeId: string):Promise<any> {
        const url = `${IOT_BASE_REQUEST_PATH}/deviceType/delFeature/${model.id}/${deviceTypeId}`;
        const res = await del(url);
        return res;
    }

    async getAllFacilityType(type?: FacilityType):Promise<Array<{value: string, name: string}>> {
        let url = null;

        if (type) {
            url = `${BI_BASE_REQUEST_PATH}/facilitytype/${type}/getTypes`;
        } else {
            url = `${BI_BASE_REQUEST_PATH}/facilitytype/getTypes`;
        }

        const res = await get(url);

        return _.map(res, item => {
            return {
                value: item.id,
                name: item.name
            };
        });
    }

    async getDeviceStatus(deviceId: string):Promise<Array<{value: string, name: string, identifier:string}>> {
        if (!deviceId) {
            return null;
        }

        const url = `${IOT_BASE_REQUEST_PATH}/device/getLatest/${deviceId}`;
        const res = await get(url);

        return _.map(res, item => {
            return {
                // value: item.value,
                value: item.valueDesc,
                name: item.name,
                identifier: item.identifier
            };
        });
    }

    async getDeviceShadow(deviceId: string):Promise<any> {
        if (!deviceId) {
            return null;
        }
        const url = `${IOT_BASE_REQUEST_PATH}/device/shadow/${deviceId}`;
        const data = await get(url);
        const res = _.map(data, r => {
            return new DeviceShadowEntityModel().toModel({
                id: r.shadow,
                ..._.mapKeys(_.get(r, 'reported'), (value, key) => {
                    return 'reported' + key;
                }),
                ..._.mapKeys(_.get(r, 'desired'), (value, key) => {
                    return 'desired' + key;
                }),
                ..._.get(r, 'metadata')
            });
        });
        return res;
    }

    // 设备影子-清空期望值
    async clearDeviceShadowDesiredVal(deviceId: string):Promise<any> {
        if (!deviceId) {
            return null;
        }
        const url = `${IOT_BASE_REQUEST_PATH}/device/shadow/${deviceId}`;
        const res = await del(url);
        return res;
    }

    /**
     * 获取Form表单中级联项的可选项
     * @param id 请求路径中的id
     * @param getPath 请求路径
     * @returns
     */
    async getSubOptions(id: string, getPath: string):Promise<Array<{value: string, name: string}>> {
        let url = null;
        const reg = /\[\]/;
        // 如果id在path中间，不在结尾，则要用id替换掉path中的占位符[]
        if (reg.test(getPath)) {
            url = getPath.replace(reg, id);
        } else {
            // 默认id拼接在path结尾
            url = `${getPath}/${id}`;
        }
        if (id === undefined) return;
        const res = await get(url);
        return _.map(res, item => {
            return {
                value: item.attributeKey || item.key || item.id,
                name: item.value || item.title || item.name || _.get(item, 'jsonValue.name'),
                title: item.name
            };
        });
    }

    async getDeviceTypeOptions(types?: DeviceTypeEnum): Promise<any> {
        const url = `${IOT_BASE_REQUEST_PATH}/product/getAllProducts`;
        let params = [];
        if (types) {
            params = [types];
        }
        const res = await post(url, params);
        const options = _.map(_.filter(res, item => item.type), item => {
            return {
                name: item.name,
                value: item.id,
                type: item.type,
                bizType: item.bizType,
                driverList: _.map(_.filter(item.driverList, item => item), driver => ({ name: driver.name, value: driver.identifier }))
            };
        });
        return options;
    }

    async getProtocolNotNullList(): Promise<any> {
        const url = `${IOT_BASE_REQUEST_PATH}/deviceType/getProtocolNotNullList`;
        const res = await get(url);
        const options = _.map(_.filter(res, item => item.type), item => {
            return {
                name: item.name,
                value: item.id,
                type: item.type
            };
        });
        return options;
    }
    async getMenuNumbers():Promise<any> {
        const url = `${WORKORDER_BASE_REQUEST_PATH}/wf/center/assigned-candidate-count`;
        const res = await get(url);
        return res;
    }

    async getAllPropertyTypes():Promise<Array<any>> {
        const url = `${COMMON_BASE_REQUEST_PATH}/propertyType/list`;
        const res = await post(url);
        return _.map(res, item => {
            return {
                id: item.id,
                propertyType: item.code,
                name: item.remark
            };
        });
    }

    // 获取工单配置所有表单设计名称
    async getAllWorkOrderForms() {
        const url = `${WORKORDER_BASE_REQUEST_PATH}/workorderActiform/list`;
        const params = Object.assign({ page: 1, limit: 9999 });
        const res = await post(url, params);
        return _.map(res.items, item => {
            return {
                value: item.id,
                name: item.displayName
            };
        });
    }

    // 获取工单配置所有流程名称
    async getAllProcess() {
        const url = `${WORKORDER_BASE_REQUEST_PATH}/process-model/list`;
        const params = Object.assign({ page: 1, limit: 999 });
        const res = await post(url, params);
        return _.map(res.items, item => {
            return {
                value: item.processKey,
                name: item.processName
            };
        });
    }

    // 获取工单配置所有流程名称
    async getAvailableProcess() {
        const url = `${WORKORDER_BASE_REQUEST_PATH}/process-model/list`;
        const params = Object.assign({ page: 1, limit: 999 });
        const res = await post(url, params);
        return _.map(res.items, item => {
            return {
                value: item.processKey,
                name: item.processName
            };
        });
    }

    // 获取服务配置流程的所有节点名称
    async getFlowTaskNodes(id:string):Promise<any> {
        if (!id || id === 'serviceId') {
            return [];
        }

        const url = `${WORKORDER_BASE_REQUEST_PATH}/workorder-type/process-definition-relation/${id}/tasksandflow`;
        const res = await get(url);
        return _.map(res, item => {
            return {
                value: item.key,
                name: item.title
            };
        });
    }

    // 获取所有服务名称
    async getSeviceConfig() {
        const url = `${WORKORDER_BASE_REQUEST_PATH}/workorder-type/process-definition-relation/list`;
        const params = Object.assign({ page: '1', limit: '999' });
        const res = await post(url, params);

        return res.items.map(item => {
            return {
                name: item.processDefinitionName,
                value: item.id
            };
        });
    }

    // 获取可以创建的所有工单名称
    async getAllWorkOrderOptions() {
        const url = `${WORKORDER_BASE_REQUEST_PATH}/workorder-type/process-definition-relation/list`;
        const params = Object.assign({ page: '1', limit: '999' });
        const res = await post(url, params);
        /*  仅显示启用工单 */
        return _.map(res.items, item => {
            return {
                value: item.id,
                name: item.serviceName
            };
        });
    }

    // 获取可以创建的所有工单名称
    async getWorkOrderOptions(params:any = {}) {
        const url = `${WORKORDER_BASE_REQUEST_PATH}/workorder-type/process-definition-relation/authorized`;
        const res = await post(url, params);
        /*  仅显示启用工单 */
        return _.map(res, item => {
            return {
                value: item.id,
                name: item.serviceName
            };
        });
    }

    async getAssetsList():Promise<Array<{value: string, name: string}>> {
        // const url = `${BI_BASE_REQUEST_PATH}/facilityV2/list`;
        // const res = await post(url, { page: 1, limit: 999, params: { forceRoot: false } });
        // return _.map(res.items, item => {
        //     return {
        //         value: item.id,
        //         name: item.name,
        //         title: item.name,
        //         type: item.typeName || '',
        //         positionName: item.locations.join('/') || ''
        //     };
        // });
        const url = `${ASSET_BASE_REQUEST_PATH}/asset/list`;
        const res = await post(url, { page: 1, limit: 999, params: { } });
        return _.map(res.items, item => {
            return {
                value: item.id,
                name: item.assetName,
                title: item.assetName,
                locationName: item.locationName || ''
            };
        });
    }

    async getDeviceList() {
        const url = `${IOT_BASE_REQUEST_PATH}/device/list`;
        const res = await post(url, { page: 1, limit: 999, params: { } });
        return _.map(res.items, item => {
            return {
                value: item.id,
                name: item.name,
                title: item.name,
                locationName: item.locationName || ''
            };
        });
    }

    async getBackupAssetsList():Promise<Array<{value: string, name: string}>> {
        const url = `${ASSET_BASE_REQUEST_PATH}/backupAsset/list`;
        const res = await post(url, { page: 1, limit: 999 });
        return _.map(res.items, item => {
            return {
                value: item.id,
                name: item.name,
                title: item.name,
                type: item.type
            };
        });
    }

    async cameraList() {
        // const url = `${BI_BASE_REQUEST_PATH}/facilityV2/list`;
        // const res = await post(url, { page: 1, limit: '99999999', params: { tagKey: 'VideoSystem', typeList: ['c251eb00-1544-11eb-82a8-57fc4aaac2f1'] } });
        // return _.map(res.items, item => {
        //     return {
        //         value: item.id,
        //         name: item.name + (item.spaceName ? ' | ' + item.spaceName : '')
        //     };
        // });
        const url = `${BIBIZ_BASE_REQUEST_PATH}/videoSystem/facility/query`;
        const res = await post(url, { page: 1, limit: '99999999', params: {} });
        return _.map(res.items, item => {
            return {
                value: item.id,
                name: item.name + (item.locationName ? ' | ' + item.locationName : '')
            };
        });
    }

    // async cameraGroupKv() {
    //     const url = `${BIZ_BASE_REQUEST_PATH}/business/camera/groupKv`;
    //     const res = await get(url);
    //     return _.map(res, item => {
    //         return {
    //             value: item.id,
    //             name: item.name
    //         };
    //     });
    // }

    async getTimePlanList():Promise<Array<{value: string, name: string}>> {
        const url = `${ASSET_BASE_REQUEST_PATH}/timePlan/list`;
        const res = await post(url, { page: 1, limit: 999, params: {} });
        return _.map(res.items, item => {
            return {
                value: item.id,
                name: item.name
            };
        });
    }

    async getProfessionalTree():Promise<any> {
        const url = `${WORKORDER_BASE_REQUEST_PATH}/professional/getModelTree`;
        const res = await get(url);
        return _.map(res, item => {
            return new SimpleTreeModel().toModel(item);
        });
    }

    // 获取资产对应专业
    async getProfessional(id:string):Promise<any> {
        const url = `${ASSET_BASE_REQUEST_PATH}/asset/basic/getProfessional/${id}`;
        const res = await get(url);
        return [new SimpleTreeModel().toModel(res)];
    }

    // 获取资产对应专业
    async getAssetProfessional(id:string):Promise<any> {
        const url = `${ASSET_BASE_REQUEST_PATH}/asset/basic/${id}`;
        const res = await get(url);
        const result = _.map(res, basic => {
            return {
                id: basic.professionalModel,
                name: basic.professionalModelName
            };
        });
        return [new SimpleTreeModel().toModel(result)];
    }

    // 获取资产对应位置
    async getAssetPosition(id:string):Promise<any> {
        const url = `${ASSET_BASE_REQUEST_PATH}/asset/basic/${id}`;
        const res = await get(url);
        let result = _.map(res, basic => {
            return {
                id: basic.positionId,
                name: basic.positionName
            };
        });
        return [new SimpleTreeModel().toModel(result)];
    }

    // 获取专业对应受理组、受理人
    async getCandidate(id:string):Promise<any> {
        const url = `${WORKORDER_BASE_REQUEST_PATH}/org/professional/findByProfessional/${id}`;
        const res = await get(url);
        return res;
    }

    // 获取组织架构
    async getOrgs():Promise<SimpleTreeModel[]> {
        const url = `${AUTH_BASE_REQUEST_PATH}/org/tree`;
        const res = await get(url);
        return _.map(res, item => {
            return new SimpleTreeModel().toModel(item);
        });
    }

    // 获取组织架构
    async getOrgNameList():Promise<SimpleTreeModel[]> {
        const url = `${AUTH_BASE_REQUEST_PATH}/org/tree`;
        const res = await get(url);
        return _.map(res, item => {
            return new SimpleNameTreeModel().toModel(item);
        });
    }

    // 获取组织架构 创建工单使用
    async getOrgsWorkOrderStart():Promise<any> {
        const url = `${AUTH_BASE_REQUEST_PATH}/org/tree`;
        const res = await get(url);
        return res;
    }

    // 获取OLA-SLA列表
    async getAvailableOLASlas(id:string):Promise<any> {
        const url = `${WORKORDER_BASE_REQUEST_PATH}/sla/OLA/search`;
        const params = Object.assign({ id: id });
        const res = await get(url, params);
        return _.map(res, resItem => {
            return {
                value: resItem.id,
                name: resItem.name,
                disabled: resItem.use
            };
        });
    }

    // 获取UC-SLA列表
    async getAvailableUCSlas(id:string):Promise<any> {
        const url = `${WORKORDER_BASE_REQUEST_PATH}/sla/UC/search`;
        const params = Object.assign({ id: id });
        const res = await get(url, params);
        return _.map(res, resItem => {
            return {
                value: resItem.id,
                name: resItem.name,
                disabled: resItem.use
            };
        });
    }

    // 获取供应商列表
    async getSuppliers():Promise<any> {
        const url = `${WORKORDER_BASE_REQUEST_PATH}/supplier/list`;
        const params = Object.assign({ page: '1', limit: '999' });
        const res = await post(url, params);
        return _.map(res.items, item => {
            return {
                value: item.id,
                name: item.name
            };
        });
    }

    async getDeviceTypeAttrs(deviceTypeId: string):Promise<any> {
        if (!deviceTypeId) {
            return;
        }
        const url = `${IOT_BASE_REQUEST_PATH}/deviceType/${deviceTypeId}/attrs`;
        const res = await get(url);
        return _.map(res, item => ({
            name: `${_.get(item, 'jsonValue.name')} | ${_.get(item, 'attributeKey')}`,
            value: `${_.get(item, 'attributeKey')}`
        }));
    }
    // 获取最新5条消息
    async getNewsetMessageList():Promise<any> {
        const url = `${MESSAGE_BASE_REQUEST_PATH}/platform/list`;
        const params = Object.assign({ page: '1', limit: '5', params: { } });
        const res = await post(url, params);
        return res;
    }
    // 去处理是否已经读取
    async toReadALLMessages(param:{ids?:Array<any>, readAll?:boolean}):Promise<any> {
        const url = `${MESSAGE_BASE_REQUEST_PATH}/platform/read`;
        const params = Object.assign(param);
        const res = await put(url, params);
        return res;
    }
    // 获取消息渠道
    async getChannelEmbedList():Promise<any> {
        const url = `${MESSAGE_BASE_REQUEST_PATH}/channel/embed/list`;
        // const params = Object.assign({});
        const res = await get(url);
        return _.map(res, item => ({
            name: item.channelTypeName,
            value: item.channelType
        }));
    }
    // 获取消息数量
    async getMessagesCount():Promise<any> {
        const url = `${MESSAGE_BASE_REQUEST_PATH}/platform/count`;
        const res = await get(url);
        return res;
    }
    async getTemplateList(channelType?:any):Promise<any> {
        const url = `${MESSAGE_BASE_REQUEST_PATH}/template/list`;
        const params = Object.assign({ page: 1,
            limit: 1000,
            params: {
                channelType
            } });
        const res = await post(url, params);
        return _.map(res.items || res, item => ({
            name: item.templateName,
            value: item.id
        }));
    }
    // 获取页面配置信息
    async getPageSettingInfo():Promise<any> {
        const url = `${AUTH_BASE_REQUEST_PATH}/theme/detail`;
        const res = await get(url);
        return res;
    }
    // 设置页面配置信息
    async setPageSettingInfo(params):Promise<any> {
        const url = `${AUTH_BASE_REQUEST_PATH}/theme/update`;
        const res = await post(url, params);
        return res;
    }
}

export default new CommonService();
