






























import { Component, Prop, Watch } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import { FormControlType } from '@common-src/model/form-control';

@Component
export default class JTLFormTableV2Component extends BaseComponent {
    @Prop()
    value: any;

    @Prop()
    displayColumn: Array<string>;

    @Prop()
    rowKey: string;

    get FirstRowKeys() {
        return _.filter(_.keys(_.get(this.value, '[0]')), item => item !== 'selected' && (!this.displayColumn || this.displayColumn.indexOf(item) > -1));
    }

    get TableSource() {
        return _.map(this.value, item => {
            item.selected = false;
            return item;
        });
    }
    set TableSource(value) {
        this.$emit('value', value);
    }
}

