
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, del } from './request';
import { FacilityConfigGroupEntityModel } from '@common-src/entity-model/facility-config-group-entity';
import { SimpleTreeModel } from '@common-src/model/simple-tree-model';

const URL_PATH = `${BI_BASE_REQUEST_PATH}/statsGroup`;

class FacilityConfigGroupService implements ICRUDQ<FacilityConfigGroupEntityModel, any> {
    async create(model: FacilityConfigGroupEntityModel):Promise<FacilityConfigGroupEntityModel> {
        let url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<FacilityConfigGroupEntityModel> {
        let url = `${URL_PATH}/getById/${modelId}`;
        const res = await post(url);
        return new FacilityConfigGroupEntityModel().toModel(res);
    }

    async update(model: FacilityConfigGroupEntityModel):Promise<FacilityConfigGroupEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: FacilityConfigGroupEntityModel):Promise<FacilityConfigGroupEntityModel> {
        const url = `${URL_PATH}/delete/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(type: any):Promise<any> {
        const url = `${URL_PATH}/getGroups`;
        let res = await get(url);
        // return res;

        if (!res) {
            res = [];
        }

        res.unshift({
            id: '',
            name: '全部分组',
            type: type
        });

        // res.push({ id: ' ', name: '未分组', type });
        // const allGroup = [{
        //     id: '',
        //     name: '全部分组',
        //     type: type,
        //     children: res
        // }];
        return _.map(res, item => item = new FacilityConfigGroupEntityModel().toModel(Object.assign({ type }, item)));
    }

    async getGroupTree(hasUnGroup: boolean = false):Promise<Array<SimpleTreeModel>> {
        const url = `${URL_PATH}/getGroups`;
        let res = await get(url);

        // if (hasUnGroup) {
        //     if (!res || res.length === 0) {
        //         res = [];
        //     }
        //     res.push({ id: ' ', name: '未分组' });
        // }

        return _.map(res, item => {
            return new SimpleTreeModel().toModel(item);
        });
    }
}

export default new FacilityConfigGroupService();
