var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "jtl-image-component" }, [
    _c("img", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.hasError,
          expression: "!hasError",
        },
      ],
      ref: "jtlImage",
      staticClass: "center",
      attrs: { src: _vm.src, "data-src": _vm.src, alt: "" },
      on: { load: _vm.onload, error: _vm.onerror },
    }),
    _c("img", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.hasError,
          expression: "hasError",
        },
      ],
      staticClass: "center load-error",
      attrs: {
        src: "http://storage.jtl3d.com/bim-iot-dev/bi-admin/assets/images/common/image-error.png",
        alt: "",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }