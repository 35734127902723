import { WorkOrderProcessGroupType } from '@common-src/model/enum';

const workOrderProcessGroupTypeList: Array<{ name: string, value: WorkOrderProcessGroupType }> = _.filter(_.map(WorkOrderProcessGroupType, item => {
    let name: string = null;
    switch (item) {
        case WorkOrderProcessGroupType.WAIT:
            name = '待处理';
            break;
        case WorkOrderProcessGroupType.HANDLE:
            name = '处理中';
            break;
        case WorkOrderProcessGroupType.DONE:
            name = '已完成';
            break;
    }
    return { name, value: item };
}), item => item.name) as any;

const workOrderProcessGroupTypeFilter = (val: WorkOrderProcessGroupType) => {
    return _.get(_.find(workOrderProcessGroupTypeList, item => item.value === val), 'name') || '-';
};

export {
    workOrderProcessGroupTypeFilter,
    workOrderProcessGroupTypeList
};
