var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jtl-select-space-component" },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _c("a-icon", {
            staticStyle: { "font-size": "20px" },
            attrs: { slot: "indicator", type: "loading", spin: "" },
            slot: "indicator",
          }),
          _c("a-tree-select", {
            attrs: {
              "show-search": "",
              "tree-data-simple-mode": "",
              "tree-node-filter-prop": "title",
              "dropdown-style": {
                maxHeight: "600px",
                overflow: "auto",
                padding: "8px",
              },
              "tree-data": _vm.options,
              placeholder: "请选择区域",
              "allow-clear": "",
              disabled: _vm.disabled,
            },
            on: { change: _vm.selectChange },
            model: {
              value: _vm.selectId,
              callback: function ($$v) {
                _vm.selectId = $$v
              },
              expression: "selectId",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }