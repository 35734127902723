






































import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { attributeDataTypeList } from '@common-src/filter/attribute-data-type';
import { AttributeDataType } from '@common-src/model/enum';
import { BaseComponent } from '@common-src/mixins/base-component';

@Component
export default class AttibuteDataTypeValueFormItem extends BaseComponent {
    attributeDataTypeList: Array<{ name: string, value: any }> = attributeDataTypeList;
    AttributeDataType = AttributeDataType;

    @Prop()
    value: { type: AttributeDataType, value: any };

    @Prop()
    formName: string;

    getPlaceholderByType(type: AttributeDataType) {
        return _.get(_.find(this.attributeDataTypeList, item => item.value === type), 'name');
    }

    selectTypeChange(type:AttributeDataType) {
        switch (type) {
            case AttributeDataType.INT:
            case AttributeDataType.FLOAT:
                this.value.value = 0;
                break;
            case AttributeDataType.BOOLEAN:
                this.value.value = false;
                break;
            case AttributeDataType.JSON:
                this.value.value = '{}';
                break;
            case AttributeDataType.STRING:
            default:
                this.value.value = '';
                break;
        }
        this.valueChange();
    }

    valueChange() {
        this.$emit('input', this.value);
        this.$emit('change');
    }
}

