import BaseCRUDQService from '@common-src/service3/base-crudq.service';
import http from '@common-src/service3/http/axios';
import { DeviceTypeEntityModel, DeviceTypeQueryModel } from '@common-src/entity-model/device-type-entity';
import { ApiMap, getApiMap } from './api-map';
import { DeviceTypeEnum } from '@common-src/model/enum';
import { generateUUID } from '@common-src/utils/base_util';

export default class DeviceTypeService extends BaseCRUDQService<DeviceTypeEntityModel, DeviceTypeQueryModel> {
    constructor(baseUrl = DeviceTypeEntityModel.baseUrl) {
        super(baseUrl, DeviceTypeEntityModel);
    }

    async retrieve(modelId: string): Promise<DeviceTypeEntityModel> {
        const url = `${this.baseUrl}/detail/${modelId}?ts=${generateUUID()}`;
        const res = await http.request(
            {
                url,
                method: 'GET'
            }
        );
        res.attrs = await DeviceTypeService.getAttrs(res.id, true);
        res.extendAttrs = await DeviceTypeService.getAttrs(res.id, true, 'EXTEND');
        res.events = await DeviceTypeService.getEvents(res.id);
        res.methods = await DeviceTypeService.getMethods(res.id);
        return new DeviceTypeEntityModel().toModel(res);
    }

    static async getTemplateOptions(): Promise<any> {
        const res = await http.request<Array<any>>({
            url: ApiMap.getAllThingsTemplate,
            method: 'GET'
        });
        const options = _.map(res, item => {
            return {
                name: `${item.name} | ${item.identifier}`,
                value: item.id
            };
        });
        return options;
    }

    static async getDeviceTypeOptions(types?: DeviceTypeEnum): Promise<any> {
        const res = await http.request<Array<any>>({
            url: ApiMap.getAllDeviceTypeList,
            method: 'POST',
            params: types
        });
        const options = _.map(_.filter(res, item => item.type), item => {
            return {
                name: item.name,
                value: item.id,
                type: item.type
            };
        });
        return options;
    }

    static async getDeviceTypeOptionsByDrivers(drivers?: Array<string>): Promise<any> {
        const res = await http.request<Array<any>>({
            url: `${DeviceTypeEntityModel.baseUrl}/getAllProductsByDrivers`,
            method: 'POST',
            params: drivers
        });
        const options = _.map(_.filter(res, item => item.type), item => {
            return {
                name: item.name,
                value: item.id,
                type: item.type
            };
        });
        return options;
    }

    static async getDeviceTypeAttrs(deviceTypeId: string):Promise<any> {
        if (!deviceTypeId) {
            return;
        }
        const res = await http.request<Array<any>>({
            url: getApiMap('getDeviceTypeAttrs', { deviceTypeId }),
            method: 'GET'
        });
        return _.map(res, item => ({
            name: `${_.get(item, 'definition.name')} | ${_.get(item, 'identifier')}`,
            value: `${_.get(item, 'identifier')}`
        }));
    }

    static async getAttrs(deviceTypeId: string, enabled: boolean = true, type: string = 'BASIC'): Promise<any> {
        if (!deviceTypeId) {
            return;
        }

        const res = await http.request<Array<any>>({
            // url: `${getApiMap('getDeviceTypeAttrs', { deviceTypeId })}?type=${type}`,
            url: `${DeviceTypeEntityModel.baseUrl}/attr/${deviceTypeId}?type=${type}&enabled=${enabled}`,
            method: 'GET'
        });
        return res;
    }

    static async getEvents(deviceTypeId: string): Promise<any> {
        if (!deviceTypeId) {
            return;
        }
        const res = await http.request<Array<any>>({
            url: getApiMap('getDeviceTypeEvents', { deviceTypeId }),
            method: 'GET'
        });
        return res;
    }

    static async getMethods(deviceTypeId: string): Promise<any> {
        if (!deviceTypeId) {
            return;
        }
        const res = await http.request<Array<any>>({
            url: getApiMap('getDeviceTypeMethods', { deviceTypeId }),
            method: 'GET'
        });
        return res;
    }

    static async exportDeviceTypeJson(exportIds: Array<string>): Promise<any> {
        const res = await http.request<Array<any>>({
            url: ApiMap.exportDeviceTypeJson,
            method: 'POST',
            params: exportIds
        });
        return res;
    }

    static async importDeviceTypesByJsonFile(importModel:any): Promise<any> {
        const res = await http.request<Array<any>>({
            url: ApiMap.importDeviceTypesByJsonFile,
            method: 'POST',
            params: importModel.toService()
        },
        { headers: { 'content-type': 'multipart/form-data' }, timeout: 600000 }
        );
        return res;
    }

    static updateFaultStatus(productId: string, val: any): Promise<any> {
        return http.request<Array<any>>({
            url: `${DeviceTypeEntityModel.baseUrl}/attr/faultStatus/${productId}`,
            method: 'POST',
            params: val
        });
    }

    static updateRunStatus(productId: string, val: any): Promise<any> {
        return http.request<Array<any>>({
            url: `${DeviceTypeEntityModel.baseUrl}/attr/runningStatus/${productId}`,
            method: 'POST',
            params: val
        });
    }

    static updateControlStatus(productId: string, val: any): Promise<any> {
        return http.request<Array<any>>({
            url: `${DeviceTypeEntityModel.baseUrl}/attr/controlFlag/${productId}`,
            method: 'POST',
            params: val
        });
    }
}
