// import { UserStoreModule } from '@common-src/store/modules/user';
// import CommonService from '@common-src/service/common';

export function beforeEnter(to, from, next) {
    // if (!UserStoreModule.IsAdmin) {
    //     // 无平台权限
    //     UserStoreModule.SET_HAS_ERROR(true);
    //     console.warn(`from ${from.fullPath} to ${to.fullPath} has no corp permission`);
    //     // 跳转到无权限访问
    //     next('/sign/deny');
    //     return;
    // }
    next();
    // CommonService.getUserInfo(5000).then(() => {
    //     next();
    // }).catch(error => {
    //     UserStoreModule.SET_HAS_ERROR(true);
    //     UserStoreModule.SET_REDIRECT_PATH(to.fullPath);
    //     $log.error(error);
    //     next('/sign/error');
    // }).finally(() => {
    //     // $eventHub.$emit('STOP_FULL_SCREEN_LOADING');
    // });
}

export default [
    {
        path: '/admin',
        meta: { authGuard: true },
        // beforeEnter,
        component: () => import('@common-src/pages/dashboard/dashboard.vue'),
        children: [
            {
                path: 'corp-list',
                component: () => import('@common-src/pages/admin-dashboard/corp-list.vue'),
                meta: { parentPath: '/admin', parentMenu: 'dashboardPlatform' }
            },
            {
                path: 'permission-list',
                component: () => import('@common-src/pages/admin-dashboard/permission-list.vue'),
                meta: { parentPath: '/admin', parentMenu: 'dashboardPlatform' }
            },
            {
                path: 'device-driver-list',
                component: () => import('@common-src/pages3/dashboard/device-driver/device-driver-list.vue'),
                meta: { parentMenu: 'dashboardPlatform' }
            },
            {
                path: 'template-list',
                component: () => import('@common-src/pages/admin-dashboard/template-list.vue'),
                meta: { parentMenu: 'dashboardPlatform' }
            },
            {
                path: 'app-list',
                component: () => import('@common-src/pages/corp-dashboard/app/app-list.vue'),
                meta: { parentMenu: 'dashboardPlatform' }
            },
            {
                path: 'app-maintenance',
                component: () => import('@common-src/pages/corp-dashboard/app/app-maintenance.vue'),
                meta: { parentMenu: 'dashboardPlatform' }
            },
            {
                path: 'app-batch-update',
                component: () => import('@common-src/pages/corp-dashboard/app/app-batch-update.vue'),
                meta: { parentMenu: 'dashboardPlatform' }
            },
            {
                path: 'service-manage',
                component: () => import('@common-src/pages/admin-dashboard/service-manage/service-manage-list.vue'),
                meta: { }
            },
            {
                path: 'subsystem-list',
                component: () => import('@common-src/pages/admin-dashboard/subsystem-list.vue'),
                meta: { parentMenu: 'dashboardBaseconfig' }
            },
            {
                path: 'unit-list',
                component: () => import('@common-src/pages/admin-dashboard/unit-list.vue'),
                meta: { parentMenu: 'dashboardBaseconfig' }
            },
            {
                path: 'configbase-flow-ware',
                component: () => import('@common-src/pages3/app-page3.vue'),
                meta: { parentMenu: 'dataMiddleground' },
                props: {
                    routePath: '/configbase-flow-ware'
                }
            },
            {
                path: 'audit-log-list-lib',
                component: () => import('@common-src/pages3/app-page3.vue'),
                meta: { parentMenu: 'dashboardPlatform' },
                props: {
                    routePath: '/audit-log-list-lib',
                    padding: '16px'
                }
            }
        ]
    }
];
