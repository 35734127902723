














import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import moment, { Moment } from 'moment';

@Component
export default class TimerPickersComponent extends BaseComponent {
    @Prop()
    value: Array<Moment>;
    @Prop()
    placeholder: string;
    @Prop()
    disabled: boolean;
    @Prop()
    format: string;

    addClick() {
        (this.value as Array<any>).push(moment());
    }

    removeClick(index: number) {
        (this.value as Array<any>).splice(index, 1);
    }

    validate() {
        for (let index = 0; index < this.value.length; index++) {
            if (!this.value[index]) {
                this.showMessageWarning('请选择计划时间');
                return false;
            }
        }
        return true;
    }
}

