









































import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import { operateList } from '@common-src/entity-model/alarm-rule-entity';

const DurationList = [
    { title: '持续30秒', value: 30 },
    { title: '持续40秒', value: 40 },
    { title: '持续50秒', value: 50 },
    { title: '持续60秒', value: 60 },
    { title: '持续90秒', value: 90 },
    { title: '持续120秒', value: 120 }
];

@Component
export default class AlarmAttributeRuleComponent extends BaseComponent {
    @Prop()
    value: Array<any>;
    @Prop()
    placeholder: string;
    @Prop()
    disabled: boolean;
    @Prop()
    options: Array<any>;

    get OperateList() {
        return operateList;
    }

    get DurationList() {
        return DurationList;
    }

    addClick() {
        (this.value as Array<any>).push({ duration: 30 });
    }

    removeClick(index: number) {
        (this.value as Array<any>).splice(index, 1);
    }

    validate() {
        for (let index = 0; index < this.value.length; index++) {
            if (!this.value[index]) {
                this.showMessageWarning('请选择计划时间');
                return false;
            }
        }
        return true;
    }
}

