import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel } from '@common-src/model/form-control';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { MOBILE_REGEXP, EMAIL_REGEXP, NUMBER_LETTER_UNDERLINE_REGEXP } from '@common-src/model/regexp';
import CommonService from '@common-src/service/common';
import { UserStoreModule } from '@common-src/store/modules/user';

export class UserResetPwdModel extends BaseEntityModel {
    @FormControl({
        label: '重置密码',
        type: FormControlType.TEXT,
        min: 6,
        max: 16,
        // pattern: NUMBER_LETTER_UNDERLINE_REGEXP,
        required: true,
        placeholder: '请输入6到16个字符，包含字母、数字'
    } as FormControlTextModel)
    password: string = undefined;
}

export class UserPwdModel extends BaseEntityModel {
    @FormControl({
        label: '旧密码',
        type: FormControlType.TEXT,
        min: 6,
        max: 20,
        // pattern: NUMBER_LETTER_UNDERLINE_REGEXP,
        required: true,
        placeholder: '请输入6到16个字符，包含字母、数字'
    } as FormControlTextModel)
    password: string = undefined;

    @FormControl({
        label: '新密码',
        type: FormControlType.TEXT,
        min: 6,
        max: 20,
        // pattern: NUMBER_LETTER_UNDERLINE_REGEXP,
        required: true,
        placeholder: '6个字符及以上，最少包含大写字母、小写字母、数字、符号中的两种!',
        validator: (rule, value, callback) => {
            const reg = /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{6,16}$/;
            if (!reg.test(value)) {
                callback(new Error('6个字符及以上，最少包含大写字母、小写字母、数字、符号中的两种'));
            } else {
                callback();
            }
        }
    } as FormControlTextModel)
    newPassword: string = undefined;

    @FormControl({
        label: '确认新密码',
        type: FormControlType.TEXT,
        min: 6,
        max: 16,
        // pattern: NUMBER_LETTER_UNDERLINE_REGEXP,
        validator: (rule, value, callback) => {
            if (rule.formModel.newPassword !== value) {
                callback(new Error('两次密码输入不一致'));
            } else {
                callback();
            }
        },
        required: true,
        placeholder: '请输入6到16个字符，包含字母、数字'
    } as FormControlTextModel)
    confirmNewPassword: string = undefined;

    toService() {
        return {
            originPassword: this.password,
            password: this.newPassword
        };
    }
}
export class UserSetBaseInfoModel extends BaseEntityModel {
    @FormControl({
        label: '姓名',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '手机号',
        type: FormControlType.TEXT,
        pattern: MOBILE_REGEXP,
        required: true
    } as FormControlTextModel)
    phone: string = undefined;

    @FormControl({
        label: '性别',
        type: FormControlType.RADIO_GROUP,
        options: [
            { value: 0, name: '男' },
            { value: 1, name: '女' }
        ],
        required: true
    } as FormControlOptionModel)
    sex: number = undefined;

    @FormControl({
        label: '职位',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    postName: string = undefined;

    toModel(json): any {
        super.toModel(json);
        this.sex = parseInt(_.get(json, 'sex'));
        return this;
    }
}
export class UserEntityModel extends BaseEntityModel {
    userId: string = undefined;

    constructor(orgIds?: string[]) {
        super();
        // this.orgIds = orgId ? [orgId] : [];
        this.orgIds = orgIds || [];
    }

    @FormControl({
        label: '组织架构',
        type: FormControlType.TREE_SELECT,
        optionsPromise: CommonService.getSelectOrgTree,
        mode: 'multiple',
        required: true
    } as FormControlOptionModel)
    orgIds: string[] = [];

    @FormControl({
        label: '工号',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    jobNo: string = undefined;

    @FormControl({
        label: '姓名',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '手机号',
        type: FormControlType.TEXT,
        pattern: MOBILE_REGEXP,
        required: true
    } as FormControlTextModel)
    phone: string = undefined;

    @FormControl({
        label: '性别',
        type: FormControlType.RADIO_GROUP,
        options: [
            { value: 0, name: '男' },
            { value: 1, name: '女' }
        ],
        required: true
    } as FormControlOptionModel)
    sex: number = undefined;

    @FormControl({
        label: '职位',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    postName: string = undefined;

    @FormControl({
        label: '邮箱',
        type: FormControlType.TEXT,
        pattern: EMAIL_REGEXP
    } as FormControlTextModel)
    email: string = undefined;

    @FormControl({
        label: '公司',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    company: string = undefined;

    // @FormControl({
    //     label: '角色',
    //     type: FormControlType.SELECT,
    //     optionsPromise: CommonService.getAllRolesV2,
    //     required: true
    // } as FormControlOptionModel)
    roleId: string = undefined;
    roleName: string = undefined;

    @FormControl({
        label: '初始密码',
        type: FormControlType.TEXT,
        min: 6,
        inputType: 'password',
        // max: 16,
        // pattern: NUMBER_LETTER_UNDERLINE_REGEXP,
        required: true,
        placeholder: '请输入6到16个字符，包含字母、数字',
        updateHidden: true
    } as FormControlTextModel)
    password: string = undefined;

    // @FormControl({
    //     label: '是否启用',
    //     type: FormControlType.RADIO_GROUP,
    //     options: [
    //         { value: true, name: '是' },
    //         { value: false, name: '否' }
    //     ],
    //     required: true
    // } as FormControlOptionModel)
    // enabled: boolean = true;

    tenantId: string = undefined;
    orgName: string[] = [];
    projectName: string[] = [];

    get key() {
        return this.id;
    }

    static getTableColumns() {
        const columns: Array<any> = [
            {
                title: '姓名',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' },
                width: 150,
                ellipsis: true
            },
            {
                title: '工号',
                dataIndex: 'jobNo',
                width: 100
            },
            {
                title: '手机号',
                dataIndex: 'phone',
                width: 150
            },
            {
                title: '邮箱',
                dataIndex: 'email'
            },
            {
                title: '职位',
                dataIndex: 'postName'
            },
            {
                title: '公司',
                dataIndex: 'company'
            },
            {
                title: '组织架构',
                dataIndex: 'orgName'
            },
            {
                title: '项目',
                dataIndex: 'projectName'
            },
            {
                title: '角色',
                dataIndex: 'roleName',
                width: 150
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 220
            }
        ];
        return columns;
    }

    toModel(json): any {
        super.toModel(json);
        this.sex = parseInt(_.get(json, 'sex'));
        // this.orgId = _.get(json, 'orgIds[0]');
        return this;
    }

    toService() {
        const data: any = super.toService();
        // if (this.orgId) {
        //     data.orgIds = [this.orgId];
        //     delete data.orgId;
        // }
        if (this.id) {
            delete data.password;
        }
        delete data.orgName;
        delete data.projectName;
        return data;
    }
}

export class UserEdgeEntityModel extends BaseEntityModel {
    static baseUrl = `${BI_BASE_REQUEST_PATH}/user`;
    userId: string = undefined;

    @FormControl({
        label: '姓名',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '手机号',
        type: FormControlType.TEXT,
        pattern: MOBILE_REGEXP,
        required: true
    } as FormControlTextModel)
    phone: string = undefined;

    @FormControl({
        label: '初始密码',
        type: FormControlType.TEXT,
        min: 6,
        // max: 16,
        // pattern: NUMBER_LETTER_UNDERLINE_REGEXP,
        required: true,
        placeholder: '请输入6到16个字符，包含字母、数字',
        updateHidden: true
    } as FormControlTextModel)
    password: string = undefined;

    // @FormControl({
    //     label: '是否启用',
    //     type: FormControlType.RADIO_GROUP,
    //     options: [
    //         { value: true, name: '是' },
    //         { value: false, name: '否' }
    //     ],
    //     required: true
    // } as FormControlOptionModel)
    // enabled: boolean = true;

    tenantId: string = undefined;

    get key() {
        return this.id;
    }

    static getTableColumns() {
        const columns: Array<any> = [
            {
                title: '姓名',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' },
                width: 150,
                ellipsis: true
            },
            {
                title: '手机号',
                dataIndex: 'phone',
                width: 150
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 200
            }
        ];
        return columns;
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        if (this.id) {
            delete data.password;
        }
        data.projectId = UserStoreModule.CurrentProjectId;
        return data;
    }
}

export class UserQueryModel extends QueryPageModel {
    @QueryControl({
        label: '姓名/手机号',
        type: QueryControlType.TEXT,
        span: 12
    })
    name: string = undefined;

    toService() {
        return {
            params: this.name
        };
    }
}
