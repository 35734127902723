import { BaseModel, BaseEntityModel } from '@common-src/model/base-model';
import { DeviceTypeEnum, DeviceStatus, GroupEntityType, TemplateType, FileExportType } from '@common-src/model/enum';
import { FormControl, FormControlType, FormControlModel, FormControlTextModel, FormControlOptionModel, FormControlUploadModel } from '@common-src/model/form-control';
import CommonService from '@common-src/service/common';
import { ThingsTemplateQueryModel } from './things-template-entity';
import { ThingsFunctionGroupModel } from './things-function-entity';
import { dateFormat, dateFromNow } from '@common-src/filter';
import { generateUUID } from '@common-src/utils/base_util';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { deviceStatusList } from '@common-src/filter/device-status';
import { protocolTypeList } from '@common-src/filter/protocol-type';
import { getBizTypes, getAllEdge } from '@common-src/service3/common.service';
import { ModuleType } from '@common-src/enum/module-type.enum';
import DeviceDriverService from '@common-src/service3/device-driver';

export class DeviceEntityModel extends ThingsFunctionGroupModel {
    static baseUrl = `${IOT_BASE_REQUEST_PATH}/device`;
    constructor(groupId?: string) {
        super(groupId, GroupEntityType.DEVICE);
    }

    @FormControl({
        label: '编号',
        type: FormControlType.TEXT,
        required: false,
        max: 255
    } as FormControlTextModel)
    code: string = undefined;
    accessToken: string = undefined;

    @FormControl({
        label: '设备名称',
        type: FormControlType.TEXT,
        required: true,
        max: 255
    } as FormControlTextModel)
    displayName: string = undefined;
    /**
     * 协议
     */
    protocol: string = undefined;

    /**
     * 网关/设备
     */
    deviceType: DeviceTypeEnum = undefined;
    // 驱动名称
    driverName: string = undefined;

    /**
     * 禁用/启用状态
     */
    status: boolean = undefined;
    /**
     * 激活/未激活状态
     */
    active: boolean = undefined;
    /**
     * 在线/离线状态
     */
    online: boolean = undefined;

    get Online() {
        return this.online ? DeviceStatus.ONLINE : DeviceStatus.OFFLINE;
    }

    get Active() {
        return this.active ? '激活' : '未激活';
    }

    /**
     * 故障状态
     */
    faultStatus: string | number = undefined;
    faultStatusDesc: string = undefined;

    runningStatus: string | number = undefined;
    runningStatusDesc: string = undefined;

    /**
     * 最后上线时间
     */
    lastActivityTime: number = undefined;

    /**
     * 空/参数
     */
    attributeEmptyRatio: string = undefined;

    @FormControl({
        label: '所属产品',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getDeviceTypeOptions,
        readonly: true,
        required: true,
        change: function(formControlList, formControl, formModel, formRules, isFirstLoad) {
            if (formModel.typeId) {
                if (isFirstLoad) {
                    // 编辑时，因为获取options会有延迟
                    // 这里循环获取，最多3次
                    const maxLoopCount = 3;
                    let currentLoop = 0;
                    const loopTimer = setInterval(() => {
                        // console.log('typeId change', currentLoop);
                        currentLoop++;
                        if (formControl.options && formControl.options.length > 0 || currentLoop > maxLoopCount) {
                            const selectOption = _.find(formControl.options, item => item.value === formModel.typeId);
                            const deviceType = _.get(selectOption, 'type');
                            if (deviceType === DeviceTypeEnum.INNER_GATEWAY) {
                                formControlList[4].invisible = true;
                                formControlList[4].options = [];
                            } else {
                                formControlList[4].invisible = false;
                                formControlList[4].options = _.get(selectOption, 'driverList');
                            }
                            clearInterval(loopTimer);
                        }
                    }, 1000);
                } else {
                    const selectOption = _.find(formControl.options, item => item.value === formModel.typeId);
                    formModel.bizType = _.get(selectOption, 'bizType');
                    const deviceType = _.get(selectOption, 'type');
                    if (deviceType === DeviceTypeEnum.INNER_GATEWAY) {
                        formControlList[4].invisible = true;
                        formControlList[4].options = [];
                    } else {
                        formControlList[4].invisible = false;
                        formControlList[4].options = _.get(selectOption, 'driverList');
                    }
                }
            }
        }
    } as FormControlOptionModel)
    typeId: string = undefined;
    typeName: string = undefined;

    @FormControl({
        label: '选择驱动',
        type: FormControlType.SELECT,
        required: false
        // invisibleFunction: model => JTL.moduleType !== ModuleType.EDGE
    } as FormControlOptionModel)
    identifier: string = undefined;

    @FormControl({
        label: '子系统类型',
        type: FormControlType.SELECT,
        optionsPromise: getBizTypes,
        required: false,
        notShowLoadMore: true
    } as FormControlOptionModel)
    bizType: string = undefined;

    /**
     * 设备类型id
     */
    @FormControl({
        label: '安装位置',
        type: FormControlType.SELECT_SPACE
    } as FormControlModel<string>)
    positionId: string = undefined;
    positionName: string = undefined;

    // @FormControl({
    //     label: '负责人',
    //     type: FormControlType.SELECT,
    //     optionsPromise: CommonService.getPersonList
    // } as FormControlOptionModel)
    // personId: string = undefined;
    // personName: string = undefined;

    @FormControl({
        label: '备注名称',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    remark: string = undefined;

    @FormControl({
        label: '服务范围',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    serviceArea: string = undefined;
    /**
     * 是否有子设备
     */
    hasChildren: boolean = undefined;

    /**
     * 子设备
     */
    children: Array<DeviceEntityModel> = undefined;
    childrenTotalCount: number = 0;
    childrenPage: number = 0;
    parent: DeviceEntityModel;
    parentId: string = undefined;
    expand: boolean = false;

    parentPath: string = null;
    keyAttributeList: Array<any> = null;
    edgeName: string = undefined;
    syncStatus: boolean = null;
    controlStatus: boolean = null;

    get Children() {
        if (this.children && this.children.length > 0) {
            if (this.children.length === this.childrenTotalCount) {
                return this.children;
            }
            return [...this.children, {
                uuid: generateUUID(),
                id: null,
                parent: this,
                displayName: '点击加载更多...'
            }];
        }
        return this.children;
    }

    check: boolean = undefined;

    static getTableColumns() {
        const tableColumns = [
            {
                title: '设备名称',
                dataIndex: 'displayName',
                scopedSlots: { customRender: 'name' },
                width: 300,
                fixed: 'left',
                ellipsis: true
            },
            {
                title: '所属产品',
                dataIndex: 'typeName',
                width: 150,
                ellipsis: true
            },
            {
                title: '所属边缘网关',
                dataIndex: 'edgeName',
                width: 150,
                ellipsis: true
            },
            {
                title: '在线/激活',
                scopedSlots: { customRender: 'deviceStatus' },
                width: 120
            },
            {
                title: '故障状态',
                dataIndex: 'faultStatusDesc',
                width: 120,
                ellipsis: true
            },
            {
                title: '运行状态',
                dataIndex: 'runningStatusDesc',
                width: 120,
                ellipsis: true
            },
            {
                title: '同步状态',
                dataIndex: 'syncStatus',
                customRender: (text) => {
                    if (_.isNil(text)) {
                        return '-';
                    }
                    return text ? '是' : '否';
                }
            },
            {
                title: '可控状态',
                dataIndex: 'controlStatus',
                customRender: (text) => {
                    if (_.isNil(text)) {
                        return '-';
                    }
                    return text ? '是' : '否';
                }
            },
            {
                title: '安装位置',
                dataIndex: 'positionName',
                width: 120,
                ellipsis: true
            },
            {
                title: '空/参数',
                dataIndex: 'attributeEmptyRatio',
                width: 120,
                ellipsis: true
            },
            {
                title: '最后活跃时间',
                dataIndex: 'lastActivityTime',
                customRender: (text, record, index) => {
                    if (text) {
                        return `${dateFormat(text)} (${dateFromNow(text)})`;
                    }
                    return '-';
                },
                width: 250
            },
            {
                title: '关键属性',
                width: 200,
                scopedSlots: { customRender: 'keyAttributeList' }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 130,
                fixed: 'right'
            }
        ];
        if (JTL.moduleType === ModuleType.EDGE) {
            return _.filter(tableColumns, item => item.dataIndex !== 'edgeName');
        }
        return tableColumns;
    }

    toModel(json): any {
        super.toModel(json);
        // if (_.get(json, 'children')) {
        //     this.children = _.map(_.get(json, 'children'), item => new DeviceEntityModel().toModel(item));
        // }
        this.deviceType = _.get(json, 'deviceType') || _.get(json, 'productType');
        if (this.hasChildren) {
            this.children = [];
        }
        // for (let i = 1; i < 10; i++) {
        //     this.keyAttributeList.push({ identifier: 'identifier' + i, value: 'value' + i });
        // }
        switch (this.faultStatus) {
            case '1':
            case 1:
                this.faultStatusDesc = '故障';
                break;
            case '0':
            case 0:
                this.faultStatusDesc = '正常';
                break;
            default:
            case '-1':
                this.faultStatusDesc = '-';
                break;
        }
        switch (this.runningStatus) {
            case '1':
            case 1:
                this.runningStatusDesc = '运行';
                break;
            case '0':
            case 0:
                this.runningStatusDesc = '停机';
                break;
            default:
            case '-1':
                this.runningStatusDesc = '-';
                break;
        }
        this.positionId = _.get(json, 'locationId');
        this.typeId = _.get(json, 'productId');
        this.positionName = _.get(json, 'locationName');
        this.typeName = _.get(json, 'productName');
        this.displayName = _.get(json, 'name');
        this.driverName = _.get(json, 'driverName');
        return this;
    }

    toService() {
        return {
            id: this.id,
            code: this.code,
            groupId: this.groupId,
            name: this.displayName,
            productId: this.typeId,
            locationId: this.positionId,
            remark: this.remark,
            bizType: this.bizType,
            identifier: this.identifier,
            serviceArea: this.serviceArea
        };
    }
}

export class DeviceCopyEntityModel extends BaseEntityModel {
    isCopy: boolean = true;
    @FormControl({
        label: '基础名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    baseName: string = undefined;

    @FormControl({
        label: '后缀名称起始值',
        type: FormControlType.NUMBER,
        required: true
    } as FormControlTextModel)
    suffixStartValue: string = undefined;

    @FormControl({
        label: '设备数量',
        type: FormControlType.NUMBER,
        min: 1,
        max: 1000,
        required: true
    } as FormControlTextModel)
    deviceCount: number = undefined;
    parentId: string = undefined;

    toService() {
        return {
            baseName: this.baseName,
            deviceCount: this.deviceCount,
            // deviceTypeId: this.typeId,
            // groupId: this.groupId,
            parentDeviceId: this.parentId,
            // positionId: this.positionId,
            suffixStartValue: this.suffixStartValue
        };
    }
}

export class DeviceImportEntityModel extends ThingsFunctionGroupModel {
    isImport: boolean = true;
    @FormControl({
        label: '所属产品',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getDeviceTypeOptions,
        readonly: false,
        required: true
    } as FormControlOptionModel)
    typeId: string = undefined;

    /**
     * 设备类型id
     */
    @FormControl({
        label: '安装位置',
        type: FormControlType.SELECT_SPACE
    } as FormControlModel<string>)
    positionId: string = undefined;

    @FormControl({
        label: '基础名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    baseName: string = undefined;

    @FormControl({
        label: '后缀名称起始值',
        type: FormControlType.NUMBER,
        required: true
    } as FormControlTextModel)
    suffixStartValue: string = undefined;

    @FormControl({
        label: '设备数量',
        type: FormControlType.NUMBER,
        min: 1,
        max: 1000,
        required: true
    } as FormControlTextModel)
    deviceCount: number = undefined;
    parentId: string = undefined;

    toService() {
        return {
            baseName: this.baseName,
            deviceCount: this.deviceCount,
            deviceTypeId: this.typeId,
            groupId: this.groupId,
            parentDeviceId: this.parentId,
            positionId: this.positionId,
            suffixStartValue: this.suffixStartValue
        };
    }
}

export class DeviceImportExcelEntityModel extends ThingsFunctionGroupModel {
    @FormControl({
        label: '选择文件',
        type: FormControlType.UPLOAD,
        accept: '.xlsx',
        maxFileNumber: 1,
        notUploadOss: true,
        required: true
    } as FormControlUploadModel)
    excelFile: Array<any> = undefined;
    parentId: string = undefined;

    isProtocol: boolean = false;

    toService() {
        const formData = new FormData();
        formData.append('file', _.get(this.excelFile, '[0].file'));
        if (this.parentId) {
            formData.append('parentId', this.parentId);
        }
        if (this.groupId) {
            formData.append('groupId', this.groupId);
        }
        return formData;
    }
}
export class ImportExcelMatchEntityModel extends BaseModel {
    @FormControl({
        label: '批量上传',
        type: FormControlType.UPLOAD,
        accept: '.xlsx',
        maxFileNumber: 1,
        notUploadOss: true,
        required: true
    } as FormControlUploadModel)
    excelFile: Array<any> = undefined;

    toService() {
        const formData = new FormData();
        formData.append('file', _.get(this.excelFile, '[0].file'));
        return formData;
    }
}
export class ExportTemplateEntityModel extends BaseModel {
    @FormControl({
        label: '类型',
        type: FormControlType.RADIO_GROUP,
        options: [
            { name: '设备模板', value: TemplateType.DEVICE },
            // { name: '参数模板', value: TemplateType.ATTRIBUTE },
            { name: '驱动模板', value: TemplateType.PROTOCOL }
        ],
        cascad: true,
        required: true
    } as FormControlOptionModel)
    type: TemplateType = TemplateType.DEVICE;

    @FormControl({
        label: '产品',
        optionsPromise: CommonService.getDeviceTypeOptions,
        type: FormControlType.SELECT,
        invisibleFunction: (model) => {
            return model.type !== TemplateType.DEVICE || model.dialogType === FileExportType.EXPORT;
        },
        change: function(formControlList, formControl, formModel, formRules, isFirstLoad) {
            const data = _.find(formControlList[1].options, r => r.value === formModel.deviceTempId);
            formModel.driver = undefined;
            if (data.driverList.length > 1) {
                formControlList[2].invisible = false;
                formControlList[2].options = data.driverList;
                formModel.driver = _.get(data, 'driverList[0].value');
            } else {
                formControlList[2].invisible = true;
                if (data.driverList.length === 1) {
                    formModel.driver = _.get(data, 'driverList[0].value');
                }
            }
        },
        required: true
    } as FormControlOptionModel)
    deviceTempId: string = undefined;

    @FormControl({
        label: '驱动',
        type: FormControlType.SELECT,
        invisible: true,
        required: true
    } as FormControlOptionModel)
    driver: string = undefined;

    // @FormControl({
    //     label: '产品',
    //     optionsPromise: CommonService.getProtocolNotNullList,
    //     type: FormControlType.SELECT,
    //     invisibleFunction: model => model.type !== TemplateType.ATTRIBUTE,
    //     required: true
    // } as FormControlOptionModel)
    // protocolTempId: string = undefined;

    @FormControl({
        label: '驱动类型',
        // options: protocolTypeList,
        optionsPromise: DeviceDriverService.getAllDriver,
        type: FormControlType.SELECT,
        required: true,
        invisibleFunction: model => { return model.type !== TemplateType.PROTOCOL || model.dialogType === FileExportType.EXPORT; }
    } as FormControlOptionModel)
    protocolType: string = undefined;

    dialogType = FileExportType.IMPORT;
    selectedDeviceIds = [];

    get DeviceTypeId() {
        switch (this.type) {
            case TemplateType.DEVICE:
                return this.deviceTempId;
            // case TemplateType.ATTRIBUTE:
            //     return this.protocolTempId;
            default:
                return null;
        }
    }

    toService() {
        const data: any = { deviceTypeId: '' };
        if (data.dialogType === FileExportType.IMPORT) {
            delete data.selectedDeviceIds;
        }
        delete data.dialogType;
        switch (this.type) {
            case TemplateType.DEVICE:
                data.deviceTypeId = this.deviceTempId;
                break;
            // case TemplateType.ATTRIBUTE:
            //     data.deviceTypeId = this.protocolTempId;
            //     break;
            case TemplateType.PROTOCOL:
                data.deviceTypeId = this.protocolType;
                data.protocol = this.protocolType;
                break;
        }
        return data;
    }
}

export class DeviceQueryModel extends ThingsTemplateQueryModel {
    deviceId: string = undefined;
    parentId: string = undefined;
    driverIdentifier: string = undefined;
    serviceAreaOrName: string = undefined; // 设备名称或者服务范围
    type: DeviceTypeEnum;;
    constructor(type?: DeviceTypeEnum) {
        super();
        this.type = type;
    }
    @QueryControl({
        label: '所属产品',
        type: QueryControlType.SELECT,
        optionsPromise: CommonService.getDeviceTypeOptions,
        hasAllOption: true,
        span: 6,
        index: 1
    })
    typeId: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '在线状态',
        type: QueryControlType.SELECT,
        options: deviceStatusList,
        hasAllOption: true,
        span: 6,
        index: 2
    })
    online: string = JTL.CONSTANT.ALL_OPTION.value;
    // status: string = undefined;
    @QueryControl({
        label: '激活状态',
        type: QueryControlType.SELECT,
        options: [
            { name: '激活', value: 'true' },
            { name: '未激活', value: 'false' }
        ],
        hasAllOption: true,
        span: 6,
        index: 3
    })
    active: string = JTL.CONSTANT.ALL_OPTION.value;
    spaceIds: Array<string> = undefined;
    supportedTypeIdList: Array<string> = undefined;
    forceRoot: boolean = true;

    @QueryControl({
        label: '子系统类型',
        placeholder: '全部',
        type: FormControlType.SELECT,
        optionsPromise: getBizTypes,
        mode: 'multiple',
        span: 12
    })
    bizTypes: string = undefined;

    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT,
        span: 6,
        index: 4
    })
    name: string = undefined;

    @QueryControl({
        label: '边缘网关',
        type: QueryControlType.SELECT,
        optionsPromise: getAllEdge,
        hasAllOption: true,
        span: 6,
        index: 5,
        invisibleFunction: () => JTL.moduleType === ModuleType.EDGE
    })
    edgeId: string = JTL.CONSTANT.ALL_OPTION.value;

    toService() {
        const data = {
            params: {
                groupIds: this.GroupIds,
                locationIds: _.filter(this.spaceIds, item => item),
                name: this.name || '',
                deviceName: this.name || '',
                productId: this.typeId || '',
                deviceId: this.deviceId || '',
                parentId: this.parentId,
                online: this.online,
                // status: this.status,
                active: this.active,
                supportedTypeIdList: this.supportedTypeIdList || [],
                forceRoot: this.forceRoot,
                edgeId: this.edgeId,
                driverIdentifier: this.driverIdentifier,
                bizTypes: this.bizTypes,
                type: this.type,
                serviceAreaOrName: this.serviceAreaOrName
            }
        };
        return data;
    }
}

export class SelectDeviceAttributeModel extends BaseModel {
    deviceId: string = undefined;
    deviceName: string = undefined;
    key: string = undefined;
    attributeName: string = undefined;
    attributeType: string = undefined;
    disabled: boolean = false;

    get DeviceIdAndKey() {
        if (this.deviceId && this.key) {
        // return `${this.deviceId}|${this.key}|${this.attributeType}`;
            return `${this.deviceId}|${this.key}`;
        }
        return undefined;
    }
    set DeviceIdAndKey(val: string) {
        const valArray = val.split('|');
        if (Array.isArray(valArray) && valArray.length === 2) {
            this.deviceId = valArray[0];
            this.key = valArray[1];
        }
    }

    get DeviceNameAndDeviceIdAndAttributeNameAndAttributeKey() {
        if (this.deviceName && this.deviceId && this.key) {
        // return `${this.deviceId}|${this.key}|${this.attributeType}`;
            return `${this.deviceName}|${this.deviceId}|${this.attributeName}|${this.key}`;
        }
        return undefined;
    }
    set DeviceNameAndDeviceIdAndAttributeNameAndAttributeKey(val: string) {
        const valArray = val.split('|');
        if (Array.isArray(valArray) && valArray.length === 4) {
            this.deviceName = valArray[0];
            this.deviceId = valArray[1];
            this.attributeName = valArray[2];
            this.key = valArray[3];
        }
    }
    get DeviceNameAndAttributeNameAndAttributeKey() {
        if (this.deviceName && this.attributeName && this.key) {
            return `${this.deviceName} | ${this.attributeName}(${this.key})`;
        }
        return undefined;
    }

    toModel(json) {
        this.deviceId = _.get(json, 'deviceId') || _.get(json, 'entityId') || _.get(json, 'devId');
        this.key = _.get(json, 'keys') || _.get(json, 'key') || _.get(json, 'attr') || _.get(json, 'attrKey');
        this.attributeType = _.get(json, 'attributeType') || _.get(json, 'attrType');
        this.attributeName = _.get(json, 'attrName');
        this.deviceName = _.get(json, 'deviceName');
        return this;
    }
}

export class SelectDeviceModel extends BaseModel {
    id: string = undefined;
    name: string = undefined;
    attributes: Array<SelectDeviceAttributeModel> = undefined;
    // get Visible() {
    //     return _.filter(this.attributes, item => item.visible).length > 0;
    // }
    toModel(json) {
        this.id = _.get(json, 'entityId') || _.get(json, 'deviceId') || _.get(json, 'deviceTypeId');
        this.name = _.get(json, 'entityName') || _.get(json, 'deviceName') || _.get(json, 'name') || _.get(json, 'deviceTypeName');
        if (_.get(json, 'values')) {
            this.attributes = _.map(_.get(json, 'values'), item => new SelectDeviceAttributeModel().toModel(Object.assign({ deviceName: this.name, entityId: this.id }, item)));
        } else if (_.get(json, 'attr') || _.get(json, 'attrKey')) {
            this.attributes = [new SelectDeviceAttributeModel().toModel(Object.assign({ deviceName: this.name, entityId: this.id }, json))];
        }
        return this;
    }
}

export function getDeviceId(unionKeyStr: string): string {
    const valArray = unionKeyStr?.split('|');
    if (Array.isArray(valArray)) {
        if (valArray.length === 2) {
            return valArray[0];
        } else if (valArray.length === 4) {
            return valArray[1];
        }
    }
    return unionKeyStr;
}

export function getDeviceIdAndAttributeKey(unionKeyStr: string): {deviceId: string, attributeKey: string} {
    const valArray = unionKeyStr.split('|');
    if (Array.isArray(valArray)) {
        if (valArray.length === 4) {
            return {
                deviceId: valArray[1],
                attributeKey: valArray[3]
            };
        } else if (valArray.length === 2) {
            return {
                deviceId: valArray[0],
                attributeKey: valArray[1]
            };
        }
    }
    return {
        deviceId: '',
        attributeKey: ''
    };
}

export class SelectDeviceAttributeRadioModel extends SelectDeviceAttributeModel {
    radio: number = undefined;

    toModel(json) {
        super.toModel(json);
        this.radio = _.get(json, 'radio');
        return this;
    }
}

export class DeviceUsingModel extends BaseEntityModel {
    listTrueIds: string[];
    listFalseIds: string[];
    toModel(json) {
        super.toModel(json);
        this.listFalseIds = _.map(_.filter(json, item => !!item.enabled === false), el => el.id);
        this.listTrueIds = _.map(_.filter(json, item => item.enabled === true), el => el.id);
        return this;
    }

    toService() {
        return {
            list: [
                {
                    column: 'enabled',
                    ids: this.listTrueIds,
                    value: true
                },
                {
                    column: 'enabled',
                    ids: this.listFalseIds,
                    value: false
                }
            ]
        };
    }
}
