
import { SelectDeviceModel } from '@common-src/entity-model/device-entity';
import { FacilityConfigTypeEntityModel, FacilityConfigTypeQueryModel } from '@common-src/entity-model/facility-config-type-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, del } from './request';

const URL_PATH = `${BI_BASE_REQUEST_PATH}/statsType`;

class FacilityConfigTypeService implements ICRUDQ<FacilityConfigTypeEntityModel, FacilityConfigTypeQueryModel> {
    async create(model: FacilityConfigTypeEntityModel):Promise<FacilityConfigTypeEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<FacilityConfigTypeEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new FacilityConfigTypeEntityModel().toModel(res);
    }

    async update(model: FacilityConfigTypeEntityModel):Promise<FacilityConfigTypeEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: FacilityConfigTypeEntityModel):Promise<FacilityConfigTypeEntityModel> {
        const url = `${URL_PATH}/delete/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: FacilityConfigTypeQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new FacilityConfigTypeEntityModel().toModel(item));
        return res;
    }

    async getAllFacilityConfigType():Promise<Array<{value: string, name: string}>> {
        const url = `${URL_PATH}/getAllGroups`;
        const res = await get(url);
        return _.map(res, item => {
            return {
                value: item.id,
                name: item.name,
                bindType: item.bindType,
                linkType: item.linkType
            };
        });
    }

    async getDeviceListByTypeId(typeId: string, identifier?: string):Promise<Array<any>> {
        if (!typeId) {
            return;
        }

        let url = `${URL_PATH}/getDeviceList/${typeId}`;

        if (identifier) {
            url += `/${identifier}`;
        }

        const res = await get(url, null, { cache: 10000 });

        return _.map(res, item => {
            return {
                name: _.get(item, 'displayName'),
                value: _.get(item, 'id'),
                remark: _.get(item, 'remark')
            };
        });
    }

    async getDeviceAttributesByTypeId(typeId?: string, identifier?: string):Promise<Array<SelectDeviceModel>> {
        if (!typeId) {
            return;
        }

        let url = `${URL_PATH}/getDeviceAttrs/${typeId}`;

        if (identifier) {
            url += `/${identifier}`;
        }

        const res = await get(url, null, { cache: 10000 });
        return _.map(_.filter(res, item => item.values && item.values.length > 0), item => new SelectDeviceModel().toModel(item));
    }
}

export default new FacilityConfigTypeService();
