













































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import { ThingsModelType } from '@common-src/model/enum';
@Component
export default class FormDataDesComponent extends BaseComponent {
    ThingsModelType = ThingsModelType;
    @Prop()
    value: any;
    @Prop()
    dataFalseName: string;
    @Prop()
    enumCloneList: Array<{name: string, value: any}>;
    @Prop()
    dataTrueName: string;
    @Prop()
    thingsType: string;
    @Prop({
        default: false
    })
    disabled: boolean;

    noValuePush = false;
    isShowAdvancedConfigDetail: boolean = false;

    get isShowAdvancedConfig() {
        return this.thingsType === ThingsModelType.FLOAT || this.thingsType === ThingsModelType.DOUBLE || this.thingsType === ThingsModelType.INT;
    }

    get isShowAdvancedConfigExcludeInt() {
        return this.thingsType === ThingsModelType.INT;
    }

    get formModel() {
        if (this.value?.expression) {
            if (this.thingsType === this.ThingsModelType.ENUM) {
                if (!this.noValuePush) {
                    _.forEach(this.value.expression.mapRules, item => {
                        this.value.currentEnumList.push({
                            source: item.source,
                            target: item.target,
                            enumCloneList: this.enumCloneList
                        });
                    });
                    this.noValuePush = true;
                }
            } else if (this.thingsType === this.ThingsModelType.BOOLEAN) {
                this.value.dataFalseValue = this.value.expression.mapRules[0]?.source;
                this.value.dataTrueValue = this.value.expression.mapRules[1]?.source;
                this.noValuePush = true;
            } else {
                this.value.expressionOther = this.value.expression.expression;
            }
            if (this.isShowAdvancedConfig && !_.isEmpty(this.value.expression.mathOperations)) {
                this.value.mathOperations = this.value.expression.mathOperations;
                if (Object.values(this.value.mathOperations[0].params).some(val => !_.isEmpty(val)) || this.value.mathOperations[1].params) {
                    this.isShowAdvancedConfigDetail = true;
                }
            }
        }
        return this.value;
    }

    showAdvancedConfig() {
        this.isShowAdvancedConfigDetail = !this.isShowAdvancedConfigDetail;
    }

    addEnumClick() {
        this.formModel.currentEnumList.push({ source: '', target: '', enumCloneList: this.enumCloneList });
    }

    removeEnumClick(index: number) {
        this.formModel.currentEnumList.splice(index, 1);
    }

    validate() {
        if (this.thingsType === this.ThingsModelType.ENUM) {
            for (let index = 0; index < this.formModel.currentEnumList.length; index++) {
                if (!this.formModel.currentEnumList[index].source) {
                    this.showMessageWarning('请填写枚举的输入值');
                    return false;
                }
                if (!this.formModel.currentEnumList[index].target) {
                    this.showMessageWarning('请选择枚举的输出值');
                    return false;
                }
            }
            const sourceArray = _.map(this.formModel.currentEnumList, item => item.source);
            if (Array.from(new Set(sourceArray)).length !== this.formModel.currentEnumList.length) {
                this.showMessageWarning('输入值重复,请重新填写输入值');
                return false;
            }
        }

        if (this.thingsType === this.ThingsModelType.BOOLEAN) {
            if ((this.formModel.dataTrueValue === this.formModel.dataFalseValue) && this.formModel.dataFalseValue) {
                this.showMessageWarning('输入值重复,请重新填写输入值');
                return false;
            }
        }
        return true;
    }
}

