
import { SpaceEntityModel, SpaceTreeEntityModel } from '@common-src/entity-model/space-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, del } from './request';
import { SimpleTreeModel } from '@common-src/model/simple-tree-model';

const URL_PATH = `${COMMON_BASE_REQUEST_PATH}/location`;

class SpaceV2Service implements ICRUDQ<SpaceEntityModel, any> {
    async create(model: SpaceEntityModel):Promise<SpaceEntityModel> {
        const url = `${URL_PATH}/add`;
        const params = model.toService ? model.toService() : model;
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<SpaceEntityModel> {
        throw new Error('Not implement.');
    }

    async update(model: SpaceEntityModel):Promise<SpaceEntityModel> {
        const url = `${URL_PATH}/update`;
        const params = model.toService ? model.toService() : model;
        const res = await post(url, params);
        return res;
    }

    async delete(model: SpaceEntityModel):Promise<SpaceEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: any):Promise<any> {
        throw new Error('Not implement.');
    }

    async getLocationTree():Promise<any> {
        const url = `${URL_PATH}/getLocationTree`;
        const res = await get(url);
        return _.map(res, item => {
            // return new SimpleTreeModel().toModel(item);
            return new SpaceTreeEntityModel().toModel(item);
        });
    }

    async getLocationTreeWithAll():Promise<any> {
        const url = `${URL_PATH}/getLocationTree`;
        let res = await get(url);
        if (!res) {
            res = [];
        }
        res.unshift({
            id: '',
            name: '全部分组'
        });
        return _.map(res, item => {
            return new SimpleTreeModel().toModel(item);
        });
    }

    async getLocations():Promise<any> {
        const url = `${URL_PATH}/getLocations`;
        const res = await get(url);
        return _.map(res, item => {
            return new SimpleTreeModel().toModel(item);
        });
    }

    async getLocationsOnlyUnitSelectable():Promise<any> {
        const url = `${URL_PATH}/getLocations`;
        const res = await get(url);
        _.forEach(res, item => {
            item.selectable = false;
            _.forEach(item.children, item1 => {
                item1.selectable = false;
                _.forEach(item1.children, item2 => {
                    item2.selectable = false;
                });
            });
        });
        return _.map(res, item => {
            return new SimpleTreeModel().toModel(item);
        });
    }

    async getLocationFloors():Promise<any> {
        const url = `${URL_PATH}/getFloors`;
        const res = await get(url);
        return _.map(res, item => {
            return new SimpleTreeModel().toModel(Object.assign({ selectable: false }, item));
        });
    }

    async getSpaceTreeWithAll():Promise<any> {
        const url = `${URL_PATH}/getSpaceTree`;
        let res = await get(url);
        // return _.map(res, item => new SpaceEntityModel().toModel(item));
        // const allGroup = [{
        //     id: '',
        //     name: '全部分组',
        //     children: res
        // }];

        if (!res) {
            res = [];
        }

        res.unshift({
            id: '',
            name: '全部分组'
        });

        return _.map(res, item => {
            return new SimpleTreeModel().toModel(item);
            // return this.initTreeData(item);
        });
    }
}

export default new SpaceV2Service();
