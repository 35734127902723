
































import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';

const PeriodTimeList = [
    { title: '1分钟', value: 60 },
    { title: '2分钟', value: 120 },
    { title: '3分钟', value: 180 },
    { title: '4分钟', value: 240 },
    { title: '5分钟', value: 300 }
];

@Component
export default class AlarmEventRuleComponent extends BaseComponent {
    @Prop()
    value: Array<any>;
    @Prop()
    placeholder: string;
    @Prop()
    disabled: boolean;
    @Prop()
    options: Array<any>;

    get PeriodTimeList() {
        return PeriodTimeList;
    }

    addClick() {
        (this.value as Array<any>).push({ periodTime: 60 });
    }

    removeClick(index: number) {
        (this.value as Array<any>).splice(index, 1);
    }

    validate() {
        for (let index = 0; index < this.value.length; index++) {
            if (!this.value[index]) {
                this.showMessageWarning('请选择计划时间');
                return false;
            }
        }
        return true;
    }
}

