// import { UserStoreModule } from '@common-src/store/modules/user';
// import CommonService from '@common-src/service/common';

import { SubSystemType } from '@common-src/model/enum';

export function beforeEnter(to, from, next) {
    // CommonService.getUserInfo(5000).then(() => {
    //     next();
    // }).catch(error => {
    //     UserStoreModule.SET_HAS_ERROR(true);
    //     UserStoreModule.SET_REDIRECT_PATH(to.fullPath);
    //     $log.error(error);
    //     next('/sign/error');
    // }).finally(() => {
    //     // $eventHub.$emit('STOP_FULL_SCREEN_LOADING');
    // });
    next();
}

export default [
    {
        path: '/dashboard',
        redirect: '/dashboard/index',
        meta: { authGuard: true },
        // beforeEnter: beforeEnter,
        component: () => import('@common-src/pages/dashboard/dashboard.vue'),
        children: [
            // {
            //     path: 'index',
            //     // redirect: '/dashboard/parking-index'
            //     component: () => import('@common-src/pages3/dashboard/index.vue')
            //     // component: () => import('@/pages/sub-system/parking-index.vue')
            // },
            {
                path: 'index',
                component: () => import('@common-src/pages3/app-page3.vue'),
                props: {
                    routePath: '/home-index'
                }
            },
            {
                path: 'person-center',
                component: () => import('@/pages/dashboard/person-center/index.vue')
            },
            {
                path: 'topo-list',
                component: () => import('@common-src/pages/topo/list.vue')
            },
            {
                path: 'config',
                // redirect: '/dashboard/config/property/unit',
                component: () => import('@/pages/dashboard/config/index.vue'),
                meta: { parentMenu: 'dashboardData' },
                children: [
                    // {
                    //     path: 'unit',
                    //     name: 'unit',
                    //     component: () => import('@/pages/dashboard/config/unit/unit-list.vue'),
                    //     meta: { parentPath: '/dashboard/config', parentMenu: 'dashboardConfig' }
                    // },
                    {
                        path: 'property/:type',
                        name: 'property',
                        component: () => import('@/pages/dashboard/config/property/property-list.vue'),
                        meta: { parentPath: '/dashboard/config', parentMenu: 'dashboardData' }
                    }
                ]
            },
            {
                path: 'space-tree-v2',
                component: () => import('@/pages/dashboard/config/space-tree-v2.vue'),
                meta: { parentMenu: 'dashboardData' }
            },
            {
                path: 'professional-tree',
                component: () => import('@/pages/dashboard/config/professional-tree.vue'),
                meta: { parentMenu: 'dashboardConfig' }
            },
            {
                path: 'label',
                component: () => import('@/pages/dashboard/config/label-list.vue'),
                meta: { parentMenu: 'dashboardData' }
            },
            {
                path: 'file',
                component: () => import('@common-src/pages/dashboard/config/file-list.vue'),
                meta: { parentMenu: 'dashboardData' }
            },
            {
                path: 'device-type-list',
                component: () => import('@common-src/pages3/dashboard/device-type/device-type-list.vue'),
                // component: () => import('@/pages/dashboard/device-type/device-type-list.vue'),
                meta: { parentMenu: 'dashboardDevice' }
            },
            {
                path: 'device-list',
                component: () => import('@common-src/pages3/dashboard/device/device-list.vue'),
                // component: () => import('@/pages/dashboard/device/device-list.vue'),
                meta: { parentMenu: 'dashboardDevice' }
            },
            {
                path: 'edge-list',
                component: () => import('@/pages/dashboard/edge/edge-list.vue'),
                meta: { parentMenu: 'dashboardDevice' }
            },
            {
                path: 'facility-v2',
                component: () => import('@/pages/dashboard/facility-v2/facility-v2-list.vue'),
                meta: { parentMenu: 'dashboardFacilityV2' }
            },
            {
                path: 'facility-type-list-v2',
                component: () => import('@/pages/dashboard/facility-v2/facility-type-list.vue'),
                meta: { parentMenu: 'dashboardFacilityV2' }
            },
            {
                path: 'facility-config-list',
                component: () => import('@/pages/dashboard/facility-v2/facility-config/facility-config-list.vue'),
                meta: { parentMenu: 'dashboardFacilityV2' }
            },
            {
                path: 'facility-config-type',
                component: () => import('@/pages/dashboard/facility-v2/facility-config/facility-config-type-list.vue'),
                meta: { parentMenu: 'dashboardFacilityV2' }
            },
            {
                path: 'facility-config',
                redirect: '/dashboard/facility-config/watch-camera',
                component: () => import('@/pages/dashboard/facility-v2/facility-config/index.vue'),
                meta: { parentMenu: 'dashboardFacilityV2' },
                children: [
                    {
                        path: 'watch-camera',
                        name: 'watch-camera',
                        component: () => import('@/pages/dashboard/facility/watch-camera.vue'),
                        meta: { parentPath: '/dashboard/facility-config', parentMenu: 'dashboardFacilityV2' }
                    },
                    {
                        path: 'dhc',
                        name: 'dhc',
                        component: () => import('@/pages/dashboard/facility/facility-config.vue'),
                        meta: { parentPath: '/dashboard/facility-config', parentMenu: 'dashboardFacilityV2' }
                    },
                    {
                        path: 'energy-config',
                        name: 'energy-config',
                        component: () => import('@/pages/dashboard/energy/energy-config.vue'),
                        meta: { parentPath: '/dashboard/facility-config', parentMenu: 'dashboardFacilityV2' }
                    },
                    {
                        path: 'access-config',
                        name: 'access-config',
                        component: () => import('@/pages/dashboard/facility/access-config.vue'),
                        meta: { parentPath: '/dashboard/facility-config', parentMenu: 'dashboardFacilityV2' }
                    }
                ]
            },
            {
                path: 'things-model',
                redirect: '/dashboard/things-model/attribute',
                component: () => import('@/pages/dashboard/things-model/index.vue'),
                meta: { parentMenu: 'dashboardThingsModel' },
                children: [
                    {
                        path: 'attribute',
                        name: 'attribute',
                        component: () => import('@common-src/pages/dashboard/things-model/things-attribute/things-attribute-list.vue'),
                        meta: { parentPath: '/dashboard/things-model', parentMenu: 'dashboardThingsModel' }
                    },
                    {
                        path: 'event',
                        name: 'event',
                        component: () => import('@common-src/pages/dashboard/things-model/things-event/things-event-list.vue'),
                        meta: { parentPath: '/dashboard/things-model', parentMenu: 'dashboardThingsModel' }
                    },
                    {
                        path: 'method',
                        name: 'method',
                        component: () => import('@common-src/pages/dashboard/things-model/things-method/things-method-list.vue'),
                        meta: { parentPath: '/dashboard/things-model', parentMenu: 'dashboardThingsModel' }
                    }
                ]
            },
            {
                path: 'things-template-list',
                component: () => import('@common-src/pages/dashboard/things-template/things-template-list.vue'),
                meta: { parentMenu: 'dashboardThingsModel' }
            },
            {
                path: 'things-feature-list',
                component: () => import('@/pages/dashboard/things-feature/things-feature-list.vue'),
                meta: { parentMenu: 'dashboardThingsModel' }
            },
            {
                path: 'user-list',
                component: () => import('@common-src/pages/dashboard/user/user/user-list.vue'),
                meta: { parentMenu: 'dashboardSetting' }
            },
            {
                path: 'role-list',
                component: () => import('@common-src/pages/dashboard/user/role/role-list.vue'),
                meta: { parentMenu: 'dashboardSetting' }
            },
            {
                path: 'org-list',
                component: () => import('@common-src/pages/dashboard/user/org/org-list.vue'),
                meta: { parentMenu: 'dashboardSetting' }
            },
            {
                path: 'project-org-list',
                component: () => import('@common-src/pages/dashboard/user/org/org-list.vue'),
                meta: { parentMenu: 'dashboardSetting' }
            },
            {
                path: 'time-task',
                component: () => import('@/pages/dashboard/system-setting/time-task/time-task-list.vue'),
                meta: { parentMenu: 'dashboardSystemConfig' }
            },
            {
                path: 'project-manage',
                component: () => import('@/pages/dashboard/system-setting/project-manage/project-manage.vue'),
                meta: { parentMenu: 'dashboardSystemConfig' }
            },
            {
                path: 'supplier',
                component: () => import('@/pages/dashboard/system-setting/supplier/supplier-list.vue'),
                meta: { parentMenu: 'dashboardSystemConfig' }
            },
            {
                path: 'config-image',
                redirect: '/dashboard/config-image/web',
                component: () => import('@/pages/dashboard/system-setting/config-image/index.vue'),
                meta: { parentMenu: 'dashboardSystemConfig' },
                children: [
                    {
                        path: 'web',
                        name: 'web',
                        component: () => import('@/pages/dashboard/system-setting/config-image/config-image-web.vue'),
                        meta: { parentPath: '/dashboard/config-image', parentMenu: 'dashboardSystemConfig' }
                    },
                    {
                        path: 'mobile',
                        name: 'mobile',
                        component: () => import('@/pages/dashboard/system-setting/config-image/config-image-mobile.vue'),
                        meta: { parentPath: '/dashboard/config-image', parentMenu: 'dashboardSystemConfig' }
                    }
                ]
            },
            {
                path: 'service-agreement',
                component: () => import('@/pages/dashboard/service-agreement/service-agreement-list.vue'),
                meta: { parentMenu: 'dashboardServiceAgreement' }
            },
            {
                path: 'project-list',
                component: () => import('@common-src/pages/corp-dashboard/project/project-list.vue'),
                meta: { parentMenu: 'dashboardSetting' }
            },
            {
                path: 'project-select',
                component: () => import('@common-src/pages/corp-dashboard/project/project-select.vue')
            },
            {
                path: 'app-list',
                component: () => import('@common-src/pages/corp-dashboard/app/app-list.vue'),
                meta: { parentMenu: 'dashboardPlatform' }
            },
            {
                path: 'app-select',
                component: () => import('@common-src/pages/corp-dashboard/app/app-select.vue')
            },
            {
                path: 'app-maintenance',
                component: () => import('@common-src/pages/corp-dashboard/app/app-maintenance.vue'),
                meta: { parentMenu: 'dashboardPlatform' }
            },
            {
                path: 'app-batch-update',
                component: () => import('@common-src/pages/corp-dashboard/app/app-batch-update.vue'),
                meta: { parentMenu: 'dashboardPlatform' }
            },
            {
                path: 'system-resources-list',
                component: () => import('@common-src/pages/corp-dashboard/system-resources/system-resources-list.vue'),
                meta: { parentMenu: 'dashboardPlatform' }
            },
            {
                path: 'corp-detail',
                component: () => import('@common-src/pages/corp-dashboard/corp-detail.vue')
            },
            {
                path: 'corp-detail/:id',
                component: () => import('@common-src/pages/corp-dashboard/corp-detail.vue')
            },
            {
                path: 'project-detail',
                component: () => import('@/pages/dashboard/system-setting/project-manage/project-manage.vue'),
                meta: { parentMenu: 'dashboardSetting' }
            },
            {
                path: 'project-detail/:id',
                component: () => import('@/pages/dashboard/system-setting/project-manage/project-manage.vue'),
                meta: { parentMenu: 'dashboardSetting' }
            },
            {
                path: 'linkage-device-list',
                component: () => import('@common-src/pages/dashboard/linkage/linkage-device-list.vue'),
                meta: { parentMenu: 'dashboardData' }
            },
            {
                path: 'linkage-config',
                component: () => import('@common-src/pages/dashboard/linkage/linkage-config-list.vue'),
                meta: { parentMenu: 'dashboardData' }
            },
            {
                path: 'alarm',
                // name: SubSystemType.ALARM,
                component: () => import('@/pages/dashboard/alarm/alarm-event-list.vue'),
                meta: { parentMenu: 'dashboardData', type: SubSystemType.ALARM }
            },
            {
                path: 'alarm-history',
                // name: SubSystemType.ALARM_HISTORY,
                component: () => import('@/pages/dashboard/alarm/alarm-event-list.vue'),
                meta: { parentMenu: 'dashboardData', type: SubSystemType.ALARM_HISTORY }
            },
            {
                path: 'alarm-rule',
                component: () => import('@/pages/dashboard/alarm/alarm-rule-list.vue'),
                meta: { parentMenu: 'alarm' }
            },
            {
                path: 'asset-list',
                component: () => import('@/pages/dashboard/asset/asset-list.vue'),
                meta: { parentMenu: 'assetManage' }
            },
            {
                path: 'quarantine-room',
                component: () => import('@/pages/dashboard/jyfc/quarantine-room.vue'),
                meta: { parentMenu: 'dashboardEpidemicPrevention' }
            },
            {
                path: 'fc-user-list',
                component: () => import('@/pages/dashboard/jyfc/fc-user-list.vue'),
                meta: { parentMenu: 'dashboardEpidemicPrevention' }
            },
            {
                path: 'fc-config',
                component: () => import('@/pages/dashboard/jyfc/fc-config.vue'),
                meta: { parentMenu: 'dashboardEpidemicPrevention' }
            },
            {
                path: 'finance-overview',
                component: () => import('@/pages/dashboard/jyfc/finance-overview.vue'),
                meta: { parentMenu: 'dashboardEpidemicPrevention' }
            },
            {
                path: 'sync-log-list',
                component: () => import('@common-src/pages3/dashboard/device-type/sync-log-list.vue'),
                meta: { parentMenu: 'dashboardSetting' }
            },
            {
                path: 'control-config-location',
                component: () => import('@common-src/pages3/app-page3.vue'),
                meta: { parentMenu: 'dashboardControlConfig' },
                props: {
                    routePath: '/control-config-location'
                }
            },
            {
                path: 'control-config-custom',
                component: () => import('@common-src/pages3/app-page3.vue'),
                meta: { parentMenu: 'dashboardControlConfig' },
                props: {
                    routePath: '/control-config-custom'
                }
            },
            {
                path: 'audit-log-list-project',
                component: () => import('@common-src/pages3/app-page3.vue'),
                meta: { parentMenu: 'dashboardSetting' },
                props: {
                    routePath: '/audit-log-list-project',
                    padding: '16px'
                }
            },
            {
                path: 'task-list',
                component: () => import('@common-src/pages3/app-page3.vue'),
                meta: { parentMenu: 'dashboardControlConfig' },
                props: {
                    routePath: '/task-list'
                }
            },
            {
                path: 'schedule-manage',
                component: () => import('@common-src/pages3/app-page3.vue'),
                meta: { parentMenu: 'dashboardControlConfig' },
                props: {
                    routePath: '/schedule-manage'
                }
            },
            {
                path: 'batch-control-config',
                component: () => import('@common-src/pages3/app-page3.vue'),
                meta: { parentMenu: 'dashboardControlConfig' },
                props: {
                    routePath: '/batch-control-config'
                }
            },
            {
                path: 'control-log',
                component: () => import('@common-src/pages3/app-page3.vue'),
                meta: { parentMenu: 'dashboardControlConfig' },
                props: {
                    routePath: '/control-log'
                }
            },
            {
                path: 'platform-basic-setting',
                component: () => import('@common-src/pages/dashboard/platform-setting/platform-basic-setting.vue'),
                meta: { parentMenu: 'dashboardSetting' }
            }
        ]
    }
];
