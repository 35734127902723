












































import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import VueQr from 'vue-qr';
import html2canvas from 'html2canvas';
import { UserStoreModule } from '@common-src/store/modules/user';
import CommonService from '@common-src/service/common';
import ProjectService from '@common-src/service/project';

@Component({
    components: {
        'vue-qr': VueQr
    }
})
export default class JTLQrcodeComponent extends BaseComponent {
    get projectName(): string {
        return UserStoreModule.CorpName;
    }

    get qrLogo(): string {
        const logo = _.get(UserStoreModule.CurrentProject, 'logo[0].url');
        return logo;
    }

    private qrCodeImgArr:any = null;
    private num:number=0;
    public success: boolean = false;
    previewVisible: boolean = false;
    batchDownload: boolean = false;
    isQRcodeCallback: boolean = false;
    logoIcon: string = null;
    logoTitle: string = null;
    projectLogo: string = null;

    @Prop()
    qrInfo: {
        id: string,
        name: string,
        code: string,
        factoryCode: string
    };

    @Prop({ default: 200 })
    qrSize: number;

    @Prop({ default: 40 })
    qrLogoSize: number;

    @Prop({ default: 14 })
    qrTextSize: number;

    created() {
        this.initThemeDetail();
        this.initProject();
    }

    initProject() {
        console.log('999999', _.get(UserStoreModule.CurrentProject, 'logo[0].url'));
        this.projectLogo = _.get(UserStoreModule.CurrentProject, 'logo[0].url');
    }

    qrCodeOKCallback() {
        if (!this.batchDownload) {
            return;
        }
        // 为了解决下载半个二维码
        setTimeout(() => {
            this.downloadClick(false).then(() => {
                this.batchDownload = false;
                // this.previewVisible = false;
            });
        }, 100);
    }

    async initThemeDetail() {
        // const detail = await CommonService.getThemeDetail();
        // this.logoIcon = detail?.logoUrl;
        // this.logoTitle = detail?.dispName;
    }

    batchDownloadClick() {
        this.previewVisible = true;
        this.batchDownload = true;
    }

    downloadClick(isDownload: boolean) {
        return this.handleQrcodeToDataUrl().then(res => {
            this.qrCodeImgArr = res;
            this.success = true;
            if (isDownload) {
                this.download();
            }
        });
    }

    download() {
        // 创建一个a链接
        var aTag = document.createElement('a');
        // 给下载文件命名
        aTag.download = `${this.qrInfo.name}.png`;
        // 创建下载
        aTag.href = this.qrCodeImgArr.url;
        // 开始下载
        aTag.click();
    }

    getImgUrl() {
        return this.qrCodeImgArr;
    }

    handleQrcodeToDataUrl() {
        if (!this.$refs.qrcodeContent) {
            console.log('qrcodeContent is empty', this.qrInfo.name);
            return;
        }
        // window.scroll(0, 0); // 首先先顶部
        const targetDom = this.$refs.qrcodeContent as Element; // 获取要截图的元素
        const copyDom: any = targetDom.cloneNode(true); // 克隆一个
        const width = 475;
        const height = 281;
        copyDom.style.width = width + 'px';
        copyDom.style.height = height + 'px';
        copyDom.style.position = 'absolute';
        copyDom.style.left = '-9999px';
        copyDom.style.top = '-9999px';
        document.body.appendChild(copyDom); // 添加
        let option = {
            width: width,
            height: height,
            backgroundColor: '#ffffff',
            scale: window.devicePixelRatio * 1,
            useCORS: true
        };
        return html2canvas(copyDom, option).then((canvas) => {
            document.body.removeChild(copyDom);
            let dataUrl = canvas.toDataURL();
            return {
                url: dataUrl,
                qrInfo: this.qrInfo
            };
        });
    }
}

