import { Component, Vue } from 'vue-property-decorator';
import { ICRUDQ } from '@common-src/model/interface';
import { ModuleType } from '@common-src/enum/module-type.enum';

export const MAX_MESSAGE_LENGTH = 200;

@Component
export class BaseComponent extends Vue {
    isEdge: boolean = false;
    created() {
        this.isEdge = (JTL.moduleType === ModuleType.EDGE);
    }
    /**
     * 打开全屏正在加载
     * @param loadingText 正在加载提示文字
     */
    startFullScreenLoading(loadingText?: string): void {
        this.$eventHub.$emit('START_FULL_SCREEN_LOADING', loadingText);
    }

    /**
     * 关闭全屏正在加载
     */
    stopFullScreenLoading(): void {
        this.$eventHub.$emit('STOP_FULL_SCREEN_LOADING');
    }

    /**
     * 打开info提示
     * @param message 消息文本
     */
    showMessageInfo(message: string): void {
        this.showMessage(message, 'info');
    }

    /**
     * 打开success提示
     * @param message 消息文本
     */
    showMessageSuccess(message: string): void {
        this.showMessage(message, 'success');
    }

    /**
     * 打开warning提示
     * @param message 消息文本
     */
    showMessageWarning(message: string): void {
        this.showMessage(message, 'warning');
    }

    /**
     * 打开error提示
     * @param message 消息文本
     */
    showMessageError(message: string): void {
        this.showMessage(message, 'error');
    }

    showContactAdmin() {
        this.showMessageError('数据异常，请联系管理员');
    }

    /**
     * 打开提示
     * @param message 消息文本
     * @param type 提示类型 info|success|warning|error
     */
    showMessage(message: string, type: string = 'info'): void {
        if (['info', 'success', 'warning', 'error'].indexOf(type) === -1) {
            console.warn('Message type is not supported.');
            return;
        }
        if (message && message.length > MAX_MESSAGE_LENGTH) {
            message = message.substr(0, MAX_MESSAGE_LENGTH) + '...';
        }
        this.$message[type](message);
    }

    getHighLightHtml(text: string, keyword: string): string {
        if (!_.trim(keyword)) {
            return text;
        }
        if (!text) {
            return '-';
        }
        const upperText = _.toUpper(text);
        const upperKeyword = _.toUpper(keyword);
        const keywordIndex = upperText.indexOf(upperKeyword);
        if (keywordIndex === -1) {
            return text;
        }
        return `${text.substr(0, keywordIndex)}<span class="hight-light-word">${text.substr(keywordIndex, keyword.length)}</span>${text.substr(keywordIndex + keyword.length)}`;
    }

    getAllOptions(options: Array<any>): Array<any> {
        return [JTL.CONSTANT.ALL_OPTION, ...options || []];
    }

    showDeveloping() {
        this.showMessageInfo('正在建设中...敬请期待!');
    }
}

@Component
export class BaseCRUDComponent<T> extends BaseComponent {
    /**
     * 增删改查服务
     */
    protected service: ICRUDQ<T, any>;

    /**
     * 初始化增删改查服务
     * @param service 增删改查服务
     */
    initService(service: ICRUDQ<T, any>): void {
        this.service = service;
    }
}
