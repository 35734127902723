import { beforeEnter } from './dashboard-route';

export default [
    {
        path: '/report',
        meta: { authGuard: true },
        beforeEnter: beforeEnter,
        component: () => import('@/pages/dashboard/dashboard.vue'),
        children: [
            {
                path: ':chartType',
                component: () => import('@/pages/report/index.vue'),
                meta: { parentMenu: 'report' },
                children: [
                    {
                        path: ':chartCode',
                        component: () => import('@/pages/report/report-chart.vue'),
                        meta: { parentMenu: 'report' }
                    }
                ]
            }
        ]
    },
    {
        path: '/parking',
        meta: { authGuard: true },
        component: () => import('@/pages/report/parking.vue')
    }
];
