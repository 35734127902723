var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jtl-form-table-component" },
    [
      _vm.addAction && !_vm.disabled
        ? _c(
            "jtl-button",
            { attrs: { type: "primary" }, on: { click: _vm.add } },
            [_vm._v("+添加")]
          )
        : _vm._e(),
      _c("a-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.dataSource,
          pagination: _vm.pagination,
          size: "small",
          bordered: "",
        },
        scopedSlots: _vm._u([
          {
            key: "input",
            fn: function (text, record, index, scopeIndex) {
              return [
                _vm.cellType[index][scopeIndex.dataIndex].visible
                  ? _c(
                      "div",
                      [
                        !_vm.disabled &&
                        _vm.cellType[index][scopeIndex.dataIndex].type ===
                          _vm.FormControlType.NUMBER
                          ? _c("a-input-number", {
                              staticStyle: { width: "100%" },
                              attrs: { type: "number" },
                              on: {
                                change: function ($event) {
                                  return _vm.inputChanged(
                                    text,
                                    record,
                                    index,
                                    scopeIndex
                                  )
                                },
                              },
                              model: {
                                value: record[scopeIndex.dataIndex],
                                callback: function ($$v) {
                                  _vm.$set(record, scopeIndex.dataIndex, $$v)
                                },
                                expression: "record[scopeIndex.dataIndex]",
                              },
                            })
                          : !_vm.disabled &&
                            _vm.cellType[index][scopeIndex.dataIndex].type ===
                              _vm.FormControlType.CHECKBOX
                          ? _c(
                              "a-checkbox",
                              {
                                attrs: {
                                  checked: record[scopeIndex.dataIndex] > 0,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.checkChanged(
                                      $event,
                                      record,
                                      scopeIndex
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.cellType[index][scopeIndex.dataIndex]
                                        .name[2]
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : !_vm.disabled
                          ? _c("a-input", {
                              on: {
                                change: function ($event) {
                                  return _vm.inputChanged(
                                    text,
                                    record,
                                    index,
                                    scopeIndex
                                  )
                                },
                              },
                              model: {
                                value: record[scopeIndex.dataIndex],
                                callback: function ($$v) {
                                  _vm.$set(record, scopeIndex.dataIndex, $$v)
                                },
                                expression: "record[scopeIndex.dataIndex]",
                              },
                            })
                          : _vm.disabled &&
                            _vm.cellType[index][scopeIndex.dataIndex].type ===
                              _vm.FormControlType.CHECKBOX
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.cellType[index][scopeIndex.dataIndex]
                                      .name[record[scopeIndex.dataIndex] + 1]
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("span", [
                              _vm._v(_vm._s(record[scopeIndex.dataIndex])),
                            ]),
                      ],
                      1
                    )
                  : _c("div"),
              ]
            },
          },
          {
            key: "singleSelect",
            fn: function (text, record, index, scopeIndex) {
              return [
                _c(
                  "a-select",
                  {
                    attrs: {
                      disabled: record["selectOptions"]["length"] === 1,
                      placeholder: "请选择",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.inputChanged(text, record, index, scopeIndex)
                      },
                    },
                    model: {
                      value: record[scopeIndex.dataIndex],
                      callback: function ($$v) {
                        _vm.$set(record, scopeIndex.dataIndex, $$v)
                      },
                      expression: "record[scopeIndex.dataIndex]",
                    },
                  },
                  _vm._l(record["selectOptions"], function (item) {
                    return _c(
                      "a-select-option",
                      { key: item.value, attrs: { value: item.value } },
                      [_vm._v(" " + _vm._s(item.name) + " ")]
                    )
                  }),
                  1
                ),
              ]
            },
          },
          {
            key: "action",
            fn: function (text, record) {
              return [
                _vm.deleteAction
                  ? _c(
                      "a-popconfirm",
                      {
                        attrs: {
                          title: "确认删除?",
                          "ok-text": "确认",
                          "cancel-text": "取消",
                        },
                        on: {
                          confirm: function ($event) {
                            return _vm.deleteClick(record.key)
                          },
                        },
                      },
                      [
                        _c("a", { staticClass: "jtl-del-link" }, [
                          _vm._v("删除"),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }