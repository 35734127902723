import { FormControl, FormControlOptionModel, FormControlTextModel, FormControlType } from '@common-src/model/form-control';
import { AxiosRequestConfig, Method } from 'axios';
import { NUMBER_INT_REGEXP } from '@common-src/model/regexp';
import { TopoDataType } from '../enum';
import TopoDataBaseModel from './topo-data-base-model';

export class TopoDataHttpBaseModel extends TopoDataBaseModel {
    constructor(options?: any) {
        super();
        this.seconds = _.get(options, 'seconds');
    }

    @FormControl({
        label: '刷新频率',
        type: FormControlType.TEXT,
        pattern: NUMBER_INT_REGEXP,
        layout: { labelCol: 24, wrapperCol: 24, width: '100%' },
        index: 21,
        suffix: '秒'
    } as FormControlTextModel)
    seconds: number = undefined;
    timerInterval: any;

    getData(): any {}

    cancelGetData() {
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
            this.timerInterval = null;
        }
    }
}

export default class TopoDataHttpModel extends TopoDataHttpBaseModel {
    constructor(options?: any) {
        super(options);
        this.type = TopoDataType.HTTP;
        this.url = _.get(options, 'url');
        this.method = _.get(options, 'method');
        this.params = _.get(options, 'params');
        this.dataAdapter = _.get(options, 'dataAdapter');
    }

    @FormControl({
        label: 'Url',
        type: FormControlType.TEXT_AREA,
        rows: 3,
        layout: { labelCol: 6, wrapperCol: 16, width: '100%' },
        index: 10
    } as FormControlTextModel)
    url: string = undefined;

    @FormControl({
        label: 'Method',
        type: FormControlType.SELECT,
        options: [
            { name: 'get', value: 'get' },
            { name: 'post', value: 'post' },
            { name: 'put', value: 'put' }
        ],
        layout: { labelCol: 6, wrapperCol: 16, width: '100%' },
        index: 11
    } as FormControlOptionModel)
    method: Method = 'get';

    @FormControl({
        key: 'Params',
        label: 'Params',
        type: FormControlType.TEXT_AREA,
        rows: 5,
        layout: { labelCol: 24, wrapperCol: 24, width: '100%' },
        index: 12
    } as FormControlTextModel)
    params: any = undefined;

    @FormControl({
        label: 'Data Adapter',
        type: FormControlType.TEXT_AREA,
        rows: 5,
        layout: { labelCol: 24, wrapperCol: 24, width: '100%' },
        index: 14
    } as FormControlTextModel)
    dataAdapter: string = undefined;

    /**
     * get
     * 右侧属性列表Text Area绑定的字段
     */
    get Params() {
        if (typeof (this.params) === 'string') {
            return this.params;
        }
        let data = null;
        try {
            data = JSON.stringify(this.params);
        } catch (e) {
            data = this.params;
        }
        return data;
    }
    /**
     * set
     * 右侧属性列表Text Area绑定的字段
     */
    set Params(val: string) {
        try {
            this.params = JSON.parse(val);
        } catch (e) {
            this.params = val;
        }
    }

    getData() {
        if (this.seconds && _.isNumber(this.seconds) && this.seconds > 0 && !this.timerInterval) {
            this.timerInterval = setInterval(() => {
                this.requestData();
            }, this.seconds * 1000);
        }
        return this.requestData();
    }

    private requestData() {
        const config: AxiosRequestConfig = {
            method: this.method,
            url: this.url,
            data: this.params
        };
        return $http?.(config).then(res => {
            // {
            //     "id":"P1-4745",
            //     "name":"P1-4745",
            //     "type":"parking",
            //     "data":{
            //       "type":"parent"
            //     },
            //     "style":{
            //       "position":{"x":319,"y":303,"w":18,"h":40},
            //       "transform":90
            //     }
            //   },
            // let xDelta = 0;
            // let yDelta = 0;
            // let rowCount = 60;
            // const parkingList = _.sortBy(_.map(res['items'], (item, index: number) => {
            //     xDelta = (index % rowCount) * 20;
            //     yDelta = Math.floor(index / rowCount) * 42;
            //     return {
            //         id: item.name,
            //         name: item.name,
            //         type: 'parking',
            //         data: { type: 'parent' },
            //         style: {
            //             position: { x: xDelta, y: yDelta, w: 18, h: 40 },
            //             transform: 0
            //         }
            //     };
            // }), 'name');
            // console.log(JSON.stringify(parkingList));

            if (this.dataAdapter) {
                let self = this;
                /* eslint-disable-next-line */
                this.componentData = (function() { return eval(self.dataAdapter); }());
            } else {
                this.componentData = res;
            }
            return this.componentData;
        });
    }
}
