import Vue from 'vue';
import Router, { Route, RouteConfig } from 'vue-router';
import NProgress from 'nprogress';
import { UserStoreModule } from '@common-src/store/modules/user';
import SignRoute from '@common-src/route/sign-route';
// import TopoRoute from './topo-route';
import DashboardRoute from './dashboard-route';
import WorkOrderRoute from './work-order-route';
import SubSystemRoute from './sub-system-route';
import ReportRoute from './report-route';
import MessageRoute from './message-route';
import App3Route from './app3-route';
import AdminRoute from '@common-src/route/admin-route';
import CommonService from '@common-src/service/common';

NProgress.configure({ showSpinner: false });

Vue.use(Router);

const routes: Array<RouteConfig> = [];
DashboardRoute[0].children = _.union(DashboardRoute[0].children, WorkOrderRoute, SubSystemRoute as Array<any>, App3Route);

routes.push(...SignRoute, ...DashboardRoute, ...ReportRoute, ...AdminRoute, ...MessageRoute, { path: '/', redirect: '/dashboard' });

const router = new Router({
    // base: '/',
    mode: 'hash',
    routes
});

const originalPush = Router.prototype.push;

Router.prototype.push = function push(location: any) {
    return originalPush.call(this, location).catch((err: any) => err);
};

router.beforeEach(async(to: Route, from: Route, next: any) => {
    // if (JTL.debug) {
    //     await CommonService.getUserInfo(600000);
    //     next();
    //     return;
    // }
    NProgress.start();
    const authGuard = _.filter(to.matched, item => item.meta.authGuard).length > 0;
    if (authGuard) {
        // 需要验证用户的登陆状态
        if (UserStoreModule.IsLogin) {
            // eslint-disable-next-line
            if (UserStoreModule.IsSuperAdmin) {
                await CommonService.getUserInfo(600000);
                // 超级管理员不判断权限
                next();
                return;
            }
            try {
                // 先获取用户信息和用户项目信息，才能获取项目的权限
                await CommonService.getUserInfo(600000);
                // if (!_.get(UserStoreModule.UserInfo, 'IsSuperAdmin') && UserStoreModule.CurrentProjectId) {
                //     // 初始化项目信息
                //     // 默认选中第一个项目
                //     await CommonService.initProject(600000);
                // }
                CommonService.getUserPermissions2(600000).then(() => {
                    // 获取登陆用户权限
                    if (_.find(UserStoreModule.UserPermissions, (item: string) => {
                        const lastPermission = _.last(item.split(':'));
                        return item && (_.last(to.matched).regex as RegExp).test(lastPermission);
                    })) {
                        // 有权限访问该页面
                        next();
                    } else {
                        // 无权限访问该页面
                        UserStoreModule.SET_HAS_ERROR(true);
                        UserStoreModule.SET_REDIRECT_PATH();
                        console.warn(`from ${from.fullPath} to ${to.fullPath} has no permission`);
                        console.warn('Current user permissions', UserStoreModule.UserPermissions);
                        // 跳转到无权限访问
                        next('/sign/deny');
                        // AntMessage.error('您没有权限访问，请联系管理员。');
                    }
                }).catch(error => {
                    // // 获取权限出错
                    throw error;
                });
            } catch (error) {
                // 获取权限出错
                UserStoreModule.SET_HAS_ERROR(true);
                UserStoreModule.SET_REDIRECT_PATH(to.fullPath);
                $log.error(error);
                next('/sign/error');
            }
        } else {
            // 当前没有登陆时
            NProgress.done();
            if (to.path.indexOf('dashboard') > -1) {
                UserStoreModule.SET_REDIRECT_PATH(to.fullPath);
            }
            next({ path: '/sign/login', replace: true });
        }
    } else {
        next();
    }
});

router.afterEach((to: Route) => {
    NProgress.done();

    // set page title
    if (to.meta.title) {
        document.title = to.meta.title;
    } else {
        document.title = UserStoreModule.CorpName; // JTL?.CONFIG?.corpName || process.env.VUE_APP_CORP_NAME;
    }
    const loadingWrapper = document.getElementById('page_loading_wrapper');
    if (loadingWrapper) {
        loadingWrapper.remove();
    }
});

// router.beforeRouteLeave((to, from, next) => {
//     const answer = window.confirm('Do you really want to leave? you have unsaved changes!');
//     if (answer) {
//         next();
//     } else {
//         next(false);
//     }
// });

export default router;
