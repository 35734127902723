import { FormControl, FormControlType, FormControlTextModel } from '@common-src/model/form-control';
import { GroupQueryModel, QueryModel } from '@common-src/model/query-model';
import { NUMBER_LETTER_UNDERLINE_REGEXP } from '@common-src/model/regexp';
import { ThingsFunctionGroupModel } from './things-function-entity';
import { DeviceTypeEnum, GroupEntityType, SyncType, ThingsEntityType } from '@common-src/model/enum';
import { deviceTypeList } from '@common-src/filter/device-type';
import { getBizTypes } from '@common-src/service3/common.service';
import DeviceDriverService from '@common-src/service3/device-driver';

export class ThingsTemplateEntityModel extends ThingsFunctionGroupModel {
    constructor(groupId?: string, groupType: GroupEntityType = GroupEntityType.TEMPLATE) {
        super(groupId, groupType);
    }
    @FormControl({
        label: '名称',
        type: FormControlType.TEXT,
        required: true,
        max: 50
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '标识符',
        type: FormControlType.TEXT,
        required: true,
        readonly: true,
        max: 50,
        pattern: NUMBER_LETTER_UNDERLINE_REGEXP
    } as FormControlTextModel)
    identifier: string = undefined;

    @FormControl({
        label: '驱动',
        type: FormControlType.SELECT,
        mode: 'multiple',
        required: true,
        // options: protocolTypeList,
        optionsPromise: DeviceDriverService.getAllDriver,
        invisibleFunction: (model: ThingsTemplateEntityModel) => model.type === DeviceTypeEnum.INNER_GATEWAY
    }as FormControlTextModel)
    drivers: Array<string> = [];

    @FormControl({
        label: '设备类型',
        type: FormControlType.RADIO_GROUP,
        required: true,
        options: deviceTypeList,
        errorMessage: { required: '设备类型是必填项' },
        readonly: true,
        cascad: true
    } as any)
    type: DeviceTypeEnum = undefined;

    @FormControl({
        label: '子系统类型',
        type: FormControlType.SELECT,
        optionsPromise: getBizTypes,
        required: false,
        errorMessage: { required: '设备子系统类型是必填项' },
        invisibleFunction: (model: ThingsTemplateEntityModel) => [DeviceTypeEnum.GATEWAY, DeviceTypeEnum.DEVICE].indexOf(model.type) === -1
    } as FormControlTextModel)
    bizType: string = undefined;

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT_AREA
        // max: 2000
    } as FormControlTextModel)
    remark: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '模板名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '标识符',
                dataIndex: 'identifier'
            },
            {
                title: '版本',
                dataIndex: 'version',
                customRender: (text) => {
                    return text || '-';
                }
            },
            {
                title: '更新时间',
                dataIndex: 'modifiedTime'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }
}

export class ThingsTemplateQueryModel extends GroupQueryModel {
    name: string = undefined;
    // groupIds: Array<string> = undefined;

    toService() {
        const data = super.toService();
        data.params = {
            groupIds: this.GroupIds
        };
        if (this.name) {
            data.params.name = this.name;
        }
        delete data.name;
        delete data.groupIds;
        return data;
    }
}

export class ThingsSyncQueryModel extends QueryModel {
    sourceName: string = '';
    targetName: string = '';
    difference: boolean = false;
    sourceGroupIds: Array<string> = undefined;
    targetGroupIds: Array<string> = undefined;
    targetGroupIdLists: Array<string> = undefined;
    token: string = undefined;
    url: string = undefined;
    syncType: SyncType = undefined; // 0:同步到本地 1:同步到目标服务
    thingsEntityType: ThingsEntityType = undefined;
    functionType: string = undefined;

    toService() {
        const data = super.toService();
        data.syncType = this.syncType;
        if (this.thingsEntityType === ThingsEntityType.FUNCTION) {
            data.group = data.targetGroup = data.sourceGroup = _.upperCase(data.sourceGroupIds);
            delete data.sourceGroupIds;
        }
        if (data.targetGroupIdLists && data.targetGroupIdLists?.length > 0) {
            data.targetGroupIds = data.targetGroupIdLists;
            delete data.targetGroupIdLists;
        }
        // delete data.syncType;
        delete data.thingsEntityType;
        return data;
    }
}
