











import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class JTLButton extends Vue {
    @Prop({ default: '' })
    type: string;
    @Prop()
    size: string;
    @Prop()
    clickProp: any;
    @Prop()
    loading: boolean;
    @Prop()
    disabled: boolean;

    btnLoading: boolean = null;

    created() {
        this.btnLoading = !!this.loading;
        // this.btnLoading = true;
    }

    loginClick() {
        if (!this.clickProp) {
            // $log.warn('jtl-button error param');
            this.$emit('click');
            return;
        }
        let clickCallBack = null;
        let clickParam = null;
        if (_.isFunction(this.clickProp)) {
            clickCallBack = this.clickProp;
        } else if (_.isFunction(this.clickProp.callback)) {
            clickCallBack = this.clickProp.callback;
            clickParam = this.clickProp.param;
        }
        if (!clickCallBack) {
            $log.warn('jtl-button has no callback');
            return;
        }
        const callBackPromise = clickCallBack(clickParam);
        if (callBackPromise && callBackPromise.then) {
            this.setBtnLoading(true);
            callBackPromise.then(res => {
                $log.jtlDebug('info', 'jtl-button: click success');
            }).catch(err => {
                $log.jtlDebug('warn', `jtl-button: click error: ${err}`);
            }).finally(() => {
                $log.jtlDebug('info', 'jtl-button: click finally');
                this.setBtnLoading(false);
            });
        }
    }

    setBtnLoading(val: boolean) {
        this.btnLoading = val;
        this.$emit('update:loading', val);
    }
}

