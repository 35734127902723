





















import { Component, Prop } from 'vue-property-decorator';
import { SelectTreeComponent } from '@common-src/mixins/tree-select-component';
import { GroupEntityType } from '@common-src/model/enum';
import ThingsGroupService from '@common-src/service/things-group';
import TagGroupService from '@common-src/service/tag-group';
import LinkageGroupService from '@common-src/service/linkage-group';

@Component
export default class JTLSelectGroupComponent extends SelectTreeComponent {
    @Prop()
    groupType: GroupEntityType;
    @Prop()
    hasUnGroup: boolean;
    @Prop({ default: '请选择分组' })
    placeholder: string;
    created() {
        let groupService = null;
        if (this.groupType === GroupEntityType.TAG) {
            groupService = TagGroupService.getGroupTree;
        } else if (this.groupType === GroupEntityType.LINKAGE) {
            groupService = LinkageGroupService.getGroupTree;
        } else {
            groupService = ThingsGroupService.getGroupTree;
        }
        this.loading = true;
        groupService(this.groupType, this.hasUnGroup).then(res => {
            this.options = res;
            this.$nextTick(() => {
                // this.selectId = this.value;
                this.setSelectId();
            });
        }).catch(err => {
            $log.error(err);
        }).finally(() => {
            this.loading = false;
        });
    }
}

