
const loadingSvg = '<i aria-label="图标: loading" style="color:#fff;width:50%;height:50%;max-width: 200px;max-height:200px;" class="anticon anticon-loading center"><svg viewBox="0 0 1024 1024" data-icon="loading" width="100%" height="100%" fill="currentColor" aria-hidden="true" focusable="false" class="anticon-spin"><path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path></svg></i>';
export default {
    bind: function(el, binding, vnode) {
        el.style.position = 'relative';
        el.className += ' custom-loading-wrapper';
        const loadingElement = document.createElement('div');
        loadingElement.className = 'custom-loading-div';
        loadingElement.style.background = 'rgba(0,0,0,0.4)';
        loadingElement.style.position = 'absolute';
        loadingElement.style.left = '0';
        loadingElement.style.top = '0';
        loadingElement.style.width = '100%';
        loadingElement.style.height = '100%';
        loadingElement.innerHTML = loadingSvg;
        loadingElement.style.display = 'none';
        loadingElement.style.zIndex = '999';
        el.appendChild(loadingElement);
    },
    update: function(el, binding, vnode, oldVnode) {
        const loading = binding.value;
        const loadingElement: HTMLDivElement = _.last(el.getElementsByClassName('custom-loading-div'));
        if (!loadingElement) {
            return;
        }
        if (loading) {
            loadingElement.style.display = 'block';
        } else {
            loadingElement.style.display = 'none';
        }
    }
};
