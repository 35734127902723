import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import NProgress from 'nprogress';
import AntMessage from 'ant-design-vue/lib/message';
import { UserStoreModule } from '@common-src/store/modules/user';
import { MAX_MESSAGE_LENGTH } from '@common-src/mixins/base-component';
import { UserModel } from '@common-src/model/user-model';
import convertRes2Blob from '@common-src/utils/res-to-blob';
import { CacheUtil } from '@common-src/model/cache-model';
// import SignService from '@common-src/service/sign';
import SignService from '@common-src/service/sign-v2';
import { Modal, message as Message } from 'ant-design-vue';

const NProgressWhiteList = [
    `${WORKORDER_BASE_REQUEST_PATH}/wf/center/assigned-candidate-count`,
    'https://3895393ed3.zicp.vip/biz/api/business/parking/spaceEachCountFromLocationIdsByGroupId',
    'https://3895393ed3.zicp.vip/biz/api/business/parking/spaceTotalCount'
];
const NO_REPONSE_HANDLE_REQUEST = ['/bi/api/project/getOne'];

/**
 * 授权状态
 */
const FORBIDDEN_STATUS = [401, 50009, 50014, '401', '50009', '50014'];

/**
 * 默认错误消息
 */
const DEFAULT_ERROR_MESSAGE = '系统内部错误';

/**
 * 默认请求头
 */
const DEFAULT_REQUEST_HEADERS = {
    'content-type': 'application/json;charset=UTF-8'
};

// export const cancelSource = axios.CancelToken.source();

/**
 * 清空登录信息
 * 跳转到登录页面
 */
function redirectToLogin() {
    // AntMessage.error('登录信息过期，请重新登录');
    // UserStoreModule.SET_USERINFO();
    // 到登录页
    Modal.destroyAll();
    Modal.warning({
        title: '提示',
        content: '登录身份已失效,请重新登录!',
        zIndex: 2000,
        onOk: () => {
            UserStoreModule.SET_USERINFO();
            localStorage.clear();
            location.href = '/#/sign/login';
            location.reload();
        }
    });
    // throw new Error('登录超时,请重新登录!');
    throw new Error('登录信息过期，请重新登录');
}

/**
 * 处理HTTP异常
 * @param response HTTP response
 */
function handleRequestError(response?) {
    if (!response) {
        AntMessage.error(DEFAULT_ERROR_MESSAGE);
        throw new Error(DEFAULT_ERROR_MESSAGE);
    }

    if (FORBIDDEN_STATUS.indexOf(response.status) > -1 || FORBIDDEN_STATUS.indexOf(response.er) > -1) {
        if (window.location.pathname !== '/sign/login') {
            redirectToLogin();
            return;
        }
        UserStoreModule.SET_USERINFO();
        // return;
    }

    const errorMessage = response.erMessage ||
        _.get(response.data, 'erMessage') ||
        _.get(response.data, 'message') ||
        response.data?.toString() ||
        response.statusText ||
        response.toString() ||
        DEFAULT_ERROR_MESSAGE;

    let msg = null;
    if (errorMessage && errorMessage.length > MAX_MESSAGE_LENGTH) {
        // 截取显示的错误消息
        msg = errorMessage.substr(0, MAX_MESSAGE_LENGTH) + '...';
    } else {
        msg = errorMessage;
    }
    AntMessage.error(msg);

    if ($log) {
        const errorCode = _.get(response.data, 'er');
        if (errorCode && Math.floor(errorCode / 100 % 10 * 100) > 100) {
            // errorCode后三位小于100，是业务错误，不需要记录错误日志
            $log.error(errorMessage);
        }
    }
    throw new Error(errorMessage);
}

/**
 * 刷新token
 */
function getRefreshToken(): Promise<any> {
    if (UserStoreModule.RefreshToken) {
        return SignService.getRefreshToken()
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                }
                $log.warn(res.statusText);
                redirectToLogin();
                throw new Error('获取登录信息失败，请重新登录');
            }).catch(err => {
                $log.error(err);
                redirectToLogin();
                throw err;
            });
    }
    redirectToLogin();
}

/**
 * 处理Token
 * @param responseConfig
 */
function handleToken(responseConfig) {
    return getRefreshToken().then(res => {
        if (res && res.er === -1) {
            // 重新设置用户登录信息
            UserStoreModule.SET_USERINFO(new UserModel().toModel(_.get(res, 'items')));
            // 再次发送请求
            return request(responseConfig).then(res => {
                return res;
            }).catch(err => {
                handleRequestError(err);
            });
        } else {
            handleRequestError(res);
        }
    });
}

/**
 * axios请求拦截器
 */
axios.interceptors.request.use(
    config => {
        if (NProgressWhiteList.indexOf(config.url) === -1) {
            NProgress.start();
        }

        if (!config.timeout) {
            config.timeout = 50000;
        }

        config.timeoutErrorMessage = 'Timeout';

        // 自定义header
        let localHeaders = null;
        try {
            localHeaders = JSON.parse(localStorage.getItem('x-header'));
        } catch (e) {
            localHeaders = {};
        }
        const customHeaders = {};
        if (UserStoreModule.Token) {
            customHeaders['X-Authorization'] = `Bearer ${UserStoreModule.Token}`;
        }
        if (!config['noProject'] && UserStoreModule.CurrentProjectId) {
            customHeaders['X-Project'] = UserStoreModule.CurrentProjectId;
        }
        config.headers = Object.assign({}, DEFAULT_REQUEST_HEADERS, config.headers,
            customHeaders,
            localHeaders
        );
        return config;
    },
    error => Promise.reject(error)
);

/**
 * axios响应拦截器
 */
axios.interceptors.response.use(
    (response: AxiosResponse<any>) => {
        NProgress.done();
        const responseContentType: string = response.headers['content-type'];
        if (response.headers['content-disposition']) {
            convertRes2Blob(response);
        } else if (responseContentType && responseContentType.indexOf('application/json') > -1) {
            switch (response.data['er']) {
                case -1:
                {
                    if (!_.isUndefined(response.data.Total)) {
                        return response.data;
                    }
                    return response.data['items'];
                }
                // case 401: // 401直接跳转到登陆页
                case 401:
                    return redirectToLogin();
                    // return handleToken(response.config);
                default:
                    if (response.data?.type === 'application/json') {
                        // 在请求下载的时候，为了解决乱码问题，我们都会给 XHR 的 responseType 指定为 blob 或者 arraybuffer。但是在实际下载的过程中，后端返回的不一定是二进制流数据，
                        // 也有可能是 json 格式的错误信息，比如导出数据量过大，需要缩小范围，或者查询结果出错等错误信息，需要在前端页面以 toast 的方式提示给使用者。
                        const readPromise = new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.readAsText(response.data, 'utf-8');
                            reader.onload = function(e) {
                                const readerres: any = reader.result;
                                const resObj = JSON.parse(readerres);
                                // // console.log(parseObj);
                                // if (resObj.er === -1) {
                                //     AntMessage.success('导入成功');
                                // } else {
                                //     AntMessage.error(resObj?.erMessage);
                                //     throw new Error(resObj?.erMessage);
                                // }
                                resolve(resObj);
                            };
                        });
                        return readPromise;
                    }
                    if (NO_REPONSE_HANDLE_REQUEST.indexOf(response.config.url) > -1) {
                        handleRequestError();
                    } else {
                        handleRequestError(response.data);
                    }
                    break;
            }
        } else {
            handleRequestError(response);
        }
    },
    (error) => {
        NProgress.done();
        if (axios.isCancel(error)) {
            AntMessage.error(error.message);
            throw error;
        }
        if (FORBIDDEN_STATUS.indexOf(_.get(error, 'response.status')) > -1) {
            return handleToken(_.get(error, 'response.config'));
        }
        handleRequestError(error.response || error);
    }
);

/**
 * axios请求
 * @param config 请求配置
 */
export function request(config: AxiosRequestConfig): Promise<any> {
    let cacheKey = {
        method: config.method,
        url: config.url,
        data: null
    };
    // config.cancelToken = cancelSource.token;

    if (config['cache']) {
        // request cache, 类型number
        // config['cache']: 大于0-缓存毫秒数; 0-更新缓存; null/undefined-不使用缓存,不设置缓存
        if (config.data) {
            cacheKey.data = config.data;
        }
        const cacheData = CacheUtil.getCacheData(cacheKey);
        if (cacheData) {
            // 优先从缓存里获取数据
            return new Promise((resolve) => {
                resolve(cacheData);
            });
        }
    }

    return axios.request(config).then(res => {
        if (config['cache'] || config['cache'] === 0) {
            CacheUtil.setCacheData(cacheKey, res, config['cache']);
        }
        return res;
    });
}

/**
 * axios post请求
 * @param url 请求地址
 * @param data 请求body
 * @param opts 请求配置
 */
export function post(url: string, data?: any, opts?: object): Promise<any> {
    const config: AxiosRequestConfig = {
        method: 'post',
        url,
        data
    };
    return request(Object.assign(config, opts));
}

/**
 * axios get请求
 * @param url 请求地址
 * @param params 请求query
 */
export function get(url: string, params?: any, opts?: object): Promise<any> {
    const config: AxiosRequestConfig = {
        method: 'get',
        url,
        params
    };
    return request(Object.assign({}, config, opts));
}

/**
 * axios put请求
 * @param url 请求地址
 * @param data 请求body
 * @param opts 请求配置
 */
export function put(url: string, data?: any, opts?: object): Promise<any> {
    const config: AxiosRequestConfig = {
        method: 'put',
        url,
        data
    };
    return request(Object.assign({}, config, opts));
}

/**
 * axios delete请求
 * @param url 请求地址
 */
export function del(url: string, data?:any): Promise<any> {
    const config: AxiosRequestConfig = {
        method: 'delete',
        url,
        data
    };
    return request(config);
}

export function download(url: string, data?: any, method: Method = 'get'): Promise<any> {
    const config: AxiosRequestConfig = {
        method,
        url,
        data,
        responseType: 'blob'
    };
    return request(config);
}
