import { BaseEntityModel } from '@common-src/model/base-model';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { CacheUtil, CACHE_ALL_COMPONENT_KEY, CACHE_ALL_PANEL_COMPONENT_KEY } from '@common-src/model/cache-model';
import TopoComponentEntityModel from './topo-component-entity-model';
import { generateUUID } from '@common-src/utils/base_util';
import { TopoActionType, TopoBehaveType, TopoComponentType } from './enum';
import TopoActionBaseModel, { TopoBroadcastActionModel, TopoLinkActionModel, TopoReloadDataActionModel, TopoResetDataActionModel, TopoStyleActionModel, TopoVisibleActionModel } from './behave-model/topo-action-model';
export class TopoPageEntityModel extends BaseEntityModel {
    constructor() {
        super();
        this.id = generateUUID();
    }

    /**
     * 页面名称
     */
    name: string = undefined;

    /**
     * 页面状态
     */
    status: boolean = undefined;

    /**
     * 页面组件树
     */
    component: TopoComponentEntityModel = undefined;

    /**
     * 获取组件总数
     */
    get ComponentCount() {
        return this.getComponentCount(this.component);
    }

    /**
     * 递归获取组件总数
     * @param componentTreeItem
     */
    getComponentCount(componentTreeItem: TopoComponentEntityModel) {
        let sumCount = 1;
        if (componentTreeItem.children && componentTreeItem.children.length > 0) {
            _.forEach(componentTreeItem.children, item => {
                sumCount += this.getComponentCount(item);
            });
        }
        return sumCount;
    }

    findAllPanelComponent(): Array<TopoComponentEntityModel> {
        const cacheData = CacheUtil.getCacheData(CACHE_ALL_PANEL_COMPONENT_KEY);
        if (cacheData) {
            return cacheData;
        }
        const findComponents = _.map(this.component.children, item => this.findChildPanelComponent(item));
        const allPanelComponentList = _.flattenDeep(findComponents);
        CacheUtil.setCacheData(CACHE_ALL_PANEL_COMPONENT_KEY, allPanelComponentList, 0);
        return allPanelComponentList;
    }

    findAllComponent(): Array<TopoComponentEntityModel> {
        const cacheData = CacheUtil.getCacheData(CACHE_ALL_COMPONENT_KEY);
        if (cacheData) {
            return cacheData;
        }
        const findComponents = _.map(this.component.children, item => this.flattenComponent(item));
        const allComponentList = _.flattenDeep(findComponents);
        CacheUtil.setCacheData(CACHE_ALL_COMPONENT_KEY, allComponentList, 0);
        return allComponentList;
        // return _.flattenDeep(this.flattenComponent(this.component));
    }
    /**
     *  从组件树中查找组件
     * @param ids
     */
    private flattenComponent(componentTreeItem: TopoComponentEntityModel): Array<TopoComponentEntityModel> {
        const findComponents = [];
        if (componentTreeItem) {
            findComponents.push(componentTreeItem);
            if (componentTreeItem.children && componentTreeItem.children.length > 0) {
                for (let i = 0; i < componentTreeItem.children.length; i++) {
                    findComponents.push(...this.flattenComponent(componentTreeItem.children[i]));
                }
            }
        }
        return findComponents;
    }

    /**
     *  从组件树中查找组件
     * @param ids
     */
    findComponent(ids: Array<string>): Array<TopoComponentEntityModel> {
        return _.flattenDeep(this.findComponentById(this.component, ids));
    }

    /**
     * 递归查找组件
     * @param componentTreeItem
     * @param ids
     */
    private findComponentById(componentTreeItem: TopoComponentEntityModel, ids: Array<string>): Array<TopoComponentEntityModel> {
        const findComponents = [];
        if (componentTreeItem) {
            if (ids.indexOf(componentTreeItem.id) > -1) {
                findComponents.push(componentTreeItem);
            }
            if (componentTreeItem.children && componentTreeItem.children.length > 0) {
                for (let i = 0; i < componentTreeItem.children.length; i++) {
                    findComponents.push(...this.findComponentById(componentTreeItem.children[i], ids));
                }
            }
        }
        return findComponents;
    }

    private findChildPanelComponent(componentTreeItem: TopoComponentEntityModel): Array<TopoComponentEntityModel> {
        const findComponents = [];
        if (componentTreeItem) {
            if (componentTreeItem.type === TopoComponentType.PANEL) {
                findComponents.push(componentTreeItem);
            }
            if (componentTreeItem.children && componentTreeItem.children.length > 0) {
                for (let i = 0; i < componentTreeItem.children.length; i++) {
                    findComponents.push(...this.findChildPanelComponent(componentTreeItem.children[i]));
                }
            }
        }
        return findComponents;
    }

    /**
     * 从组件树中查找panel组件
     * @param component
     */
    findParentPanelComponent(component: TopoComponentEntityModel) {
        if (component) {
            if (component.type === TopoComponentType.PANEL) {
                return component;
            }
            return this.findParentPanelComponent(component.parent);
        }
        return null;
    }

    /**
     * 渲染模式
     * 单击组件事件
     * @param component
     */
    doClickComponent(component: TopoComponentEntityModel) {
        this.handleComponentBehave(component, TopoBehaveType.CLICK);
    }

    /**
     * 渲染模式
     * 双击组件事件
     * @param component
     */
    doDbClickComponent(component: TopoComponentEntityModel) {
        this.handleComponentBehave(component, TopoBehaveType.DBCLICK);
    }

    /**
     * 处理组件的行为
     * @param component
     * @param behaveType
     */
    handleComponentBehave(component: TopoComponentEntityModel, behaveType: TopoBehaveType) {
        _.forEach(component.behaves, behaveItem => {
            if (behaveItem.type === behaveType) {
                this.handleComponentAction(behaveItem.action);
            }
        });
    }

    /**
     * 处理组件的动作
     * @param action
     */
    async handleComponentAction(action: TopoActionBaseModel) {
        switch (action.type) {
            case TopoActionType.LINK:
                {
                    const linkAction: TopoLinkActionModel = action as TopoLinkActionModel;
                    window.open(linkAction.url, linkAction.target);
                }
                break;
            case TopoActionType.VISIBLE:
                {
                    const visibleAction: TopoVisibleActionModel = action as TopoVisibleActionModel;
                    visibleAction.visible = !visibleAction.visible;
                    this.showOrHideComponent(visibleAction.targets, visibleAction.visible);
                }
                break;
            case TopoActionType.BROADCAST:
                {
                    const broadcastAction: TopoBroadcastActionModel = action as TopoBroadcastActionModel;
                    $topoEventHub.$emit(broadcastAction.eventName, broadcastAction.eventParam);
                }
                break;
            case TopoActionType.RELOAD_DATA:
                {
                    const reloadDataAction: TopoReloadDataActionModel = action as TopoReloadDataActionModel;
                    const components: Array<TopoComponentEntityModel> = this.findComponent(reloadDataAction.targets);
                    _.forEach(components, item => {
                        item.getData(false);
                    });
                }
                break;
            case TopoActionType.RESET_DATA:
                {
                    const resetDataAction: TopoResetDataActionModel = action as TopoResetDataActionModel;
                    const components: Array<TopoComponentEntityModel> = this.findComponent(resetDataAction.targets);
                    _.forEach(components, item => {
                        item.data.componentData = resetDataAction.actionParam;
                    });
                }
                break;
            case TopoActionType.STYLE:
                {
                    const styleAction: TopoStyleActionModel = action as TopoStyleActionModel;
                    _.forEach(styleAction.targets, item => {
                        // update component styles
                    });
                }
                break;
        }
    }

    /**
     * 显示/隐藏组件
     * @param ids
     * @param visible
     */
    showOrHideComponent(ids: Array<string>, visible: boolean) {
        const components: Array<TopoComponentEntityModel> = this.findComponent(ids);
        for (let i = 0; i < components.length; i++) {
            components[i].style.Visible = visible;
        }
    }

    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name',
                ellipsis: true
            },
            {
                title: '状态',
                dataIndex: 'status',
                scopedSlots: { customRender: 'status' },
                width: 150
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(jsonData) {
        super.toModel(jsonData);
        this.component = new TopoComponentEntityModel().toModel(_.get(jsonData, 'component'));
        return this;
    }

    toService() {
        return {
            id: this.id,
            name: this.name,
            component: this.component.toService(),
            status: this.status
        };
    }
}

export class TopoPageQueryModel extends QueryPageModel {
    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT
    })
    name: string = undefined;

    toService() {
        return {
            params: {
                name: this.name
            }
        };
    }
}
