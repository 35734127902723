





















import { Component, Prop, Watch } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';

@Component
export default class JTLInputGroupComponent extends BaseComponent {
    @Prop()
    value: any;
    @Prop()
    disabled: boolean;
    @Prop()
    valueDesc: string;
    @Prop()
    nameDesc: string;
    @Prop()
    addDesc: string;
    options: Array<{ value: string, name: string }>;

    // @Watch('options')
    // update(newVal) {
    //     this.$emit('input', this.options);
    // }

    created() {
        this.options = this.value;
        if (!this.options || this.options.length <= 0) {
            // this.options = [{ name: '', value: '' }];
            this.options = [];
            this.change();
        }
    }

    removeEnumClick(index) {
        this.options.splice(index, 1);
        this.change();
    }

    addEnumClick() {
        this.options.push({ name: '', value: '' });
        this.change();
    }

    change() {
        this.$emit('input', this.options);
    }

    validate() {
        let validateFlag = true;

        _.forEach(this.options, item => {
            if (!item.name || !item.value) {
                this.showMessageWarning('所有枚举项不可为空，请补充');
                validateFlag = false;
                return false;
            }
        });

        if (validateFlag) {
            return true;
        } else {
            return false;
        }
    }
}

