import moment from 'moment';
import { get, post } from '@common-src/service/request';
// import HB4ParkingData from '@topo/assets/data/h-b4-parking.json';
// import ParkingTotalData from '@topo/assets/data/parking-total.json';
// import ParkingHTotalData from '@topo/assets/data/parking-h-mock-data.json';
// function getHB4ParkingData() {
//     return new Promise((resolve, reject) => {
//         setTimeout(() => {
//             resolve(HB4ParkingData);
//         }, 2000);
//     });
// }
// function getParkingTotalData() {
//     return new Promise((resolve, reject) => {
//         setTimeout(() => {
//             resolve(ParkingTotalData.items);
//         }, 1000);
//     });
// }

// function getParkingHTotalData() {
//     return new Promise((resolve, reject) => {
//         setTimeout(() => {
//             resolve(ParkingHTotalData.items);
//         }, 1000);
//     });
// }

function TopoService(option: any) {
    return Reflect.metadata('TopoService', option);
}

class TopoInnerService {
    @TopoService('获取设备运行状态')
    getDeviceLatestById = async(deviceId: string): Promise<any> => {
        const url = `${BI_BASE_REQUEST_PATH}/device/getLatest/${deviceId}`;
        const res = await get(url);
        return res;
    };

    @TopoService('获取所有大区总车位和空闲车位列表')
    getParkingSpaceCount = async(): Promise<any> => {
        const url = `${BI_BASE_REQUEST_PATH}/business/parking/spaceEachCount`;
        // 使用mock数据
        // const res: any = await getParkingTotalData();
        const res = await post(url);
        const spaceInfo: any = {
            total: 0,
            totalFree: 0,
            chartHistogramData: {
                title: '区域停车统计',
                desc: 'Regional parking statistics',
                data: {
                    columns: ['parkingArea', 'totalCount', 'parkedCount', 'freeCount'],
                    rows: []
                },
                settings: {
                    metrics: ['parkedCount', 'freeCount'],
                    dimension: ['parkingArea'],
                    stack: { count: ['parkedCount', 'freeCount'] },
                    labelMap: {
                        totalCount: '总车位',
                        freeCount: '剩余车位',
                        parkedCount: '已停车位'
                    }
                },
                colors: ['firebrick', 'green'],
                extend: {
                    series: {
                        label: { show: true, position: 'inside' }
                    }
                }
            },
            chartAreaPercentData: {
                title: '区域停车率',
                desc: 'Area parking rate'
            }
        };
        _.forEach(res, item => {
            const freeCount = item.freeCount + _.random(-50, 50, false);
            item.displayText = `总车位: ${item.totalCount}, 剩余车位: ${freeCount}`;
            item.parkedCount = item.totalCount - freeCount;
            spaceInfo[item.name] = item;
            spaceInfo.total += item.totalCount;
            spaceInfo.totalFree += freeCount;
            spaceInfo.chartHistogramData.data.rows.push({
                parkingArea: item.name,
                totalCount: item.totalCount,
                freeCount: freeCount,
                parkedCount: item.parkedCount
            });
            spaceInfo.chartAreaPercentData[item.name] = Math.floor(item.parkedCount / item.totalCount * 100);
        });
        return spaceInfo;
    };

    @TopoService('获取指定大区总车位和空闲车位')
    spaceEachCountFromLocationIdsByGroupId = async(groupId): Promise<any> => {
        const url = 'https://3895393ed3.zicp.vip/biz/api/business/parking/spaceEachCountFromLocationIdsByGroupId';
        // 使用mock数据
        // const res: any = await getParkingHTotalData();
        const res = await post(url, JSON.stringify(groupId));
        const spaceInfo: any = {
            total: 0,
            totalFree: 0,
            totalUsing: 0
        };
        // const random = _.random(1, 4);
        _.forEach(res, item => {
            // if (random === 1) {
            //     item.freeCount = 2;
            //     item.usingCount = item.totalCount - item.freeCount;
            // } else if (random === 2) {
            //     item.usingCount = 2;
            //     item.freeCount = item.totalCount - item.usingCount;
            // } else if (random === 3) {
            //     item.freeCount = (item.totalCount * 0.12).toFixed(0);
            //     item.usingCount = item.totalCount - item.freeCount;
            // }
            item.usingPercent = Math.floor(item.usingCount / item.totalCount * 100);
            spaceInfo[item.name] = item;
            spaceInfo.total += item.totalCount;
            spaceInfo.totalFree += item.freeCount;
            spaceInfo.totalUsing += item.usingCount;
        });
        $topoEventHub.$emit('TOPO_PARKING_SUCCESS_EVENT_HUB', `刷新时间: ${moment().format('YYYY-MM-DD HH:mm:ss')}`);
        return spaceInfo;
    };

    @TopoService('获取大区总车位和空闲车位')
    spaceTotalCount = async(): Promise<any> => {
        const url = 'https://3895393ed3.zicp.vip/biz/api/business/parking/spaceTotalCount';
        const res = await post(url);
        // const res = {
        //     totalCount: 3314,
        //     freeCount: 2241,
        //     usingCount: 3314 - 2241
        // };
        const spaceInfo: any = {
            total: _.get(res, 'totalCount'),
            totalFree: _.get(res, 'freeCount'),
            totalUsing: _.get(res, 'usingCount')
        };
        return spaceInfo;
    };

    @TopoService('获取H区B4停车位信息')
    getHB4ParkingInfo = async(): Promise<any> => {
        const url = `${BI_BASE_REQUEST_PATH}/facilityV2/listWithAttrKvs`;
        const params = {
            page: 1,
            limit: 999,
            params: {
                forceRoot: false,
                typeId: '53c3ffa0-17b7-11eb-a073-b30c1aec1080',
                groupIds: ['32712705-3446-48d8-a890-ac0bb0ff8dba'],
                locationId: 'b73a4efd-6f25-4b8c-800f-38e1cdf653c6'
            }
        };
        const res: any = await post(url, params);
        // 使用mock数据
        // const res = await getHB4ParkingData();
        const spaceObj: any = {
            total: _.get(res, 'Total'),
            unpark: 0,
            parked: 0,
            unable: 0,
            breakDown: 0,
            parkedPercent: 0
        };
        // res.unpark = res.parked = res.unable = res.breakDown = 0;
        // res.unpark = _.filter(_.get(res, 'items'), item => _.get(_.find(_.get(item, 'attributeKvList'), item => item.identifier === 'ParkingSpaceStatus'), 'val') === '空闲').length;
        // res.parked = _.filter(_.get(res, 'items'), item => _.get(_.find(_.get(item, 'attributeKvList'), item => item.identifier === 'ParkingSpaceStatus'), 'val') === '占用').length;
        // res.unable = _.filter(_.get(res, 'items'), item => _.get(_.find(_.get(item, 'attributeKvList'), item => item.identifier === 'ParkingSpaceStatus'), 'val') === '未启用').length;
        // res.breakDown = _.filter(_.get(res, 'items'), item => _.get(_.find(_.get(item, 'attributeKvList'), item => item.identifier === 'ParkingSpaceStatus'), 'val') === '故障').length;
        _.forEach(_.get(res, 'items'), item => {
            const spaceStatus = _.get(_.find(_.get(item, 'attributeKvList'), item => item.identifier === 'ParkingSpaceStatus'), 'val');
            const spaceNo = _.get(_.find(_.get(item, 'attributeKvList'), item => item.identifier === 'SpaceNO'), 'val');
            spaceObj[_.trim(spaceNo)] = { spaceNo: _.trim(spaceNo), spaceStatus };
            switch (spaceStatus) {
                case '空闲':
                    spaceObj.unpark++;
                    break;
                case '占用':
                    spaceObj.parked++;
                    break;
                case '未启用':
                    spaceObj.unable++;
                    break;
                case '故障':
                    spaceObj.breakDown++;
                    break;
            }
        });
        spaceObj.parkedPercent = (spaceObj.parked / spaceObj.total * 100).toFixed() + '%';
        return spaceObj;
    };
}

const topoInnerService = new TopoInnerService();
const topoInnerServiceOptions = _.map(_.keys(topoInnerService), (item) => {
    return {
        name: Reflect.getMetadata('TopoService', topoInnerService, item),
        value: item
    };
});

export { topoInnerServiceOptions };
export default topoInnerService;
