
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, put, del } from './request';
import { FacilityGroupEntityModel } from '@common-src/entity-model/facility-group-entity';
import { SimpleTreeModel } from '@common-src/model/simple-tree-model';
import { GroupEntityType } from '@common-src/model/enum';

const URL_PATH = `${ASSET_BASE_REQUEST_PATH}/asset/groups`;
// const URL_PATH = `http://172.21.232.249:3000/mock/190/api/asset/groups`;

class AssetGroupService implements ICRUDQ<FacilityGroupEntityModel, any> {
    async create(model: FacilityGroupEntityModel):Promise<FacilityGroupEntityModel> {
        let url = `${URL_PATH}/add`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<FacilityGroupEntityModel> {
        let url = `${URL_PATH}/getById/${modelId}`;
        const res = await post(url);
        return new FacilityGroupEntityModel().toModel(res);
    }

    async update(model: FacilityGroupEntityModel):Promise<FacilityGroupEntityModel> {
        const url = `${URL_PATH}/update`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: FacilityGroupEntityModel):Promise<FacilityGroupEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(type: any):Promise<any> {
        const url = `${URL_PATH}/list/tree`;
        let res = await get(url);
        // return res;
        if (!res) {
            res = [];
        }

        res.unshift({
            id: '',
            name: '全部分组',
            type: type
        });
        return _.map(res, item => item = new FacilityGroupEntityModel().toModel(Object.assign({ type }, item)));
    }

    async getGroupTree(type: GroupEntityType):Promise<Array<SimpleTreeModel>> {
        const url = `${URL_PATH}/list/tree`;
        let res = await get(url);
        return _.map(res, item => {
            return new SimpleTreeModel().toModel(item);
        });
    }
}

export default new AssetGroupService();
