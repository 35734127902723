var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jtl-custom-gauge" },
    [
      _c("a-progress", {
        staticClass: "pie-progress",
        attrs: {
          type: "circle",
          percent: _vm.percent * 100,
          width: _vm.width,
          "stroke-width": _vm.strokeWidth,
          "stroke-color": _vm.strokeColor,
          "stroke-linecap": "square",
        },
        scopedSlots: _vm._u([
          {
            key: "format",
            fn: function (percent) {
              return [
                _c("div", { staticClass: "jtl-custom-gauge-percent-wrapper" }, [
                  _c(
                    "span",
                    {
                      staticClass: "jtl-custom-gauge-percent",
                      style: { "font-size": _vm.percentFontSize },
                    },
                    [_vm._v(_vm._s(_vm._f("percentFormat")(percent / 100, 0)))]
                  ),
                  _c("br"),
                  _c("span", { staticClass: "jtl-custom-gauge-desc" }, [
                    _vm._v(_vm._s(_vm.percentDesc)),
                  ]),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }