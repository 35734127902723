var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("jtl-tree-select-component", {
    attrs: {
      "tree-data": _vm.TreeData,
      "expanded-keys": _vm.ExpandedKeys,
      multiple: true,
      placeholder: "请选择目标组件",
    },
    model: {
      value: _vm.SelectValue,
      callback: function ($$v) {
        _vm.SelectValue = $$v
      },
      expression: "SelectValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }