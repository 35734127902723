
import { OrgEntityModel, OrgQueryModel } from '@common-src/entity-model/org-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { post, del, get } from './request';
import { GroupEntityType } from '@common-src/model/enum';
import { UserEntityModel } from '@common-src/entity-model/user-entity';
import { SimpleTreeModel } from '@common-src/model/simple-tree-model';

const URL_PATH = `${AUTH_BASE_REQUEST_PATH}/org`;

class OrgService implements ICRUDQ<OrgEntityModel, OrgQueryModel> {
    async create(model: OrgEntityModel):Promise<OrgEntityModel> {
        const url = `${URL_PATH}/add`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<OrgEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        // return new OrgEntityModel().toModel(res);
        return res;
    }

    async update(model: OrgEntityModel):Promise<OrgEntityModel> {
        const url = `${URL_PATH}/update`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: OrgEntityModel):Promise<OrgEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: OrgQueryModel, page?: number, limit?: number):Promise<any> {
        const params = Object.assign({ page, limit }, query?.toService());
        const url = `${URL_PATH}/orgUserList`;
        const res = await post(url, params);
        if (res) {
            res.items = _.map(_.get(res, 'items'), item => item = new UserEntityModel().toModel(item));
        }
        return res;
    }

    async getUsers(query?: OrgQueryModel, page?: number, limit?: number):Promise<any> {
        const params = Object.assign({ page, limit }, query?.toService());
        const url = `${URL_PATH}/orgUserList`;
        const res = await post(url, params, { noProject: true });
        if (res) {
            res.items = _.map(_.get(res, 'items'), item => item = new UserEntityModel().toModel(item));
        }
        return res;
    }

    async getOrgTree() {
        const url = `${URL_PATH}/tree`;
        const res = await get(url);
        res.unshift({
            id: '',
            name: '全部',
            type: GroupEntityType.ORG
        });
        return _.map(res, item => item = new OrgEntityModel().toModel(Object.assign({ type: GroupEntityType.ORG }, item)));
    }

    async getOrgUserTree() {
        const url = `${URL_PATH}/getOrgUserWithTree`;
        const res = await get(url);
        return _.map(res, item => item = new OrgEntityModel().toModel(Object.assign({ type: GroupEntityType.ORG }, item)));
    }

    async getSelectOrgTree(requestConfig) {
        const url = `${URL_PATH}/tree`;
        const res = await get(url, null, requestConfig);
        return _.map(res, item => {
            return new SimpleTreeModel().toModel(item);
        });
    }

    async addOrgUsers(orgId: string, userIds: Array<string>) {
        const url = `${URL_PATH}/user/add`;
        const param = {
            orgId,
            userIds
        };
        return await post(url, param);
    }

    async deleteOrgUsers(orgId: string, userId: string) {
        const url = `${URL_PATH}/user/delete/${orgId}/${userId}`;
        const res = await post(url);
        return res;
    }
}

export default new OrgService();
