






import { BaseComponent } from '@common-src/mixins/base-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import * as echartsLib from 'echarts/lib/echarts';

@Component
export default class JtlCustomChartComponent extends BaseComponent {
    echart: any;
    @Prop()
    options: any;

    mounted() {
        this.$nextTick(function() {
            this.setOption(this.options);
        });
    }

    @Watch('options', { immediate: true, deep: true })
    optionsOnChange() {
        if (this.options) {
            this.setOption(this.options);
        }
    }

    setOption(option) {
        // if (this.echart) {
        //     this.echart.dispose();
        // }
        let view: any = this.$refs.jtlChart;
        if (view) {
            if (!this.echart) {
                this.echart = echartsLib.init(view, 'light', { renderer: 'svg' });
            }
            this.echart.setOption(option);
            // this.echart.on('click', function(param) {
            //     debugger;
            // });
            // this.echart.on('mousemove', function(param) {
            //     param.event.event.preventDefault();
            //     param.event.event.stopPropagation();
            //     return false;
            // });
        }
    }

    onResize() {
        if (this.echart) {
            this.echart.resize();
        }
    }
    updateView() {
        this.setOption(this.options);
    }
}
