import { BaseModel } from '@common-src/model/base-model';

export class SimpleTreeModel extends BaseModel {
    title: string = undefined;
    value: string = undefined;
    key: string = undefined;
    id: string = undefined;
    pId: string = undefined;
    children: Array<any> = undefined;
    scopedSlots: any = { title: 'custom' };
    parent: any = undefined;
    selectable: boolean = true;
    visible: boolean = true;
    // disabled: boolean = true;
    sort: number = null;

    toModel(json): any {
        this.title = _.get(json, 'name') || _.get(json, 'locationName');
        this.key = this.value = this.id = _.get(json, 'id') || _.get(json, 'locationId');
        this.pId = _.get(json, 'parentId');
        this.sort = _.get(json, 'sort');
        if (!_.isUndefined(_.get(json, 'selectable'))) {
            this.selectable = !!_.get(json, 'selectable');
            // this.disabled = !this.selectable;
        }
        if (!_.isUndefined(_.get(json, 'visible'))) {
            this.visible = _.get(json, 'visible') === 'visible';
        }
        if (json && json.children && json.children.length > 0) {
            this.children = _.map(json.children || json.roomList, item => {
                item.parentId = this.id;
                const child: SimpleTreeModel = new SimpleTreeModel().toModel(item);
                child.parent = this;
                return child;
            });
        } else {
            this.children = [];
        }
        // return this;
        return _.toPlainObject(this);
    }
}

export class SimpleNameTreeModel extends SimpleTreeModel {
    toModel(json): any {
        super.toModel(json);
        this.key = this.value = this.title = _.get(json, 'name');
        return this;
    }
}
