import { Moment } from 'moment';
import { ThingsFunctionType, GroupEntityType, TimePlanRuleType, FormControlSelectDeviceAttributeType, AttributeEntityType } from './enum';
import { ThingsModelTypeModel } from '@common-src/entity-model/things-model-type-entity';
import { SelectMode, UploadListType } from '@common-src/model/enum';
import { generateUUID } from '@common-src/utils/base_util';
import { DEFAULT_DATE_RANGES } from './query-control';

export enum FormControlType {
    DEFAULT = 'TEXT',
    AUTO_COMPLETE = 'AUTO_COMPLETE',
    AUTO_COMPLETE_THINGS_FUNCTION = 'AUTO_COMPLETE_THINGS_FUNCTION',
    TEXT = 'TEXT',
    TEXT_RICH = 'TEXT_RICH',
    JSON = 'JSON',
    NUMBER = 'NUMBER',
    UPLOAD = 'UPLOAD',
    SELECT = 'SELECT',
    SELECT_DATE = 'SELECT_DATE',
    TEXT_AREA = 'TEXT_AREA',
    SWITCH = 'SWITCH',
    RADIO_GROUP = 'RADIO_GROUP',
    CHECKBOX = 'CHECKBOX',
    CHECKBOX_GROUP = 'CHECKBOX_GROUP',
    THINGS_MODEL_TYPE = 'THINGS_MODEL_TYPE',
    ATTRIBUTE_DATA_TYPE_VALUE = 'ATTRIBUTE_DATA_TYPE_VALUE',
    SELECT_DEVICE_ATTRIBUTE = 'SELECT_DEVICE_ATTRIBUTE',
    SELECT_DEVICE = 'SELECT_DEVICE',
    SELECT_SPACE = 'SELECT_SPACE',
    SELECT_GROUP = 'SELECT_GROUP',
    DATA_PARAM = 'DATA_PARAM',
    TREE_SELECT = 'TREE_SELECT',
    TREE = 'TREE',
    TIME_PICKER = 'TIME_PICKER',
    TIME_PICKERS = 'TIME_PICKERS',
    TABLE ='TABLE',
    TABLEV2 ='TABLEV2',
    SELECT_DATERANGE = 'SELECT_DATERANGE',
    CASCADER_CITYS = 'CASCADER_CITYS',
    MONTH_SEASON_RULE = 'MONTH_SEASON_RULE',
    RATE = 'RATE',
    INPUT_GROUP = 'INPUT_GROUP',
    TOPO_TARGET_SELECT = 'TOPO_TARGET_SELECT',
    SELECT_USER = 'SELECT_USER',
    RESOURCE_URL = 'RESOURCE_URL',
    ARRAY_STRING = 'ARRAY_STRING',
    LINKAGE_DEVICE = 'LINKAGE_DEVICE',
    FORM_ENUM = 'FORM_ENUM',
    ALARM_RULE_EVENT = 'ALARM_RULE_EVENT',
    ALARM_RULE_ATTRIBUTE = 'ALARM_RULE_ATTRIBUTE',
    FORM_DOUBLE = 'FORM_DOUBLE',
    FORM_TRIPLE = 'FORM_TRIPLE',
    TRANSFER_SELECT = 'TRANSFER_SELECT',
    FORM_EDIT_TABLE = 'FORM_EDIT_TABLE',
    FORM_DATA_DES = 'FORM_DATA_DES',
}

export enum FormControlDeviceType {
    DEVICE = 'DEVICE',
    DEVICE_TREE = 'DEVICE_TREE',
    DEVICE_ATTRIBUTE = 'DEVICE_ATTRIBUTE',
}

export interface IFormLayout {
    labelCol?: number;
    wrapperCol?: number;
    width?: string;
}

export class FormControlModel<T> {
    value?: T;
    key?: string;
    label: string;
    labelFunction?: (model: any) => string;
    required?: boolean;
    pattern?: RegExp;
    type: FormControlType;
    readonly?: boolean;
    index?: number; // 表单字段排列顺序，按照升序排列。1->2->3......
    layout?: IFormLayout;
    validator?: (rule, value, callback) => void;
    validators?: Array<{ validator:(rule, value, callback) => void, trigger: string }>;
    asyncValidator?: (rule, value) => Promise<any>;
    errorMessage?: object;
    className?: string;
    updateHidden?: boolean;
    addHidden?: boolean;
    invisible?: boolean;
    invisibleFunction?: (model: any, controlList?: Array<any>) => boolean;
    message?: string;
    noMessageTooltip?: boolean;
    relationKeys?: Array<string>;
    relationPath?: string;
    disabled?: boolean;
    // 工单使用字段类型
    specialType?: string;
    defaultValue?: any;
    labelName?: string;
    valueName?: string;
    keyWord?: string;
    maxLength?: number;

    constructor(option: any) {
        this.value = _.isEmpty(option.value) ? option.defaultValue : option.value;
        this.key = option.key || '';
        this.label = option.label || '';
        this.labelFunction = option.labelFunction;
        this.required = !!option.required;
        this.pattern = option.pattern;
        this.type = option.type || FormControlType.DEFAULT;
        this.readonly = option.readonly || false;
        this.index = option.index;
        this.layout = option.layout || { labelCol: 5, wrapperCol: 12, width: '100% ' };
        this.validator = option.validator;
        this.validators = option.validators;
        this.asyncValidator = option.asyncValidator;
        this.errorMessage = option.errorMessage;
        this.className = option.className;
        this.updateHidden = option.updateHidden;
        this.addHidden = option.addHidden;
        this.invisible = option.invisible;
        this.invisibleFunction = option.invisibleFunction;
        this.message = option.message;
        this.relationKeys = option.relationKeys;
        this.relationPath = option.relationPath;
        this.specialType = option.specialType;
        this.noMessageTooltip = option.noMessageTooltip;
        this.disabled = option.disabled;
        this.defaultValue = option.defaultValue;
        this.labelName = option.labelName;
        this.valueName = option.valueName;
        this.keyWord = option.keyWord;
        this.maxLength = option.maxLength;
    }
}

export class FormControlTextModel extends FormControlModel<string> {
    placeholder?: string;
    inputType?: string;
    min?: number;
    max?: number;
    step?: number;
    prefix?: string;
    suffix?: string;
    rows?:number;
    constructor(options) {
        super(options);
        this.placeholder = options.placeholder || `请输入${this.label}`;
        this.inputType = options.inputType || 'text';
        this.min = options.min;
        this.max = options.max;
        this.step = options.step || 1;
        this.prefix = options.prefix;
        this.suffix = options.suffix;
        this.rows = options.rows;
    }
}

export class FormControlTextRichModel extends FormControlTextModel {
    output?: string;

    constructor(options) {
        super(options);
        this.output = options.output;
    }
}

export class FormControlJSONModel extends FormControlModel<any> {
    output?: string;

    constructor(options) {
        super(options);
        this.output = options.output;
    }
}

export class FormControlTimePickerModel extends FormControlModel<string> {
    placeholder?: string;
    format?: string;

    constructor(options) {
        super(options);
        this.placeholder = options.placeholder || `请选择${this.label}`;
        this.format = options.format;
    }
}

export class FormControlOptionModel extends FormControlModel<string> {
    options?: Array<{name: string, value: any} | {name: string, id: any}>;
    optionsPromise?: (...param: any) => Promise<Array<any>>;
    optionsPromiseParam?: any;
    placeholder?: string;
    mode?: SelectMode;
    loading?: boolean;
    cascad?: boolean;
    notShowLoadMore?:boolean;// options是否需要分页，(如果分页，联动时可能获取不到对应选项)

    showLoadMoreButton: boolean = false;
    searchText: string = '';
    filterPage: number = 1;
    change: any = null;

    debounceOptionsPromise: Function;

    get FilterOptions(): Array<{name: string, value: any} | {name: string, id: any}> {
        let filterOptions = null;
        if (!this.searchText) {
            filterOptions = this.options;
        } else {
            filterOptions = _.filter(this.options, item => item.name.indexOf(this.searchText) > -1);
        }
        if (!this.notShowLoadMore) {
            this.showLoadMoreButton = filterOptions && this.filterPage * JTL.CONSTANT.PAGE_SIZE < filterOptions.length;
        }
        const selectOptions = _.filter(this.options, (item: any) => {
            const itemValue = item.value || item.id;
            if (Array.isArray(this.value)) {
                return this.value && this.value.indexOf(itemValue) > -1;
            }
            return this.value && this.value === itemValue;
        });
        if (this.notShowLoadMore) {
            return filterOptions;
        } else return _.union(selectOptions, _.take(filterOptions, this.filterPage * JTL.CONSTANT.PAGE_SIZE));
    }

    constructor(option) {
        super(option);
        this.mode = option.mode || 'default';
        this.options = option.options;
        this.optionsPromise = option.optionsPromise;
        this.optionsPromiseParam = option.optionsPromiseParam;
        this.placeholder = option.placeholder || `请选择${this.label}`;
        this.loading = option.loading;
        this.cascad = option.cascad;
        this.change = option.change;
        this.notShowLoadMore = option.notShowLoadMore || false;
        if (this.optionsPromise) {
            this.debounceOptionsPromise = _.debounce(this.optionsPromise, 500);
        }
    }

    loadMore(self: FormControlOptionModel) {
        self.filterPage++;
    }
    onSearch(value: string, self: FormControlOptionModel) {
        self.filterPage = 1;
        self.searchText = _.trim(value);
    }

    async onAutoCompleteSearch(searchText: string, formControl: FormControlOptionModel) {
        if (formControl.optionsPromise) {
            formControl.options = await this.debounceOptionsPromise(searchText);
        }
    }
}

export class FormControlThingsFunctionAutoCompleteModel extends FormControlOptionModel {
}

export class FormControlSwitchModel extends FormControlModel<boolean> {
    checkText?: string;
    uncheckText?: string;

    constructor(option) {
        super(option);
        this.checkText = option.checkText;
        this.uncheckText = option.uncheckText;
    }
}

export class FormControlUploadModel extends FormControlModel<boolean> {
    listType?: UploadListType;
    showUploadList?: boolean;
    accept?: string;
    uploadUrl?: string;
    maxFileSize?:number;
    maxFileNumber?:number;
    notUploadOss?:boolean;

    constructor(option) {
        super(option);
        _.forEach(this.value as any, (item:any) => {
            item.uid = item.id || item.uid || generateUUID();
        });
        this.accept = option.accept;
        this.listType = option.listType || 'picture';
        this.showUploadList = option.showUploadList || true;
        this.maxFileNumber = option.maxFileNumber || 9;
        this.maxFileSize = option.maxFileSize || 10;
        this.notUploadOss = option.notUploadOss;
        this.uploadUrl = option.uploadUrl;
    }
}

export class FormControlDateModel extends FormControlModel<boolean> {
    disabledDate: (currentDate: Moment) => boolean;
    placeholder?: string;
    ranges?: any;
    showTime?: boolean;

    constructor(option) {
        super(option);
        this.disabledDate = option.disabledDate || function(currentDate: Moment) { return false; };
        this.placeholder = option.placeholder || `请选择${this.label}`;
        this.ranges = option.ranges || DEFAULT_DATE_RANGES();
        this.showTime = option.showTime || false;
    }
}

export class FormControlThingsTypeModel extends FormControlModel<ThingsModelTypeModel> {
    thingsType?: ThingsFunctionType;
    constructor(option) {
        super(option);
        this.thingsType = option.thingsType;
    }
}

export class FormControlSelectGroupModel extends FormControlModel<string> {
    groupType: GroupEntityType;
    constructor(option) {
        super(option);
        this.groupType = option.groupType || GroupEntityType.DEVICE;
    }
}

export class FormControlSelectDeviceAttributeModel extends FormControlModel<string> {
    mode: SelectMode;
    multiple: boolean;
    scopeId: string;
    selectType: FormControlSelectDeviceAttributeType;
    constructor(option) {
        super(option);
        this.mode = option.mode || 'default';
        this.multiple = option.multiple;
        this.scopeId = option.scopeId;
        this.selectType = option.selectType;
    }
}

export class FormControlTableModel extends FormControlModel<string> {
    columns?: Array<{title:string, dataIndex:string, width?:number, scopedSlots?:any}>;
    dataSource?: Array<any>;
    dataService?: (...param: any) => Promise<Array<any>>;
    deleteAction: boolean = false;
    pagination: boolean = true;
    addAction: boolean = false;
    cellType: Array<any> = [];

    constructor(option) {
        super(option);
        this.columns = option.columns;
        this.dataSource = option.dataSource;
        this.dataService = option.dataService;
        this.deleteAction = option.deleteAction;
        this.pagination = option.pagination;
        this.addAction = option.addAction;
        this.cellType = option.cellType;
    }
}

export class FormControlMonthSeasonModel extends FormControlModel<string> {
    ruleType?:TimePlanRuleType;

    constructor(option) {
        super(option);
        this.ruleType = option.ruleType;
    }
}

export class FormControlInputGroupModel extends FormControlModel<string> {
    options?: Array<{name: string, value: any} | {name: string, id: any}>;
    valueDesc?: string;
    nameDesc?: string;
    addDesc?: string;

    constructor(option) {
        super(option);
        this.options = option.options;
        this.valueDesc = option.valueDesc || '参数值';
        this.nameDesc = option.nameDesc || '参数描述';
        this.addDesc = option.addDesc || '+增加枚举项';
    }
}

export class FormControlLinkDeviceModel extends FormControlModel<string> {
    linkageType?: AttributeEntityType;

    constructor(option) {
        super(option);
        this.linkageType = option.linkageType;
    }
}

export class FormControlTableV2Model extends FormControlModel<string> {
    displayColumn?: Array<string>;
    rowKey?: string;

    constructor(option) {
        super(option);
        this.displayColumn = option.displayColumn;
        this.rowKey = option.rowKey || 'id';
    }
}
export class FormControlDataAttrModel extends FormControlModel<string> {
    thingsType?: ThingsFunctionType;
    enumCloneList?: Array<{value:string, name:string}>;
    dataTrueName?: string;
    dataFalseName?: string;
    constructor(option) {
        super(option);
        this.thingsType = option.thingsType;
        this.enumCloneList = option.enumCloneList;
        this.dataTrueName = option.dataTrueName;
        this.dataFalseName = option.dataFalseName;
    }
}
/**
 * Form控件类型元数据
 * @param option Form控件配置
 */
export function FormControl(option: any) {
    return Reflect.metadata('FormControl', option);
}
