

















import { Component, Prop } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { FormControlOptionModel } from '@common-src/model/form-control';

@Component
export default class JTLEntityDialogComponent extends FormDialogComponent<any> {
    @Prop({ default: 'large' })
    size: string;
    formOnChange(formControlModel: FormControlOptionModel) {
        this.$emit('change', formControlModel);
    }
}
