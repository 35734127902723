import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel, FormControlUploadModel, FormControlTableModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { dateFormat, text as textFormat } from '@common-src/filter';
import { getAppGroupList } from '@common-src/service3/app';

export class AppEntityModel extends BaseEntityModel {
    static baseUrl = `/infra-appmgr/api/app`;
    @FormControl({
        label: '应用标识符',
        type: FormControlType.TEXT,
        required: true,
        readonly: true
    } as FormControlTextModel)
    identifier: string = undefined;

    @FormControl({
        label: '应用类型',
        type: FormControlType.SELECT,
        required: true,
        options: [
            { name: '应用', value: 'APP' },
            { name: '基础服务', value: 'BACKEND' },
            { name: '前端服务', value: 'FRONTEND' }
        ],
        change: function(formControlList, formControl, formModel, formRules) {
            console.log('formControlList', formControlList);
            const iconItem = _.find(formControlList, item => item.key === 'icon');
            iconItem.invisible = iconItem.invisibleFunction(formModel);
            const resourcesItem = _.find(formControlList, item => item.key === 'resourceTypes');
            resourcesItem.invisible = resourcesItem.invisibleFunction(formModel);
        }
    } as FormControlTextModel)
    type: string = undefined;

    @FormControl({
        label: '应用名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '应用描述',
        type: FormControlType.TEXT_AREA,
        required: true
    } as FormControlTextModel)
    remark: string = undefined;

    @FormControl({
        label: '应用图标',
        type: FormControlType.UPLOAD,
        listType: 'picture-card',
        showUploadList: true,
        notUploadOss: true,
        uploadUrl: '/infra-common/api/filestorage/upload',
        maxFileNumber: 1,
        invisibleFunction: (model) => model.type !== 'APP',
        required: false
    } as FormControlUploadModel)
    icon: Array<any> = undefined;

    // @FormControl({
    //     label: '版本号',
    //     type: FormControlType.TEXT,
    //     required: true,
    //     readonly: true
    // } as FormControlTextModel)
    // version: string = undefined;

    // @FormControl({
    //     label: '版本描述',
    //     type: FormControlType.TEXT_AREA,
    //     required: true
    // } as FormControlTextModel)
    // versionRemark: string = undefined;

    @FormControl({
        label: '资源',
        type: FormControlType.SELECT,
        mode: 'multiple',
        options: [
            { name: 'Web站点', value: 'WEB' },
            { name: '移动端站点', value: 'MP' }
            // { name: '服务', value: 'BACKEND' }
        ],
        invisibleFunction: (model) => model.type !== 'APP',
        required: true
    } as FormControlOptionModel)
    resourceTypes: Array<any> = [];

    get OpenUrl() {
        return `/web/${this.webPort}/`;
    }

    get AppLogo() {
        return _.get(this.icon, '[0].url');
    }

    typeDesc: string = undefined;
    version: string = undefined;
    latestVersion: string = undefined;
    statusDesc: string = undefined;
    status: string = undefined;
    updateTime: string = undefined;
    webPort: number = undefined;

    static getTableColumns() {
        return [
            {
                title: '应用标识符',
                dataIndex: 'identifier'
            },
            {
                title: '应用名称',
                dataIndex: 'name'
            },
            {
                title: '应用类型',
                dataIndex: 'typeDesc'
            },
            {
                title: '当前版本号',
                dataIndex: 'version'
            },
            {
                title: '最新版本号',
                dataIndex: 'latestVersion'
            },
            // {
            //     title: '版本描述',
            //     dataIndex: 'versionRemark'
            // },
            {
                title: '版本状态',
                dataIndex: 'statusDesc'
            },
            // {
            //     title: '提交人',
            //     dataIndex: 'submitUserName'
            // },
            {
                title: '更新时间',
                dataIndex: 'updateTime',
                customRender: (text) => {
                    return dateFormat(text);
                }
            },
            // {
            //     title: '审核人',
            //     dataIndex: 'auditUserName'
            // },
            // {
            //     title: '审核时间',
            //     dataIndex: 'auditTime',
            //     customRender: (text) => {
            //         return dateFormat(text);
            //     }
            // },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        if (json && json.icon) {
            this.icon = [{ url: json.icon, type: 'image' }];
        }
        // if (json.images) {
        //     this.images = _.map(json.images, (item: any) => ({ httpMethod: item.imageType, url: item.imageName }));
        // }
        return this;
    }

    toService() {
        const data: any = super.toService();
        // data.images = _.map(this.images, (item: any) => ({ imageType: item.imageType || item.httpMethod, imageName: item.imageName || item.url }));
        data.icon = _.get(this.icon, '[0].url');
        return data;
    }
}

export class AppQueryModel extends QueryPageModel {
    @QueryControl({
        label: '应用标识符',
        type: QueryControlType.TEXT,
        span: 5
    })
    identifier: string = undefined;

    @QueryControl({
        label: '应用名称',
        type: QueryControlType.TEXT,
        span: 5
    })
    name: string = undefined;

    @QueryControl({
        label: '版本状态',
        type: QueryControlType.SELECT,
        options: [
            { value: 'UN_DEPLOYED', name: '未部署' },
            { value: 'DEPLOY_SUCCESS', name: '部署成功' },
            { value: 'DEPLOY_FAILED', name: '部署失败' },
            { value: 'OFFLINE', name: '下线' }
        ],
        span: 5
    })
    status: string = undefined;

    @QueryControl({
        label: '应用类型',
        type: QueryControlType.SELECT,
        options: [
            { name: '应用', value: 'APP' },
            { name: '基础服务', value: 'BACKEND' },
            { name: '前端服务', value: 'FRONTEND' }
        ],
        span: 5
    })
    type: string = undefined;

    toService() {
        const params: any = super.toService();
        return { params };
    }
}

export class AppGroupEntityModel extends BaseEntityModel {
    static baseUrl = `/infra-appmgr/api/app/association`;
    @FormControl({
        label: '应用名称',
        type: FormControlType.TEXT,
        required: true,
        maxLength: 10
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '所属分组',
        type: FormControlType.SELECT,
        optionsPromise: getAppGroupList
    } as FormControlTextModel)
    groupId: string = undefined;

    @FormControl({
        label: '应用图标',
        type: FormControlType.UPLOAD,
        listType: 'picture-card',
        showUploadList: true,
        maxFileNumber: 1,
        required: true
    } as FormControlUploadModel)
    icon: Array<any> = [];

    @FormControl({
        label: '备注',
        type: FormControlType.TEXT_AREA,
        maxLength: 100
    } as FormControlTextModel)
    remark: string = undefined;

    @FormControl({
        label: '应用标识符',
        type: FormControlType.TEXT,
        required: true,
        readonly: true
    } as FormControlTextModel)
    identifier: string = undefined;

    @FormControl({
        label: '版本号',
        type: FormControlType.TEXT,
        required: true,
        readonly: true
    } as FormControlTextModel)
    version: string = undefined;

    get AppLogo() {
        return _.get(this.icon, '[0].url');
    }

    appSequence: number = undefined;
    appId: string = undefined;
    groupName: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '图标',
                dataIndex: 'icon',
                scopedSlots: { customRender: 'icon' }
            },
            {
                title: '应用名称',
                dataIndex: 'name'
            },
            {
                title: '所属分组',
                dataIndex: 'groupName',
                customRender: (text, record) => {
                    return textFormat(record.groupName);
                }
            },
            {
                title: '排序号',
                dataIndex: 'appSequence',
                scopedSlots: { customRender: 'appSequence' },
                defaultSortOrder: 'ascend',
                sorter: (a, b) => a.appSequence - b.appSequence
            },
            {
                title: '应用标识符',
                dataIndex: 'identifier'
            },
            {
                title: '版本号',
                dataIndex: 'version'
            },
            {
                title: '备注',
                dataIndex: 'remark'
            }
            // {
            //     title: '操作',
            //     dataIndex: 'action',
            //     scopedSlots: { customRender: 'action' }
            // }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        if (json.icon) {
            this.icon = [{ url: json.icon, type: 'image' }];
        } else {
            this.icon = [];
        }
        return this;
    }

    toService() {
        const data: any = super.toService();
        data.icon = _.get(this.icon, '[0].url');
        return data;
    }
}

export class AppGroupQueryModel extends QueryPageModel {
    @QueryControl({
        label: '',
        type: QueryControlType.TEXT,
        span: 8,
        placeholder: '请输入应用名称或者标识符'
    })
    keyword: string = undefined;

    groupId: string = 'all';

    toService() {
        const data: any = super.getParams();
        return data;
    }
}
