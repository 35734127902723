var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-user-component" },
    [
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "center" } },
        [
          _c(
            "a-col",
            {
              staticClass: "select-user-wrapper",
              attrs: { span: _vm.hasSelectBtn ? 22 : 24 },
              on: { click: _vm.selectUserClick },
            },
            [
              _vm._l(_vm.value, function (item, index) {
                return _c(
                  "span",
                  { key: index, staticClass: "select-user-tag" },
                  [
                    _c("span", [_vm._v(_vm._s(item.name))]),
                    _c("a-icon", {
                      staticClass: "select-user-icon",
                      attrs: { type: "close" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.deleteItem(index)
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
              !_vm.value || _vm.value.length === 0
                ? _c("label", { staticClass: "no-user-label" }, [
                    _vm._v("请选择对象"),
                  ])
                : _vm._e(),
            ],
            2
          ),
          _vm.hasSelectBtn
            ? _c(
                "a-col",
                {
                  staticClass: "text-center",
                  staticStyle: { "min-width": "" },
                  attrs: { span: 2 },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "jtl-link",
                      on: { click: _vm.selectUserClick },
                    },
                    [_vm._v("选择")]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("select-user-dialog", {
        ref: "selectUserDialog",
        on: { dialogOK: _vm.selectUserChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }