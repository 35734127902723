var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "array-string-form-component" },
    _vm._l(_vm.value, function (item, index) {
      return _c(
        "div",
        { key: index },
        [
          _c("a-input", {
            staticClass: "string-input",
            attrs: { placeholder: _vm.placeholder, disabled: _vm.disabled },
            on: { change: _vm.change },
            model: {
              value: _vm.value[index],
              callback: function ($$v) {
                _vm.$set(_vm.value, index, $$v)
              },
              expression: "value[index]",
            },
          }),
          !_vm.disabled
            ? [
                index === 0
                  ? _c(
                      "a-button",
                      {
                        attrs: { type: "link" },
                        on: {
                          click: function ($event) {
                            return _vm.addClick()
                          },
                        },
                      },
                      [_vm._v("+")]
                    )
                  : _c(
                      "a-button",
                      {
                        attrs: { type: "link" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteClick(index)
                          },
                        },
                      },
                      [_vm._v("-")]
                    ),
              ]
            : _vm._e(),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }