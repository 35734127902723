var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alarm-event-rule-component" },
    _vm._l(_vm.value, function (item, index) {
      return _c(
        "a-row",
        { key: index, attrs: { type: "flex", justify: "center" } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("a-select", {
                staticClass: "width100",
                attrs: { placeholder: "请选择事件", options: _vm.options },
                model: {
                  value: item.identifier,
                  callback: function ($$v) {
                    _vm.$set(item, "identifier", $$v)
                  },
                  expression: "item.identifier",
                },
              }),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 6 } },
            [
              _c("a-select", {
                staticClass: "width100",
                attrs: {
                  placeholder: "请选择周期时间",
                  options: _vm.PeriodTimeList,
                },
                model: {
                  value: item.periodTime,
                  callback: function ($$v) {
                    _vm.$set(item, "periodTime", $$v)
                  },
                  expression: "item.periodTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 16 } },
            [
              _c("span", { staticStyle: { padding: "0 8px" } }, [
                _vm._v("内累计发生"),
              ]),
              _vm._v(" "),
              _c("a-input", {
                staticStyle: { width: "200px" },
                attrs: { suffix: "次" },
                model: {
                  value: item.count,
                  callback: function ($$v) {
                    _vm.$set(item, "count", _vm._n($$v))
                  },
                  expression: "item.count",
                },
              }),
            ],
            1
          ),
          _c(
            "a-col",
            { staticClass: "text-center", attrs: { span: 2 } },
            [
              index === 0
                ? _c("a-icon", {
                    attrs: { type: "plus-circle" },
                    on: { click: _vm.addClick },
                  })
                : _c("a-icon", {
                    attrs: { type: "minus-circle" },
                    on: {
                      click: function ($event) {
                        return _vm.removeClick(index)
                      },
                    },
                  }),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }