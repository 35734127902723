



















import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import CommonService from '@common-src/service/common';
import { PropertyEntityType } from '@common-src/model/enum';

@Component
export default class JTLSelectPropertyComponent extends BaseComponent {
    /**
     * 正在加载标识
     */
    loading: boolean = false;

    /**
     * 当前选中值
     */
    @Prop()
    value: string;

    /**
     * 属性类型
     */
    @Prop()
    propertyType: PropertyEntityType;

    /**
     * 判断是否为条件选择控件，是则额外添加“全部”项
     */
    @Prop({ default: true })
    isCondition: boolean;

    /**
     * 所有可选项
     */
    selectOptions: Array<{name: string, value: string}> = null;

    created() {
        this.loading = true;
        CommonService.getPropertyOptions(this.propertyType)
            .then(res => {
                if (this.isCondition) {
                    res.splice(0, 1, JTL.CONSTANT.ALL_OPTION as any);
                    this.selectOptions = res;
                    this.SelectValue = JTL.CONSTANT.ALL_OPTION.value;
                } else {
                    this.selectOptions = res;
                }
            })
            .catch(err => {
                $log.error(err);
            })
            .finally(() => {
                this.loading = false;
            });
    }

    get SelectValue() {
        return this.value;
    }

    set SelectValue(val: string) {
        this.$emit('input', val);
    }
}
