var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jtl-form-tablev2-component" },
    [
      _c(
        "a-table",
        {
          attrs: {
            "data-source": _vm.value,
            pagination: false,
            bordered: false,
            scroll: { x: 1000, y: 300 },
            "row-key": _vm.rowKey,
            size: "small",
          },
        },
        [
          _c("a-table-column", {
            key: "delete",
            attrs: { title: "删除", width: "50px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (record) {
                  return [
                    _c("a-checkbox", {
                      model: {
                        value: record.selected,
                        callback: function ($$v) {
                          _vm.$set(record, "selected", $$v)
                        },
                        expression: "record.selected",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._l(_vm.FirstRowKeys, function (key) {
            return _c("a-table-column", {
              key: key,
              attrs: { title: key, "data-index": key, ellipsis: true },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (text, record) {
                      return [
                        _c(
                          "span",
                          { class: { "table-row-delete": record.selected } },
                          [_vm._v(_vm._s(text))]
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }