













































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import TableComponent from '@common-src/mixins/table-component';
import TransformComponent from '@common-src/mixins/transform-component';
import AssetService from '@common-src/service/asset';
import { DeviceEntityModel, DeviceQueryModel } from '@common-src/entity-model/device-entity';
import { AssetEntityModel } from '@common-src/entity-model/asset-entity';
import { ModuleType } from '@common-src/enum/module-type.enum';
import { GroupEntityType } from '@common-src/model/enum';
import DeviceService from '@common-src/service/device';
import DeviceTypeService from '@common-src/service3/device-type';

@Component
export default class DataFilterConfigComponent extends Mixins(TransformComponent, TableComponent) {
    @Prop({ default: { selectList: [] } })
    value: any;
    @Prop()
    options: Array<any>;
    @Prop({ default: 'device' })
    type: string;
    @Prop()
    disabled: boolean;
    @Prop()
    productId: string;
    @Prop({ default: '' })
    ruleId: string;

    get Label() {
        if (this.type === 'attr') {
            return '属性';
        }
        return '设备';
    }

    created() {
        this.debounceTransformGetList = _.debounce(this.transformGetList, 500);
        this.isAutoQuery = false;
        if (this.type === 'attr') {
            this.pageSize = null;
            this.isLoadMore = false;
        } else {
            this.isLoadMore = true;
            this.queryModel = new DeviceQueryModel();
            this.queryModel.forceRoot = false;
            this.initTable({ listFunc: DeviceService.query, queryModel: this.queryModel });
        }
    }

    dialogOpen() {
        this.dialogVisible = true;
        this.listData = [];
        this.rightDataList = _.get(this.value, 'selectList') || [];
        if (this.type === 'device') {
            this.queryModel.typeId = this.productId;
            this.getList().then(() => {
                this.initListData();
            });
        } else {
            DeviceTypeService.getAttrs(this.productId).then(res => {
                this.listData = res;
                this.initListData();
            });
        }
    }

    initListData() {
        if (this.rightDataList && this.rightDataList.length > 0) {
            _.forEach(this.rightDataList, item => {
                const leftDataItem = _.find(this.listData, listItem => ((item.id && listItem.id === item.id) || (item.identifier && listItem.identifier === item.identifier)));
                if (leftDataItem) {
                    leftDataItem.check = true;
                    item.displayName = leftDataItem.displayName || leftDataItem.name;
                }
            });
        }
    }

    valueChange() {
        this.$emit('input', this.value);
    }

    dialogOK() {
        this.value.selectList = this.rightDataList;
        this.value.selectCount = this.rightDataList.length;
        this.valueChange();
        this.dialogClose();
    }
    selectDevice() {
        if (!this.productId) {
            this.showMessageWarning('请先选择产品！');
            return;
        }
        this.dialogOpen();
    }
    deleteItem(index) {
        this.value.selectList.splice(index, 1);
        this.value.selectCount--;
        this.valueChange();
    }
}
