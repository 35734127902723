var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-device-attribute-table-component" },
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableColumns,
          "data-source": _vm.TableData,
          bordered: "",
          pagination: false,
          "show-header": false,
          "row-key": "uuid",
        },
        scopedSlots: _vm._u([
          {
            key: "name",
            fn: function (text, record) {
              return [
                _c("a-input", {
                  attrs: { placeholder: "请输入名称", "allow-clear": "" },
                  model: {
                    value: record.name,
                    callback: function ($$v) {
                      _vm.$set(record, "name", $$v)
                    },
                    expression: "record.name",
                  },
                }),
              ]
            },
          },
          {
            key: "setting",
            fn: function (text, record) {
              return [
                _c(
                  "a-radio-group",
                  {
                    model: {
                      value: record.hasDevice,
                      callback: function ($$v) {
                        _vm.$set(record, "hasDevice", $$v)
                      },
                      expression: "record.hasDevice",
                    },
                  },
                  [
                    _c("a-radio", { attrs: { value: false } }, [
                      _vm._v("无设置"),
                    ]),
                    _c("a-radio", { attrs: { value: true } }, [
                      _vm._v("关联设备"),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "device",
            fn: function (text, record) {
              return [
                record.hasDevice
                  ? _c(
                      "a-form-model-item",
                      { attrs: { prop: record.name } },
                      [
                        _c(
                          "a-spin",
                          { attrs: { spinning: _vm.sourceLoading } },
                          [
                            _vm.deviceType ===
                            _vm.FormControlDeviceType.DEVICE_ATTRIBUTE
                              ? _c(
                                  "a-select",
                                  {
                                    attrs: {
                                      "show-search": "",
                                      placeholder: "请选择设备属性",
                                    },
                                    on: { search: _vm.handleAttributeSearch },
                                    model: {
                                      value: record.DeviceIdAndKey,
                                      callback: function ($$v) {
                                        _vm.$set(record, "DeviceIdAndKey", $$v)
                                      },
                                      expression: "record.DeviceIdAndKey",
                                    },
                                  },
                                  [
                                    _vm._l(
                                      _vm.allDeviceAttributeList,
                                      function (optionGroup, index) {
                                        return [
                                          _c(
                                            "a-select-opt-group",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: optionGroup.Visible,
                                                  expression:
                                                    "optionGroup.Visible",
                                                },
                                              ],
                                              key: index,
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  attrs: { slot: "label" },
                                                  slot: "label",
                                                },
                                                [
                                                  _c("a-icon", {
                                                    attrs: { type: "android" },
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(optionGroup.name)
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._l(
                                                optionGroup.attributes,
                                                function (option) {
                                                  return [
                                                    _c(
                                                      "a-select-option",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              optionGroup.visible,
                                                            expression:
                                                              "optionGroup.visible",
                                                          },
                                                        ],
                                                        key: option.key,
                                                        attrs: {
                                                          value:
                                                            option.DeviceIdAndKey,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(option.key) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm.deviceType ===
                            _vm.FormControlDeviceType.DEVICE_TREE
                              ? _c("a-tree-select", {
                                  attrs: {
                                    "show-search": "",
                                    "tree-node-filter-prop": "name",
                                    "dropdown-style": {
                                      maxHeight: "600px",
                                      overflow: "auto",
                                      padding: "8px",
                                    },
                                    "tree-data": _vm.allDeviceTree,
                                    placeholder: "请选择设备",
                                    "allow-clear": "",
                                  },
                                  model: {
                                    value: record.deviceId,
                                    callback: function ($$v) {
                                      _vm.$set(record, "deviceId", $$v)
                                    },
                                    expression: "record.deviceId",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "action",
            fn: function (text, record) {
              return [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.deleteClick(record)
                      },
                    },
                  },
                  [_vm._v("删除")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }