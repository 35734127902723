import { AppEntityModel } from '@common-src/entity-model/app-entity';
import { ModuleType } from '@common-src/enum/module-type.enum';
import http from '@common-src/service3/http/axios';

/**
 * @description: 获取Select选项
 */
export async function getOptions(url: string): Promise<Array<OptionItem>> {
    if (!url) {
        return;
    }
    const res = await http.request<Array<OptionItem>>({
        url,
        method: 'GET'
    });
    return _.map(res, item => ({
        label: item.name,
        value: item.id
    }));
}

/**
 * @description: 获取Select选项
 */
export async function getAllDeviceType(): Promise<Array<OptionItem>> {
    const res = await http.request<Array<OptionItem>>({
        url: `${IOT_BASE_REQUEST_PATH}/deviceType/getTypes`,
        method: 'POST',
        params: { types: [3] }
    });
    return _.map(res, item => ({
        label: item.name,
        value: item.id
    }));
}

export async function getAllEdge(): Promise<Array<OptionItem>> {
    if (JTL.moduleType === ModuleType.EDGE) {
        return new Promise((resolve) => {
            resolve(null);
        });
    }
    const res = await http.request<Array<OptionItem>>({
        url: `${IOT_BASE_REQUEST_PATH}/edge/getAll`,
        method: 'POST',
        params: {}
    });
    return _.map(res, item => ({
        name: item.name,
        label: item.name,
        value: item.id
    }));
}

export async function getBizTypes(): Promise<Array<OptionItem>> {
    const res = await http.request<Array<OptionItem>>({
        url: `${COMMON_BASE_REQUEST_PATH}/subsystem/getAll`,
        method: 'GET'
    });
    return _.map(res, item => ({
        name: item.name,
        label: item.name,
        value: item.identifier
    }));
}

export async function getDeviceTypeAttributes(deviceTypeId: string): Promise<Array<OptionItem>> {
    if (!deviceTypeId) {
        return;
    }
    const res = await http.request<Array<OptionItem>>({
        url: `${IOT_BASE_REQUEST_PATH}/deviceType/detail/${deviceTypeId}`,
        method: 'GET',
        params: {}
    });
    return _.map(_.get(res, 'attrs'), item => ({
        label: `${_.get(item, 'jsonValue.name')} | ${_.get(item, 'jsonValue.type')}`,
        value: `${item.attributeKey}|${_.get(item, 'jsonValue.type')}`
    }));
}

export async function getPermissionApp():Promise<Array<any>> {
    const res = await http.request<Array<any>>({
        url: `${AppEntityModel.baseUrl}/permission/web/list`,
        method: 'GET'
    });
    return res;
}
