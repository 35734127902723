import { EntityStatus } from '@common-src/model/enum';

const entityStatusList: Array<{ name: string, value: EntityStatus }> = _.filter(_.map(EntityStatus, item => {
    let name: string = null;
    switch (item) {
        case EntityStatus.ENABLE:
            name = '启用';
            break;
        case EntityStatus.DISABLE:
            name = '未启用';
            break;
    }
    return { name, value: item };
}), item => item.name) as any;

const entityStatusFilter = (val: EntityStatus | boolean) => {
    if (typeof (val) === 'boolean') {
        val = `${val}` as EntityStatus;
    }
    return _.get(_.find(entityStatusList, item => item.value === val), 'name') || '-';
};

export {
    entityStatusFilter,
    entityStatusList
};
