import http from '@common-src/service3/http/axios';
import { BaseEntityModel } from '@common-src/model/base-model';
import { IResponseFilterData } from '@common-src/interface/reponse-data.interface';
import BaseFilterModel from '@common-src/model/base-filter.model';

/**
 * API的请求路径地图
 */
const CRUDQ_PATH_TYPE = {
    common: {
        create: '/add',
        update: '/update',
        delete: '',
        query: '/list'
    },
    database: {
        create: '/create',
        update: '/update',
        delete: '/delete',
        query: '/list'
    }
};

export default class BaseCRUDQService<ModelT extends BaseEntityModel, FilterModelT extends BaseFilterModel> {
    baseUrl: string = null;
    ModelType: { new (): ModelT };
    pathType: string; // 为了兼容不同的api，临时方案

    constructor(baseUrl: string, ModelType: { new (): ModelT }, pathType = 'common') {
        this.baseUrl = baseUrl;
        this.ModelType = ModelType;
        this.pathType = pathType;
    }

    /**
     * 获取url path
     * @param type create|update|delete|query
     * @returns
     */
    getUrlPath(type: string) {
        if (!CRUDQ_PATH_TYPE[this.pathType]?.[type]) {
            throw new Error(`There is no ${this.pathType}`);
        }
        return this.baseUrl + CRUDQ_PATH_TYPE[this.pathType][type];
    }

    async create(model: ModelT): Promise<ModelT> {
        const url = this.getUrlPath('create');
        const params = model?.toService?.() || model;
        const res = await http.request(
            {
                url,
                method: 'POST',
                params
            },
            { modelType: this.ModelType }
        );
        return res;
    }

    async retrieve(modelId: string): Promise<ModelT> {
        const url = `${this.baseUrl}/detail/${modelId}`;
        const res = await http.request(
            {
                url,
                method: 'GET'
            },
            { modelType: this.ModelType }
        );
        return res;
    }

    async update(model: ModelT):Promise<ModelT> {
        const url = this.getUrlPath('update');
        const params = model?.toService?.() || model;
        const res = await http.request(
            {
                url,
                method: 'POST',
                params
            },
            { modelType: this.ModelType }
        );
        return res;
    }

    async delete(model: ModelT): Promise<Boolean> {
        const url = `${this.baseUrl}/${model?.id || model}`;
        const res = await http.request(
            {
                url,
                method: 'DELETE'
            }
        );
        return res;
    }

    async query(filter?: FilterModelT, page?: number, pageSize?: number):Promise<IResponseFilterData<ModelT>> {
        const url = this.getUrlPath('query');
        const params = Object.assign({}, (filter as any)?.toService?.() || filter, { page, limit: pageSize });
        const res = await http.request<IResponseFilterData<ModelT>>(
            {
                url,
                method: 'POST',
                params
            },
            { modelType: this.ModelType }
        );
        return res;
    }
}
