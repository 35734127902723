import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel, FormControlUploadModel } from '@common-src/model/form-control';
import moment from 'moment';

export class DeviceShadowEntityModel extends BaseEntityModel {
    static baseUrl = `${IOT_BASE_REQUEST_PATH}/device`;

    @FormControl({
        label: '属性名称',
        type: FormControlType.TEXT,
        disabled: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '标识符',
        type: FormControlType.TEXT,
        disabled: true
    } as FormControlTextModel)
    identifier: string = undefined;

    @FormControl({
        label: '数据类型',
        type: FormControlType.TEXT,
        disabled: true
    } as FormControlTextModel)
    type: string = undefined;

    reportedval: any = undefined;
    reportedvalueDesc: string = undefined;
    reportedupdateTime: string = undefined;
    desiredval: any = undefined;
    desiredvalueDesc: string = undefined;
    desiredupdateTime: string = undefined;
    id: string = undefined;

    static getTableColumns() {
        const tableColumns = [
            {
                title: '属性名称',
                dataIndex: 'name'
            },
            {
                title: '标识符',
                dataIndex: 'identifier'
            },
            {
                title: '数据类型',
                dataIndex: 'type'
            },
            {
                title: '最新上报值',
                dataIndex: 'reportedvalueDesc'
            },
            {
                title: '上报时间',
                dataIndex: 'reportedupdateTime',
                customRender: (text, record, index) => {
                    return text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : undefined;
                }
            },
            {
                title: '期望值',
                dataIndex: 'desiredvalueDesc'
            },
            {
                title: '更新时间',
                dataIndex: 'desiredupdateTime',
                customRender: (text, record, index) => {
                    return text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : undefined;
                }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
        return tableColumns;
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}
