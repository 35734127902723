import { FormControl, FormControlType, FormControlModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { ThingsEntityModel } from './things-entity';
import { DataParamModel } from './data-param-entity';
import { ThingsEntityType, ThingsFunctionType } from '@common-src/model/enum';

export class ThingsMethodEntityModel extends ThingsEntityModel {
    static baseUrl = `${IOT_BASE_REQUEST_PATH}/product/method`;
    constructor(type?: ThingsEntityType, entityId?: string) {
        super(type, entityId);
        this.functionType = ThingsFunctionType.METHOD;
    }
    // @FormControl({
    //     label: '调用方式',
    //     type: FormControlType.RADIO_GROUP,
    //     options: [
    //         { name: '同步', value: 1 },
    //         { name: '异步', value: 2 }
    //     ],
    //     index: 3
    // } as FormControlOptionModel)
    // oneway: number = undefined;

    @FormControl({
        label: '输入参数',
        type: FormControlType.DATA_PARAM,
        index: 4
    } as FormControlModel<Array<DataParamModel>>)
    inputData: Array<DataParamModel> = undefined;

    @FormControl({
        label: '输出参数',
        type: FormControlType.DATA_PARAM,
        index: 5
    } as FormControlModel<Array<DataParamModel>>)
    outputData: Array<DataParamModel> = undefined;

    static getTableColumns() {
        return [
            {
                title: '服务名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '标识符',
                dataIndex: 'identifier'
            },
            // {
            //     title: '调用方式',
            //     dataIndex: 'oneway',
            //     customRender: (text, record, index) => {
            //         return text ? '异步' : '同步';
            //     }
            // },
            {
                title: '更新时间',
                dataIndex: 'modifiedTime'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        this.inputData = _.map(_.get(json, 'inputData'), item => new DataParamModel().toModel(item));
        this.outputData = _.map(_.get(json, 'outputData'), item => new DataParamModel().toModel(item));
        return this;
    }

    toService() {
        const data: any = super.toService();
        // data.type = ThingsEntityType.FUNCTION;
        data.type = this.entityType;
        delete data.entityType;
        data.productId = this.entityId;
        data.templateId = this.templateId || this.entityId;
        data.remark = this.remark;
        data.inputData = _.map(this.inputData, item => item.toService());
        data.outputData = _.map(this.outputData, item => item.toService());
        return data;
    }
}

export class ThingsMethodQueryModel extends QueryPageModel {
    name: string = undefined;

    toService() {
        const data = super.toService();
        data.params = this.name;
        delete data.name;
        return data;
    }
}
